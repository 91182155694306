import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import NewToxicologyOrderForm from './NewToxicologyOrderForm';
import SelectPatient from './SelectPatient';

import Loading from '../Loading';
import { useAuth } from '../auth';
import { createNotification } from '../Notification';
import { GET_ALL_CLINICS } from '../../constants/gql';
import * as ROUTES from '../../constants/routes';

export const CREATE_TOXICOLOGY_ORDER = gql`
  mutation CreateToxicologyOrder(
    $clinicCode: String!
    $patientForeignKey: String!
    $patientId: String!
    $patientFirstName: String!
    $patientLastName: String!
    $patientBirthMonth: String!
    $patientBirthDay: String!
    $patientBirthYear: String!
    $patientEmail: String!
    $patientPhone: String!
    $specimenId: String!
    $specimenType: String!
    $clinicalPurpose: String
    $medicationList: String!
    $medicationListTags: [String]
    $icd10Codes: String!
    $icd10CodeTags: [String]
    $providerId: String
    $providerName: String!
    $providerSuffix: String
    $providerNPI: String
    $toxicologyTests: [ToxicologyTestInput]!
    $customTests: String
    $pocTests: [POCTestInput]!
    $etgTests: [String]
    $testStrips: [TestStripInput]
    $urineValidationReporting: Boolean
    $specimenCollectionDate: String
  ) {
    createToxicologyOrder(
      data: {
        clinicCode: $clinicCode,
        patientForeignKey: $patientForeignKey,
        patientId: $patientId,
        patientFirstName: $patientFirstName,
        patientLastName: $patientLastName,
        patientBirthMonth: $patientBirthMonth,
        patientBirthDay: $patientBirthDay,
        patientBirthYear: $patientBirthYear,
        patientEmail: $patientEmail,
        patientPhone: $patientPhone,
        specimenId: $specimenId,
        specimenType: $specimenType,
        clinicalPurpose: $clinicalPurpose,
        medicationList: $medicationList,
        medicationListTags: $medicationListTags,
        icd10Codes: $icd10Codes,
        icd10CodeTags: $icd10CodeTags,
        providerId: $providerId,
        providerName: $providerName,
        providerSuffix: $providerSuffix,
        providerNPI: $providerNPI,
        toxicologyTests: $toxicologyTests,
        customTests: $customTests,
        pocTests: $pocTests,
        etgTests: $etgTests,
        testStrips: $testStrips,
        urineValidationReporting: $urineValidationReporting,
        specimenCollectionDate: $specimenCollectionDate
      }
    ) {
      id
      specimenId
    }
  }
`;

const NewToxicologyOrder = (props) => {
  const [selectedClinicCode, setSelectedClinicCode] = useState('');
  const [selectedPatient, setSelectedPatient] = useState({});
  const [showPatientSection, setShowPatientSection] = useState(true);
  const [clinics, setClinics] = useState([]);
  const [filterClinicsInput, setFilterClinicsInput] = useState('');
  const [filteredClinicsList, setFilteredClinicsList] = useState([]);
  const [hideClinicAndPatientSections, setHideClinicAndPatientSections] = useState(false);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  // Require auth
  useAuth({ messageType: "info", messageText: "Please log in" });

  // Set clinic and patient if passed in as props.
  //
  // This happens when the "Order Test" button is pressed on the
  // patient details page.
  useEffect(() => {
    if (props.location.state) {
      if ('patient' in props.location.state) {
        const { patient } = props.location.state;

        setSelectedClinicCode(patient.clinicCode);
        setSelectedPatient(patient);
        setHideClinicAndPatientSections(true);
      }
    }
  }, []);

  // Get clinics
  useQuery(
    GET_ALL_CLINICS,
    {
      // fetchPolicy: 'network-only',
      onCompleted({ allClinics }) {
        // console.log(allClinics);

        setClinics(allClinics);
        setFilteredClinicsList(allClinics);
        setLoading(false);
      },
      onError(error) {
        console.log('Get all clinics query', error);
        createNotification('danger', 'Sorry, could not retrieve clinics.');
      },
    }
  );

  const [createOrder, { loading: saving }] = useMutation(
    CREATE_TOXICOLOGY_ORDER,
    {
      onCompleted({ createToxicologyOrder }) {
        if (process.env.NODE_ENV === 'development') console.log(createToxicologyOrder);

        createNotification('info', `Toxicology order created with specimen ID: ${createToxicologyOrder.specimenId}`);

        // Go back or refresh page (new blank order)
        if (hideClinicAndPatientSections) {
          // Came from patient details page, go back
          history.goBack();
        } else {
          // Reload page to create new order
          history.go(0);
        }
      },
      onError(error) {
        console.log('Create toxicology order', error);
        createNotification('danger', 'Sorry, could not create toxicology order.');
      },
    }
  );

  const handleFilterClinics = (e) => {
    setFilterClinicsInput(e.target.value);

    const filtered = clinics.filter(({ clinicCode, clinicName }) => (
      clinicCode.toLowerCase().includes(e.target.value.toLowerCase()) ||
        clinicName.toLowerCase().includes(e.target.value.toLowerCase())
    ));

    setFilteredClinicsList(filtered);
  };

  const handleSelectPatient = (patient) => {
    // Set the selected patient
    setSelectedPatient(patient);

    // Hide patient section once selected
    setShowPatientSection(false);
  };


  const renderSelectClinic = () => {
    return (
      <div className="select">
        <select
          id="input-clinics"
          name="clinics"
          className="input"
          onChange={handleSelectClinic}
        >
          <option defaultValue value="-">--</option>
          {filteredClinicsList.map((clinic, i) => (
            <option key={i} value={clinic.clinicCode}>{clinic.clinicCode} -- {clinic.clinicName}</option>
            ))}
        </select>
      </div>
    );
  };

  const handleSelectClinic = (e) => {
    if (process.env.NODE_ENV === 'development') console.log('Selected clinic:', e.target.value);

    setSelectedClinicCode(e.target.value);
  };

  const generateToxicologyTests = (values) => {
    const tests = values.tests.map(({
      drugName,
      drugClass,
      drugBrand,
      pos,
      neg,
      scrn,
      result,
      cutoff,
      c_i,
      flag,
    }) => ({ drugName, drugClass, drugBrand, pos, neg, scrn, result, cutoff, c_i, flag }));

    // Remove urine validation testing if oral fluid specimen
    if (values.specimenType.toUpperCase() === 'ORAL FLUID') {
      const idx = _.findIndex(tests, { drugName: 'Urine Validation Testing'});

      tests[idx].pos = false;
    }

    return tests;
  };

  const handleFormSubmit = (values) => {
    // Handle test strips
    const testStrips = [];
    if (values.testStrips.length > 0) {
      values.testStrips.forEach((drug) => {
        const idx = _.findIndex(values.tests, ({ drugName }) => drugName.toUpperCase() === drug.drugName.toUpperCase());

        if (idx > -1) {
          const { cutoffs, drugBrand, drugClass, drugName, isActive, scrnAvailable, selected } = drug;

          if (selected) {
            testStrips.push({
              cutoffs: {
                urineDefault: cutoffs.urineDefault,
                urineCustom: cutoffs.urineCustom,
                oralFluidDefault: cutoffs.oralFluidDefault,
                oralFluidCustom: cutoffs.oralFluidCustom,
              },
              drugBrand,
              drugClass,
              drugName,
              isActive,
              scrnAvailable,
              scrn: false,
            });
          }

          // Remove from values.tests
          values.tests.splice(idx, 1);
        } else {
          const { cutoffs, drugBrand, drugClass, drugName, isActive, scrnAvailable, selected } = drug;

          if (selected) {
            testStrips.push({
              cutoffs: {
                urineDefault: cutoffs.urineDefault,
                urineCustom: cutoffs.urineCustom,
                oralFluidDefault: cutoffs.oralFluidDefault,
                oralFluidCustom: cutoffs.oralFluidCustom,
              },
              drugBrand,
              drugClass,
              drugName,
              isActive,
              scrnAvailable,
              scrn: false,
            });
          }
        }
      });
    }

    const data = {
      clinicCode: selectedClinicCode,
      patientForeignKey: selectedPatient.id,
      patientId: selectedPatient.patientId,
      patientFirstName: selectedPatient.firstName,
      patientLastName: selectedPatient.lastName,
      patientBirthMonth: selectedPatient.birthMonth,
      patientBirthDay: selectedPatient.birthDay,
      patientBirthYear: selectedPatient.birthYear,
      patientEmail: selectedPatient.email,
      patientPhone: selectedPatient.phone,
      specimenId: values.specimenId,
      specimenType: values.specimenType,
      clinicalPurpose: values.clinicalPurpose === "Other" ? values.clinicalPurposeOther : values.clinicalPurpose,
      icd10Codes: values.icd10Codes,
      icd10CodeTags: values.icd10CodeTags,
      medicationList: values.medicationList,
      medicationListTags: values.medicationListTags,
      providerId: values.providerId,
      providerName: values.providerName,
      providerSuffix: values.providerSuffix,
      providerNPI: values.providerNPI,
      urineValidationReporting: values.urineValidationReporting,
      pocTests: values.pocTests,
      etgTests: values.specimenType.toUpperCase() === 'ORAL FLUID' ? [] : values.etgTests,
      customTests: values.customTests,
      toxicologyTests: (values.tests.length > 0) && ('isActive' in values.tests[0]) && !_.isNull(values.tests[0].isActive) ? values.tests : generateToxicologyTests(values),
      testStrips,
      specimenCollectionDate: values.specimenCollectionDate,    // new Date().toISOString()
    };

    // Save mutation
    createOrder({ variables: data });
  };

  if (loading) return <Loading />;

  return (
    <section className="section">
      <div className="container">
        <div className="page">
          {/* <div className="column is-half-desktop"> */}
          <div className="column">
            <h3 className="title is-3">New Toxicology Order</h3>

            {!hideClinicAndPatientSections && (
              <>
                <h4 className="subtitle mt-2">Select a Clinic</h4>

                <div className="column is-half-desktop">
                  <div className="block">
                    <label className="label" htmlFor="input-filterClinics">
                      1) Filter
                    </label>
                    <input
                      id="input-filterClinics"
                      name="filterClinics"
                      className="input"
                      type="text"
                      placeholder="Clinic name or code"
                      value={filterClinicsInput}
                      onChange={handleFilterClinics}
                    />
                  </div>

                  <div className="block">
                    <label className="label" htmlFor="input-clinics">
                      2) Select Clinic
                    </label>
                    {renderSelectClinic()}
                  </div>
                </div>

                {selectedClinicCode.length > 0 && showPatientSection && (
                  <>
                    <hr />

                    <SelectPatient clinicCode={selectedClinicCode} handleSelectPatient={handleSelectPatient} />
                  </>
                )}

                {!showPatientSection && (
                  <div className="has-text-centered">
                    <hr />

                    <button
                      className="button is-info"
                      onClick={() => setShowPatientSection(true)}
                    >
                      Show Patient Section
                    </button>
                  </div>
                )}
              </>
            )}

            {selectedPatient && Object.keys(selectedPatient).length > 0 && (
              <>
                <hr />

                <NewToxicologyOrderForm
                  clinicCode={selectedClinicCode}
                  patient={selectedPatient}
                  handleFormSubmit={handleFormSubmit}
                  saving={saving}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewToxicologyOrder;
