import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { authUserVar } from '../../cache';
import * as ROUTES from '../../constants/routes';

const AUTH_USER_QUERY = gql`
  query AuthUser {
    authUser @client {
      id
      roles
    }
  }
`;

const SignOutButton = () => {
  const history = useHistory();

  const { client } = useQuery(AUTH_USER_QUERY);

  return (
    <button
      className="button is-ghost has-text-primary"

      type="button"
      onClick={() => {
        signOut();

        // Reset graphql store
        client.resetStore();

        // Redirect
        history.push(ROUTES.ROOT);
      }}
    >
      Sign Out
    </button>
  );
};

const signOut = () => {
  // Log out the user
  localStorage.removeItem('token');
  authUserVar(null);
};

export default SignOutButton;

export { signOut };
