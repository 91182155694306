import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import SpecimenRejectedNotesModal from '../ToxicologyOrder/SpecimenRejectedNotesModal';
import Loading from '../Loading';

import { createNotification } from '../Notification';
import { useAuth } from '../auth';
import * as ROUTES from '../../constants/routes';
import * as STATUS from '../../constants/status';

const POLL_INTERVAL = 2500;
const PAGINATION_LIMIT = 20;

export const COMPLETED_TOXICOLOGY_ORDERS = gql`
  query AllCompletedToxicologyOrders {
    allCompletedToxicologyOrders {
      id
      patientFirstName
      patientLastName
      patientId
      clinicCode
      specimenId
      specimenType
      clinicUserId
      radeasUserId
      createdDate
      resultsUploadedDate
      status
      rejectSpecimenComment
    }
  }
`;

export const PAGINATED_COMPLETED_TOXICOLOGY_ORDERS = gql`
  query PaginatedCompletedToxicologyOrders(
    $cursor: String,
    $limit: Int
  ) {
    paginatedCompletedToxicologyOrders(
      cursor: $cursor,
      limit: $limit
    ) {
      id
      patientFirstName
      patientLastName
      patientId
      clinicCode
      specimenId
      specimenType
      clinicUserId
      radeasUserId
      createdDate
      resultsUploadedDate
      status
      rejectSpecimenComment
    }
  }
`;

const CompletedClinicOrdersList = () => {
  const [cursor, setCursor] = useState('');
  const [orders, setOrders] = useState([]);
  const [showRejectedNotesModal, setShowRejectedNotesModal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [moreData, setMoreData] = useState(true);

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  // Get completed orders
//   const { startPolling, stopPolling } = useQuery(
//     COMPLETED_TOXICOLOGY_ORDERS,
//     {
//       fetchPolicy: 'network-only',
//       notifyOnNetworkStatusChange: true,
//       onCompleted({ allCompletedToxicologyOrders }) {
//         if (process.env.NODE_ENV === 'development') console.log('Query:', allCompletedToxicologyOrders);
//
//         // Sort by completed date, descending (newest first)
//         const orders = _.orderBy(allCompletedToxicologyOrders, ['resultsUploadedDate'], ['desc']);
//
//         setOrders(orders);
//         setLoading(false);
//       },
//       onError(error) {
//         console.log('Pending toxicology orders error:', error);
//         createNotification('danger', 'Sorry, something went wrong. Please try again.');
//
//         setLoading(false);
//       }
//     }
//   );

  // Get completed orders
  const { data, error, startPolling, stopPolling, fetchMore } = useQuery(
    PAGINATED_COMPLETED_TOXICOLOGY_ORDERS,
    {
      // fetchPolicy: 'network-only',
      // notifyOnNetworkStatusChange: true,
      variables: {
        cursor: cursor,
        limit: PAGINATION_LIMIT,
      },
    }
  );

  useEffect(() => {
    if (data) {
      const { paginatedCompletedToxicologyOrders } = data;

      if (process.env.NODE_ENV === 'development') {
        console.log('data:', data);
        console.log('Query:', paginatedCompletedToxicologyOrders);
      }

      // Sort by completed date, descending (newest first)
      // const orders = _.orderBy(paginatedCompletedToxicologyOrders, ['resultsUploadedDate'], ['desc']);
      const orders = _.cloneDeep(paginatedCompletedToxicologyOrders);

      setOrders(orders);
      setLoading(false);

      if (paginatedCompletedToxicologyOrders.length % PAGINATION_LIMIT !== 0) {
        setMoreData(false);
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      console.log('Completed toxicology orders error:', error);
      createNotification('danger', 'Sorry, something went wrong. Please try again.');

      setLoading(false);
    }
  }, [error]);

  // TODO: Disable polling for now until pagination is complete.
  //       Need to see how new orders will be merged with paginated results.

  // Start/stop polling
//   useEffect(() => {
//     if (!loading) {
//       startPolling(POLL_INTERVAL);
//
//       return () => stopPolling();
//     }
//   }, [loading]);
  // }, [startPolling, stopPolling]);

  const loadMore = () => {
    if (moreData) {
      const cursor = orders.at(-1).id;
      setCursor(cursor);

      if (process.env.NODE_ENV === 'development') {
        console.log('Loading more...');
        console.log(`Cursor: ${cursor}`);
      }

      fetchMore({
        variables: {
          cursor,
          length: PAGINATION_LIMIT,
        },
      });
    }
  };

  const handleShowRejectedNotesModal = (order) => {
    setShowRejectedNotesModal(order);
  };

  const handleCloseRejectedNotesModal = () => {
    setShowRejectedNotesModal(null);
  };

  if (loading) return <Loading />;

  return (
    <>
      <div>
        <div className="page">
          <h4 className="title is-4 is-inline-block mt-1">Completed Orders</h4>

          <br />

          {loading ? (
            <Loading />
          ) : (
            <>
              <div style={{ overflowX: "auto" }}>
                <table className="table is-fullwidth is-striped is-hoverable">
                  <thead>
                    <tr>
                      <th>Patient Name</th>
                      <th>Specimen ID</th>
                      <th>Clinic Code</th>
                      <th>Type</th>
                      <th>Created Date</th>
                      <th>Analyzed Date</th>
                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {orders.map((order) => (
                      <tr key={order.id}>
                        <td>{order.patientLastName}, {order.patientFirstName}</td>
                        <td>{order.specimenId}</td>
                        <td>{order.clinicCode}</td>
                        <td>{order.specimenType}</td>
                        <td>{new Date(parseInt(order.createdDate)).toDateString()}</td>
                        <td>{new Date(parseInt(order.resultsUploadedDate)).toDateString() === 'Invalid Date' ? 'N/A' : new Date(parseInt(order.resultsUploadedDate)).toDateString()}</td>
                        <td style={{textAlign: "center"}}>
                          {order.status === STATUS.TOXICOLOGY_ANALYST_COMPLETE ? (
                            <Link
                              to={ROUTES.DASHBOARD_ANALYST_VIEW_RESULTS.replace(':id', order.id)}
                              className="button is-info is-small"
                            >
                              View Result
                            </Link>
                          ) : (
                            <span
                              className="is-clickable is-size-7 has-text-danger"
                              data-tooltip="View analyst notes"
                              onClick={() => handleShowRejectedNotesModal(order)}
                            >
                              <span className="mr-1 icon is-small">
                                <i className="fas fa-info-circle" aria-hidden="true"/>
                              </span>
                              Specimen Rejected
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {moreData && (
                <div className="mt-6 has-text-centered">
                  <button
                    className="button is-info"
                    onClick={loadMore}
                    disabled={!moreData}
                  >
                    Load More
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <SpecimenRejectedNotesModal
        isOpen={!!showRejectedNotesModal}
        handleCloseModal={handleCloseRejectedNotesModal}
        specimenId={showRejectedNotesModal?.specimenId}
        message={showRejectedNotesModal?.rejectSpecimenComment}
      />
    </>
  );
};

export default CompletedClinicOrdersList;
