import React from 'react';

import './iconStyles.css';

const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" x="0px" y="0px" className="check">
      <g data-name="Check">
        <path d="M22.06,11.61a1.49,1.49,0,0,1,0,2.12l-6.67,6.66a1.45,1.45,0,0,1-1.06.44,1.48,1.48,0,0,1-1.06-.44L9.94,17.06a1.5,1.5,0,0,1,2.12-2.12l2.27,2.27,5.61-5.6A1.49,1.49,0,0,1,22.06,11.61ZM29.5,8V24A5.51,5.51,0,0,1,24,29.5H8A5.51,5.51,0,0,1,2.5,24V8A5.51,5.51,0,0,1,8,2.5H24A5.51,5.51,0,0,1,29.5,8Zm-3,0A2.5,2.5,0,0,0,24,5.5H8A2.5,2.5,0,0,0,5.5,8V24A2.5,2.5,0,0,0,8,26.5H24A2.5,2.5,0,0,0,26.5,24Z" />
      </g>
    </svg>
  );
};

export default CheckIcon;
