import React, { useEffect, useState } from 'react';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';

import Loading, { ButtonLoading } from '../Loading';
import { createNotification } from '../Notification';
import { GET_TOXICOLOGY_TEST_PROFILE_BY_CLINIC_CODE } from '../ToxicologyOrder/NewToxicologyOrderForm';
import { GET_TOX_TEST_DEFAULTS } from './DrugTestMenu';
import { mergeTestProfileWithDefaultTestProfile } from '../../utils';

export const GET_V2_TOXICOLOGY_TEST_PROFILE_BY_CLINIC_CODE = gql`
  query ToxicologyTestProfileByClinicCode(
    $clinicCode: String!
  ) {
    toxicologyTestProfileByClinicCode(
      clinicCode: $clinicCode
    ) {
      id
      clinicCode
      clinicShortCode
      etgTests
      physicianInfo {
        id
        physicianName
        physicianSuffix
      }
      clinicSettings {
        name
        value
      }
      toxicologyTests {
        drugName
        drugClass
        drugBrand
        isActive
        scrnAvailable
        parents
        cutoffs {
          urineDefault
          oralFluidDefault
          urineCustom
          oralFluidCustom
        }
      }
      customTests
    }
  }
`;

export const UPDATE_TOXICOLOGY_TESTS = gql`
  mutation UpdateToxicologyTestProfile(
    $clinicCode: String!
    $toxicologyTests: [ToxicologyTestInput]
  ) {
    updateToxicologyTestProfile(
      data: {
        clinicCode: $clinicCode,
        toxicologyTests: $toxicologyTests,
      }
    ) {
      id
      clinicCode
      toxicologyTests {
        drugName
        drugClass
        drugBrand
        isActive
        scrnAvailable
        parents
        cutoffs {
          urineDefault
          oralFluidDefault
          urineCustom
          oralFluidCustom
        }
      }
    }
  }
`;

const ClinicDrugTestMenu = ({ clinicInfo, clinicSettings }) => {
  const [drugTests, setDrugTests] = useState([]);
  const [drugTestDefaults, setDrugTestDefaults] = useState([]);
  const [toxicologyTestProfile, setToxicologyTestProfile] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [initializing, setInitializing] = useState(true);
  const [drugTestsMerged, setDrugTestsMerged] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [editCutoffs, setEditCutoffs] = useState(false);

  // Get test profile for clinic
  useEffect(() => {
    if (!_.isEmpty(clinicInfo)) {
      getToxicologyTestProfile();
    }
  }, [clinicInfo]);

  // Merge legacy test profile with default test profile
  useEffect(() => {
    if (!drugTestsMerged && drugTests.length > 0 && drugTestDefaults.length > 0) {
      if (process.env.NODE_ENV === 'development') {
        console.log('useEffect() Merging drugTests and drugTestDefaults...');
      }

      // Check if there's nothing to update
      if (toxicologyTestProfile.toxicologyTests.length > 0 && toxicologyTestProfile.toxicologyTests[0]?.parents) {
        setDrugTestsMerged(true);

        return;
      }

      const merged = mergeTestProfileWithDefaultTestProfile(toxicologyTestProfile, drugTestDefaults);

      // Save new toxicology test profile (mutation)
      const toxicologyTests = merged.map((drug) => ({
        drugClass: drug.drugClass,
        drugName: drug.drugName,
        drugBrand: drug.drugBrand,
        isActive: drug.isActive,
        scrnAvailable: drug.scrnAvailable,
        parents: drug.parents,
        cutoffs: {
          urineDefault: drug.cutoffs.urineDefault,
          urineCustom: drug.cutoffs.urineCustom,
          oralFluidDefault: drug.cutoffs.oralFluidDefault,
          oralFluidCustom: drug.cutoffs.oralFluidCustom,
        },
      }));

      // console.log(JSON.stringify(toxicologyTests));

      saveClinicTestProfile({
        variables: {
          clinicCode: clinicInfo.clinicCode,
          toxicologyTests,
        },
      });

      setDrugTests(merged);
      setDrugTestsMerged(true);
    }
  }, [toxicologyTestProfile, drugTestDefaults]);

  const [getToxicologyTestProfile, { loading: gettingToxicologyTestProfile }] = useLazyQuery(
    GET_TOXICOLOGY_TEST_PROFILE_BY_CLINIC_CODE,
    {
      variables: { clinicCode: clinicInfo.clinicCode },
      onCompleted({ toxicologyTestProfileByClinicCode }) {
        if (process.env.NODE_ENV === 'development') {
          console.log({ toxicologyTestProfileByClinicCode });
        }

        setToxicologyTestProfile(toxicologyTestProfileByClinicCode)
        setDrugTests(toxicologyTestProfileByClinicCode.toxicologyTests);
      },
      onError(error) {
        console.log('getToxicologyTestProfileByClinicCode', error);
        console.log('Trying v2 test profile...');

        getV2ToxicologyTestProfile();
      },
    },
  );

  const [getV2ToxicologyTestProfile, { loading: gettingV2ToxicologyTestProfile }] = useLazyQuery(
    GET_V2_TOXICOLOGY_TEST_PROFILE_BY_CLINIC_CODE,
    {
      variables: { clinicCode: clinicInfo.clinicCode },
      onCompleted({ toxicologyTestProfileByClinicCode }) {
        if (process.env.NODE_ENV === 'development') {
          console.log('getV2ToxicologyTestProfile()');
          console.log({ toxicologyTestProfileByClinicCode });
        }

        setToxicologyTestProfile(toxicologyTestProfileByClinicCode)
        setDrugTests(toxicologyTestProfileByClinicCode.toxicologyTests);
      },
      onError(error) {
        console.log('getV2ToxicologyTestProfileByClinicCode', error);
      },
    },
  );

  useQuery(
    GET_TOX_TEST_DEFAULTS,
    {
      onCompleted({ getToxicologyTestDefaults: data }) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Query getToxicologyTestDefaults');
          console.log({ data });
        }

        setDrugTestDefaults(addCheckboxState(data));

        // Set timeout to wait for getToxicologyTestProfile to fire
        setTimeout(() => {
          setInitializing(false);
        }, 250);
      },
      onError(error) {
        console.log('GetToxicologyTestDefaults query', error);
        createNotification('danger', 'Sorry, could not load drug test info.');

        setInitializing(false);
      }
    },
  );

  const [saveClinicTestProfile, { loading: savingClinicTestProfile }] = useMutation(
    UPDATE_TOXICOLOGY_TESTS,
    {
      onCompleted({ updateToxicologyTestProfile: data }) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Save complete');
          console.log('Mutation updateToxicologyTestProfile:', data);
        }

        console.log('setHasChanges(false)');
        setHasChanges(false);
        console.log('setEditCutoffs(false)');
        setEditCutoffs(false);
        console.log('setDrugTests()');
        setDrugTests(data.toxicologyTests);
        console.log('setToxicologyTestProfile()');
        setToxicologyTestProfile({
          ...toxicologyTestProfile,
          toxicologyTests: data.toxicologyTests,
        });
      },
      onError(error) {
        console.log('UpdateToxicologyTestProfile mutation', error);
        createNotification('danger', 'Sorry, there was a problem updating the Clinic test profile.');
      }
    }
  );

  const addCheckboxState = (data) => {
    return data.map((row) => ({
      ...row,
      isActive: true,
    }));
  };

  const handleClickEditCutoffs = () => {
    // Canceling edit cutoffs, reset cutoffs
    if (editCutoffs) {
      const reverted = drugTests.map((test) => {
        const idx = _.findIndex(toxicologyTestProfile.toxicologyTests, { drugName: test.drugName });

        if (idx > -1) {
          return { ...toxicologyTestProfile.toxicologyTests[idx] };
        }
      });

      setDrugTests(reverted);
      setHasChanges(false);
    }

    setEditCutoffs(!editCutoffs)
  };

  const handleCutoffsChange = (e, idx) => {
    const updated = _.cloneDeep(drugTests);
    const defaultCutoffs = drugTests[idx].cutoffs;

    const type = e.target.name.split('-')[0];
    const { value } = e.target;

    // Set to default cutoff
//     if (value.length === 0) {
//       if (type === 'urine') {
//         updated[idx].cutoffs.urineCustom = null;
//       } else if (type === 'oralFluid') {
//         updated[idx].cutoffs.oralFluidCustom = null;
//       }
//
//       setDrugTests(updated);
//       return;
//     }

    // Handle empty string (submit function converts this to null)
    if (value.length === 0  || parseFloat(value) === 0) {
      if (type === 'urine') {
        updated[idx].cutoffs.urineCustom = parseFloat(value) === 0 ? null : '';
      } else if (type === 'oralFluid') {
        updated[idx].cutoffs.oralFluidCustom = parseFloat(value) === 0 ? null : '';
      }

      setDrugTests(updated);
      console.log('setHasChanges(true)');
      setHasChanges(true);
      return;
    }

    if (isNaN(value) || isNaN(parseFloat(value))) {
      return;
    }

    // Check if same as default value
    if (type === 'urine') {
      if (parseFloat(value) === drugTests[idx].cutoffs.urineDefault) {
        updated[idx].cutoffs.urineCustom = null;

        setDrugTests(updated);
        console.log('setHasChanges(true)');
        setHasChanges(true);
        return;
      }
    } else if (type === 'oralFluid') {
      if (parseFloat(value) === drugTests[idx].cutoffs.oralFluidDefault) {
        updated[idx].cutoffs.oralFluidCustom = null;

        setDrugTests(updated);
        console.log('setHasChanges(true)');
        setHasChanges(true);
        return;
      }
    }

    if (type === 'urine') {
      updated[idx].cutoffs.urineCustom = parseFloat(value);
    } else if (type === 'oralFluid') {
      updated[idx].cutoffs.oralFluidCustom = parseFloat(value);
    }

    setDrugTests(updated);
    console.log('setHasChanges(true)');
    setHasChanges(true);
  };

  const handleSave = () => {
    const toxicologyTests = drugTests.map((drug) => ({
      drugName: drug.drugName,
      drugClass: drug.drugClass,
      drugBrand: drug.drugBrand,
      isActive: drug.isActive,
      scrnAvailable: drug.scrnAvailable,
      parents: drug.parents,
      cutoffs: {
        urineDefault: drug.cutoffs.urineDefault,
        oralFluidDefault: drug.cutoffs.oralFluidDefault,
        urineCustom: drug.cutoffs.urineCustom ? drug.cutoffs.urineCustom : null,
        oralFluidCustom: drug.cutoffs.oralFluidCustom ? drug.cutoffs.oralFluidCustom : null,
      },
    }));

    const variables = {
      clinicCode: clinicInfo.clinicCode,
      toxicologyTests,
    };

    saveClinicTestProfile({ variables });
  };

  const handleSelectAll = () => {
    let updatedState;

    if (selectAll) {
      // selectAll was just unchecked
      updatedState = drugTests.map((row) => ({
        ...row,
        isActive: false,
      }));
    } else {
      // selectAll was just checked
      updatedState = drugTests.map((row) => ({
        ...row,
        isActive: true,
      }));
    }

    setDrugTests(updatedState);
    setSelectAll(!selectAll);
    console.log('setHasChanges(true)');
    setHasChanges(true);
  };

  const handleChange = (idx) => {
    const updatedState = drugTests.map((row, i) => {
      if (i === idx) {
        return {
          ...row,
          isActive: !drugTests[i].isActive,
        };
      }

      return row;
    });

    // Update selectAll if necessary
    const selectAllUpdate = updatedState.filter((row) => !row.isActive);
    if (selectAllUpdate.length > 0 && selectAll) {
      setSelectAll(false);
    } else if (selectAllUpdate.length === 0 && !selectAll) {
      setSelectAll(true);
    }

    setDrugTests(updatedState);
    console.log('setHasChanges(true)');
    setHasChanges(true);
  };

  const handleResetCutoff = (i) => {
    if (drugTests[i].cutoffs.urineCustom || drugTests[i].cutoffs.oralFluidCustom) {
      const updated = _.cloneDeep(drugTests);

      updated[i].cutoffs.urineCustom = null;
      updated[i].cutoffs.oralFluidCustom = null;

      setDrugTests(updated);
      console.log('setHasChanges(true)');
      setHasChanges(true);
    }
  }

  const formatCutoff = (cutoffs, type, index) => {
    let cutoff;
    let custom = false;

    if (type === 'oralFluid') {
      if (cutoffs.oralFluidCustom || cutoffs.oralFluidCustom === '') {
        cutoff = cutoffs.oralFluidCustom;
        custom = true;
      } else {
        cutoff = cutoffs.oralFluidDefault;
      }
    } else {
      if (cutoffs.urineCustom || cutoffs.urineCustom === '') {
        cutoff = cutoffs.urineCustom;
        custom = true;
      } else {
        cutoff = cutoffs.urineDefault;
      }
    }

    if (editCutoffs) {
      return (
        <div
          className="has-tooltip-top"
          data-tooltip={type === 'oralFluid' ? `Default: ${cutoffs.oralFluidDefault}` : `Default: ${cutoffs.urineDefault}`}
        >
          <input
            name={`${type}-cutoff`}
            type="text"
            className="input"
            value={cutoff}
            onChange={(e) => handleCutoffsChange(e, index)}
          />
        </div>
      );
    }

    if (custom) {
      return <span className="has-text-warning">{cutoff}</span>;
    }

    return cutoff;
  };

  const generateTable = () => {
    return (
      drugTests.map((row, i) => {
        return (
          <tr key={i}>
            <td className={!row.isActive ? "has-text-danger" : ""}>
              <input
                type="checkbox"
                checked={row.isActive}
                onChange={() => handleChange(i)}
              />
            </td>
            <td className={!row.isActive ? "has-text-danger" : ""}>{row.drugClass}</td>
            <td className={!row.isActive ? "has-text-danger" : ""}>{row.parents && `${new Array(row.parents.length+1).join("\u00A0\u00A0\u00A0\u00A0")}${row.drugName}`}</td>
            <td className={!row.isActive ? "has-text-danger" : ""}>{row.drugBrand}</td>
            <td className={!row.isActive ? "has-text-danger" : ""}>{row.scrnAvailable ? "Available" : null}</td>
            <td className={!row.isActive ? "has-text-danger" : ""}>{formatCutoff(row.cutoffs, 'urine', i)}</td>
            <td className={!row.isActive ? "has-text-danger" : ""}>{formatCutoff(row.cutoffs, 'oralFluid', i)}</td>
            <td>
              {editCutoffs && (
                <span
                  className="icon has-text-white has-tooltip-left"
                  data-tooltip="Reset to default cutoffs"
                  onClick={() => handleResetCutoff(i)}
                >
                  <i
                    className="fas fa-undo"
                  />
                </span>
              )}
            </td>
          </tr>
        );
      })
    );
  };

  return (
    <div className="page">
      <h2 className="is-size-3 title mt-1">Available Tests</h2>

      {initializing || gettingToxicologyTestProfile || gettingV2ToxicologyTestProfile ? (
        <Loading />
      ) : (
        <div style={{ overflowX: "auto" }}>
          <div className="mb-6">
            <button
              className="button is-info is-pulled-left"
              onClick={handleSave}
              disabled={!hasChanges}
            >
              {savingClinicTestProfile ? "Saving..." : "Save"}
            </button>

            <button
              className="button is-info is-pulled-right"
              onClick={handleClickEditCutoffs}
              disabled={savingClinicTestProfile}
            >
              {editCutoffs ? "Cancel Edit" : "Edit Cutoffs"}
            </button>
          </div>

          {editCutoffs && (
            <div className="has-text-right">
              <p>Hint: To revert to default cutoff, leave blank or enter 0.</p>
            </div>
          )}

          <table className="table is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                  />
                </th>
                <th>Drug Class</th>
                <th>Drug Name</th>
                <th>Drug Brand</th>
                <th>Scrn</th>
                <th>Urine Cutoff</th>
                <th>Oral Fluid Cutoff</th>
                <th />
              </tr>
            </thead>

            <tbody>
              {generateTable()}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ClinicDrugTestMenu;
