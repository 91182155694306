import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import NewPatientForm from './NewPatientForm';

import Loading from '../Loading';
import { useAuth } from "../auth";
import { createNotification } from '../Notification';
import * as ROUTES from '../../constants/routes';
import { GET_ALL_CLINICS } from '../../constants/gql';

export const CREATE_PATIENT = gql`
  mutation CreatePatient(
    $patientId: String!
    $clinicCode: String!
    $firstName: String!
    $lastName: String!
    $middleName: String!
    $sex: String!
    $birthMonth: String!
    $birthDay: String!
    $birthYear: String!
    $email: String!
    $phone: String!
    $address1: String!
    $address2: String!
    $city: String!
    $state: String!
    $zipCode: String!
    $race: String!
    $maritalStatus: String!
    $responsiblePartyRelationship: String!
    $responsiblePartyFirstName: String!
    $responsiblePartyLastName: String!
    $responsiblePartyMiddleName: String!
    $responsiblePartyBirthMonth: String!
    $responsiblePartyBirthDay: String!
    $responsiblePartyBirthYear: String!
    $responsiblePartySex: String!
    $responsiblePartyAddress1: String!
    $responsiblePartyAddress2: String!
    $responsiblePartyCity: String!
    $responsiblePartyState: String!
    $responsiblePartyZipCode: String!
    $responsiblePartyPhone: String!
    $responsiblePartyEmail: String!
    $patientInsurance: String!
    $primaryInsuranceCompany: String!
    $primaryInsurancePolicyNumber: String!
    $primaryInsuranceGroupNumber: String!
    $primaryInsuranceSubscriberRelationship: String!
    $additionalInsurance: [AdditionalInsuranceInput]
  ) {
    createPatient(
      data: {
        patientId: $patientId
        clinicCode: $clinicCode
        firstName: $firstName
        lastName: $lastName
        middleName: $middleName
        sex: $sex
        birthMonth: $birthMonth
        birthDay: $birthDay
        birthYear: $birthYear
        email: $email
        phone: $phone
        address1: $address1
        address2: $address2
        city: $city
        state: $state
        zipCode: $zipCode
        race: $race
        maritalStatus: $maritalStatus
        responsiblePartyRelationship: $responsiblePartyRelationship
        responsiblePartyFirstName: $responsiblePartyFirstName
        responsiblePartyLastName: $responsiblePartyLastName
        responsiblePartyMiddleName: $responsiblePartyMiddleName
        responsiblePartyBirthMonth: $responsiblePartyBirthMonth
        responsiblePartyBirthDay: $responsiblePartyBirthDay
        responsiblePartyBirthYear: $responsiblePartyBirthYear
        responsiblePartySex: $responsiblePartySex
        responsiblePartyAddress1: $responsiblePartyAddress1
        responsiblePartyAddress2: $responsiblePartyAddress2
        responsiblePartyCity: $responsiblePartyCity
        responsiblePartyState: $responsiblePartyState
        responsiblePartyZipCode: $responsiblePartyZipCode
        responsiblePartyPhone: $responsiblePartyPhone
        responsiblePartyEmail: $responsiblePartyEmail
        patientInsurance: $patientInsurance
        primaryInsuranceCompany: $primaryInsuranceCompany
        primaryInsurancePolicyNumber: $primaryInsurancePolicyNumber
        primaryInsuranceGroupNumber: $primaryInsuranceGroupNumber
        primaryInsuranceSubscriberRelationship: $primaryInsuranceSubscriberRelationship
        additionalInsurance: $additionalInsurance
      }
    ) {
      id
      firstName
      lastName
    }
  }
`;

const NewPatient = () => {
  const [selectedClinicCode, setSelectedClinicCode] = useState('');
  const [clinics, setClinics] = useState([]);
  const [filterClinicsInput, setFilterClinicsInput] = useState('');
  const [filteredClinicsList, setFilteredClinicsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  // Require auth
  useAuth({ messageType: "info", messageText: "Please log in" });

  // Get clinics
  useQuery(
    GET_ALL_CLINICS,
    {
      onCompleted({ allClinics }) {
        // console.log(allClinics);

        setClinics(allClinics);
        setFilteredClinicsList(allClinics);
        setLoading(false);
      },
      onError(error) {
        console.log('Get all clinics query', error);
        createNotification('danger', 'Sorry, could not retrieve clinics.');
      },
    }
  );

  const [createPatient, { loading: saving }] = useMutation(
    CREATE_PATIENT,
    {
      onCompleted({ createPatient }) {
        if (process.env.NODE_ENV === 'development') console.log(createPatient);

        createNotification('info', `Patient ${createPatient.lastName}, ${createPatient.firstName} created.`);

        history.push(ROUTES.RADEAS_PATIENT_PAGE.replace(':id', createPatient.id));
      },
      onError(error) {
        console.log('Create patient', error);
        createNotification('danger', 'Sorry, could not create patient.');
      },
    }
  );

  const handleFilterClinics = (e) => {
    setFilterClinicsInput(e.target.value);

    const filtered = clinics.filter(({ clinicCode, clinicName }) => (
      clinicCode.toLowerCase().includes(e.target.value.toLowerCase()) ||
        clinicName.toLowerCase().includes(e.target.value.toLowerCase())
    ));

    setFilteredClinicsList(filtered);
  };

  const handleSelectClinic = (e) => {
    if (process.env.NODE_ENV === 'development') console.log('Selected clinic:', e.target.value);

    setSelectedClinicCode(e.target.value);
  };

  const renderSelectClinic = () => {
    return (
      <div className="select">
        <select
          id="input-clinics"
          name="clinics"
          className="input"
          onChange={handleSelectClinic}
        >
          <option defaultValue value="-">--</option>
          {filteredClinicsList.map((clinic, i) => (
            <option key={i} value={clinic.clinicCode}>{clinic.clinicCode} -- {clinic.clinicName}</option>
            ))}
        </select>
      </div>
    );
  };

  const handleFormSubmit = (values) => {
    const data = { ...values, clinicCode: selectedClinicCode };

    // Save mutation
    createPatient({ variables: data });
  };

  if (loading) return <Loading />;

  return (
    <section className="section">
      <div className="container">
        <div className="page">
          <div className="column is-half-desktop">
            <h3 className="title is-3">New Patient</h3>

            <h4 className="subtitle mt-2">Select a Clinic</h4>

            <div className="block">
              <label className="label" htmlFor="input-filterClinics">
                1) Filter
              </label>
              <input
                id="input-filterClinics"
                name="filterClinics"
                className="input"
                type="text"
                placeholder="Clinic name or code"
                value={filterClinicsInput}
                onChange={handleFilterClinics}
              />
            </div>

            <div className="block">
              <label className="label" htmlFor="input-clinics">
                2) Select Clinic
              </label>
              {renderSelectClinic()}
            </div>

            {selectedClinicCode.length > 0 && (
              <>
                <hr />

                <NewPatientForm handleFormSubmit={handleFormSubmit} saving={saving} />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewPatient;
