import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { notificationVar } from '../cache';
import { GET_NOTIFICATION } from '../constants/gql';

// const MESSAGE_TIMEOUT = 10000;
const MESSAGE_TIMEOUT = false;

const Notification = ({ messageText, messageType }) => {
  // Create state
  const [isVisible, setIsVisible] = useState(false);

  // Set notification visibility
  useEffect(() => {
    // if (process.env.REACT_APP_ENV === 'development') {
    //   console.log(`Notification - type: ${messageType}, text: ${messageText}`);
    // }

    setIsVisible(messageType && messageText ? true : false);
  }, [messageType, messageText]);

  // Set timeout for message to disappear
  useEffect(() => {
    if (MESSAGE_TIMEOUT) {
      if (isVisible) {
        setTimeout(() => {
          setIsVisible(false);
        }, MESSAGE_TIMEOUT);
      }
    }
  }, [isVisible]);

  if (isVisible) {
    return (
      <div
        className={`notification is-${messageType} has-text-centered`}
      >
        <button
          className="delete"
          onClick={() => {
            setIsVisible(false);
            clearNotification();
          }}
        />
        {messageText}
      </div>
    );
  } else {
    return (
      <div
        className={`notification is-${messageType} has-text-centered`}
        style={{ backgroundColor: "inherit" }}
      >
        <button
          className="delete"
          onClick={() => {
            setIsVisible(false);
            clearNotification();
          }}
          style={{ visibility: "hidden" }}
        />
        <span style={{ visibility: "hidden" }}>invisible</span>
      </div>
    );
  }
};

// Clear notification
const clearNotification = () => {
  notificationVar({});
};

// Create a new notification
const createNotification = (messageType, messageText) => {
  const notification = { messageType, messageText };

  // Save notification reactive variable
  notificationVar(notification);

  if (process.env.REACT_APP_ENV === 'development') {
    console.log(`Notification created: {${messageType}} - ${messageText}`);
  }

  return notification;
};

// Custom hook
const useNotification = () => {
  // Execute the query
  const { data } = useQuery(GET_NOTIFICATION);

  // Check if anything is returned
  if (data) {
    const { notification } = data;

    return notification;
  }

  return {};
};

export default Notification;

export { clearNotification, createNotification, useNotification };
