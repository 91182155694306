import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { getAlgoliaResults } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch';

import Account from './Account';
import Admin from './Admin';
import AnalystDashboard, { EnterToxicologyResults, ReviewToxicologyResults, ViewToxicologyResults } from './AnalystDashboard';
import ClinicPage from './Admin/ClinicPage';
import ClinicSuppliesLogistics from './ClinicSuppliesLogistics';
import CovidDashboard, { EnterCovidResults } from './CovidDashboard';
import DrugTestMenu from './Admin/DrugTestMenu';
import ForgotPassword from './auth/ForgotPassword';
import LabDashboard, { BatchDetails, SpecimenValidation } from './LabDashboard';
import LandingPage from './Landing';
// import Navigation from './Navigation';
import NewClinic from './Admin/NewClinic';
import ResetPassword from './auth/ResetPassword';
import Patient, { NewPatient } from './Patient';
import SideNavigation from './SideNavigation';
import SignIn from './auth/SignIn';
import SignUp from './auth/SignUp';
import TestMachines from './TestMachines';
import { NewToxicologyOrder, ToxicologyOrderDetails } from './ToxicologyOrder';
import Notification, { useNotification } from './Notification';

import { SearchBox, SearchItem } from './Search';

import * as ROUTES from '../constants/routes';

import '../styles/App.scss';

const appId = process.env.REACT_APP_ALGOLIA_APP_ID;
const apiKey = process.env.REACT_APP_ALGOLIA_API_KEY;
const searchClient = algoliasearch(appId, apiKey);

const App = () => {
  // Get notifications
  const { messageType, messageText } = useNotification();

  const getAuthUser = () => {
    // Look for auth token in localStorage (TODO: change to cookie)
    return localStorage.getItem('token');
  };

  const authUser = getAuthUser();

  return (
    <Router>
      {/* <Navigation /> */}
      <div className="columns">
        <SideNavigation />

        <div className="column">
          <Notification messageType={messageType} messageText={messageText} />

          {authUser && (
            <div className="column is-one-third ml-3">
              <SearchBox
                // openOnFocus={true}
                getSources={({ query })=> [
                  {
                    sourceId: 'primary',
                    getItems() {
                      const results = getAlgoliaResults({
                        searchClient,
                        queries: [{
                          indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
                          query,
                          params: {},
                        }],
                      });

                      return results;
                    },
                    templates: {
                      item({ item, components }) {
                        return <SearchItem hit={item} components={components} />;
                      },
                    },
                  },
                ]}
              />
            </div>
          )}

          <Switch>
            <Route exact path={ROUTES.ROOT} component={LandingPage} />

            <Route path={ROUTES.SIGN_UP} component={SignUp} />
            <Route path={ROUTES.SIGN_IN} component={SignIn} />
            <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
            <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />

            <Route path={ROUTES.ACCOUNT} component={Account} />
            <Route path={ROUTES.RADEAS_CLINIC_SUPPPLIES_AND_LOGISTICS} component={ClinicSuppliesLogistics} />
            <Route path={ROUTES.RADEAS_CLINIC_PAGE} component={ClinicPage} />

            <Route path={ROUTES.RADEAS_PATIENT_CREATE} component={NewPatient} />
            <Route path={ROUTES.RADEAS_PATIENT_PAGE} component={Patient} />

            <Route path={ROUTES.RADEAS_TOX_ORDER_CREATE} component={NewToxicologyOrder} />
            <Route path={ROUTES.RADEAS_TOX_ORDER_DETAILS} component={ToxicologyOrderDetails} />

            <Route path={ROUTES.RADEAS_ADMIN} component={(props) => (<Admin {...props} activeTab="radeas" />)} />
            <Route path={ROUTES.CLINICS_ADMIN} component={(props) => (<Admin {...props} activeTab="clinics" />)} />

            <Route path={ROUTES.DASHBOARD_ANALYST_NEW_ORDERS} component={(props) => (<AnalystDashboard {...props} activeTab="new_orders" />)} />
            <Route path={ROUTES.DASHBOARD_ANALYST_REVIEW_ORDERS} component={(props) => (<AnalystDashboard {...props} activeTab="review_orders" />)} />
            <Route path={ROUTES.DASHBOARD_ANALYST_COMPLETED_ORDERS} component={(props) => (<AnalystDashboard {...props} activeTab="completed_orders" />)} />
            <Route path={ROUTES.DASHBOARD_ANALYST_ENTER_RESULTS} component={EnterToxicologyResults} />
            <Route path={ROUTES.DASHBOARD_ANALYST_REVIEW_RESULTS} component={ReviewToxicologyResults} />
            <Route path={ROUTES.DASHBOARD_ANALYST_VIEW_RESULTS} component={ViewToxicologyResults} />

            <Route path={ROUTES.DASHBOARD_LAB_RERUN} component={(props) =>(<LabDashboard {...props} activeTab="re-run" />)} />
            <Route path={ROUTES.DASHBOARD_LAB_COMPLETE} component={(props) =>(<LabDashboard {...props} activeTab="complete" />)} />
            <Route path={ROUTES.DASHBOARD_LAB_ENTER_RESULTS} component={(props) => (<SpecimenValidation {...props} isReRun={false} />)} />
            <Route path={ROUTES.DASHBOARD_LAB_ENTER_NEW_RESULTS} component={(props) => (<SpecimenValidation {...props} isReRun={false} />)} />
            <Route path={ROUTES.DASHBOARD_LAB_ENTER_RERUN_RESULTS} component={(props) => (<SpecimenValidation {...props} isReRun={true} />)} />
            <Route path={ROUTES.DASHBOARD_LAB_SCANNED} component={(props) =>(<LabDashboard {...props} activeTab="scanned" />)} />
            <Route path={ROUTES.DASHBOARD_LAB_IN_TRANSIT} component={(props) =>(<LabDashboard {...props} activeTab="in-transit" />)} />
            <Route path={ROUTES.DASHBOARD_LAB_BATCHES} component={(props) =>(<LabDashboard {...props} activeTab="batched" />)} />
            <Route path={ROUTES.DASHBOARD_LAB_BATCH_DETAILS} component={BatchDetails} />

            <Route path={ROUTES.DASHBOARD_COVID_RESULTS} component={CovidDashboard} />
            <Route path={ROUTES.DASHBOARD_UPLOAD_COVID_RESULTS} component={EnterCovidResults} />

            <Route path={ROUTES.DASHBOARD_TEST_MACHINES} component={TestMachines} />
            <Route path={ROUTES.TEST_MENU} component={DrugTestMenu} />

            <Route path={ROUTES.ADD_CLINIC} component={NewClinic} />
          </Switch>
        </div>
      </div>
    </Router>
  );
};

export default App;
