import React from 'react';
import { useQuery } from '@apollo/client';
// import { useHistory, Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import RadeasUsersList from './RadeasUsersList';
import ClinicsList from './ClinicsList';
import Loading from '../Loading';

import { createNotification } from '../Notification';
import { AUTH_USER_QUERY } from '../../constants/gql';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

const AdminPage = ({ activeTab }) => {
  const history = useHistory();

  // Get auth user
  const { data, loading } = useQuery(AUTH_USER_QUERY);
  const { authUser } = data;

  // Require auth and admin
  if (!authUser || !authUser.roles.includes(ROLES.RADEAS_ADMIN)) {
    // Create notification and redirect
    createNotification('info', 'Please log in');
    history.push(ROUTES.SIGN_IN);
  }

  if (loading) return <Loading />;

  return (

    <section className="section">
      <div className="container">
        <h3 className="title is-3">User Management</h3>

        {/*<div className="tabs is-boxed">*/}
        {/*  <ul>*/}
        {/*    <li className={activeTab === "radeas" ? "is-active" : null}>*/}
        {/*      <Link to={ROUTES.RADEAS_ADMIN}>*/}
        {/*        <span className="icon is-small"><i className="fas fa-flask" aria-hidden="true" /></span>*/}
        {/*        <span>Radeas</span>*/}
        {/*      </Link>*/}
        {/*    </li>*/}
        {/*    <li className={activeTab === "clinics" ? "is-active" : null}>*/}
        {/*      <Link to={ROUTES.CLINICS_ADMIN}>*/}
        {/*        <span className="icon is-small"><i className="fas fa-clinic-medical" aria-hidden="true" /></span>*/}
        {/*        <span>Clinics</span>*/}
        {/*      </Link>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</div>*/}

        {activeTab === 'radeas' ? <RadeasUsersList /> : <ClinicsList />}
      </div>
    </section>
  );
}

export default AdminPage;
