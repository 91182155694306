import React, { useEffect, useRef, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import _ from 'lodash';

import CurrentBatch from './CurrentBatch';
import ScannedSamples from './ScannedSamples';
import ToDo from './ToDo';

import Loading from '../Loading';
import { createNotification } from '../Notification';
import { useAuth } from '../auth';
import { SET_VALIDATION } from '../../constants/gql';

export const ACCESSIONED_SPECIMENS = gql`
  query AccessionedSpecimensByLabUser {
    accessionedSpecimensByLabUser {
      id
      clinicCode
      clinicName
      specimenId
      batchIds
      batches {
        id
        batchId
        testPanel
      }
      specimenType
      specimenIsValid
      specimenReceivedLabDate
      specimenAnomaly
      specimenLabNotes
      specimenPH
      specimenSpecificGravity
      specimenCreatinine
      additionalTests {
        name
        result
      }
      toxicologyTests {
        drugName
        pos
      }
      etgTests
    }
  }
`;

export const SET_ADDITIONAL_TESTS_RESULT = gql`
  mutation UpdateToxicologyOrder(
    $id: String!,
    $additionalTests: [AdditionalTestInput],
  ) {
    updateToxicologyOrder(
      id: $id,
      data: {
        additionalTests: $additionalTests,
      }
    ) {
      id
      clinicCode
      clinicName
      specimenId
      batchIds
      batches {
        id
        batchId
        testPanel
      }
      specimenType
      specimenIsValid
      specimenReceivedLabDate
      specimenAnomaly
      specimenLabNotes
      specimenPH
      specimenSpecificGravity
      specimenCreatinine
      additionalTests {
        name
        result
      }
      toxicologyTests {
        drugName
        pos
      }
      etgTests
    }
  }
`;

export const GENERATE_BATCH = gql`
  mutation CreateBatch(
    $machineId: String!
    $machineName: String!
    $testPanel: String!
    $specimenIds: [String]!
    $orderIds: [String]!
  ) {
    createBatch(
      data: {
        machineId: $machineId,
        machineName: $machineName,
        testPanel: $testPanel,
        specimenIds: $specimenIds,
        orderIds: $orderIds,
      }
    ) {
      id
      batchId
      testPanel
      createdDate
    }
  }
`;

// TODO
// Bug: If specimen clicked on, then click on "fill batch", first
// specimen is added twice.

const ReceivedSpecimenList = () => {
  const [specimens, setSpecimens] = useState([]);
  const [filterClinicCode, setFilterClinicCode] = useState([]);
  const [selectedTestPanel, setSelectedTestPanel] = useState('');
  const [testMachine, setTestMachine] = useState({});
  const [batchSize, setBatchSize] = useState(0);
  const [currentBatch, setCurrentBatch] = useState([]);
  const [currentBatchOrderIds, setCurrentBatchOrderIds] = useState([]);
  const [batchId, setBatchId] = useState('');
  const [fileDownloadUrl, setFileDownloadUrl] = useState('');
  const [labelsDownloadUrl, setLabelsDownloadUrl] = useState('');
  const [loading, setLoading] = useState(true);

  const linkRef = useRef();
  const labelRef = useRef();

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  useEffect(() => {
    if (fileDownloadUrl !== '') {
      linkRef.current.click();
      URL.revokeObjectURL(fileDownloadUrl);

      setFileDownloadUrl('');
    }
  }, [fileDownloadUrl]);

  useEffect(() => {
    if (labelsDownloadUrl !== '') {
      labelRef.current.click();
      URL.revokeObjectURL(labelsDownloadUrl);

      setLabelsDownloadUrl('');
    }
  }, [labelsDownloadUrl]);

  useQuery(
    ACCESSIONED_SPECIMENS,
    {
      // fetchPolicy: 'network-only',
      onCompleted({ accessionedSpecimensByLabUser }) {
        if (process.env.NODE_ENV === 'development') console.log('Query AccessionedSpecimensByLabUser:', accessionedSpecimensByLabUser);

        const orders = accessionedSpecimensByLabUser.reduce((result, specimen) => {
          if (specimen.batchIds.length > 0) {
            const skip = checkOrderComplete(specimen);

            if (skip) return result;
          }

          // Generate testPanels array
          const testPanels = checkTestPanels(specimen);

          result.push({
            ...specimen,
            batchSelected: false,
            testPanels,
          });

          return result;
        }, []);

        setSpecimens(orders);
        setLoading(false);
      },
      onError(error) {
        console.log('accessionedSpecimensByLabUser', error);
        createNotification('danger', 'Sorry, could not retrieve scanned samples list.');

        setLoading(false);
      },
    }
  );

  const [specimenValidation, { loading: uploading }] = useMutation(
    SET_VALIDATION,
    {
      onCompleted({ setToxicologyLabResults }) {
        if (process.env.NODE_ENV === 'development') console.log('Set Validation:', setToxicologyLabResults);

        // Generate testPanels array
        const testPanels = checkTestPanels(setToxicologyLabResults);

        updateSpecimenList({
          ...setToxicologyLabResults,
          batchSelected: false,
          testPanels,
        });
      },
      onError(error) {
        console.log('setToxicologyLabResults', error);
        createNotification('danger', 'Sorry, could not set validation.');
      },
    }
  );

  const [generateBatch, { loading: generatingBatch} ] = useMutation(
    GENERATE_BATCH,
    {
      onCompleted({ createBatch }) {
        if (process.env.NODE_ENV === 'development') console.log('Create Batch:', createBatch);

        setBatchId(createBatch.batchId);

        // Generate specimen ID CSV file
        const outputString = generateOutputString(currentBatch, createBatch.testPanel);
        const blob = new Blob([outputString]);

        // Generate labels CSV file
        const labelsOutputString = generateLabelsOutputString(currentBatch, createBatch.testPanel);
        const labelsBlob = new Blob([labelsOutputString]);

        setFileDownloadUrl(URL.createObjectURL(blob));
        setLabelsDownloadUrl(URL.createObjectURL(labelsBlob));
      },
      onError(error) {
        console.log('createBatch', error);
        createNotification('danger', 'Sorry, could not create batch.');
      },
    }
  );

  const [setAdditionalTests] = useMutation(
    SET_ADDITIONAL_TESTS_RESULT,
    {
      onCompleted({ updateToxicologyOrder }) {
        if (process.env.NODE_ENV === 'development') console.log('Set Additional Test Results:', updateToxicologyOrder);

        // Generate testPanels array
        const testPanels = checkTestPanels(updateToxicologyOrder);

        updateSpecimenList({
          ...updateToxicologyOrder,
          batchSelected: false,
          testPanels,
        });
      },
      onError(error) {
        console.log('updateToxicologyOrder', error);
        createNotification('danger', 'Sorry, could not set test results.');
      },
    }
  );

  const checkTestPanels = (specimen) => {
    const testPanels = [];

    // Check PAIN panel
    if (specimen.toxicologyTests.length > 0) {
      if (specimen.batches.length > 0) {
        const i = _.findIndex(specimen.batches, { testPanel: 'PAIN' });

        // Pain batch found
        if (i > -1) {
          testPanels.push('PAIN-BATCHED');
        } else {
          testPanels.push('PAIN');
        }
      } else {
        // No pain batch found
        testPanels.push('PAIN');
      }
    }

    // Check ETG panel
    if (specimen.etgTests.length > 0) {
      if (specimen.batches.length > 0) {
        const j = _.findIndex(specimen.batches, { testPanel: 'ETG' });

        // ETG batch found
        if (j > -1) {
          testPanels.push('ETG-BATCHED');
        } else {
          testPanels.push('ETG');
        }
      } else {
        // No ETG batch found
        testPanels.push('ETG');
      }
    }

    return testPanels;
  };

  const checkOrderComplete = (specimen) => {
    const result = [];

    // Check additional tests
    if (specimen.additionalTests.length > 0) {
      specimen.additionalTests.forEach((test) => {
        if (test.result === null) {
          result.push(test.name);
        }
      });
    }

    // Check specimen validation
    const urineValidation = _.find(specimen.toxicologyTests, { drugName: 'Urine Validation Testing' });

    if (urineValidation && urineValidation.pos) {
      if (specimen.specimenPH.length <= 0) {
        result.push('specimenPH');
      }

      if (specimen.specimenCreatinine.length <= 0) {
        result.push('specimenCreatinine');
      }

      if (specimen.specimenSpecificGravity.length <= 0) {
        result.push('specimenSpecificGravity');
      }
    }

    // Check pain batch
    if (specimen.toxicologyTests.length > 0) {
      if (specimen.batches.length > 0) {
        const i = _.findIndex(specimen.batches, { testPanel: 'PAIN' });

        // Pain batch found
        if (i === -1) {
          result.push('PAIN');
        }
      }
    }

    // Check ETG batch
    if (specimen.etgTests.length > 0) {
      if (specimen.batches.length > 0) {
        const j = _.findIndex(specimen.batches, { testPanel: 'ETG' });

        // ETG batch found
        if (j === -1) {
          result.push('ETG');
        }
      }
    }

    return result.length === 0;
  };

  const generateOutputString = (batch, panel) => {
    let output = '';

    batch.forEach((specimenId) => output += `${specimenId.toUpperCase()}--${panel.toUpperCase()}\n`);

    return output;
  };

  const generateLabelsOutputString = (batch, panel) => {
    let output = 'location,specimenId\n';

    batch.forEach((specimenId, i) => {
      const index = `${i+1}`.padStart(4, '0');

      output += `"${index}",${specimenId.toUpperCase()}--${panel.toUpperCase()}\n`;
    });

    return output;
  };

  const updateSpecimenList = (specimen) => {
    const updated = _.cloneDeep(specimens);
    const index = _.findIndex(specimens, { id: specimen.id });

    updated.splice(index, 1, specimen);

    setSpecimens(updated);
  };

  const handleValidation = (id, specimenId, target) => {
    const variables = {
      isReRun: false,
      specimenId: specimenId,
      specimenIsValid: !!(target === 'check'),
    };

    specimenValidation({ variables });
  };

  const handleAdditionalTests = (id, results) => {
    const testResults = [];

    for (const test in results) {
      testResults.push({
        name: test,
        result: results[test],
      });
    }

    const variables = {
      id,
      additionalTests: testResults,
    };

    // Execute mutation
    setAdditionalTests({ variables });
  };

  const handleToDoClick = (clinicCodeList) => {
    setFilterClinicCode(clinicCodeList);
  };

  const handleSetBatchSize = (size) => {
    setBatchSize(size);
  };

  const handleSetTestPanel = (testPanel) => {
    setSelectedTestPanel(testPanel.toUpperCase());
  };

  const handleSetTestMachine = (machine) => {
    setTestMachine(machine);
  };

  const handleGenerateBatch = () => {
    const panels =  selectedTestPanel.split('::');

    panels.forEach((panel) => {
      const variables = {
        machineId: testMachine.id,
        machineName: testMachine.name,
        testPanel: panel,
        specimenIds: currentBatch,
        orderIds: currentBatchOrderIds,
      };

      generateBatch({ variables });
    });

//     const variables = {
//       machineId: testMachine.id,
//       machineName: testMachine.name,
//       testPanel: selectedTestPanel,
//       specimenIds: currentBatch,
//       orderIds: currentBatchOrderIds,
//     };
//
//     generateBatch({ variables });
  };

  const handleBatchComplete = (complete=false) => {
    if (complete) {
      const updated = _.cloneDeep(specimens);

      currentBatchOrderIds.forEach((id) => {
        const index = _.findIndex(specimens, { id });

        const incomplete = specimens[index].additionalTests.filter((test) => (
          test.result === null
        ));

        if (incomplete.length > 0) {
          const i = specimens[index].testPanels.indexOf(selectedTestPanel);

          if (i > -1) {
            updated[index].testPanels[i] = selectedTestPanel + '-BATCHED';
            updated[index].batchSelected = false;
          }
        } else {
          updated.splice(index, 1);
        }
      });

      setSpecimens(updated);
      setBatchId('');
      setCurrentBatch([]);
      setCurrentBatchOrderIds([]);
    }
  };

  const handleBatchCheckbox = (specimen) => {
    // Don't add if it will overflow batchSize
    if (!specimen.batchSelected) {
      if (currentBatch.length >= batchSize) return;
    }

    const index = _.findIndex(specimens, { id: specimen.id });
    const updated = _.cloneDeep(specimens);

    updated[index] = {
      ...updated[index],
      batchSelected: !updated[index].batchSelected,
    };

    // Add/remove from currentBatch
    const newBatch = [ ...currentBatch ];
    const newBatchIds = [ ...currentBatchOrderIds ];

    if (currentBatch.includes(specimen.specimenId)) {
      const i = newBatch.indexOf(specimen.specimenId);

      if (i > -1) {
        newBatch.splice(i, 1);
        newBatchIds.splice(i, 1);
      }
    } else {
      newBatch.push(specimen.specimenId);
      newBatchIds.push(specimen.id);
    }

    setSpecimens(updated);
    setCurrentBatch(newBatch);
    setCurrentBatchOrderIds(newBatchIds);
  };

  const handleFillBatch = (empty=false) => {
    if (empty) {
      // Uncheck selected specimens
      clearBatch();

      return;
    }

    let candidates = [];
    const batch = [ ...currentBatch ];
    const batchIds = [ ...currentBatchOrderIds ];
    const updated = _.cloneDeep(specimens);

    // Filter candidates if necessary
    if (filterClinicCode.length > 0) {
      candidates = specimens.filter((specimen) => filterClinicCode.includes(specimen.clinicCode));
    } else {
      candidates = [ ...specimens ];
    }

    candidates
      .filter((specimen) => {
        const panels = selectedTestPanel.split('::');

        if (specimen.testPanels.length > 1 && panels.length > 1) {
          const active = specimen.testPanels.sort().every((e, i) => e === panels.sort()[i]);

          if (active)
            return true;
          else
            return false;
        }

        return specimen.testPanels.includes(selectedTestPanel);
      })
      .filter((specimen) => (specimen.specimenIsValid !== null))
      .slice(0, batchSize - batch.length)
      .forEach((specimen) => {
        const index = _.findIndex(specimens, { id: specimen.id });

        updated[index] = {
          ...updated[index],
          batchSelected: true,
        };

        batch.push(specimen.specimenId);
        batchIds.push(specimen.id);
      });

    setSpecimens(updated);
    setCurrentBatch(batch);
    setCurrentBatchOrderIds(batchIds);
  };

  const clearBatch = () => {
    const updated = _.cloneDeep(specimens);

    specimens.filter((specimen) => specimen.batchSelected)
      .forEach((specimen) => {
        const index = _.findIndex(specimens, { id: specimen.id });

        updated[index] = {
          ...updated[index],
          batchSelected: false,
        };
      });

    setSpecimens(updated);
    setCurrentBatch([]);
    setCurrentBatchOrderIds([]);
  };

  if (loading) return <Loading />;

  return (
    <>
      <div className="columns">
        <div className="column is-7-desktop">
          <CurrentBatch
            handleSetBatchSize={handleSetBatchSize}
            currentBatch={currentBatch}
            handleSetTestPanel={handleSetTestPanel}
            handleSetTestMachine={handleSetTestMachine}
            handleGenerateBatch={handleGenerateBatch}
            handleBatchComplete={handleBatchComplete}
            generatingBatch={generatingBatch}
            batchId={batchId}
          />
        </div>

        <div className="column">
          <ToDo specimens={specimens} handleClick={handleToDoClick} />
        </div>
      </div>

      <ScannedSamples
        specimens={specimens}
        batchSize={batchSize}
        testPanel={selectedTestPanel}
        handleValidation={handleValidation}
        handleAdditionalTests={handleAdditionalTests}
        handleBatchCheckbox={handleBatchCheckbox}
        handleFillBatch={handleFillBatch}
        isReRun={false}
        filterClinicCode={filterClinicCode}
      />

      <a
        style={{ display: "none" }}
        download={process.env.REACT_APP_SERVER_ENV === 'dev' ? `${batchId}-${testMachine.name?.toUpperCase()}_dev.csv` : `${batchId}-${testMachine.name?.toUpperCase()}.csv`}
        href={fileDownloadUrl}
        ref={linkRef}
      />

      <a
        style={{ display: "none" }}
        download={process.env.REACT_APP_SERVER_ENV === 'dev' ? `Labels-${batchId}-${testMachine.name?.toUpperCase()}_dev.csv` : `Labels-${batchId}-${testMachine.name?.toUpperCase()}.csv`}
        href={labelsDownloadUrl}
        ref={labelRef}
      />
    </>
  );
};

export default ReceivedSpecimenList;
