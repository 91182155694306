import React from 'react';

import { formatDate } from './utils';

const UploadedFiles = (props) => {
  const renderTable = () => {
    return (
      props.reports.map((row, i) => (
        <tr key={i}>
          <td>
            <a href={`${process.env.REACT_APP_COVID_BATCH_FILE_DOWNLOAD_URL}/${row.filename}`}>
              {row.filename}
            </a>
          </td>
          <td>{formatDate(row.upload_date)}</td>
          <td>{row.sent}</td>
          <td>{row.not_sent}</td>
        </tr>
      ))
    );
  };

  return (
    <div className="page px-5 py-5">
      <h2 className="title is-5">Uploaded Files</h2>

      <div style={{ overflowX: "auto" }}>
        <table className="table is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th>File Name</th>
              <th>Upload Date</th>
              <th>Processed</th>
              <th><span className="icon-text">
                <span>Unprocessed</span>
                <span
                  className="icon is-clickable has-tooltip-left"
                  data-tooltip="QC etc, unknown sample ID, no patient record"
                >
                  <i className="fas fa-question-circle" />
                </span>
              </span></th>
            </tr>
          </thead>

          <tbody>
            {renderTable()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

UploadedFiles.defaultProps = {
  reports: [],
};

export default UploadedFiles;
