export const TOXICOLOGY_REPORT_FLAGS = [
  "Illicit drug detected",
  "Illicit drug detected, D-Isomer detected",
  "Possible contaminant of Amphetamine",
  "Indicated med not detected",
  "Indicated med not detected, metabolite detected",
  "Non-indicated med detected",
  "Non-indicated substance detected",
  "Nicotine metabolite detected",
  "Placental hormone detected",
  "Large amounts of medication present without metabolites. May indicate direct addition of drug into sample",
];
