import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import * as ROUTES from '../../constants/routes';

const BATCH_RESULT = 'BATCH_RESULT  ';
const SINGLE_RESULT = 'SINGLE_RESULT';

const SendResults = (props) => {
  const [active, setActive] = useState(BATCH_RESULT);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadingFile, setUploadingFile] = useState(false);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = () => {
    console.log('handleFileUpload()');

    const formData = new FormData();
    formData.append('file', selectedFile, selectedFile.name);

    setUploadingFile(true);

    const headers = {
      'x-api-key': process.env.REACT_APP_COVID_RESULT_UPLOAD_API_KEY,
    };

    axios.post(process.env.REACT_APP_COVID_BATCH_RESULTS_UPLOAD_URL, formData)
      .then((res) => {
        console.log(res);

        setUploadingFile(false);
        setSelectedFile(null);
      })
      .catch((error) => {
        console.log('CSV File Upload', error);

        setUploadingFile(false);
      });
  };

  return (
    <div>
      <h2 className="title is-5">Send Results</h2>

      <div className="page">
        <div className="columns">
          <div className="column is-half has-text-centered my-5">
            <p style={{ fontSize: "0.6rem" }}>Batch Result</p>

            <button
              className="button is-large"
              style={active === BATCH_RESULT ? { color: "#9EF6CF", backgroundColor: "#48C78E" } : { color: "#536287", backgroundColor: "#333B52", border: 0 }}
              onClick={() => setActive(BATCH_RESULT)}
            >
              <span className="icon is-medium">
                <i
                  className="fas fa-vials fa-2x"
                />
              </span>
            </button>
          </div>

          <div className="column is-half has-text-centered my-5">
            <p style={{ fontSize: "0.6rem" }}>Single Result</p>

            <Link to={ROUTES.DASHBOARD_UPLOAD_COVID_RESULTS}>
              <button
                className="button is-large"
                style={active === SINGLE_RESULT ? { color: "#9EF6CF", backgroundColor: "#48C78E" } : { color: "#536287", backgroundColor: "#333B52", border: 0 }}
                onClick={() => setActive(SINGLE_RESULT)}
              >
                <span className="icon is-medium">
                  <i
                    className="fas fa-vial fa-2x"
                  />
                </span>
              </button>
            </Link>
          </div>
        </div>

        <div className="has-text-centered px-5">
          <h5 className="title is-6 mb-2">Upload CSV File</h5>

          {!selectedFile && (
            <div className="file is-info is-centered">
              <label className="file-label">
                <input
                  name="file"
                  className="file-input"
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                  disabled={active !== BATCH_RESULT}
                />

                <span className="file-cta">
                  <span className="file-label">
                    Choose File
                  </span>
                </span>
              </label>
            </div>
          )}

          {selectedFile && (
            <div className="">
              <button
                className="button is-primary is-fullwidth"
                onClick={handleFileUpload}
                disabled={active !== BATCH_RESULT || uploadingFile}
              >
                Upload {selectedFile.name}
              </button>
            </div>
          )}

          <br />

          <a
            href={process.env.REACT_APP_COVID_RESULT_TEMPLATE_URL}
            className="has-text-white is-size-7"
          >
            <span className="icon">
              <i className="fas fa-download" />
            </span>
            {" "}
            Batch Results File Template
          </a>
        </div>
      </div>
    </div>
  );
};

export default SendResults;
