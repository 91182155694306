import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import SpecimenValidationModal from './SpecimenValidationModal';
import ReturnSpecimenInTransitModal from './ReturnSpecimenInTransitModal';

import * as ROUTES from '../../constants/routes';

import CrossIcon from './assets/CrossIcon';
import CheckIcon from './assets/CheckIcon';

import './scannedSamples.css';

const colors = {
  cobalt: '#536287',
  blue: '#66ADE8',
  green: '#00D1B2',
  red: '#F14668',
  yellow: '#FFE08A',
}

// TODO
// 1. Reconcile batch checkbox with testPanel if testPanel is changed.
//    (i.e. uncheck row if new testPanel is not in checked order)


const ScannedSamples = ({ specimens, testPanel, batchSize, handleValidation, handleAdditionalTests, handleBatchCheckbox, handleFillBatch, isReRun, filterClinicCode }) => {
  const [testStripsActive, setTestStripsActive] = useState({});
  const [fillBatch, setFillBatch] = useState(false);

  // Process specimens
  useEffect(() => {
    const active = {};

    specimens.forEach((specimen) => {
      const results = {};
      specimen.additionalTests.forEach((test) => {
        results[test.name.toUpperCase()] = test.result ? test.result : null;
      });

      active[specimen.id] = {
        active: false,
        results: specimen.additionalTests.length > 0 ? results : null,
      };
    });

    setTestStripsActive(active);
  }, [specimens]);

  const checkUrineValidationRequired = (tests) => {
    const index = _.findIndex(tests, {
      drugName: 'Urine Validation Testing',
      pos: true,
    });

    return !!(index >= 0);
  };

  const handleValidationClick = ({ id, specimenId }, target) => {
    handleValidation(id, specimenId, target);
  };

  const handleResultsButton = (id, submit = false) => {
    setTestStripsActive({
      ...testStripsActive,
      [id]: {
        ...testStripsActive[id],
        active: !testStripsActive[id].active,
      },
    });

    if (submit) {
      const results = {};

      for (const test in testStripsActive[id].results) {
        let name = test.toLowerCase();
        name = name.charAt(0).toUpperCase() + name.slice(1);

        results[name] = testStripsActive[id].results[test];
      }

      handleAdditionalTests(id, results);
    }
  };

  const handleStripTest = (id, e) => {
    const name = e.target.name.split("-")[1].toUpperCase();
    const value = e.target.value;

    setTestStripsActive({
      ...testStripsActive,
      [id]: {
        ...testStripsActive[id],
        results: {
          ...testStripsActive[id].results,
          [name]: value,
        },
      },
    });
  };

  const handleStripTestClick = (id, e) => {
    const name = e.target.name.split("-")[1].toUpperCase();
    const value = e.target.value;

    if (testStripsActive[id].results[name] === value) {
      setTestStripsActive({
        ...testStripsActive,
        [id]: {
          ...testStripsActive[id],
          results: {
            ...testStripsActive[id].results,
            [name]: null,
          },
        },
      });
    }
  };

  const renderCheckbox = (specimen) => {
    const borderWidth = '12px';

    // Determine if checkbox is active or not
    let active = true;
    const a = [ ...specimen.testPanels ].sort();      // Specimen panels from order
    const b = testPanel.split('::').sort();           // Selected panel from "Current Batch" component

    if (a.length === 1) {
      if (a.includes(b[0])) {
        active = true;
      } else {
        active = false;
      }
    } else if (a.length === b.length) {
      active = a.every((e, i) => e === b[i]);
    }

    switch (specimen.specimenIsValid) {
      case true:
        return (
          <td style={{ borderLeft: `${borderWidth} solid ${colors.green}` }}>
            <input
              type="checkbox"
              checked={specimen.batchSelected}
              onChange={() => handleBatchCheckbox(specimen)}
              disabled={!active}
            />
          </td>
        );
      case false:
        return (
          <td style={{ borderLeft: `${borderWidth} solid ${colors.red}` }}>
            <input
              type="checkbox"
              checked={specimen.batchSelected}
              onChange={() => handleBatchCheckbox(specimen)}
              disabled={!active}
            />
          </td>
        );
      case null:
        return (
            <td style={{ borderLeft: `${borderWidth} solid ${colors.cobalt}` }}>
              <input
                type="checkbox"
                checked={specimen.batchSelected}
                onChange={() => handleBatchCheckbox(specimen)}
                disabled
              />
            </td>
          );
      default:
        return (
          <td style={{ borderLeft: `${borderWidth} solid ${colors.cobalt}` }}>
            <input
              type="checkbox"
              checked={specimen.batchSelected}
              onChange={() => handleBatchCheckbox(specimen)}
            />
          </td>
        );
    }
  };

  const renderValidation = (specimen, type) => {
    if (type === 'buttons') {
      return (
        <>
          <div
            style={{ float: "left" }}
            onClick={() => handleValidationClick(specimen, 'check')}
            className="ml-1"
          >
            <CheckIcon fillColor={colors.green} />
          </div>
          <div
            style={{ float: "right"}}
            onClick={() => handleValidationClick(specimen, 'cross')}
            className="mr-1"
          >
            <CrossIcon fillColor={colors.red} />
          </div>
        </>
      );
    } else if (type === 'edit') {
      const validationDataRequired = checkUrineValidationRequired(specimen.toxicologyTests);
      const validationMissing = !!(validationDataRequired
        && specimen.specimenPH.length === 0
        && specimen.specimenSpecificGravity.length === 0
        && specimen.specimenCreatinine.length === 0
      );

      if (specimen.specimenIsValid) {
        return (
          <span style={{ fontSize: "0.9rem" }}>
            <i className="fas fa-check mt-1 mr-2 is-pulled-left ml-2" style={{ color: colors.green }} />
            Valid
            <span
              className="has-tooltip-top is-pulled-right mr-2"
              data-tooltip={validationDataRequired ? "[Required] Add/Edit Validation Data" : "Add/Edit Validation Data"}
            >
              {/* <i className="far fa-edit ml-2 edit" style={validationMissing ? { color: colors.red } : null} /> */}
              <SpecimenValidationModal
                specimen={specimen}
                validationMissing={validationMissing}
                colors={colors}
                isReRun={false}
              />
            </span>
          </span>
        );
      } else {
        return (
          <span style={{ fontSize: "0.9rem" }}>
            <i className="fas fa-times mt-1 mr-2 is-pulled-left ml-2" style={{ color: colors.red }} />
            Invalid
            <span
              className="has-tooltip-top is-pulled-right mr-2"
              data-tooltip={validationDataRequired ? "[Required] Add/Edit Validation Data" : "Add/Edit Validation Data"}
            >
              {/* <i className="far fa-edit ml-2 edit" style={validationMissing ? { color: colors.red } : null} /> */}
              <SpecimenValidationModal
                specimen={specimen}
                validationMissing={validationMissing}
                colors={colors}
                isReRun={isReRun}
              />
            </span>
          </span>
        );
      }
    }
  };

  const renderTestPanels = (specimen) => {
    return (
      <>
        {specimen.hasOwnProperty("etgTests") && specimen.etgTests.length > 0 ?
          specimen.testPanels && specimen.testPanels.includes('ETG-BATCHED') ?
            <td className="has-text-centered"><i className="fas fa-hourglass-half"/></td>
            :
            <td className="has-text-centered"><input type="radio" disabled /></td>
          :
          <td />
        }
        {specimen.hasOwnProperty("toxicologyTests") && specimen.toxicologyTests.length > 0 ?
          specimen.testPanels && specimen.testPanels.includes('PAIN-BATCHED') ?
            <td className="has-text-centered"><i className="fas fa-hourglass-half"/></td>
            :
            <td className="has-text-centered"><input type="radio" disabled /></td>
          :
          <td />
        }
      </>
    );
  };

  const renderStripTests = (specimen) => {
    const tests = specimen.additionalTests.map((test) => test.name.toUpperCase());

    if (testStripsActive[specimen.id] && testStripsActive[specimen.id].active && testStripsActive[specimen.id].results !== null) {
      return (
        <>
          <td className="has-text-centered px-0" style={{ width: "36px" }}>
            <span style={{ display: "block", borderBottom: "1px solid #C7C7C7" }}>
              <input
                type="radio"
                value="Not Detected"
                name={`${specimen.id}-barbiturates`}
                checked={testStripsActive[specimen.id].results["BARBITURATES"] === "Not Detected"}
                onChange={(e) => handleStripTest(specimen.id, e)}
                onClick={(e) => handleStripTestClick(specimen.id, e)}
                className={!tests.includes("BARBITURATES") ? "is-invisible" : null}
                disabled={!tests.includes("BARBITURATES")}
              />
            </span>
            <span style={{ display: "block" }}>
              <input
                type="radio"
                value="Detected"
                name={`${specimen.id}-barbiturates`}
                checked={testStripsActive[specimen.id].results["BARBITURATES"] === "Detected"}
                onChange={(e) => handleStripTest(specimen.id, e)}
                onClick={(e) => handleStripTestClick(specimen.id, e)}
                className={!tests.includes("BARBITURATES") ? "is-invisible" : null}
                disabled={!tests.includes("BARBITURATES")}
              />
            </span>
          </td>
          <td className="has-text-centered px-0" style={{ width: "36px" }}>
            <span style={{ display: "block", borderBottom: "1px solid #C7C7C7" }}>
              <input
                type="radio"
                value="Not Detected"
                name={`${specimen.id}-cocaine`}
                checked={testStripsActive[specimen.id].results["COCAINE"] === "Not Detected"}
                onChange={(e) => handleStripTest(specimen.id, e)}
                onClick={(e) => handleStripTestClick(specimen.id, e)}
                className={!tests.includes("COCAINE") ? "is-invisible" : null}
                disabled={!tests.includes("COCAINE")}
              />
            </span>
            <span style={{ display: "block" }}>
              <input
                type="radio"
                value="Detected"
                name={`${specimen.id}-cocaine`}
                checked={testStripsActive[specimen.id].results["COCAINE"] === "Detected"}
                onChange={(e) => handleStripTest(specimen.id, e)}
                onClick={(e) => handleStripTestClick(specimen.id, e)}
                className={!tests.includes("COCAINE") ? "is-invisible" : null}
                disabled={!tests.includes("COCAINE")}
              />
            </span>
          </td>
          <td className="has-text-centered px-0" style={{ width: "36px" }}>
            <span style={{ display: "block", borderBottom: "1px solid #C7C7C7" }}>
              <input
                type="radio"
                value="Not Detected"
                name={`${specimen.id}-methamphetamine`}
                checked={testStripsActive[specimen.id].results["METHAMPHETAMINE"] === "Not Detected"}
                onChange={(e) => handleStripTest(specimen.id, e)}
                onClick={(e) => handleStripTestClick(specimen.id, e)}
                className={!tests.includes("METHAMPHETAMINE") ? "is-invisible" : null}
                disabled={!tests.includes("METHAMPHETAMINE")}
              />
            </span>
            <span style={{ display: "block" }}>
              <input
                type="radio"
                value="Detected"
                name={`${specimen.id}-methamphetamine`}
                checked={testStripsActive[specimen.id].results["METHAMPHETAMINE"] === "Detected"}
                onChange={(e) => handleStripTest(specimen.id, e)}
                onClick={(e) => handleStripTestClick(specimen.id, e)}
                className={!tests.includes("METHAMPHETAMINE") ? "is-invisible" : null}
                disabled={!tests.includes("METHAMPHETAMINE")}
              />
            </span>
          </td>
          <td className="has-text-centered px-0" style={{ width: "36px" }}>
            <span style={{ display: "block", borderBottom: "1px solid #C7C7C7" }}>
              <input
                type="radio"
                value="Not Detected"
                name={`${specimen.id}-pregnancy`}
                checked={testStripsActive[specimen.id].results["PREGNANCY"] === "Not Detected"}
                onChange={(e) => handleStripTest(specimen.id, e)}
                onClick={(e) => handleStripTestClick(specimen.id, e)}
                className={!tests.includes("PREGNANCY") ? "is-invisible" : null}
                disabled={!tests.includes("PREGNANCY")}
              />
            </span>
            <span style={{ display: "block" }}>
              <input
                type="radio"
                value="Detected"
                name={`${specimen.id}-pregnancy`}
                checked={testStripsActive[specimen.id].results["PREGNANCY"] === "Detected"}
                onChange={(e) => handleStripTest(specimen.id, e)}
                onClick={(e) => handleStripTestClick(specimen.id, e)}
                className={!tests.includes("PREGNANCY") ? "is-invisible" : null}
                disabled={!tests.includes("PREGNANCY")}
              />
            </span>
          </td>
        </>
      );
    }

    return (
      <>
        <td className="has-text-centered" style={{ width: "36px" }}>
          {renderTestStripIcon(specimen, tests, 'BARBITURATES')}
        </td>
        <td className="has-text-centered" style={{ width: "36px" }}>
          {renderTestStripIcon(specimen, tests, 'COCAINE')}
        </td>
        <td className="has-text-centered" style={{ width: "36px" }}>
          {renderTestStripIcon(specimen, tests, 'METHAMPHETAMINE')}
        </td>
        <td className="has-text-centered" style={{ width: "36px" }}>
          {renderTestStripIcon(specimen, tests, 'PREGNANCY')}
        </td>
      </>
    );
  };

  const renderTestStripIcon = (specimen, tests, name) => {
    if (tests.includes(name)) {
      if (testStripsActive[specimen.id]?.results[name] === null) {
        return (
          <input
            type="radio"
            disabled
          />
        );
      } else if (testStripsActive[specimen.id]?.results[name] === 'Detected') {
        return (
          <span
            className="has-tooltip-top"
            data-tooltip="Detected"
          >
            <i className="fas fa-plus" style={{ color: colors.green }} />
          </span>
        );
      } else if (testStripsActive[specimen.id]?.results[name] === 'Not Detected') {
        return (
          <span
            className="has-tooltip-top"
            data-tooltip="Not Detected"
          >
            <i className="fas fa-minus" style={{ color: colors.red }} />
          </span>
        );
      }
    }

    return null;
  };

  const renderResultsButton = (specimen) => {
    if ((specimen.specimenIsValid === true || specimen.specimenIsValid === false) && specimen.additionalTests.length > 0 && !testStripsActive[specimen.id]?.active) {
      return (
        <button
          // className="button is-small is-info is-outlined is-fullwidth"
          className="button is-small is-info is-fullwidth"
          onClick={() => handleResultsButton(specimen.id)}
        >
          Enter Results
        </button>
      );
    } else if ((specimen.specimenIsValid === true || specimen.specimenIsValid === false) && testStripsActive[specimen.id]?.active) {
      return (
        <button
          className="button is-small is-info is-fullwidth"
          onClick={() => handleResultsButton(specimen.id, true)}
        >
          Done
        </button>
      );
    } else {
      return (
        <button className="button is-small is-info is-outlined is-fullwidth" disabled>
          Enter Results
        </button>
      );
    }
  };

  const populateBatch = () => {
    if (batchSize < 1) {
      return;
    }

    if (!fillBatch) {
      handleFillBatch();
    } else {
      handleFillBatch(true);
    }

    setFillBatch(!fillBatch);
  };

  return (
    <div
      className="page px-0 pb-3 pt-5"
      style={{ borderRadius: "10px" }}
    >
      <div className="mx-5">
        <h4 className="title is-4">Scanned Samples</h4>
      </div>

      <div className="mt-5" style={{ overflowX: "auto" }}>
        <table className="table is-fullwidth is-striped is-hoverable">
          <thead>
            <tr>
              <th style={{ borderBottom: "none" }} />
              <th style={{ borderBottom: "none" }} />
              <th style={{ borderBottom: "none" }} />
              <th style={{ borderBottom: "none" }} />
              <th style={{ borderBottom: "none" }} />
              <th style={{ borderBottom: "none" }} />
              <th style={{ borderBottom: "none" }} />
              <th style={{ borderBottom: "none" }} />
              <th
                colSpan="2"
                className="has-text-centered"
                style={{ borderBottom: "1px solid #C7C7C7", fontSize: "0.8rem" }}
              >
                Test Panels
              </th>
              <th style={{ borderBottom: "none" }} />
              <th
                colSpan="4"
                className="has-text-centered"
                style={{ borderBottom: "1px solid #C7C7C7", fontSize: "0.8rem" }}
              >
                {/* Indicators */}
                Test Strips
              </th>
              <th style={{ borderBottom: "none" }} />
              <th style={{ borderBottom: "none" }} />
            </tr>

            <tr>
              <th>
                <span
                  className="has-tooltip-right"
                  data-tooltip={!fillBatch ? "Fill batch" : "Empty batch"}
                >
                  <input
                    style={{ marginLeft: "6px" }}
                    type="checkbox"
                    checked={fillBatch}
                    onChange={populateBatch}
                  />
                </span>
              </th>
              <th>Clinic</th>
              <th>Specimen ID</th>
              <th>Received Date</th>
              <th>Type</th>
              <th />
              <th className="has-text-centered">Validation</th>
              <th />
              <th className="has-text-centered">ETG</th>
              <th className="has-text-centered">Pain</th>
              <th />
              <th className="has-text-centered">
                <span
                  className="has-tooltip-top"
                  data-tooltip="Barbiturates"
                >
                  B
                </span>
              </th>
              <th className="has-text-centered">
                <span
                  className="has-tooltip-top"
                  data-tooltip="Cocaine"
                >
                  C
                </span>
              </th>
              <th className="has-text-centered">
                <span
                  className="has-tooltip-top"
                  data-tooltip="Methamphetamine"
                >
                  M
                </span>
              </th>
              <th className="has-text-centered">
                <span
                  className="has-tooltip-top"
                  data-tooltip="Pregnancy"
                >
                  P
                </span>
              </th>
              <th className="has-text-centered">Results</th>
              <th />
            </tr>
          </thead>

          <tbody>
            {specimens &&
              specimens.filter((specimen) => {
                if (filterClinicCode.length > 0) {
                  return filterClinicCode.includes(specimen.clinicCode);
                } else {
                  return true;
                }
              }).map((specimen) => (
              <tr key={specimen.id} style={{ height: "70px" }}>
                {renderCheckbox(specimen)}
                <td>{specimen.clinicName}</td>
                <td>
                  <ReturnSpecimenInTransitModal specimenId={specimen.specimenId} />
                </td>
                <td>{new Date(parseInt(specimen.specimenReceivedLabDate)).toDateString()}</td>
                <td
                  className="has-text-centered"
                  style={specimen.specimenType.toLowerCase() === 'urine' ?
                    { color: colors.yellow }
                    :
                    { color: colors.blue }
                  }
                >
                  {specimen.specimenType.split(' ')[0]}
                </td>
                <td />
                <td  className="has-text-centered" style={{ width: "128px" }}>
                  {specimen.specimenIsValid === null ? renderValidation(specimen, 'buttons') : renderValidation(specimen, 'edit')}
                </td>
                <td />
                {renderTestPanels(specimen)}
                <td className="has-text-right pr-0" style={{ fontSize: "0.8rem" }}>
                  <span
                    className={testStripsActive[specimen.id] && !testStripsActive[specimen.id].active ? "is-invisible" : null}
                    style={{ display: "block", borderBottom: "1px solid #C7C7C7" }}
                  >
                    Not Detected
                  </span>
                  <span
                    className={testStripsActive[specimen.id] && !testStripsActive[specimen.id].active ? "is-invisible" : null}
                    style={{ display: "block" }}
                  >
                    Detected
                  </span>
                </td>
                {renderStripTests(specimen)}
                <td className="has-text-centered">{renderResultsButton(specimen)}</td>
                <td>
                  <Link
                    to={ROUTES.RADEAS_TOX_ORDER_DETAILS.replace(':id', specimen.id)}
                    className="icon has-text-info"
                  >
                    <i className="fas fa-info-circle" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScannedSamples;
