// Firestore collection names
export const CLINICS = 'Clinics';
export const CLINIC_USERS = 'Clinic-Users';
export const TEST_PANEL_DEFAULTS = 'Test-Panel-Defaults';
export const ICD_10_CODES = 'ICD-10-Codes';
export const PASSWORD_RESET_TOKENS = 'Password-Reset-Tokens';
export const PATIENTS = 'Patients';
export const RADEAS_USERS = 'Radeas-Users';
export const SIGNATURES = 'Signatures';
export const TEST_BATCHES = 'Test-Batches';
export const TEST_MACHINES = 'Test-Machines';
export const TOXICOLOGY_ORDERS = 'Toxicology-Orders';
export const TOXICOLOGY_TEST_PROFILES = 'Toxicology-Test-Profiles';
export const TOXICOLOGY_DRUG_METABOLITES = 'Toxicology-Drug-Metabolites';
export const TOXICOLOGY_DRUG_NAMES = 'Toxicology-Drug-Names';

export const DB_TRACKING = 'DB-Changes';
