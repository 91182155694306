import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';

import { ButtonLoading } from '../Loading';

import '../../styles/modal.css';

const DELAY = 100;    // Delay in ms

const CreateBatchModal = ({ batchId, machine, batchSize, currentBatchSize, handleConfirm, handleBatchComplete, loading, disabled }) => {
  const [submitted, setSubmitted] = useState(false);
  const [complete, setComplete] = useState(false);

  let closeModal;
  const history = useHistory();

  useEffect(() => {
    if (loading && !submitted) {            // Submitted
      setSubmitted(true);
    }
    // } else if (!loading && submitted) {     // Batch creation complete
    //   setSubmitted(false);
    //   setComplete(true);
    // }
  }, [loading]);

  useEffect(() => {
    if (!loading && submitted && batchId !== '') {    // Batch creation complete
      setSubmitted(false);
      setComplete(true);
    }
  }, [batchId]);

  const renderContent = () => {
    if (!complete) {
      return (
        <>
          <div className="content">
            {currentBatchSize < batchSize ? (
              <div>
                <p className="has-text-weight-semibold has-text-danger">
                  Note: Batch for {machine.name} is not full, {currentBatchSize} / {batchSize} specimens selected.
                </p>

                <p className="has-text-weight-semibold">
                  Continue with batch creation?
                </p>
              </div>
            ) : (
              <div>
                <p className="has-text-weight-semibold">
                  Create batch on {machine.name} with {currentBatchSize} / {batchSize} specimens selected?
                </p>
              </div>
            )}
          </div>

          <button
            className="button is-primary mb-2"
            onClick={handleConfirm}
          >
            Create Batch
          </button>
          {loading && <ButtonLoading />}

          <button
            className="button is-danger is-pulled-right"
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
          >
            Cancel
          </button>
        </>
      );
    } else {
      return (
        <>
          <div className="content">
            <div>
              <p className="has-text-weight-semibold">
                Batch ID: {batchId}
              </p>
            </div>
          </div>

          <button
            className="button is-primary mb-2"
            onClick={(e) => {
              e.preventDefault();

              closeModal();
            }}
          >
            Close
          </button>
        </>
      );
    }
  };

  return (
    <Popup
      trigger={
        <button
          className="button is-info mt-4"
          disabled={disabled}
        >
          Create Batch
        </button>
      }
      onClose={() => {
        handleBatchComplete(complete);

        // Reset state
        setSubmitted(false);
        setComplete(false);

        if (batchId.length > 0) {
          // Refresh page
          history.go(0);
        }
      }}
      modal
    >
      {(close) => {
        closeModal = close;
        return (
          <div>
            <span className="has-text-white is-pulled-right">
              <i className="fas fa-times fa-lg icon-link" onClick={close} />
            </span>

            <h4 className="title is-4">
              Create Batch
            </h4>

            {renderContent()}
          </div>
        );
      }}
    </Popup>
  );
};

export default CreateBatchModal;
