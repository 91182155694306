import React from 'react';
import ReactDOM from 'react-dom';

import { CookiesProvider } from 'react-cookie';

import { gql, createHttpLink, ApolloClient, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { cache } from './cache';

import App from './components/App';

// Local reactive variables (state)
export const typeDefs = gql`
  extend type Query {
    authUser: AuthUser!,
    notification: Notification!
  }

  type AuthUser {
    id: String!,
    roles: [String]!
  }

  type Notification {
    messageType: String,
    messageText: String
  }
`;

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Initialize ApolloClient
const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink),
  typeDefs,
});

// const client = new ApolloClient({
//   cache,
//   uri: process.env.REACT_APP_GRAPHQL_URI,
//   headers: {
//     authorization: `Bearer ${localStorage.getItem('token')}` || '',
//   },
//   typeDefs,
// });

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
