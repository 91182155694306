import React from 'react';
// import { Link } from 'react-router-dom';

import NewClinicOrdersList, { SET_TOXICOLOGY_ORDER_STATUS } from './NewClinicOrdersList';
import ReviewClinicOrdersList from './ReviewClinicOrdersList';
import CompletedClinicOrdersList from './CompletedClinicOrdersList';
import EnterToxicologyResults from './EnterToxicologyResults';
import ReviewToxicologyResults from './ReviewToxicologyResults';
import ViewToxicologyResults from './ViewToxicologyResults';

import { useAuth } from '../auth';
// import * as ROUTES from '../../constants/routes';

const Dashboard = ({ activeTab }) => {
  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  const renderTab = (tab) => {
    switch (tab) {
      case 'new_orders':
        return <NewClinicOrdersList />;
      case 'review_orders':
        return <ReviewClinicOrdersList />;
      case 'completed_orders':
        return <CompletedClinicOrdersList />;
      default:
        return <NewClinicOrdersList />;
    }
  };

  return (
    <section className="section">
      <div className="container">
        <h3 className="title is-3">Analyst Dashboard</h3>

        {/*<div className="tabs is-boxed mt-6">*/}
        {/*  <ul>*/}
        {/*    <li className={activeTab === "new_orders" ? "is-active" : null}>*/}
        {/*      <Link to={ROUTES.DASHBOARD_ANALYST_NEW_ORDERS}>*/}
        {/*        <span className="icon is-small"><i className="fas fa-clipboard-list" aria-hidden="true"></i></span>*/}
        {/*        <span>Pending Orders</span>*/}
        {/*      </Link>*/}
        {/*    </li>*/}

        {/*    <li className={activeTab === "review_orders" ? "is-active" : null}>*/}
        {/*      <Link to={ROUTES.DASHBOARD_ANALYST_REVIEW_ORDERS}>*/}
        {/*        <span className="icon is-small"><i className="fas fa-clipboard-list" aria-hidden="true"></i></span>*/}
        {/*        <span>Review Orders</span>*/}
        {/*      </Link>*/}
        {/*    </li>*/}

        {/*    <li className={activeTab === "completed_orders" ? "is-active" : null}>*/}
        {/*      <Link to={ROUTES.DASHBOARD_ANALYST_COMPLETED_ORDERS}>*/}
        {/*        <span className="icon is-small"><i className="fas fa-clipboard-check" aria-hidden="true"></i></span>*/}
        {/*        <span>Completed Orders</span>*/}
        {/*      </Link>*/}
        {/*    </li>*/}
        {/*  </ul>*/}
        {/*</div>*/}

        {renderTab(activeTab)}
      </div>
    </section>
  );
};

export default Dashboard;

export {
  EnterToxicologyResults,
  ReviewToxicologyResults,
  ViewToxicologyResults,
  SET_TOXICOLOGY_ORDER_STATUS,
};
