import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';

import Loading from '../Loading';
import { createNotification } from '../Notification';
import * as ROUTES from '../../constants/routes';
import * as STATUS from '../../constants/status';

export const GET_TOXICOLOGY_ORDERS_BY_PATIENT_ID = gql `
  query GetToxicologyOrdersByPatientId(
    $id: String!
  ) {
    getToxicologyOrdersByPatientId(
      id: $id
    ) {
      id
      clinicCode
      specimenId
      specimenType
      status
      createdDate
      specimenRunDate
      medicationListTags
      resultsReview {
        drugName
        result
      }
      etgResultsReview {
        drugName
        result
      }
    }
  }
`;

const PatientHistory = () => {
  const [orders, setOrders] = useState([]);
  const [medicationList, setMedicationList] = useState([]);
  const [posMetabolites, setPosMetabolites] = useState([]);
  const [loading, setLoading] = useState(true);

  // Get Patient ID from URL
  const { id } = useParams();

  // Get orders
  useQuery(
    GET_TOXICOLOGY_ORDERS_BY_PATIENT_ID, {
      variables: { id },
      onCompleted({ getToxicologyOrdersByPatientId }) {
        if (process.env.NODE_ENV === 'development') console.log('Orders:', getToxicologyOrdersByPatientId);

        parseMedicationList(getToxicologyOrdersByPatientId);
        parsePosMetabolites(getToxicologyOrdersByPatientId);

        setOrders(getToxicologyOrdersByPatientId);
        setLoading(false);
      },
      onError(error) {
        console.log('Get Toxicology Orders by Patient ID', error);
        createNotification('danger', 'Sorry, could not get patient order history.');

        setLoading(false);
      },
    },
  );

  const parseMedicationList = (orderDetails) => {
    const medList = [];
    const medications = new Set();

    // Find unique prescribed medications
    orderDetails.length > 0 && orderDetails.forEach(({ medicationListTags }) => {
      medicationListTags.forEach((med) => medications.add(med));
    });

    // Find orders with these meds
    medications.forEach((drugName) => {
      const medDetails = { drugName, orders: [] };

      orderDetails.forEach((order) => {
        if (order.medicationListTags.includes(drugName)) {
          medDetails.orders.push(order);
        }
      });

      medList.push(medDetails);
    });

    // Sort medList alphabetically by drugName


    setMedicationList(medList);
  };

  const parsePosMetabolites = (orderDetails) => {
    const metabolites = [];

    orderDetails.length > 0 && orderDetails.forEach((order) => {
      const pos = order.resultsReview.filter((test) => test.result !== 'ND' && test.result !== '');
      const etg = order.etgResultsReview.filter((test) => test.result !== 'ND' && test.result !== '');

      // pos.forEach((drug) => {
      [ ...pos, ...etg ].forEach((drug) => {
        metabolites.push({
          drugName: drug.drugName,
          result: drug.result,
          specimenId: order.specimenId,
          clinicCode: order.clinicCode,
          collectionDate: order.createdDate,
          runDate: order.specimenRunDate,
        });
      })
    });

    setPosMetabolites(metabolites);
  };

  const renderOrderStatus = (status) => {
    // TODO: How many different statuses do we want to display, and which colors?

    switch (status) {
      case STATUS.TOXICOLOGY_ANALYST_COMPLETE:
        return (
          <span style={{color: '#66ff8c'}}>
            <span className="mr-2 icon is-small">
              <i className="fas fa-circle fa-small" aria-hidden="true"/>
            </span>
            Complete
          </span>
        );
      case STATUS.TOXICOLOGY_CLINIC_CREATED:
      case STATUS.TOXICOLOGY_LAB_RECEIVED:
      case STATUS.TOXICOLOGY_LAB_BATCHED:
      case STATUS.TOXICOLOGY_LAB_COMPLETE:
      case STATUS.TOXICOLOGY_LAB_RERUN:
      case STATUS.TOXICOLOGY_LAB_IN_PROCESS:
      case STATUS.TOXICOLOGY_ANALYST_PENDING:
      case STATUS.TOXICOLOGY_ANALYST_IN_PROCESS:
      case STATUS.TOXICOLOGY_ANALYST_REVIEW_IN_PROCESS:
      case STATUS.TOXICOLOGY_ANALYST_PENDING_IN_PROCESS:
      case STATUS.TOXICOLOGY_ANALYST_REVIEW:
        // TODO: Change color, yellow?
        return (
          <span style={{color: '#66ff8c'}}>
            <span className="mr-2 icon is-small">
              <i className="fas fa-circle fa-small" aria-hidden="true"/>
            </span>
            Pending
          </span>
        );
      case STATUS.TOXICOLOGY_LAB_REJECTED:
        return (
          <span className="has-text-danger" style={{ whiteSpace: "nowrap", fontSize: "0.8rem" }}>
            <span className="mr-2 icon is-small">
              <i className="fas fa-circle fa-small" aria-hidden="true"/>
            </span>
            Specimen Rejected
          </span>
        );
      case STATUS.ARCHIVE:
        return (
          <span className="has-text-primary-light" style={{ whiteSpace: "nowrap" }}>
            <span className="mr-2 icon is-small">
              <i className="fas fa-circle fa-small" aria-hidden="true"/>
            </span>
            Archived
          </span>
        );
      default:
        // TODO: Change color, red?
        return (
          <span style={{color: '#66ff8c', whiteSpace: 'nowrap'}}>
            <span className="mr-2 icon is-small">
              <i className="fas fa-circle fa-small" aria-hidden="true"/>
            </span>
            Other
          </span>
        );
    }
  };

  const renderResultsButton = (id, status) => {
    // Only render button if order is complete
    if (status !== STATUS.TOXICOLOGY_ANALYST_COMPLETE) {
      return null;
    }

    return (
      <Link
        to={ROUTES.DASHBOARD_ANALYST_VIEW_RESULTS.replace(':id', id)}
        className="button is-info is-small"
      >
        View Result
      </Link>
    );
  };

  const generateTooltip = (orders) => {
    // const tooltip = orders.map((order) => (
    //   `${new Date(parseFloat(order.createdDate)).toDateString()}, ${order.specimenId}`
    // ));
    const tooltip = orders.map((order) => (
      `${new Date(parseFloat(order.createdDate))
        .toLocaleString([], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })}, ${order.specimenId}`
    ));

    return tooltip.join('\n');
  };

  // if (loading) return <Loading />;

  return (
    <div className="page">
      <span className="has-text-grey-light has-text-weight-semibold is-size-6">Patient History</span>
      <br/>
      <br />

      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="mt-3 label">
            <span className="has-text-white">Medications:</span> {medicationList.map(({ drugName, orders }, i) => {
              if (i+1 === medicationList.length) {
                return (
                  <span
                    key={i}
                    className="has-tooltip-arrow"
                    data-tooltip={generateTooltip(orders)}
                  >
                    {drugName}
                  </span>
                );
              } else {
                return (
                  <span
                    key={i}
                    className="has-tooltip-arrow"
                    data-tooltip={generateTooltip(orders)}
                  >
                    {`${drugName}, `}
                  </span>
                );
              }
            })}
          </div>

          <hr />

          <h6 className="title is-6">Orders</h6>

          <div style={{ overflowX: "auto" }}>
            <table className="table is-fullwidth is-striped is-hoverable">
              <thead>
                <tr>
                  <th style={{ fontSize: "0.9rem" }}>Specimen ID</th>
                  <th style={{ fontSize: "0.9rem" }}>Clinic Code</th>
                  <th style={{ fontSize: "0.9rem" }}>Type</th>
                  <th style={{ fontSize: "0.85rem" }}>Collection Date</th>
                  <th style={{ fontSize: "0.85rem" }}>Run Date</th>
                  <th style={{ fontSize: "0.9rem" }}>Status</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {orders.map((order) => (
                  <tr key={order.id}>
                    <td style={{ fontSize: "0.8rem" }}>{order.specimenId}</td>
                    <td style={{ fontSize: "0.8rem" }}>{order.clinicCode}</td>
                    <td style={{ fontSize: "0.8rem" }}>{order.specimenType}</td>
                    <td style={{ fontSize: "0.8rem" }}>{new Date(parseFloat(order.createdDate)).toDateString()}</td>
                    <td style={{ fontSize: "0.8rem" }}>{new Date(Date.parse(order.specimenRunDate)).toDateString() === 'Invalid Date' ? 'N/A' : new Date(Date.parse(order.specimenRunDate)).toDateString()}</td>
                    <td style={{ fontSize: "0.8rem", maxWidth: "105px"}}>{renderOrderStatus(order.status)}</td>
                    <td style={{ fontSize: "0.8rem" }}>{renderResultsButton(order.id, order.status)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <hr />

          <h6 className="title is-6">Previous Positive Results</h6>

          <div style={{ overflowX: "auto" }}>
            <table className="table is-fullwidth is-striped is-hoverable">
              <thead>
                <tr>
                  <th>Drug/Metabolite</th>
                  <th>Value</th>
                  <th>Collection Date</th>
                </tr>
              </thead>

              <tbody>
                {posMetabolites.map((metab, i) => (
                  <tr key={i}>
                    <td>{metab.drugName}</td>
                    <td>{metab.result}</td>
                    <td>{new Date(parseFloat(metab.collectionDate)).toDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default PatientHistory;
