import React, { useEffect, useState } from 'react';

const ClinicProfile = ({ clinicProfile }) => {
  const [clinicInfo, setClinicInfo] = useState(null);
  const [supplies, setSupplies] = useState(null);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') console.log(clinicProfile);

    setClinicInfo(formatClinicInfo(clinicProfile));
    setSupplies(formatSupplies(clinicProfile));
  }, []);

  const formatClinicInfo = (profile) => {
    //  1. Name
    //  2. Clinic Code
    //  3. Short Code
    //  4. Address
    //  5. Phone
    //  6. Fax
    //  7. Contact Name
    //  8. Contact Phone
    //  9. Contact Email
    // 10. Sales Rep

    return [
      {
        label: 'Clinic Name',
        value: profile.clinicName,
      },
      {
        label: 'Clinic Code',
        value: profile.clinicCode,
      },
      {
        label: 'Clinic Short Code',
        value: profile.clinicShortCode,
      },
      {
        label: 'Address',
        value: <>
          {profile.address1}
          <br />
          {profile.address2 && <>{profile.address2}<br /></>}
          {profile.city}, {profile.state} {profile.zipCode}
          </>,
      },
      {
        label: 'Clinic Phone',
        value: profile.clinicPhone,
      },
      {
        label: 'Clinic Fax',
        value: profile.clniicFax,
      },
      {
        label: 'Contact Name',
        value: profile.contactName,
      },
      {
        label: 'Contact Phone',
        value: profile.contactPhone,
      },
      {
        label: 'Contact Email',
        value: profile.contactEmail,
      },
      {
        label: 'Sales Representative',
        value: profile.salesRepresentative,
      },
    ];
  };

  const formatSupplies = (profile) => {
    // 1. Anticipated start date
    // 2. Estimated volume
    // 3. Initial supplies?
    // 4. Auto-ship supplies?
    // 5. Collection devices
    // 6. Temporary specimen pickups needed?
    // 7. Pickup schedule

    return [
      {
        label: 'Anticipated Start Date',
        value: new Date(Date.parse(profile.startDate)).toDateString(),
      },
      {
        label: 'Initial Supplies Needed',
        value: profile.initialSuppliesNeeded ? 'Yes' : 'No',
      },
      {
        label: 'Auto-Ship Supplies',
        value: profile.suppliesAutoShipped ? 'Yes' : 'No',
      },
      {
        label: 'Estimated Monthly Volume',
        value: <>{profile.estimatedVolume} Units</>,
      },
      {
        label: 'Collection Devices',
        value: <span className="is-capitalized">{profile.collectionDevices.join(', ').replace('-', ' ')}</span>,
      },
      {
        label: 'Temporary Specimen Pickups Needed',
        value: profile.temporarySpecimenPickupsNeeded ? 'Yes, until regular pickups is established ' : 'No ',
      },
      {
        label: 'Requested Pickup Schedule',
        value: renderPickups(profile.specimenPickupSchedule),
      },
    ];
  };

  const renderPickups = (schedule) => {
    return schedule.map((line, i) => {
      const [day, startTime, endTime] = line.split('-');

      return <div className="columns" key={i}>
        <div className="column is-one-fifth">
          <span className="is-capitalized">{day}:</span>
        </div>
        <div className="column">
          {startTime} – {endTime} PM
        </div>
      </div>;
    });
  };

  const renderClinicInfo = () => {
    return clinicInfo.map((d, i) => (
      <div className="columns" key={i}>
        <div className="column is-one-fifth">
          <span className="sublabel is-4">{d.label}</span>
        </div>
        <div className="column">
          <span className="has-text-white">{d.value}</span>
        </div>
      </div>
    ));
  };

  const renderSupplies = () => {
    return supplies.map((d, i) => (
      <div className="columns" key={i}>
        <div className="column is-one-fifth">
          <span className="sublabel is-4">{d.label}</span>
        </div>
        <div className="column">
          <span className="has-text-white">{d.value}</span>
        </div>
      </div>
    ));
  };

  return (
    <div>
      <h2 className="title has-text-primary is-size-2">{clinicProfile.clinicName}</h2>

      {clinicInfo && renderClinicInfo()}

      <br /><br />
      <hr />
      <br /><br />

      {supplies && renderSupplies()}
    </div>
  );
};

export default ClinicProfile;
