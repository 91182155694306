import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Loading, { ButtonLoading } from '../Loading';

const ClinicSettings = ({ clinicSettings, analystInstructions, loading, onSave, saving }) => {
  const [settings, setSettings] = useState([]);
  const [additionalInstructions, setAdditionalInstructions] = useState('');
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!saving) {
      reset();
    }
  }, [clinicSettings, analystInstructions]);

  const handleChange = (e) => {
    const updated = _.cloneDeep(settings);

    const index = _.findIndex(updated, { name: e.target.name });
    updated.splice(index, 1, { ...updated[index], value: !updated[index].value });

    setSettings(updated);
    setUpdated(true);
  };

  const handleAdditionalInstructionsChange = (e) => {
    setAdditionalInstructions(e.target.value);
    setUpdated(true);
  };

  const handleSave = () => {
    const data = {
      settings: settings.map(({ name, label, value }) => ({ name, label, value })),
      instructions: additionalInstructions,
    };

    onSave(data);
  };

  const reset = () => {
    const settingsWithLabel = clinicSettings.map((setting) => {
      switch (setting.name) {
        case 'enableMedicationList':
          return {
            ...setting,
          };
        case 'enableMedicationSummary':
          return {
            ...setting,
          };
        case 'enableScreen':
          return {
            ...setting,
          };
        default:
          return { ...setting };
      }
    });

    setSettings(settingsWithLabel);
    setAdditionalInstructions(analystInstructions);
    setUpdated(false);
  };

  return (
    <div className="column is-two-thirds-desktop is-two-thirds-tablet">
      <div
        className="page"
        style={{ minHeight: "29vh" }}
      >
        <h2 className="is-size-3 title mt-1">Clinic Settings</h2>

        {loading ? (
          <Loading />
        ) : (
          <>
            <div>
              {settings.map((setting) => (
                <div className="field" key={setting.name}>
                  <input
                    id={setting.name}
                    name={setting.name}
                    className="switch"
                    type="checkbox"
                    onChange={handleChange}
                    checked={setting.value}
                  />
                  <label htmlFor={setting.name} className="has-text-white">
                    {setting.label}
                  </label>
                </div>
              ))}
            </div>

            <hr />

            <div>
              <h4 className="is-size-6 title mt-2">
                Clinic-specific Instructions for Analysts
              </h4>

              <textarea
                className="textarea"
                placeholder=""
                value={additionalInstructions}
                onChange={handleAdditionalInstructionsChange}
              >

              </textarea>
            </div>

            <div className="mt-6 pb-5">
              <button
                className="button is-info is-pulled-left"
                onClick={handleSave}
                disabled={!updated}
              >
                Save
              </button>
              {saving && <ButtonLoading />}

              <button
                className="button is-danger is-pulled-right"
                onClick={reset}
                disabled={!updated}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ClinicSettings;
