import React, { useEffect, useState } from 'react';
import axios from 'axios';

import DailyStats from './DailyStats';
import UploadedFiles from './UploadedFiles';

const ResultsCenter = (props) => {
  const [stats, setStats] = useState({});
  const [reports, setReports] = useState([]);

  useEffect(() => {
    console.log('ResultsCenter()');

    const headers = {
      'x-api-key': process.env.REACT_APP_COVID_RESULT_UPLOAD_API_KEY,
    };

    axios.get(process.env.REACT_APP_COVID_RESULT_CENTER_URL, { headers })
      .then((res) => {
        console.log(res.data);

        setStats(res.data.stats);
        setReports(res.data.reports);
      })
      .catch((error) => {
        console.log('Results Center', error);

        // TODO: Handle this properly after CORS is resolved in cloud functionw


      });
  }, []);

  return (
    <div>
      <h2 className="title is-5">Results Center</h2>

      <div>
        {/* <button className="button is-info">Reconciliation Report</button> */}

        <div className="mt-3">
          <DailyStats stats={stats} />
        </div>

        <div className="mt-3">
          <UploadedFiles reports={reports} />
        </div>
      </div>
    </div>
  );
};

export default ResultsCenter;
