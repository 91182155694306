import React, { useState } from 'react';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import ClinicProfile from './ClinicProfile';
import Loading from '../Loading';
import { createNotification } from '../Notification';
import { useAuth } from '../auth';

export const GET_CLINIC_CODE = gql`
  query GetClinicById(
    $id: String!
  ) {
    getClinicById(
      id: $id
    ) {
      clinicCode
    }
  }
`;

export const GET_TOXICOLOGY_TEST_PROFILE_BY_CLINIC_CODE = gql`
  query ToxicologyTestProfileByClinicCode(
    $clinicCode: String!
  ) {
    toxicologyTestProfileByClinicCode(
      clinicCode: $clinicCode
    ) {
      id
      clinicName
      clinicCode
      clinicShortCode
      address1
      address2
      city
      state
      zipCode
      country
      clinicPhone
      clinicFax
      contactName
      contactPhone
      contactEmail
      salesRepresentative
      billingPoctOrIa
      billingCodes
      feeForTestBilling
      specialInstructions
      startDate
      estimatedVolume
      estimatedVolumeOther
      initialSuppliesNeeded
      suppliesAutoShipped
      initialSuppliesDate
      initialSuppliesShipSpeed
      collectionDevices
      collectionDevicesOralSwabsAmount
      temporarySpecimenPickupsNeeded
      specimenPickupSchedule
      authorization
      isComplete
    }
  }
`;

const ClinicSuppliesLogistics = () => {
  const [clinicCode, setClinicCode] = useState(null);
  const [clinicProfile, setClinicProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  // Get clinic code
  useQuery(
    GET_CLINIC_CODE,
    {
      variables: { id },
      onCompleted({ getClinicById }) {
        setClinicCode(getClinicById.clinicCode);

        getClinicProfile();
      },
      onError(error) {
        console.log('Get clinic code', error);
        createNotification('danger', 'Sorry, could not retrieve clinic details.');
      },
    },
  );

  // Get clinic profile
  const [getClinicProfile] = useLazyQuery(
    GET_TOXICOLOGY_TEST_PROFILE_BY_CLINIC_CODE,
    {
      variables: { clinicCode },
      onCompleted({ toxicologyTestProfileByClinicCode }) {
        setClinicProfile(toxicologyTestProfileByClinicCode);
        setLoading(false);
      },
      onError(error) {
        console.log('Get clinic profile', error);
        createNotification('danger', 'Sorry, could not retrieve clinic details.');
      },
    },
  );

  if (loading) return <Loading />;

  return (
    <section className="section">
      <div className="container">
        <h3 className="title is-size-3">Clinic Supplies and Logistics</h3>

        <div className="page">
          <div className="column">

            <div className="">
              <ClinicProfile clinicProfile={clinicProfile} />
            </div>
          </div>
        </div>
      </div>

    </section>
  );
};

export default ClinicSuppliesLogistics;
