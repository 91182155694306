import React from 'react';

import { useAuth, ChangePassword } from './auth';

const Account = () => {
  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <div>
              <h3 className="title is-size-3">Account</h3>
              <hr />
              <br />
            </div>

            <ChangePassword />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Account;
