import React from 'react';
import { Link } from 'react-router-dom';

import BatchDetails from './BatchDetails';
import BatchedSpecimenList from './BatchedSpecimenList';
import InTransitSpecimenList from './InTransitSpecimenList';
import ReceivedSpecimenList from './ReceivedSpecimenList';
import CompletedSpecimenList from './CompletedSpecimenList';
import ReRunRequests from './ReRunRequests';
import SpecimenValidation from './SpecimenValidation';

import { useAuth } from '../auth';
import * as ROUTES from '../../constants/routes';

const LabDashboard = ({ activeTab }) => {
  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  const renderTab = (tab) => {
    switch (tab) {
      case 'in-transit':
        return <InTransitSpecimenList />;
      case 'scanned':
        return <ReceivedSpecimenList />;
      case 'batched':
        return <BatchedSpecimenList />;
      case 'complete':
        return <CompletedSpecimenList />;
      case 're-run':
        return <ReRunRequests />;
      default:
        return <ReceivedSpecimenList />;
    }
  };

  return (
    <div className="section">
      <div className="container">
        {renderTab(activeTab)}
      </div>
    </div>
  );
};

const EnterLabResultsButton = () => {
  return (
    <Link
      to={ROUTES.DASHBOARD_LAB_ENTER_NEW_RESULTS}
      className="button is-primary"
    >
      <span className="icon is-small"><i className="fas fa-vial" aria-hidden="true"></i></span>
      <span>New Specimen Validation</span>
    </Link>
  );
};

export default LabDashboard;

export { BatchDetails, EnterLabResultsButton, SpecimenValidation };
