import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Popup from 'reactjs-popup';

import { ButtonLoading } from '../Loading';

import '../../styles/modal.css';

const ReturnSpecimenInTransitModal = ({ specimenId }) => {
  const [submitting, setSubmitting] = useState(false);

  let closeModal;
  const history = useHistory();

  const handleSubmit = () => {
    if (submitting) return;

    setSubmitting(true);

    const token = localStorage.getItem('token');

    const config = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    axios.post(process.env.REACT_APP_RETURN_SPECIMEN_URL, { specimenId }, config)
      .then((res) => {
        if (process.env.NODE_ENV === 'development') {
          console.log(res);
        }

        // Refresh page
        history.go(0);

        setSubmitting(false);
        closeModal();
      })
      .catch((err) => {
        console.log(err);

        setSubmitting(false);
      });
  };

  return (
    <Popup
      trigger={<span className="is-clickable">{specimenId}</span>}
      // contentStyle={{ overflowY: "auto", margin: "10px auto" }}
      lockScroll
      modal
    >
      {(close) => {
        closeModal = close;

        return (
          <div className="">
            <span className="has-text-white is-pulled-right">
              <i className="fas fa-times fa-lg icon-link" onClick={close} />
            </span>

            <h4 className="title is-4">
              Return {specimenId} to "In-Transit"?
            </h4>

            <div className="mt-6">
              <button
                className="button is-primary mb-2"
                onClick={handleSubmit}
              >
                Return Specimen
              </button>
              {submitting && <ButtonLoading />}

              <button
                className="button is-danger is-pulled-right"
                onClick={(e) => {
                  e.preventDefault();
                  close();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        );
      }}
    </Popup>
  );
};

export default ReturnSpecimenInTransitModal;
