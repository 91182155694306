import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import Loading, { ButtonLoading } from '../Loading';
import { GET_TOXICOLOGY_TEST_PROFILE_BY_CLINIC_CODE } from './NewToxicologyOrderForm';
import CLINICAL_PURPOSE from '../../constants/clinicalPurpose';
// import { filterTests } from '../../utils';

import '../../styles/form.css';

const ToxicologyOrderDetailsForm = (props) => {
  const {
    orderDetails,
    isEditing,
    isUpdating,
    testDetails,
    handleCancel,
  } = props;

  const [tests, setTests] = useState([]);
  const [testProfile, setTestProfile] = useState(null);
  const [providers, setProviders] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [pocTests, setPocTests] = useState(null);
  const [etgCheckbox, setEtgCheckbox] = useState(false);
  const [loading, setLoading] = useState(true);

  const formikRef = useRef();

  // Set initial state from orderDetails
  useEffect(() => {
    const { providerId, providerName, providerSuffix } = orderDetails;

    setSelectedProvider({
      id: providerId,
      physicianName: providerName,
      physicianSuffix: providerSuffix,
      label: `${providerName}, ${providerSuffix}`,
    });

    // POC tests
    const tests = orderDetails?.pocTests.filter((test) => test.result.toLowerCase() !== 'n/a');

    // console.log({ [tests[0].code]: { result: tests[0].result } });
    const pocTests = {};
    tests?.forEach((test) => pocTests[test.code] = { code: test.code, name: test.name, result: test.result });

    setPocTests(pocTests);
  }, []);

  // Reset tox tests on cancel edit
  useEffect(() => {
    if (!isEditing) {
      resetToxTests();
    }
  }, [isEditing]);

  // Get clinic test profile
  useQuery(
    GET_TOXICOLOGY_TEST_PROFILE_BY_CLINIC_CODE,
    {
      variables: { clinicCode: orderDetails?.clinicCode },
      onCompleted({ toxicologyTestProfileByClinicCode }) {
        const physicianInfo = toxicologyTestProfileByClinicCode.physicianInfo.map((p) => ({
          ...p,
          label: `${p.physicianName}, ${p.physicianSuffix}`,
        }));

        if (toxicologyTestProfileByClinicCode.toxicologyTests.length > 0) {
          const toxTests = toxicologyTestProfileByClinicCode.toxicologyTests
            .filter((test) => test.drugName.substring(0, 4).toUpperCase() !== 'ALL ')
            .map((test) => ({ ...test, pos: false }));

          orderDetails?.toxicologyTests.forEach((test) => {
            const idx = _.findIndex(toxTests, { drugName: test.drugName });

            toxTests[idx] = { ...test };
          });

          setTests(toxTests);
          setEtgCheckbox(orderDetails?.etgTests.length > 0);
        }

        setProviders(physicianInfo);
        setTestProfile(toxicologyTestProfileByClinicCode);
        setLoading(false);
      },
      onError(error) {
        // TODO: show error notification
        console.log('Clinic toxicology test profile', error);
      }
    },
  );

  const onKeyDown = (e) => {
    // Prevent "Enter" key from submitting form
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault();
    }
  };

//   const handleKeyUp = ({ key }, drugName, setFieldValue) => {
//     if (key === 'Enter') {
//       setFieldValue('medicationList', '');
//
//       if (drugName.length > 0 && !medicationTags.map((d) => d.toUpperCase()).includes(drugName.toUpperCase())) {
//         setMedicationTags([...medicationTags, drugName]);
//       }
//     }
//   };

  const resetToxTests = () => {
    const toxTests = _.cloneDeep(tests)
      .map((test) => ({ ...test, pos: false }));

    orderDetails?.toxicologyTests.forEach((test) => {
      const idx = _.findIndex(toxTests, { drugName: test.drugName });

      toxTests[idx] = { ...test };
    });

    setTests(toxTests);
  };

  const generateTestRows = () => {
    return tests?.map((row, i) => (
      <tr
        key={i}
        // className={i === 0 || i === tests?.length-1 ? "has-background-white-ter" : ""}
      >
        <td className={row.indent && `pl-${4+row.indent}`}>
      <span className={i === 0 || i === tests?.length-1 ? "has-text-weight-semibold" : ""}>
        {row.drugName}
      </span>
        </td>
        <td onClick={() => handleClick(i, 'pos')}>
      <span className="icon is-small">
        <i
          className="fas fa-check"
          style={{ visibility: row.pos ? 'visible' : 'hidden' }}
        />
      </span>
        </td>
      </tr>
    ));
  };

  const handleClick = (i, val) => {
    const toxTests = _.cloneDeep(tests);

    // Toggle row
    toxTests[i][val] = !toxTests[i][val];

    setTests(toxTests);
  };

  const generatePOCTestsTable = (pocTests) => {
    const tests = pocTests?.filter(({ name, result }) => name.length > 0 && result !== "n/a");

    if (tests?.length === 0) {
      // No point of care tests were administered
      return <p>No point of care tests were administered</p>;
    }

    return (
      <div style={{ overflowX: "auto" }}>
        <table className="table is-bordered is-hoverable mt-5 mb-6">
          <thead>
          <tr className="has-background-info-dark">
            <th className="">Code</th>
            <th className="">Drug Name</th>
            <th className="">Positive</th>
            <th className="">Negative</th>
            <th className="">N/A</th>
          </tr>
          </thead>
          <tbody>
            {tests?.map(({ code, name, result}, i) => (
              <tr key={i}>
                <td>{code}</td>
                <td>{name}</td>
                {isEditing ? (
                  <>
                    <td><Field type="radio" name={code} value="pos" /></td>
                    <td><Field type="radio" name={code} value="neg" /></td>
                    <td><Field type="radio" name={code} value="n/a" /></td>
                  </>
                ) : (
                  <>
                    <td className="has-text-centered">{result === 'pos' ? <i className="fas fa-check-square" /> : <i className="fas fa-square" />}</td>
                    <td className="has-text-centered">{result === 'neg' ? <i className="fas fa-check-square" /> : <i className="fas fa-square" />}</td>
                    <td className="has-text-centered">{result === 'n/a' ? <i className="fas fa-check-square" /> : <i className="fas fa-square" />}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const getClinicalPurpose = (clinicalPurpose) => {
    if (!clinicalPurpose || clinicalPurpose.length < 1) {
      return '';
    }

    const split = clinicalPurpose.split('::');
    const otherIndex = _.findIndex(split, (s) => (s.includes("Other")));

    if (otherIndex > -1) {
      split.splice(otherIndex, 1, "Other");
    }

    return split;
  };

  const getClinicalPurposeOther = (clinicalPurpose) => {
    if (!clinicalPurpose || clinicalPurpose.length < 1) {
      return '';
    }

    const split = clinicalPurpose.split('::');
    const other = split.filter((line) => line.includes("Other"));

    if (other.length > 0) {
      return other[0].split('Other: ').at(-1).trim();
    }

    return '';
  };

  if (loading) return <Loading />;

  // TODO: Reset dropdowns on cancel

  return (
    <Formik
      initialValues={{
        provider: orderDetails?.providerId || '',
        specimenType: orderDetails?.specimenType || 'Urine',
        clinicalPurpose: getClinicalPurpose(orderDetails?.clinicalPurpose),
        clinicalPurposeOther: getClinicalPurposeOther(orderDetails?.clinicalPurpose),
        medicationList: (orderDetails?.medicationListTags && orderDetails?.medicationListTags.join(', ')) || '',
        icd10Codes: (orderDetails?.icd10CodeTags && orderDetails?.icd10CodeTags.join(', ')) || '',
        customTests: orderDetails?.customTests || '',
        AMP: (pocTests.AMP && pocTests.AMP.result) || 'n/a',
        BAR: (pocTests.BAR && pocTests.BAR.result) || 'n/a',
        BUP: (pocTests.BUP && pocTests.BUP.result) || 'n/a',
        BZO: (pocTests.BZO && pocTests.BZO.result) || 'n/a',
        COC: (pocTests.COC && pocTests.COC.result) || 'n/a',
        mAMP: (pocTests.mAMP && pocTests.mAMP.result) || 'n/a',
        MDMA: (pocTests.MDMA && pocTests.MDMA.result) || 'n/a',
        MTS: (pocTests.MTS && pocTests.MTS.result) || 'n/a',
        OPI: (pocTests.OPI && pocTests.OPI.result) || 'n/a',
        OXY: (pocTests.OXY && pocTests.OXY.result) || 'n/a',
        PCP: (pocTests.PCP && pocTests.PCP.result) || 'n/a',
        PPX: (pocTests.PPX && pocTests.PPX.result) || 'n/a',
        TCA: (pocTests.TCA && pocTests.TCA.result) || 'n/a',
        THC: (pocTests.THC && pocTests.THC.result) || 'n/a',
        otherPOCDrugName0: (pocTests.OTHER_0 && pocTests.OTHER_0.name) || '',
        otherPOCDrug0: (pocTests.OTHER_0 && pocTests.OTHER_0.result) || 'n/a',
        otherPOCDrugName1: (pocTests.OTHER_1 && pocTests.OTHER_1.name) || '',
        otherPOCDrug1: (pocTests.OTHER_1 && pocTests.OTHER_1.result) || 'n/a',
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        // Filter the tests, "pos" only
        // const posTestsOnly = filterTests(tests);

        const pocTests = [
          {
            code: 'AMP',
            name: 'Amphetamines',
            result: values.AMP,
          },
          {
            code: 'BAR',
            name: 'Barbiturates',
            result: values.BAR,
          },
          {
            code: 'BUP',
            name: 'Buprenorphine',
            result: values.BUP,
          },
          {
            code: 'BZO',
            name: 'Benzodiazepines',
            result: values.BZO,
          },
          {
            code: 'COC',
            name: 'Cocaine',
            result: values.COC,
          },
          {
            code: 'mAMP',
            name: 'Methamphetamine',
            result: values.mAMP,
          },
          {
            code: 'MDMA',
            name: 'Ecstasy',
            result: values.MDMA,
          },
          {
            code: 'MTS',
            name: 'Methadone',
            result: values.MTS,
          },
          {
            code: 'OPI',
            name: 'Opiates',
            result: values.OPI,
          },
          {
            code: 'OXY',
            name: 'Oxycodone',
            result: values.OXY,
          },
          {
            code: 'PCP',
            name: 'Phencyclidine',
            result: values.PCP,
          },
          {
            code: 'PPX',
            name: 'Propoxyphene',
            result: values.PPX,
          },
          {
            code: 'TCA',
            name: 'Tricyclic Antidepressants',
            result: values.TCA,
          },
          {
            code: 'THC',
            name: 'Marijuana',
            result: values.THC,
          },
          {
            code: 'OTHER_0',
            name: values.otherPOCDrugName0,
            result: values.otherPOCDrug0,
          },
          {
            code: 'OTHER_1',
            name: values.otherPOCDrugName1,
            result: values.otherPOCDrug1,
          },
        ];

        const clinicalPurposeString = values.clinicalPurpose.includes("Other") ?
          values.clinicalPurpose.join("::").replaceAll('"', '').replace("Other", `Other: ${values.clinicalPurposeOther}`) :
          values.clinicalPurpose.join("::").replaceAll('"', '');

        testDetails({
          ...values,
          // tests: posTestsOnly,
          tests,
          pocTests,
          specimenId: orderDetails?.specimenId,
          icd10CodeTags: values.icd10Codes.split(',').map((tag) => tag.trim()),
          medicationListTags: values.medicationList.split(',').map((med) => med.trim()),
          clinicalPurpose: clinicalPurposeString,
          clinicalPurposeOther: values.clinicalPurpose.includes("Other") ? values.clinicalPurposeOther : '',
          providerId: selectedProvider.id,
          providerName: selectedProvider.physicianName,
          providerSuffix: selectedProvider.physicianSuffix,
          etgTests: etgCheckbox ? ['ETG'] : [],
        });

        // resetForm();
        setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        provider: Yup.string()
          .required("Please select a provider"),
        specimenType: Yup.string()
          .required("Specimen type is required"),
        clinicalPurpose: Yup.array().of(Yup.string())
          .required("Clinical purpose is required"),
        clinicalPurposeOther: Yup.string()
          .when('clinicalPurpose', {
            is: (clinicalPurpose) => clinicalPurpose?.includes("Other"),
            then: Yup.string().required("Clinical purpose is required"),
          }),
        medicationList: Yup.string(),
        icd10Codes: Yup.string(),
        etgTests: Yup.boolean(),
        customTests: Yup.string(),
      })}
    >
      {(formProps) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
        } = formProps;

        return (
          <form className="mt-4"
            onKeyDown={onKeyDown}
            onSubmit={handleSubmit}
            style={{paddingLeft:"0"}}
          >
            <div className="block">
              <label className="label" htmlFor="input-medicationList">
                Provider Name
              </label>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <input
                    id="input-TODO-DELETE"
                    name="TODO"
                    className="input"
                    type="text"
                    value={`${selectedProvider?.physicianName}${selectedProvider?.physicianSuffix ? `, ${selectedProvider?.physicianSuffix}` : ""}`}
                    onChange={() => {}}
                    disabled
                  />
                </div>
              </div>
            </div>
{/*             <div className="block"> */}
{/*               <label className="label" htmlFor="input-provider"> */}
{/*                 Provider Name */}
{/*               </label> */}
{/*               <div className={!isEditing ? "select not-editing" : "select"}> */}
{/*                 <select */}
{/*                   id="input-provider" */}
{/*                   name="provider" */}
{/*                   className={errors.provider && touched.provider ? "input error" : "input"} */}
{/*                   value={values.provider} */}
{/*                   onChange={(e) => { */}
{/*                     handleChange(e); */}
{/*  */}
{/*                     const physician = providers.filter((p) => p.id === e.target.value); */}
{/*                     setSelectedProvider(physician[0]); */}
{/*                   }} */}
{/*                   onBlur={handleBlur} */}
{/*                   disabled={!isEditing} */}
{/*                   style={!isEditing ? { cursor: 'default' } : null} */}
{/*                 > */}
{/*                   {providers && providers.map((p) => ( */}
{/*                     <option */}
{/*                       key={p.id} */}
{/*                       value={p.id} */}
{/*                       label={p.label} */}
{/*                     /> */}
{/*                   ))} */}
{/*                 </select> */}
{/*               </div> */}
{/*               {errors.provider && touched.provider && ( */}
{/*                 <div className="input-feedback" data-testid="errors-provider"> */}
{/*                   {errors.provider} */}
{/*                 </div> */}
{/*               )} */}
{/*             </div> */}

            <br/>

            <div className="block">
              <label className="label" htmlFor="input-specimenType">
                Specimen Type
              </label>
              <div className={!isEditing ? "select not-editing" : "select"}>
                <select
                  id="input-specimenType"
                  name="specimenType"
                  className={errors.specimenType && touched.specimenType ? "input error" : "input"}
                  value={values.specimenType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!isEditing}
                  style={!isEditing ? { cursor: 'default' } : null}
                >
                  <option value="Urine" label="Urine" />
                  <option value="Oral Fluid" label="Oral Fluid" />
                </select>
              </div>
              {errors.specimenType && touched.specimenType && (
                <div className="input-feedback" data-testid="errors-specimenType">
                  {errors.specimenType}
                </div>
              )}
            </div>

            <div className="block">
              <label className="label" htmlFor="input-clinicalPurpose">
                Clinical Purpose <span className="is-size-7">(Clinical info/indicator(s) supporting order for definitve testing)</span>
              </label>

              <div className="mt-3 ml-4">
                {CLINICAL_PURPOSE.map((purpose, i) => (
                  <div
                    key={i}
                    style={!isEditing && !values.clinicalPurpose.includes(purpose) ? { display: "none" } : { display: "block" }}
                  >
                    <label className="checkbox">
                      <Field
                        type="checkbox"
                        name="clinicalPurpose"
                        value={purpose}
                        disabled={!isEditing}
                        checked={values.clinicalPurpose.includes(purpose)}
                      />
                      <span className="ml-2 has-text-grey">{purpose}</span>
                    </label>
                    <br />
                  </div>
                ))}

                <div style={!isEditing && !values.clinicalPurpose.includes("Other") ? { display: "none" } : { display: "block" }}>
                  <label className="checkbox">
                    <Field
                      type="checkbox"
                      name="clinicalPurpose"
                      value="Other"
                      disabled={!isEditing}
                      checked={values.clinicalPurpose.includes("Other")}
                    />
                    <span className="ml-2 has-text-grey">Other</span>
                  </label>
                </div>
              </div>

{/*               <div className={!isEditing ? "select not-editing" : "select"}> */}
{/*                 <select */}
{/*                   id="input-clinicalPurpose" */}
{/*                   name="clinicalPurpose" */}
{/*                   className={errors.clinicalPurpose && touched.clinicalPurpose ? "input error" : "input"} */}
{/*                   value={values.clinicalPurpose} */}
{/*                   onChange={handleChange} */}
{/*                   onBlur={handleBlur} */}
{/*                   disabled={!isEditing} */}
{/*                   style={!isEditing ? { cursor: "default" } : null} */}
{/*                 > */}
{/*                   <option value="">&mdash;</option> */}
{/*  */}
{/*                   {CLINICAL_PURPOSE.map((purpose, i) => ( */}
{/*                     <option key={i} value={purpose}>{purpose}</option> */}
{/*                   ))} */}
{/*  */}
{/*                   <option value="Other">Other</option> */}
{/*                 </select> */}
{/*               </div> */}

              {values.clinicalPurpose.includes("Other") && (
                <>
                  <label className="label mt-2" htmlFor="input-clinicalPurposeOther">
                    Other Clinical Purpose
                  </label>
                  <input
                    id="input-clinicalPurposeOther"
                    name="clinicalPurposeOther"
                    className={errors.clinicalPurposeOther ? "input error" : "input"}
                    type="text"
                    value={values.clinicalPurposeOther}
                    onChange={handleChange}
                  />
                  {errors.clinicalPurposeOther && touched.clinicalPurposeOther && (
                    <div className="input-feedback" data-testid="errors-clinicalPurposeOther">
                      {errors.clinicalPurposeOther}
                    </div>
                  )}
                </>
              )}

              {errors.clinicalPurpose && touched.clinicalPurpose && (
                <div className="input-feedback" data-testid="errors-clinicalPurpose">
                  {errors.clinicalPurpose}
                </div>
              )}
            </div>

            <br/>

            <div className="block">
              <label className="label" htmlFor="input-medicationList">
                Prescribed Medications{" "}
                {isEditing && <span className="is-size-7">(Comma separated list of medications)</span>}
              </label>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <input
                    id="input-medicationList"
                    name="medicationList"
                    className={errors.medicationList && touched.medicationList ? "input error" : "input"}
                    type="text"
                    placeholder="(Optional) e.g. Codeine, Oxycodone"
                    value={values.medicationList}
                    onChange={handleChange}
                    // onChange={(e) => handleMedicationListOnChange(e, handleChange)}
                    onBlur={handleBlur}
                    // onKeyUp={(e) => handleKeyUp(e, values.medicationList, setFieldValue)}
                    disabled={!isEditing}
                    style={!isEditing ? { cursor: 'default' } : null}
                  />
                </div>
{/*                 {isEditing && ( */}
{/*                   <div className="control"> */}
{/*                     <button */}
{/*                       className="button is-info" */}
{/*                       onClick={(e) => { */}
{/*                         e.preventDefault(); */}
{/*  */}
{/*                         if (process.env.NODE_ENV === 'development') { */}
{/*                           console.log('Add prescribed medication (add button):', values.medicationList); */}
{/*                         } */}
{/*  */}
{/*                         // handleKeyUp({ key: 'Enter' }, values.medicationList, setFieldValue); */}
{/*                       }} */}
{/*                     > */}
{/*                       <span className="icon is-small mr-1"> */}
{/*                         <i className="fas fa-plus-circle" /> */}
{/*                       </span> */}
{/*                       Add */}
{/*                     </button> */}
{/*                   </div> */}
{/*                 )} */}
              </div>
              {errors.medicationList && touched.medicationList && (
                <div className="input-feedback" data-testid="errors-medicationList">
                  {errors.medicationList}
                </div>
              )}

{/*               {medicationTags.length > 0 && ( */}
{/*                 <div className="tags" style={{marginTop: "2em"}}> */}
{/*  */}
{/*                   {medicationTags.map((med) => { */}
{/*                     return generateTag(med, 'medicationList'); */}
{/*                   })} */}
{/*                 </div> */}
{/*               )} */}
            </div>

            <br/>

            <div className="block">
              <label className="label" htmlFor="input-icd10Codes">
                Diagnosis Codes{" "}
                {isEditing && <span className="is-size-7">(Comma separated ICD-10 codes)</span>}
              </label>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <div className="control is-expanded">
                    <input
                      id="input-icd10Codes"
                      name="icd10Codes"
                      className={errors.icd10Codes && touched.icd10Codes ? "input error" : "input"}
                      type="text"
                      placeholder="ICD-10 code(s)"
                      value={values.icd10Codes}
                      onChange={handleChange}
                      // onChange={(e) => handleMedicationListOnChange(e, handleChange)}
                      onBlur={handleBlur}
                      // onKeyUp={(e) => handleKeyUp(e, values.medicationList, setFieldValue)}
                      disabled={!isEditing}
                      style={!isEditing ? { cursor: 'default' } : null}
                    />
                  </div>
{/*                   <Autosuggest */}
{/*                     id="input-icd10Codes" */}
{/*                     className={errors.icd10Codes && touched.icd10Codes ? "input error" : "input"} */}
{/*                     suggestions={icd10Suggestions} */}
{/*                     onSuggestionsFetchRequested={onSuggestionsFetchRequested} */}
{/*                     onSuggestionsClearRequested={onSuggestionsClearRequested} */}
{/*                     getSuggestionValue={getSuggestionValue} */}
{/*                     renderSuggestion={renderSuggestion} */}
{/*                     inputProps={{ */}
{/*                       ...inputProps, */}
{/*                       onBlur: (e) => { */}
{/*                         e.target.id = 'input-icd10Codes'; */}
{/*                         e.target.name = 'icd10Codes'; */}
{/*                         handleBlur(e); */}
{/*                       }, */}
{/*                       onKeyUp: (e) => { */}
{/*                         handleAutocompleteKeyUp(e); */}
{/*                       }, */}
{/*                       className: errors.icd10Codes && touched.icd10Codes ? "input error" : "input", */}
{/*                     }} */}
{/*                     onSuggestionSelected={(e, { suggestion, method }) => { */}
{/*                       if (method === 'enter') { */}
{/*                         e.preventDefault(); */}
{/*                       } */}
{/*  */}
{/*                       addIcd10Tag(suggestion.code); */}
{/*                       setIcd10Code(''); */}
{/*                       // setFieldValue('icd10Codes', suggestion.code); */}
{/*                     }} */}
{/*                   /> */}
                </div>
  {/*               {isEditing && ( */}
  {/*                 <div className="control"> */}
  {/*                   <button */}
  {/*                     className="button is-info" */}
  {/* //                     onClick={(e) => { */}
  {/* //                       e.preventDefault(); */}
  {/* // */}
  {/* //                       if (process.env.NODE_ENV === 'development') { */}
  {/* //                         console.log('Add ICD-10 code (add button):', icd10Code); */}
  {/* //                       } */}
  {/* // */}
  {/* //                       if (icd10Code.length > 0) { */}
  {/* //                         addIcd10Tag(icd10Code.toUpperCase()); */}
  {/* //                         setIcd10Code(''); */}
  {/* //                         // setFieldValue('icd10Codes', suggestion.code); */}
  {/* //                       } */}
  {/* //                     }} */}
  {/*                   > */}
  {/*                     <span className="icon is-small mr-1"> */}
  {/*                       <i className="fas fa-plus-circle" /> */}
  {/*                     </span> */}
  {/*                     Add */}
  {/*                   </button> */}
  {/*                 </div> */}
  {/*               )} */}
              </div>
              {(errors.icd10Codes && touched.icd10Codes ?
                <div className="input-feedback help" data-testid="errors-icd10Codes">
                  {errors.icd10Codes}
                </div>
                :
                <div className="input-feedback help feedback-hidden" data-testid="errors-icd10Codes"/>
              )}

              {/* {icd10CodeTags.length > 0 && ( */}
              {/*   <div className="tags"> */}
              {/*     {icd10CodeTags.map((tag) => { */}
              {/*       return generateTag(tag, 'icd10Code'); */}
              {/*     })} */}
              {/*   </div> */}
              {/* )} */}
            </div>

            <h4 className="title is-5">Requested Tests</h4>

            {/* <span className="sublabel is-4 mr-3">Urine Validation</span> */}
            {/* <span className="has-text-white"> */}
            {/*   {orderDetails?.toxicologyTests[orderDetails?.toxicologyTests.length-1].pos ? "True" : "False"} */}
            {/* </span> */}
            {/* <br /><br /> */}

            <div className="mb-4">
              <div style={{ overflowX: "auto" }}>
                <table className="table is-bordered is-hoverable mb-6">
                  <thead>
                  <tr className="has-background-info-dark">
                    <th className="has-text-white-ter">Drug Name</th>
                    {/* <th className="has-text-white-ter"><i className="fas fa-plus" /></th> */}
                    <th />
                  </tr>
                  </thead>
                  <tbody>
                  {!isEditing ? orderDetails?.toxicologyTests.filter((test) => test.drugName.substring(0, 4).toUpperCase() !== 'ALL ' && test.pos).map((row, i) => (
                    <tr
                      key={i}
                    >
                      <td className={row.indent && `pl-${4+row.indent}`}>
                        <span className={i === 0 || i === tests?.length-1 ? "has-text-weight-semibold" : ""}>
                          {row.drugName}
                        </span>
                      </td>
                      {/* <td onClick={() => handleClick(row, 'pos')}> */}
                      <td>
                        <span className="icon is-small">
                          <i
                            className="fas fa-check"
                            style={{ visibility: row.pos ? 'visible' : 'hidden' }}
                          />
                        </span>
                      </td>
                    </tr>
                  )) : (
                    generateTestRows()
                  )}
                  </tbody>
                </table>
              </div>

              <div className="columns">
                <div className="column is-5">
                  <label className="label" htmlFor="input-etgTests">
                    Alcohol Biomarker (EtG/EtS)
                  </label>
                </div>

                <div className="column">
                  <label className="checkbox">
                    <Field
                      id="input-etgTests"
                      name="etgTests"
                      type="checkbox"
                      checked={!!etgCheckbox}
                      onChange={() => setEtgCheckbox(!etgCheckbox)}
                      disabled={!isEditing}
                      style={!isEditing ? { cursor: 'default' } : null}
                    />
                    {/* <span className="ml-2 has-text-grey-light"> */}
                    {/*   {etgCheckbox ? "(yes)" : "(no)"} */}
                    {/* </span> */}
                  </label>
                </div>
              </div>

              <div className="block">
                <label className="label" htmlFor="input-customTests">
                  Additional Tests {isEditing && "(test names, separated by commas)"}
                </label>
                <textarea
                  id="input-customTests"
                  name="customTests"
                  className="textarea"
                  placeholder="(Optional)"
                  value={values.customTests}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!isEditing}
                  style={!isEditing ? { cursor: 'default' } : null}
                />
              </div>
            </div>

            <br/>

            <h4 className="title is-5">Point of Care Tests</h4>

            {!isEditing ? generatePOCTestsTable(orderDetails?.pocTests) : (
              <div style={{ overflowX: "auto" }}>
                <table className="table is-bordered is-hoverable mt-5 mb-6">
                  <thead>
                    <tr className="has-background-info-dark">
                      <th className="">Code</th>
                      <th className="">Drug Name</th>
                      <th className="">Positive</th>
                      <th className="">Negative</th>
                      <th className="">N/A</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>AMP</td>
                      <td>Amphetamines</td>
                      <td><Field type="radio" name="AMP" value="pos" /></td>
                      <td><Field type="radio" name="AMP" value="neg" /></td>
                      <td><Field type="radio" name="AMP" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>BAR</td>
                      <td>Barbiturates</td>
                      <td><Field type="radio" name="BAR" value="pos" /></td>
                      <td><Field type="radio" name="BAR" value="neg" /></td>
                      <td><Field type="radio" name="BAR" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>BUP</td>
                      <td>Buprenorphine</td>
                      <td><Field type="radio" name="BUP" value="pos" /></td>
                      <td><Field type="radio" name="BUP" value="neg" /></td>
                      <td><Field type="radio" name="BUP" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>BZO</td>
                      <td>Benzodiazepines</td>
                      <td><Field type="radio" name="BZO" value="pos" /></td>
                      <td><Field type="radio" name="BZO" value="neg" /></td>
                      <td><Field type="radio" name="BZO" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>COC</td>
                      <td>Cocaine</td>
                      <td><Field type="radio" name="COC" value="pos" /></td>
                      <td><Field type="radio" name="COC" value="neg" /></td>
                      <td><Field type="radio" name="COC" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>mAMP</td>
                      <td>Methamphetamine</td>
                      <td><Field type="radio" name="mAMP" value="pos" /></td>
                      <td><Field type="radio" name="mAMP" value="neg" /></td>
                      <td><Field type="radio" name="mAMP" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>MDMA</td>
                      <td>Ecstasy</td>
                      <td><Field type="radio" name="MDMA" value="pos" /></td>
                      <td><Field type="radio" name="MDMA" value="neg" /></td>
                      <td><Field type="radio" name="MDMA" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>MTS</td>
                      <td>Methadone</td>
                      <td><Field type="radio" name="MTS" value="pos" /></td>
                      <td><Field type="radio" name="MTS" value="neg" /></td>
                      <td><Field type="radio" name="MTS" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>OPI</td>
                      <td>Opiates</td>
                      <td><Field type="radio" name="OPI" value="pos" /></td>
                      <td><Field type="radio" name="OPI" value="neg" /></td>
                      <td><Field type="radio" name="OPI" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>OXY</td>
                      <td>Oxycodone</td>
                      <td><Field type="radio" name="OXY" value="pos" /></td>
                      <td><Field type="radio" name="OXY" value="neg" /></td>
                      <td><Field type="radio" name="OXY" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>PCP</td>
                      <td>Phencyclidine</td>
                      <td><Field type="radio" name="PCP" value="pos" /></td>
                      <td><Field type="radio" name="PCP" value="neg" /></td>
                      <td><Field type="radio" name="PCP" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>PPX</td>
                      <td>Propoxyphene</td>
                      <td><Field type="radio" name="PPX" value="pos" /></td>
                      <td><Field type="radio" name="PPX" value="neg" /></td>
                      <td><Field type="radio" name="PPX" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>TCA</td>
                      <td>Tricyclic Antidepressants</td>
                      <td><Field type="radio" name="TCA" value="pos" /></td>
                      <td><Field type="radio" name="TCA" value="neg" /></td>
                      <td><Field type="radio" name="TCA" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>THC</td>
                      <td>Marijuana</td>
                      <td><Field type="radio" name="THC" value="pos" /></td>
                      <td><Field type="radio" name="THC" value="neg" /></td>
                      <td><Field type="radio" name="THC" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>Other</td>
                      <td>
                        <input
                          id="input-otherPOCDrugName0"
                          name="otherPOCDrugName0"
                          className="input"
                          type="text"
                          placeholder="Drug name"
                          value={values.otherPOCDrugName0}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </td>
                      <td><Field type="radio" name="otherPOCDrug0" value="pos" /></td>
                      <td><Field type="radio" name="otherPOCDrug0" value="neg" /></td>
                      <td><Field type="radio" name="otherPOCDrug0" value="n/a" /></td>
                    </tr>
                    <tr>
                      <td>Other</td>
                      <td>
                        <input
                          id="input-otherPOCDrugName1"
                          name="otherPOCDrugName1"
                          className="input"
                          type="text"
                          placeholder="Drug name"
                          value={values.otherPOCDrugName1}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </td>
                      <td><Field type="radio" name="otherPOCDrug1" value="pos" /></td>
                      <td><Field type="radio" name="otherPOCDrug1" value="neg" /></td>
                      <td><Field type="radio" name="otherPOCDrug1" value="n/a" /></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            <br/><br/>

            {isEditing && (
              <div className="mt-6">
                <input
                  type="submit"
                  className="button is-primary"
                  value="Update Order"
                  disabled={isSubmitting}
                />
                {isUpdating ? <ButtonLoading /> : null}

                <button
                  className="button has-text-danger-dark is-pulled-right"
                  onClick={() => {
                    formikRef.current?.resetForm();

                    // Reset toxicology tests
                    resetToxTests();

                    handleCancel();
                  }}
                >
                  Cancel
                </button>
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default ToxicologyOrderDetailsForm;
