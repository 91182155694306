import React, { useEffect, useRef, useState } from 'react';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { useHistory, useParams, Link } from 'react-router-dom';
import _ from 'lodash';

import UpdateSpecimenValidationResultsModal from './UpdateSpecimenValidationResultsModal';
import RequestLabReRun from './RequestLabReRun';
import { createNotification } from '../Notification';
import Loading, { ButtonLoading } from '../Loading';
import { SET_TOXICOLOGY_ORDER_STATUS } from './NewClinicOrdersList';
import { GET_DRUG_METABOLITES, GET_ANALYST_INSTRUCTIONS, SET_TOXICOLOGY_LAB_RESULTS } from '../../constants/gql';
import DrugReport from '../../drug-report';
import { TOXICOLOGY_REPORT_FLAGS } from '../../constants/flags';
import { useAuth } from '../auth';
import { filterTests } from '../../utils';
import * as STATUS from '../../constants/status';
import * as ROUTES from '../../constants/routes';

// const REFRESH_DELAY = 1500;
const REFRESH_DELAY = 3000;

export const GET_TOXICOLOGY_ORDER = gql`
  query GetToxicologyOrder(
    $id: String!
  ) {
    getToxicologyOrder(
      id: $id
    ) {
      id
      clinicCode
      clinicName
      patientForeignKey
      patientId
      patientFirstName
      patientLastName
      patientBirthMonth
      patientBirthDay
      patientBirthYear
      patientEmail
      patientPhone
      providerName
      providerSuffix
      specimenId
      specimenType
      clinicalPurpose
      medicationListTags
      medicationConsistent
      medicationInconsistentNotPrescribed
      medicationInconsistentRxNotFound
      specimenPH
      specimenSpecificGravity
      specimenCreatinine
      clinicUserId
      radeasUserId
      createdDate
      specimenCollectionDate
      status
      prevStatus
      toxicologyTests {
        drugName
        drugClass
        drugBrand
        pos
        neg
        scrn
        result
        cutoff
        c_i
        flag

        isActive
        parents
        scrnAvailable
        cutoffs {
          urineDefault
          urineCustom
          oralFluidDefault
          oralFluidCustom
        }
      }
      batches {
        id
        batchId
        testPanel
        machineName
        createdDate
        updatedDate
        isReRun
      }
      etgTests
      etgTestPanel {
        drugName
        drugClass
        drugBrand
        result
        cutoff
        c_i
        flag

        cutoffs {
          urineDefault
          urineCustom
          oralFluidDefault
          oralFluidCustom
        }
      }
      testStrips {
        drugName
        drugClass
        drugBrand
        scrnAvailable
        isActive
        scrn
        cutoffs {
          urineDefault
          urineCustom
          oralFluidDefault
          oralFluidCustom
        }
        c_i
        flag
      }
      customTests
      pocTests {
        code
        name
        result
      }
      results {
        drugName
        drugClass
        drugBrand
        pos
        neg
        scrn
        result
        cutoff
        c_i
        flag
      }
      etgResults {
        drugName
        drugClass
        drugBrand
        result
        posNeg
        cutoff
        c_i
        flag
      }
      customTestResults {
        drugName
        drugClass
        drugBrand
        result
        posNeg
        cutoff
        c_i
        flag
      }
      testStripsResults {
        drugName
        drugClass
        drugBrand
        scrnAvailable
        isActive
        scrn
        cutoffs {
          urineDefault
          urineCustom
          oralFluidDefault
          oralFluidCustom
        }
        c_i
        flag
      }
      radeasAnalyst0Notes
      additionalFlags
      ehrIntegrationData {
        ormFilename
        receivingApplication
        receivingFacility
        sendingApplication
        sendingFacility
        specimenCollectionDate
        testPanelCode
        testPanelName
      }
    }
  }
`;

export const SET_TOXICOLOGY_ORDER_RESULTS = gql`
  mutation SetToxicologyOrderResults(
    $toxicologyOrderId: String!,
    $specimenId: String,
    $step: String!,
    $medicationConsistent: [String],
    $medicationInconsistentNotPrescribed: [String],
    $medicationInconsistentRxNotFound: [String],
    $medicationListTags: [String],
    $medicationList: String,
    $notes: String,
    $results: [ToxicologyTestInput]!,
    $customTestResults: [CustomTestInput],
    $additionalFlags: [String],
  ) {
    setToxicologyOrderResults(
      data: {
        toxicologyOrderId: $toxicologyOrderId,
        specimenId: $specimenId,
        step: $step,
        medicationConsistent: $medicationConsistent,
        medicationInconsistentNotPrescribed: $medicationInconsistentNotPrescribed,
        medicationInconsistentRxNotFound: $medicationInconsistentRxNotFound,
        medicationListTags: $medicationListTags,
        medicationList: $medicationList,
        notes: $notes,
        results: $results,
        customTestResults: $customTestResults,
        additionalFlags: $additionalFlags,
      }
    ) {
      id
    }
  }
`;

export const SET_ETG_RESULTS = gql`
  mutation SetEtgResult(
    $toxicologyOrderId: String!,
    $step: String!,
    $etgResults: [EtgTestInput]!,
  ) {
    setEtgResult(
      data: {
        toxicologyOrderId: $toxicologyOrderId,
        step: $step,
        etgResults: $etgResults,
      }
    ) {
      id
    }
  }
`;

export const SET_TEST_STRIPS_RESULTS = gql`
  mutation SetTestStripsResults(
    $toxicologyOrderId: String!,
    $step: String!,
    $testStripsResults: [TestStripInput]!,
  ) {
    setTestStripsResults(
      data: {
        toxicologyOrderId: $toxicologyOrderId,
        step: $step,
        testStripsResults: $testStripsResults,
      }
    ) {
      id
    }
  }
`;

const EnterToxicologyResults = () => {
  const { id } = useParams();
  const history = useHistory();

  const [drugReport, setDrugReport] = useState(null);
  const [metabolites, setMetabolites] = useState(null);
  const [orderDetails, setOrderDetails] = useState({});
  const [etgResults, setEtgResults] = useState([]);
  const [testStripsResults, setTestStripsResults] = useState([]);
  const [notes, setNotes] = useState('');
  const [showMedicationSection, setShowMedicationSection] = useState(true);
  const [medicationComparisonSummary, setMedicationComparisonSummary] = useState({});
  const [overrideMedicationComparisonSummary, setOverrideMedicationComparisonSummary] = useState(false);
  const [medicationList, setMedicationList] = useState('');
  const [filter, setFilter] = useState('');
  const [reRunRequested, setReRunRequested] = useState(false);
  const [customTestResults, setCustomTestResults] = useState([]);
  const [pocTests, setPocTests] = useState([]);
  const [additionalFlags, setAdditionalFlags] = useState([]);
  const [analystInstructions, setAnalystInstructions] = useState('');

  const [isV2, setIsV2] = useState(false);
  const [loading, setLoading] = useState(true);

  const [showSpecimenValidationModal, setShowSpecimenValidationModal] = useState(null);
  const [tempStorage, setTempStorage] = useState({});

  // TODO: page reload hack
  const [init, setInit] = useState(false);
  const [timeOut, setTimeOut] = useState(null);

  const filterRef = useRef(null);

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  // TODO: Fix this. This is a hack to fix the page not loading issue
  // that happens when someone navigates away from this page back to the
  // pending orders list, and then clicks on a new specimen to enter results.
  // Possible graphql cache issue?
  useEffect(() => {
    // Gets canceled if "init" variable is set to true.
    const t = setTimeout(() => {
      window.location.reload();
    }, REFRESH_DELAY);

    setTimeOut(t);

    return () => {
      // Clear timeout interval on component unmount
      clearTimeout(timeOut);
    };
  }, []);

  // Cancel reload if page loads properly
  useEffect(() => {
    if (init) {
      clearTimeout(timeOut);
    }
  }, [init]);

  // Handle back button press
  // TODO: Intercepting back button press is unreliable. State is being set,
  //       but previous page isn't always getting it.
//   useEffect(() => {
//     window.onpopstate = (e) => {
//       setOrderStatus({ variables: {
//         id: orderDetails.id,
//         newStatus: STATUS.TOXICOLOGY_ANALYST_PENDING,
//         prevStatus: orderDetails.status,
//       }});
//
//       const state = { id, status: STATUS.TOXICOLOGY_ANALYST_PENDING };
//
//       history.push(ROUTES.DASHBOARD_ANALYST_NEW_ORDERS, state);
//     };
//   });

  // Focus cursor in drug filter input element
  useEffect(() => {
    filterRef.current && filterRef.current.focus();
  }, []);

  // Update drugReport on medication list change
  useEffect(() => {
    if (!_.isEmpty(orderDetails)){
      const drugReport = new DrugReport({
        metabolites,
        medicationList: medicationList.split(',').map((name) => name.trim()),
        toxicologyTests: filterTests(orderDetails.toxicologyTests),
      });

      setDrugReport(drugReport);
    }
  }, [medicationList]);

  // Check if v2
  useEffect(() => {
    if (!isV2) {
      if (orderDetails.toxicologyTests && orderDetails.toxicologyTests.length > 0 && 'isActive' in orderDetails.toxicologyTests[0] && !_.isNull(orderDetails.toxicologyTests[0].isActive)) {
        setIsV2(true);
      }
    }
  }, [orderDetails]);

  useEffect(() => {
    if (isV2) {
      console.log('useEffect() isV2');

      const tests = orderDetails.toxicologyTests.map((test) => ({
        ...test,
        c_i: "C",
        flag: "",
        pos: true,
        result: "ND",
        cutoff: getCutoff(test.cutoffs, orderDetails.specimenType),
      }));

      let etg = [];

      if (orderDetails.etgTests.includes('ETG')) {
        etg = tests
          .filter(({ drugClass }) => drugClass.toUpperCase().includes('ALCOHOL'))
          .map((test) => ({
            ...test,
            posNeg: "NEG",
          }));
      } else if (orderDetails.etgTests.includes('ETHYL GLUCURONIDE') || orderDetails.etgTests.includes('ETHYL SULFATE')) {
        etg = orderDetails.etgTestPanel
          .map((test) => ({
            ...test,
            posNeg: "NEG",
            c_i: "C",
            flag: "",
            pos: true,
            result: "ND",
            cutoff: getCutoff(test.cutoffs, orderDetails.specimenType),
          }));
      }

      setOrderDetails({
        ...orderDetails,
        toxicologyTests: tests.filter(({ drugClass }) => !drugClass.toUpperCase().includes('ALCOHOL')),
      });
      setEtgResults(etg);
    }
  }, [isV2]);

  // Handle test strips C/I and flags
  const testStripsWithFlags = ['BARBITURATES', ];
  useEffect(() => {
    // Add C/I and flag if test strip uses flags
    if (testStripsResults.length > 0 ) {
      const updated = testStripsResults.map((test) => {
        if (testStripsWithFlags.includes(test.drugName.toUpperCase())) {
          if (_.isNull(test.c_i) && _.isNull(test.flag)) {
            return {
              ...test,
              c_i: 'C',
              flag: '',
            };
          }
        }

        return test;
      });

      // Prevent an infinite loop
      if (!_.isEqual(testStripsResults, updated)) {
        setTestStripsResults(updated);
      }
    }
  }, [testStripsResults]);

  // Get the order details
  useQuery(
    GET_TOXICOLOGY_ORDER,
    {
      // fetchPolicy: 'network-only',
      fetchPolicy: 'no-cache',
      variables: { id },
      onCompleted({ getToxicologyOrder }) {
        if (process.env.NODE_ENV === 'development') {
          console.log('getToxicologyOrder:', getToxicologyOrder);
        }

        // Get analyst instructions for clinic
        getAnalystInstructions({ variables: { clinicCode: getToxicologyOrder.clinicCode } });

        // Merge saved toxicologyTests
        if (getToxicologyOrder.results.length > 0) {
          const tox = _.unionBy(getToxicologyOrder.results, getToxicologyOrder.toxicologyTests, 'drugName');

          const details = _.cloneDeep(getToxicologyOrder);
          details.toxicologyTests = tox;

          setOrderDetails(details);
        } else {
          setOrderDetails(getToxicologyOrder);
        }

        // Merge saved test strips results
        if (getToxicologyOrder.testStripsResults.length > 0) {
          const strips = _.unionBy(getToxicologyOrder.testStripsResults, getToxicologyOrder.testStrips, 'drugName');

          setTestStripsResults(strips);
        } else {
          setTestStripsResults(getToxicologyOrder.testStrips);
        }

        // Check for saved notes
        if (getToxicologyOrder.radeasAnalyst0Notes && getToxicologyOrder.radeasAnalyst0Notes.length > 0) {
          setNotes(getToxicologyOrder.radeasAnalyst0Notes);
        }

        // Process EtG if necessary
        let etg = [];

        if ('etgTestPanel' in getToxicologyOrder) {
          // Check if result is null, replace with "ND"
          etg = _.cloneDeep(getToxicologyOrder.etgTestPanel).map((row) => {
            if (row.result == null) {
              row.result = 'ND';
            }

            if (row.c_i == null) {
              row.c_i = 'C';
            }

            if (!('posNeg' in row)) {
              row['posNeg'] = 'NEG';
            }

            return row;
          });
        }

        // Merge saved EtG tests
        if (getToxicologyOrder.etgResults.length > 0) {
          const mergedEtg = _.unionBy(getToxicologyOrder.etgResults, etg, 'drugName');

          setEtgResults(mergedEtg);
        } else {
          setEtgResults(etg);
        }

        // Process custom tests if necessary
        let custom = [];

        if ('customTests' in getToxicologyOrder) {
          if (getToxicologyOrder.customTests.length > 0) {
            custom = getToxicologyOrder.customTests.split(',').map((drugName) => ({
              drugName: drugName.trim(),
              drugClass: drugName.trim().toUpperCase() === 'ZOLPIDEM' ? 'Zolpidem' : '',
              drugBrand: drugName.trim().toUpperCase() === 'ZOLPIDEM' ? 'Ambien' : '',
              result: 'ND',
              posNeg: 'NEG',
              cutoff: drugName.trim().toUpperCase() === 'ZOLPIDEM' ? '50' : '',
              c_i: 'C',
              flag: '',
            }));
          }
        }

        // Merge saved custom test results
        if (getToxicologyOrder.customTestResults.length > 0) {
          const mergedCustomTests = _.unionBy(getToxicologyOrder.customTestResults, custom, 'drugName');

          setCustomTestResults(mergedCustomTests);
        } else {
          setCustomTestResults(custom);
        }

        // Process POC tests (show positive results only)
        if ('pocTests' in getToxicologyOrder) {
          if (getToxicologyOrder.pocTests.length > 0) {
            const posPocTests = getToxicologyOrder.pocTests.filter((test) => (
              test.result.toUpperCase() === 'POS'
            ));

            setPocTests(posPocTests);
          }
        }

        // Process medication list if necessary
        if ('medicationListTags' in getToxicologyOrder) {
          const meds = getToxicologyOrder.medicationListTags.join(', ');

          setMedicationList(meds);

          if (meds.length > 0) {
            setShowMedicationSection(true);
          } else {
            setShowMedicationSection(false);
          }
        }

        setMedicationComparisonSummary({
          medicationConsistent: getToxicologyOrder.medicationConsistent.join(', '),
          medicationInconsistentNotPrescribed: getToxicologyOrder.medicationInconsistentNotPrescribed.join(', '),
          medicationInconsistentRxNotFound: getToxicologyOrder.medicationInconsistentRxNotFound.join(', '),
        });

        if ('additionalFlags' in getToxicologyOrder) {
          setAdditionalFlags(getToxicologyOrder.additionalFlags);
        }

        getDrugMetabolitesQuery();
      },
      onError(error) {
        console.log('Get toxicology error:', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      }
    }
  );

  // Get drug metabolites list
  const [getDrugMetabolitesQuery] = useLazyQuery(
    GET_DRUG_METABOLITES,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ drugMetabolites }) {
        setMetabolites(drugMetabolites);

        const drugReport = new DrugReport({
          drugMetabolites,
          // medicationList: orderDetails.medicationList && DrugReport.splitDrugList(orderDetails.medicationList),
          medicationList: orderDetails.medicationListTags,
          toxicologyTests: filterTests(orderDetails.toxicologyTests),
        });

        setDrugReport(drugReport);

        // Activate all tests and child metabolites
        const order = _.cloneDeep(orderDetails);
        const tests = [];
        order.toxicologyTests.forEach((test) => {
          if (drugReport.allTestsAndMetabolites.includes(test.drugName.toUpperCase())) {
            tests.push({
              ...test,
              pos: true,
            });
          } else {
            tests.push({ ...test });
          }
        });

        order.toxicologyTests = tests;
        setOrderDetails(order);

        setInit(true);
        setLoading(false);
      },
      onError(error) {
        console.log('Get drug metabolites:', error);
        createNotification('danger', 'Sorry, could not retrieve drug metabolites.');
      }
    }
  );

  // Clinic Analyst special instructions
  const [getAnalystInstructions, { loading: loadingAnalystInstructions }] = useLazyQuery(
    GET_ANALYST_INSTRUCTIONS,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ toxicologyTestProfileByClinicCode: instructions }) {
        if (process.env.NODE_ENV === 'development') console.log('Analyst Instructions:', instructions);

        setAnalystInstructions(instructions.analystInstructions);
      },
      onError(error) {
        console.log('ClinicPage query error:', error);
        createNotification('danger', 'Sorry, could not get clinic-specific analyst instructions.');
      }
    }
  );

  // Save results
  const [saveOrderResults, { loading: savingTox }] = useMutation(
    SET_TOXICOLOGY_ORDER_RESULTS,
    {
      onCompleted({ setToxicologyOrderResults }) {
        setOrderStatus({ variables: {
            id: orderDetails.id,
            newStatus: STATUS.TOXICOLOGY_ANALYST_REVIEW,
            prevStatus: orderDetails.status,
          }});

        createNotification('info', 'Results saved');
        history.push(ROUTES.DASHBOARD_ANALYST_NEW_ORDERS, { id, status: STATUS.TOXICOLOGY_ANALYST_REVIEW });
      },
      onError(error) {
        setOrderStatus({ variables: {
            id: orderDetails.id,
            newStatus: orderDetails.prevStatus,
            prevStatus: orderDetails.status,
          }});

        console.log(error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    }
  );

  // Save partial results
  const [savePartialOrderResults, { loading: savingPartial }] = useMutation(
    SET_TOXICOLOGY_ORDER_RESULTS,
    {
      onCompleted({ setToxicologyOrderResults }) {
        // setOrderStatus({ variables: {
        //     id: orderDetails.id,
        //     newStatus: STATUS.TOXICOLOGY_ANALYST_REVIEW,
        //     prevStatus: orderDetails.status,
        //   }});

        createNotification('info', 'Results saved');
        // history.push(ROUTES.DASHBOARD_ANALYST_NEW_ORDERS);
      },
      onError(error) {
        // setOrderStatus({ variables: {
        //     id: orderDetails.id,
        //     newStatus: orderDetails.prevStatus,
        //     prevStatus: orderDetails.status,
        //   }});

        console.log(error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    }
  );

  // Save EtG/EtS results
  const [saveEtgResults, { loading: savingEtg }] = useMutation(
    SET_ETG_RESULTS,
    {
      onCompleted({ setEtgResult }) {
        if (process.env.NODE_ENV === 'development')
          console.log('setEtgResult:', setEtgResult);
      },
      onError(error) {
        console.log(error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    }
  );

  // Save test strips results
  const [saveTestStripsResults, { loading: savingTestStrips }] = useMutation(
    SET_TEST_STRIPS_RESULTS,
    {
      onCompleted({ setTestStripsResults }) {
        if (process.env.NODE_ENV === 'development')
          console.log('setTestStripsResults:', setTestStripsResults);
      },
      onError(error) {
        console.log(error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    }
  );

  // Update specimen validation results
  const [setSpecimenValidation, { loading: savingValidation }] = useMutation(
    SET_TOXICOLOGY_LAB_RESULTS,
    {
      onCompleted({ setToxicologyLabResults }) {
        if (process.env.NODE_ENV === 'development') console.log('Set Validation:', setToxicologyLabResults);

        setTempStorage({});

        createNotification('info', 'Specimen validation results updated.');
        // handleCloseSpecimenValidationModal();
      },
      onError(error) {
        console.log('setToxicologyLabResults', error);

        setOrderDetails({
          ...orderDetails,
          specimenPH: tempStorage.specimenPH,
          specimenCreatinine: tempStorage.specimenCreatinine,
          specimenSpecificGravity: tempStorage.specimenSpecificGravity,
        });
        setTempStorage({});

        createNotification('danger', 'Sorry, could not update specimen validation values.');
        // handleCloseSpecimenValidationModal();
      },
    }
  );

  // Set order status
  const [setOrderStatus] = useMutation(SET_TOXICOLOGY_ORDER_STATUS);

  const getCutoff = (cutoffs, specimenType) => {
    switch (specimenType.toUpperCase()) {
      case 'URINE':
        return cutoffs.urineCustom ? `${cutoffs.urineCustom}` : `${cutoffs.urineDefault}`;
      case 'ORAL FLUID':
        return cutoffs.oralFluidCustom ? `${cutoffs.oralFluidCustom}` : `${cutoffs.oralFluidDefault}`;
      default:
        return cutoffs.urineCustom ? `${cutoffs.urineCustom}` : `${cutoffs.urineDefault}`;
    }
  };

  const generateTable = () => {
    if (!orderDetails.toxicologyTests) return null;

    // Only display selected tests and their metabolites
    const tests = filterTests(orderDetails.toxicologyTests);

    if (filter === '') {
      return generateRows(tests);
    } else {
      // Filter the results
      const filtered = tests.filter((test, i) => {
        // Skip "All" and "Urine Validation"
        if (test.drugName.substring(0, 4).includes('All ') || test.drugName.includes('Urine Validation Testing')) {
          return false;
        }

        return test.drugName.toLowerCase().includes(filter.toLowerCase());
      });

      return generateRows(filtered);
    }
  };

  const handleAutoGeneratedMedicationComparisonSummary = (summary) => {
    // Bypass if overridden
    if (overrideMedicationComparisonSummary) {
      return;
    }

    const keys = Object.keys(summary);

    let update = false;

    keys.forEach((key) => {
      // if (summary[key].join(', ').toUpperCase() !== medicationComparisonSummary[key].toUpperCase()) {
      //   update = true;
      // }

      const iSummary = summary[key].map((name) => name.toUpperCase()).sort();
      const list = medicationComparisonSummary[key].length > 0 ? medicationComparisonSummary[key].split(',').map((name) => name.trim().toUpperCase()).sort() : [];

      if (!_.isEqual(iSummary, list)) {
        update = true;
      }
    });

    if (update) {
      setMedicationComparisonSummary({
        medicationConsistent: summary['medicationConsistent'].join(', '),
        medicationInconsistentNotPrescribed: summary['medicationInconsistentNotPrescribed'].join(', '),
        medicationInconsistentRxNotFound: summary['medicationInconsistentRxNotFound'].join(', '),
      });
    }
  };

  const generateRows = (rows) => {
    return (
      rows.filter((test) => (
        !(test.drugName === 'All (G0483; 80307)' || test.drugName === 'Urine Validation Testing')
      )).map((test, i) => {
        const result = drugReport.evaluate({
          drugName: test.drugName,
          cutoff: test.cutoff,
          value: test.result,
        });

        if (test.c_i !== result.cI) {
          mergeResults(test, {
            c_i: result.cI,
            flag: result.flags.join(', '),
          });
        }

        // Handle medication comparison summary
        handleAutoGeneratedMedicationComparisonSummary(result.medicationComparisonSummary);

        return (
          <tr key={i} className={test.result !== "ND" ? "has-text-danger" : null}>
            <td><span className="has-text-weight-semibold">{test.drugName}</span></td>
            <td>{test.drugBrand}</td>
            <td>
              {test.scrn === '+' || test.scrn === '-' ?
                (<React.Fragment>
                    <input
                      name="scrn"
                      type="checkbox"
                      checked={test.scrn === '+'}
                      onChange={() => handleScrn(test)}
                    />
                    <span className="ml-2">{test.scrn}</span>
                  </React.Fragment>
                )
                :
                null}
            </td>
            <td>{test.cutoff}</td>
            <td>{result.posNeg}</td>
            <td>{result.cI}</td>
            <td>
              <input
                name="result"
                type="text"
                className="input"
                value={test.result}
                onChange={(e) => handleChange(e, test)}
                onFocus={selectAll}
              />
            </td>
            <td>
              {/* <input */}
              {/*   name="flag" */}
              {/*   type="text" */}
              {/*   className="input" */}
              {/*   value={result.flags.join(', ')} */}
              {/*   readOnly */}
              {/* /> */}
              <div className="select">
                <select
                  style={{ width: '180px' }}
                  onChange={(e) => handleSelect(e, test)}
                  value={test.flag}
                >
                  <option value="" />

                  {TOXICOLOGY_REPORT_FLAGS.map((flag) => (
                    <option key={flag} value={flag}>
                      {flag}
                    </option>
                  ))}
                </select>
              </div>
            </td>
          </tr>
        );
      })
    );
  };

  const generateTestStrips = () => {
    if (orderDetails.testStrips?.length === 0)
      return;

    return (
      <>
        <br /><br />
        <hr />

        <h4 className="title is-4">Test Strips</h4>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Drug Name</th>
              <th>Drug Class</th>
              <th>Drug Brand</th>
              <th>Scrn</th>
              <th>Cutoff (ng/ml)</th>
              <th>C/I</th>
              <th>Flag</th>
            </tr>
            </thead>
            <tbody>
              {generateTestStripsRows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const generateTestStripsRows = () => {
    return (
      testStripsResults.map((test, i) => {
        const cutoff = test.cutoffs.urineCustom ? test.cutoffs.urineCustom : test.cutoffs.urineDefault;

        return (
          <tr key={i}>
            <td><span className="has-text-weight-semibold">{test.drugName}</span></td>
            <td>{test.drugClass}</td>
            <td>{test.drugBrand}</td>
            <td>
              <input
                name="scrn"
                type="checkbox"
                checked={test.scrn}
                onChange={() => handleTestStrip(i, test)}
              />
              <span className="ml-2">{test.scrn}</span>
            </td>
            <td>{cutoff}</td>
            <td>{test.c_i ? test.c_i : ""}</td>
            <td>
              {!_.isNull(test.flag) ? (
                <div className="select">
                  <select
                    style={{ width: '180px' }}
                    onChange={(e) => handleTestStripsFlag(e, test)}
                    value={test.flag}
                  >
                    <option value="" />

                    {TOXICOLOGY_REPORT_FLAGS.map((flag) => (
                      <option key={flag} value={flag}>
                        {flag}
                      </option>
                    ))}
                  </select>
                </div>
              ) : ("")}
            </td>
          </tr>
        );
      })
    );
  };

  const handleTestStrip = (i, test) => {
    const updated = _.cloneDeep(testStripsResults);

    updated[i] = {
      ...updated[i],
      scrn: !updated[i].scrn,
    };

    // Update medication comparison summary if needed
    if (testStripsWithFlags.includes(test.drugName.toUpperCase())) {
      // Regenerate medication comparison summary
      const result = drugReport.evaluate({
        drugName: test.drugName,
        cutoff: '5',                            // Dummy cutoff
        value: updated[i].scrn ? '100' : '0',   // Dummy value (screen is boolean)
      });

      // Merge results
      updated[i] = {
        ...updated[i],
        c_i: result.cI,
        flag: result.flags[0] ? result.flags[0] : "",
      };

      // Handle medication comparison summary
      handleAutoGeneratedMedicationComparisonSummary(result.medicationComparisonSummary);
    }

    setTestStripsResults(updated);
  };

  const generateEtg = () => {
    if (orderDetails.etgTests?.length === 0)
      return;

    return (
      <>
        <br /><br />
        <hr />

        <h4 className="title is-4">Alcohol(s)</h4>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Test Performed</th>
              <th>Name</th>
              <th>Cutoff (ng/ml)</th>
              <th>POS/NEG</th>
              <th>C/I</th>
              <th>Result</th>
              <th>Flag</th>
            </tr>
            </thead>
            <tbody>
              {generateEtgRows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const generateEtgRows = () => {
    return (
      etgResults.map((row, i) => {
        // Regenerate medication comparison summary
        const result = drugReport.evaluate({
          drugName: row.drugName,
          cutoff: row.cutoff,
          value: row.result,
        });

        // Handle medication comparison summary
        handleAutoGeneratedMedicationComparisonSummary(result.medicationComparisonSummary);

        return (
          <tr key={i}>
            <td>{row.drugName}</td>
            <td>{row.drugBrand}</td>
            <td>{row.cutoff}</td>
            <td>{row.posNeg}</td>
            <td>{row.c_i}</td>
            <td>
              <input
                name="result"
                type="text"
                className="input"
                value={row.result}
                // onChange={(e) => checkEtgResult(e, i)}
                onChange={(e) => handleChange(e, row)}
                onFocus={selectAll}
              />
            </td>
            <td>
              <div className="select">
                <select
                  style={{ width: '180px' }}
                  onChange={(e) => handleEtgFlagSelect(e, i)}
                  value={row.flag}
                >
                  <option value="" />

                  {TOXICOLOGY_REPORT_FLAGS.map((flag) => (
                    <option key={flag} value={flag}>
                      {flag}
                    </option>
                  ))}
                </select>
              </div>
            </td>
          </tr>
        );
      })
    );
  };

  const checkEtgResult = (e, index) => {
    let result;

    const etg = _.cloneDeep(etgResults);
    etg[index].result = e.target.value;

    if (e.target.value.trim()[0] === '>') {
      result = parseFloat(e.target.value.trim().split('>')[1].trim());
    } else {
      result = parseFloat(e.target.value);
    }

    // Check if result is above cutoff
    let cutoff = parseFloat(etgResults[index].cutoff);

    if (_.isNaN(cutoff)) {
      cutoff = 500;

      etg[index].cutoff = cutoff.toString();
    }

    if (!_.isNaN(result)) {
      if (result >= cutoff) {
        etg[index].posNeg = 'POS';
        etg[index].c_i = 'I';
      } else {
        etg[index].posNeg = 'NEG';
        etg[index].c_i = 'C';
      }
    }

    setEtgResults(etg);
  };

  const handleEtgFlagSelect = (e, index) => {
    const etg = _.cloneDeep(etgResults);
    etg[index].flag = e.target.value;

    setEtgResults(etg);
  };

  const generateCustomTests = () => {
    if (customTestResults.length === 0)
      return;

    return (
      <>
        <br /><br />
        <hr />

        <h4 className="title is-4">Additional Tests</h4>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Drug Name</th>
              <th>Drug Class</th>
              <th>Drug Brand</th>
              <th>Cutoff (ng/ml)</th>
              <th>POS/NEG</th>
              <th>C/I</th>
              <th>Result</th>
              <th>Flag</th>
            </tr>
            </thead>
            <tbody>
              {generateCustomTestRows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const generateCustomTestRows = () => {
    return (
      customTestResults.map((row, i) => (
        <tr key={i}>
          <td>
            <input
              name="drugName"
              type="text"
              className="input"
              value={row.drugName}
              onChange={(e) => handleCustomDrugChange(e, i)}
            />
          </td>
          <td>
            <input
              name="drugClass"
              type="text"
              className="input"
              value={row.drugClass}
              onChange={(e) => handleCustomDrugChange(e, i)}
            />
          </td>
          <td>
            <input
              name="drugBrand"
              type="text"
              className="input"
              value={row.drugBrand}
              onChange={(e) => handleCustomDrugChange(e, i)}
            />
          </td>
          <td>
            <input
              name="cutoff"
              type="text"
              className="input"
              value={row.cutoff}
              onChange={(e) => handleCustomDrugChange(e, i)}
            />
          </td>
          <td>
            <input
              name="posNeg"
              type="text"
              className="input"
              value={row.posNeg}
              onChange={(e) => handleCustomDrugChange(e, i)}
            />
          </td>
          <td>
            <input
              name="c_i"
              type="text"
              className="input"
              value={row.c_i}
              onChange={(e) => handleCustomDrugChange(e, i)}
            />
          </td>
          <td>
            <input
              name="result"
              type="text"
              className="input"
              value={row.result}
              onChange={(e) => handleCustomDrugChange(e, i)}
              onFocus={selectAll}
            />
          </td>
          <td>
            <div className="select">
              <select
                style={{ width: '180px' }}
                onChange={(e) => handleCustomDrugFlagSelect(e, i)}
                value={row.flag}
              >
                <option value="" />

                {TOXICOLOGY_REPORT_FLAGS.map((flag) => (
                  <option key={flag} value={flag}>
                    {flag}
                  </option>
                ))}
              </select>
            </div>
          </td>
        </tr>
      ))
    );
  };

  const handleCustomDrugChange = (e, i) => {
    const updated = _.cloneDeep(customTestResults);
    updated[i][e.target.name] = e.target.value;

    setCustomTestResults(updated);
  };

  const handleCustomDrugFlagSelect = (e, index) => {
    const updated = _.cloneDeep(customTestResults);
    updated[index].flag = e.target.value;

    setCustomTestResults(updated);
  };

  const handleSelect = (e, test) => {
    mergeResults(test, {
      flag: e.target.value,
    });
  };

  const handleTestStripsFlag = (e, test) => {
    const newTests = _.cloneDeep(testStripsResults);
    const index = _.findIndex(testStripsResults, { drugName: test.drugName });

    newTests.splice(index, 1, { ...test, flag: e.target.value });

    setTestStripsResults(newTests);
  };

  const generateMedicationList = () => {
    return (
      <input
        name="medicationListInput"
        type="text"
        className="input mt-2"
        value={medicationList}
        onChange={(e) => setMedicationList(e.target.value)}
      />
    );
  };

  const handleScrn = (test) => {
    if (test.scrn === '-' || test.scrn === null) {
      mergeResults(test, { scrn: '+' });
    } else {
      mergeResults(test, { scrn: '-' });
    }
  };

  const mergeResults = (test, result) => {
    // console.log('[mergeResults] test:', test);
    // console.log('[mergeResults] result:', result);
    const newToxicologyTests = _.cloneDeep(orderDetails.toxicologyTests);
    const newVal = _.cloneDeep(test);
    const index = _.findIndex(newToxicologyTests, { drugName: test.drugName });

    newToxicologyTests.splice(index, 1, { ...newVal, ...result });

    setOrderDetails({ ...orderDetails, toxicologyTests: newToxicologyTests });
  };

  const mergeEtgResults = (test, result) => {
    const newEtgTests = _.cloneDeep(etgResults);
    const newVal = _.cloneDeep(test);
    const index = _.findIndex(newEtgTests, { drugName: test.drugName });

    newEtgTests.splice(index, 1, { ...newVal, ...result });

    setEtgResults(newEtgTests);
  };

  const selectAll = (e) => {
    if (e.target.value.toUpperCase() === 'ND') {
      e.target.select();
    }
  };

  const handleChange = (e, test) => {
    // Evaluate
    const result = drugReport.evaluate({
      drugName: test.drugName,
      cutoff: test.cutoff,
      value: e.target.value,
    });

    // Handle medication comparison summary
    handleAutoGeneratedMedicationComparisonSummary(result.medicationComparisonSummary);

    if (test.drugName.substring(0, 5).toUpperCase() === 'ETHYL') {
      mergeEtgResults(test, {
        c_i: result.cI,
        flag: result.flags.join(', '),
        posNeg: result.posNeg,
        result: e.target.value,
      });
    } else {
      mergeResults(test, {
        c_i: result.cI,
        flag: result.flags.join(', '),
        result: e.target.value,
      });
    }
  };

  const handleMedicationComparisonSummary = (e) => {
    const { name, value } = e.target;

    setMedicationComparisonSummary({
      ...medicationComparisonSummary,
      [name]: value,
    });
  };

  const handleAdditionalFlags = (e, idx) => {
    setAdditionalFlags(additionalFlags.map((row, i) => {
      if (i === idx) {
        return e.target.value;
      }

      return row;
    }));
  };

  const handleDeleteAdditionalFlag = (idx) => {
    setAdditionalFlags(additionalFlags.filter((_, i) => i !== idx));
  };

  const handleShowSpecimenValidationModal = (validation) => {
    setShowSpecimenValidationModal({ ...validation });
  };

  const handleCloseSpecimenValidationModal = () => {
    setShowSpecimenValidationModal(null);
  };

  const handleUpdateSpecimenValidationResults = ({ specimenPH, specimenSpecificGravity, specimenCreatinine }) => {
    setTempStorage({
      specimenPH: orderDetails.specimenPH,
      specimenSpecificGravity: orderDetails.specimenSpecificGravity,
      specimenCreatinine: orderDetails.specimenCreatinine,
    });

    setOrderDetails({
      ...orderDetails,
      specimenPH,
      specimenCreatinine,
      specimenSpecificGravity,
    });

    setSpecimenValidation({ variables: {
      isReRun: false,
      specimenId: orderDetails.specimenId,
      specimenPH,
      specimenSpecificGravity,
      specimenCreatinine,
    }});

    handleCloseSpecimenValidationModal();
  };

  const generateEtgVariables = () => {
    return {
      toxicologyOrderId: orderDetails.id,
      step: '0',
      etgResults: etgResults.map(({
        drugName,
        drugClass,
        drugBrand,
        result,
        posNeg,
        cutoff,
        c_i,
        flag,
      }) => ({ drugName, drugClass, drugBrand, result, posNeg, cutoff, c_i, flag })),
    };
  };

  const generateTestStripsVariables = () => {
    return {
      toxicologyOrderId: orderDetails.id,
      step: '0',
      testStripsResults: testStripsResults.map(({
        drugName,
        drugClass,
        drugBrand,
        cutoffs,
        scrn,
        c_i,
        flag,
      }) => ({ drugName, drugClass, drugBrand, scrn, c_i, flag,
        cutoffs: {
          urineDefault: cutoffs.urineDefault,
          urineCustom: cutoffs.urineCustom,
          oralFluidDefault: cutoffs.oralFluidDefault,
          oralFluidCustom: cutoffs.oralFluidCustom,
        },
      })),
    };
  };

  const generateToxVariables = () => {
    return {
      toxicologyOrderId: orderDetails.id,
      step: '0',
      medicationConsistent: medicationComparisonSummary.medicationConsistent.split(',').map((m) => m.trim()),
      medicationInconsistentNotPrescribed: medicationComparisonSummary.medicationInconsistentNotPrescribed.split(',').map((m) => m.trim()),
      medicationInconsistentRxNotFound: medicationComparisonSummary.medicationInconsistentRxNotFound.split(',').map((m) => m.trim()),
      medicationListTags: medicationList.split(',').map((name) => name.trim()),
      medicationList: medicationList,
      notes,
      results: orderDetails.toxicologyTests.map(({
        drugName,
        drugClass,
        drugBrand,
        pos,
        neg,
        scrn,
        result,
        cutoff,
        c_i,
        flag,
      }) => ({ drugName, drugClass, drugBrand, pos, neg, scrn, result, cutoff, c_i, flag })),
      customTestResults: customTestResults.map(({
        drugName,
        drugClass,
        drugBrand,
        result,
        posNeg,
        cutoff,
        c_i,
        flag,
      }) => ({ drugName, drugClass, drugBrand, result, posNeg, cutoff, c_i, flag })),
      additionalFlags: additionalFlags
        .filter((flag) => flag.trim().length > 0)
        .map((flag) => flag.trim()),
    };
  };

  const handleSavePartial = () => {
    // Save EtG results
    const etgVariables = generateEtgVariables();
    saveEtgResults({ variables: etgVariables });

    // Save test strips results
    const testStripsVariables = generateTestStripsVariables();
    saveTestStripsResults({ variables: testStripsVariables });

    // Save toxicology (pain) and custom tests
    const toxVariables = generateToxVariables();
    savePartialOrderResults({ variables: toxVariables });
  };

  const handleSubmit = () => {
    // Save EtG results
    const etgVariables = generateEtgVariables();
    saveEtgResults({ variables: etgVariables });

    // Save test strips results
    const testStripsVariables = generateTestStripsVariables();
    saveTestStripsResults({ variables: testStripsVariables });

    // Save toxicology (pain) and custom tests
    const toxVariables = generateToxVariables();
    saveOrderResults({ variables: toxVariables });
  };

  if (loading) return <Loading />;

  return (
    <section className="section">
      <div className="container">
        <div className="page">
          <div
            style={{
              position: "-webkit-sticky",
              position: "sticky",
              top: 20,
              zIndex: 10
            }}
          >
            <div className="is-pulled-left">
              {savingTox ? <ButtonLoading /> : null}

              <button
                className="button is-primary"
                style={{ display: !!showSpecimenValidationModal ? "none" : "inline-block" }}
                disabled={savingTox || !!showSpecimenValidationModal}
                onClick={() => {
                  handleSubmit();
                  // history.goBack();
                }}
              >
                Submit Results
              </button>
            </div>

            <div className="is-pulled-right">
              <button
                className="button is-info ml-3"
                style={{ display: !!showSpecimenValidationModal ? "none" : "inline-block" }}
                disabled={!!showSpecimenValidationModal}
                onClick={handleSavePartial}
              >
                {savingPartial ? <ButtonLoading /> : "Save Partial"}
              </button>

              <span className="ml-3">
                <RequestLabReRun
                  orderDetails={orderDetails}
                  setReRunRequested={setReRunRequested}
                  savePartialResults={handleSavePartial}
                  buttonText="Request Lab Re-run"
                  buttonColor="warning"
                  isCompleteRetest={false}
                  hidden={!!showSpecimenValidationModal}
                />
              </span>

              <button
                className="button is-danger ml-3"
                style={{ display: !!showSpecimenValidationModal ? "none" : "inline-block" }}
                disabled={!!showSpecimenValidationModal}
                onClick={() => {
                  setOrderStatus({ variables: {
                    id: orderDetails.id,
                    newStatus: STATUS.TOXICOLOGY_ANALYST_PENDING,
                    prevStatus: orderDetails.status,
                  }});

                  history.push(ROUTES.DASHBOARD_ANALYST_NEW_ORDERS, { id, status: STATUS.TOXICOLOGY_ANALYST_PENDING });
                }}
              >
                Cancel
              </button>
            </div>

            <br />
            <br />
            <br />
          </div>

          <h3 className="title is-3">Order Details</h3>

          <div>
            <div style={{ overflowX: "auto" }}>
              <table className="table is-fullwidth">
                <thead>
                <tr>
                  <th>Patient Name</th>
                  <th>Patient ID</th>
                  <th>Provider</th>
                  <th>Clinic</th>
                  <th>Clinic Code</th>
                  <th>Specimen ID</th>
                  <th>Specimen Type</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <Link
                      to={`${ROUTES.RADEAS_PATIENT_PAGE.replace(':id', orderDetails.patientForeignKey)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {orderDetails.patientLastName}, {orderDetails.patientFirstName}
                    </Link>
                  </td>
                  <td>{orderDetails.patientId}</td>
                  <td>{orderDetails.providerName}, {orderDetails.providerSuffix}</td>
                  <td>{orderDetails.clinicName}</td>
                  <td>{orderDetails.clinicCode}</td>
                  <td>
                    <Link
                      to={`${ROUTES.RADEAS_TOX_ORDER_DETAILS.replace(':id', orderDetails.id)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {orderDetails.specimenId}
                    </Link>
                  </td>
                  <td>{orderDetails.specimenType}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <hr />

            <div className="is-pulled-right">
              <button
                className="button is-info is-small"
                // data-tooltip="Set/update specimen validation values"
                disabled={savingValidation}
                onClick={() => handleShowSpecimenValidationModal({
                  specimenPH: orderDetails.specimenPH,
                  specimenSpecificGravity: orderDetails.specimenSpecificGravity,
                  specimenCreatinine: orderDetails.specimenCreatinine,
                })}
              >
                {savingValidation ? <ButtonLoading /> : "Update Validation"}
              </button>
            </div>

            <div className="columns has-text-white">
              <div className="column">
                {/* {orderDetails.specimenPH && `Specimen PH: ${orderDetails.specimenPH}`} */}
                Specimen PH: {orderDetails.specimenPH}
              </div>

              <div className="column">
                {/* {orderDetails.specimenSpecificGravity && `Specimen Specific Gravity: ${orderDetails.specimenSpecificGravity}`} */}
                Specimen Specific Gravity: {orderDetails.specimenSpecificGravity}
              </div>

              <div className="column">
                {/* {orderDetails.specimenCreatinine && `Specimen Creatinine: ${orderDetails.specimenCreatinine}`} */}
                Specimen Creatinine: {orderDetails.specimenCreatinine}
              </div>
            </div>

            <div className="my-3">
              <p className="has-text-white-ter">
                <span className="has-text-white has-text-weight-bold">
                  Clinical Purpose:{" "}
                </span>
                {orderDetails.clinicalPurpose ? orderDetails.clinicalPurpose.replace('::', ' ') : "Not specified"}
              </p>
            </div>

            {pocTests.length > 0 && (
              <div className="mt-5 mb-6">
                <p className="has-text-white has-text-weight-bold">Positive POC Tests:</p>
                <ul>
                  {pocTests.map((test, i) => (
                    <li
                      key={i}
                      className="ml-5 has-text-white-ter"
                    >
                      {test.name} ({test.code})
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="columns mt-4">
              <div className="column">
                {orderDetails.batches && orderDetails.batches.length > 0 && (
                  <>
                    <p className="has-text-white has-text-weight-bold">Test Machines:</p>
                    <table className="table is-striped">
                      <thead>
                        <tr>
                          <th>Panel</th>
                          <th>Name</th>
                          <th>Run Date</th>
                          <th>ReRun?</th>
                        </tr>
                      </thead>

                      <tbody>
                        {orderDetails.batches.map((batch) => (
                          <tr key={batch.id}>
                            <td>{batch.testPanel}</td>
                            <td>{batch.machineName}</td>
                            <td>{new Date(parseInt(batch.createdDate)).toLocaleString()}</td>
                            <td className="has-text-centered">{!!batch.isReRun ? <span className="icon"><i className="fas fa-check-circle" /></span> : null}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>

              <div className="column">
                {analystInstructions.length > 0 && (
                  <div className="content">
                    <h4 className="is-size-6 title has-text-white mt-2">
                      Clinic-specific Instructions for Analysts:
                    </h4>

                    <ul>
                      {analystInstructions.split(/\r?\n/).map((line) => {
                        if (line.length > 0) {
                          return <li key={line} className="has-text-white">{line}</li>;
                        }

                        return null;
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {!showMedicationSection && (
              <div
                className="has-text-white is-clickable"
                onClick={() => setShowMedicationSection(!showMedicationSection)}
              >
                <span className="icon">
                  <i className="fas fa-angle-double-down" />
                </span>
                Show Medications Section
                <span className="icon">
                  <i className="fas fa-angle-double-down" />
                </span>
              </div>
            )}

            {showMedicationSection && (
              <>
                <div className="my-5">
                  <p className="has-text-white"><span className="has-text-weight-bold">Medication List</span> {generateMedicationList()}</p>
                </div>

                <div className="my-5">
                  <div className="columns">
                    <div className="column is-one-third">
                      <div className="field">
                        <label className="label" htmlFor="input-medicationInconsistentNotPrescribed">
                          Inconsistent - Not Prescribed
                        </label>
                        <textarea
                          id="input-medicationInconsistentNotPrescribed"
                          name="medicationInconsistentNotPrescribed"
                          className="textarea"
                          placeholder=""
                          value={medicationComparisonSummary.medicationInconsistentNotPrescribed}
                          onChange={handleMedicationComparisonSummary}
                        />
                      </div>
                    </div>

                    <div className="column is-one-third">
                      <div className="field">
                        <label className="label" htmlFor="input-medicationInconsistentRxNotFound">
                          Inconsistent - RX Not Found
                        </label>
                        <textarea
                          id="input-medicationInconsistentRxNotFound"
                          name="medicationInconsistentRxNotFound"
                          className="textarea"
                          placeholder=""
                          value={medicationComparisonSummary.medicationInconsistentRxNotFound}
                          onChange={handleMedicationComparisonSummary}
                        />
                      </div>
                    </div>

                    <div className="column is-one-third">
                      <div className="field">
                        <label className="label" htmlFor="input-medicationConsistent">
                          Consistent
                        </label>
                        <textarea
                          id="input-medicationConsistent"
                          name="medicationConsistent"
                          className="textarea"
                          placeholder=""
                          value={medicationComparisonSummary.medicationConsistent}
                          onChange={handleMedicationComparisonSummary}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="field">
                    <input
                      id="overrideMedicationComparisonSummary"
                      className="switch"
                      type="checkbox"
                      checked={overrideMedicationComparisonSummary}
                      onChange={() => setOverrideMedicationComparisonSummary(!overrideMedicationComparisonSummary)}
                    />
                    <label
                      htmlFor="overrideMedicationComparisonSummary"
                      className="has-text-white"
                    >
                      Override Medication Comparison Summary
                    </label>
                  </div>
                </div>
              </>
            )}
          </div>

          <hr />

          <h4 className="title is-4">Toxicology</h4>

          <div className="my-5">
            <div className="columns">
              <div className="column is-one-third">
                <div className="field is-grouped">
                  <div className="control is-expanded">
                    <input
                      ref={filterRef}
                      name="filter"
                      type="text"
                      className="input"
                      placeholder="Filter by drug name"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  </div>
                  <div className="control">
                    <button
                      className="button is-info"
                      onClick={() => setFilter('')}
                      disabled={filter === '' ? true : false}
                    >
                      <span className="icon is-small mr-2">
                        <i className="fas fa-times-circle" />
                      </span>
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ overflowX: "auto" }}>
            <table className="table is-fullwidth is-striped is-hoverable">
              <thead>
              <tr>
                <th>Drug Name</th>
                <th>Drug Brand</th>
                <th>Scrn</th>
                <th>Cutoff (ng/ml)</th>
                <th>POS/NEG</th>
                <th>C/I</th>
                <th>Result</th>
                <th>Flag</th>
              </tr>
              </thead>
              <tbody>
              {generateTable()}
              </tbody>
            </table>
          </div>

          {generateEtg()}
          {generateTestStrips()}
          {generateCustomTests()}

          <br /><br />
          <hr />

          <div className="my-5">
            <div className="field">
              <label className="label" htmlFor="input-notes">
                Analyst Notes
              </label>
              <textarea
                id="input-notes"
                name="notes"
                className="textarea"
                placeholder="(optional)"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div>

          <hr />

          <div className="my-5">
            <h4 className="title is-4">Additional Flags</h4>

            <div className="columns">
              <div className="column is-full">
                {additionalFlags.map((row, i) => (
                  <div key={i} className="field has-addons">
                    <div className="control is-expanded">
                      <input
                        name="additional-flag"
                        className="input is-fullwidth"
                        type="text"
                        value={additionalFlags[i]}
                        onChange={(e) => handleAdditionalFlags(e, i)}
                      />
                    </div>

                    <div className="control">
                      <button
                        className="button is-danger"
                        onClick={() => handleDeleteAdditionalFlag(i)}
                      >
                        <span className="icon">
                          <i className="fas fa-trash-alt" />
                        </span>
                      </button>
                    </div>
                  </div>
                ))}

                <button
                  className="button is-info my-4"
                  onClick={() => {
                    setAdditionalFlags([
                      ...additionalFlags,
                      "",
                    ]);
                  }}
                >
                  Add Flag
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UpdateSpecimenValidationResultsModal
        isOpen={!!showSpecimenValidationModal}
        handleCloseModal={handleCloseSpecimenValidationModal}
        handleSave={handleUpdateSpecimenValidationResults}
        specimenPH={showSpecimenValidationModal?.specimenPH}
        specimenSpecificGravity={showSpecimenValidationModal?.specimenSpecificGravity}
        specimenCreatinine={showSpecimenValidationModal?.specimenCreatinine}
      />
    </section>
  );
};

export default EnterToxicologyResults;
