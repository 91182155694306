import React, { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHistory, Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Notification, { clearNotification, createNotification, useNotification } from '../Notification';
import { ButtonLoading } from '../Loading';
import { ForgotPasswordLink } from './ForgotPassword';
import { SignUpLink } from './SignUp';

import { decodeJwt } from '../../utils';
import { authUserVar } from '../../cache';
import * as ROUTES from '../../constants/routes';

import '../../styles/form.css';

export const SIGNIN_RADEAS_USER = gql`
  mutation RadeasLogin(
    $email: String!,
    $password: String!
  ) {
    radeasLogin(
      data: {
        email: $email,
        password: $password
      }
    ) {
      token
    }
  }
`;

const SignIn = () => {
  useEffect(() => {
    // Clear notifications when navigating away
    return function cleanup() {
      clearNotification();
    }
  }, []);

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">

            <SignInForm title="Log In" />
            <br />
            <ForgotPasswordLink />
            <SignUpLink />
          </div>
        </div>
      </div>
    </section>
  );
};

const SignInForm = ({ title }) => {
  const history = useHistory();
  const { messageType, messageText } = useNotification();
  const [login, { loading }] = useMutation(
    SIGNIN_RADEAS_USER,
    {
      onCompleted({ radeasLogin }) {
        if (radeasLogin) {
          localStorage.setItem('token', radeasLogin.token);

          // Save id and roles in local state
          const { authUserId, roles } = decodeJwt(radeasLogin.token);
          authUserVar({ id: authUserId, roles });

          // Clear any notifications
          clearNotification();

          // Redirect after login
          history.push(ROUTES.DASHBOARD_ANALYST_NEW_ORDERS);
        }
      },
      onError(error) {
        console.log('Login', error);
        createNotification('danger', 'Incorrect email or password.');
      },
    },
  );

  return (
    <div>
      {/* <Notification messageType={messageType} messageText={messageText} /> */}
      <h1 className="title is-size-2 has-text-centered">Radeas Labs</h1>
      <h4 className="subtitle is-size-6 has-text-centered is">LAB INFORMATION SYSTEM</h4>
      <br/><br/>
      <div className="page">
        <h3 className="title is-4 has-text-centered">{title}</h3>

        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const { email, password } = values;

            // Execute the mutation
            login({ variables: { email, password, } });

            // resetForm();
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Enter a valid email.")
              .required("Email is required."),
            password: Yup.string()
              .required("Password is required.")
          })}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = formProps;

            return (
              <form onSubmit={handleSubmit}>

                  <label className="label" htmlFor="input-email">
                    Email
                  </label>
                  <input
                    id="input-email"
                    name="email"
                    className={errors.email && touched.email ? "input error" : "input"}
                    type="email"
                    placeholder="Enter your email address"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {(errors.email && touched.email ?
                    <div className="input-feedback help has-text-right" data-testid="errors-email">
                      {errors.email}
                    </div>:
                    <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode">
                    </div>
                  )}


                  <label className="label" htmlFor="input-password">
                    Password
                  </label>
                  <input
                    id="input-password"
                    name="password"
                    className={errors.password && touched.password ? "input error" : "input"}
                    type="password"
                    placeholder="Enter a password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {(errors.password && touched.password ?
                    <div className="input-feedback help has-text-right" data-testid="errors-password">
                      {errors.password}
                    </div> :
                    <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode"/>
                  )}


                <input
                  type="submit"
                  className="button is-primary"
                  value="Submit"
                  disabled={isSubmitting}
                />
                {loading ? <ButtonLoading /> : null}
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const SignInLink = () => {
  return (
    <p className="ml-5">
      Already have an account? <Link to={ROUTES.SIGN_IN}>Log In</Link>
    </p>
  );
};

export default SignIn;

export { SignInForm, SignInLink };
