import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useLocation, Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import _ from 'lodash';

import Loading from '../Loading';

import { createNotification } from '../Notification';
import { useAuth } from '../auth';
import * as ROUTES from '../../constants/routes';
import * as STATUS from '../../constants/status';
import * as COOKIES from '../../constants/cookies';
import { SET_TOXICOLOGY_ORDER_STATUS } from './NewClinicOrdersList';

const POLL_INTERVAL = 2500;

export const REVIEW_TOXICOLOGY_ORDERS = gql`
  query AllReviewToxicologyOrders {
    allReviewToxicologyOrders {
      id
      clinicCode
      patientForeignKey
      patientId
      patientForeignKey
      patientFirstName
      patientLastName
      patientBirthMonth
      patientBirthDay
      patientBirthYear
      patientEmail
      patientPhone
      specimenId
      specimenType
      clinicUserId
      radeasUserId
      status
      createdDate
      resultsUploadedDate
      batches {
        batchId
        testPanel
      }
      storageTrayLocation
    }
  }
`;

const ReviewClinicOrdersList = () => {
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState({ column: null, value: null });
  const [loading, setLoading] = useState(true);

  const [cookies, setCookie, removeCookie] = useCookies([COOKIES.ANALYST_REVIEW_ORDERS_FILTER]);

  const location = useLocation();
  const returnOrderId = location?.state?.id;
  const returnOrderStatus = location?.state?.status;

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  // Get pending orders
  const { startPolling, stopPolling } = useQuery(
    REVIEW_TOXICOLOGY_ORDERS,
    {
      // fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted({ allReviewToxicologyOrders }) {
        // Sort order by batch, then by order in batch
        const sortedOrders = _.sortBy(allReviewToxicologyOrders,
          [(o) => (o.batches.at(-1)?.batchId ?? ''), 'storageTrayLocation']
        );

        // Update order if returning to table from enter results page
        if (returnOrderId && returnOrderStatus && loading) {
          const idx = _.findIndex(sortedOrders, { id: returnOrderId });

          if (idx > -1) {
            if (returnOrderStatus === STATUS.TOXICOLOGY_ANALYST_REVIEW || returnOrderStatus === STATUS.TOXICOLOGY_ANALYST_REVIEW_IN_PROCESS) {
              sortedOrders.splice(idx, 1, { ...sortedOrders[idx], status: returnOrderStatus });
            } else if (returnOrderStatus === STATUS.TOXICOLOGY_ANALYST_COMPLETE) {
              sortedOrders.splice(idx, 1);
            }
          }
        }

        // setOrders(allReviewToxicologyOrders);
        setOrders(sortedOrders.map((o) => ({
          ...o,
          batchId: o.batches.at(-1)?.batchId ?? '',
        })));

        setLoading(false);
      },
      onError(error) {
        console.log('Pending toxicology orders', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');

        setLoading(false);
      }
    }
  );

  const [setOrderStatus] = useMutation(SET_TOXICOLOGY_ORDER_STATUS);

  // Start/stop polling
  useEffect(() => {
    startPolling(POLL_INTERVAL);

    return () => stopPolling();
  });
  // }, [startPolling, stopPolling]);

  // Get filter cookie
  useEffect(() => {
    if (COOKIES.ANALYST_REVIEW_ORDERS_FILTER in cookies) {
      if (process.env.NODE_ENV === 'development') {
        console.log('Analyst filter cookie set!');
        console.log(cookies[COOKIES.ANALYST_REVIEW_ORDERS_FILTER]);
      }

      const filterColumns = ['Clinic Code', 'Batch ID'];
      const cookieData = cookies[COOKIES.ANALYST_REVIEW_ORDERS_FILTER];

      // Validate cookie data
      if (!_.has(cookieData, 'column') || !_.has(cookieData, 'value')) {
        return;
      }
      if (!_.includes(filterColumns, cookieData.column)) {
        return;
      }
      if (typeof cookieData.value !== 'string') {
        return;
      }
      if (typeof cookieData.value === 'string' && cookieData.value.length < 1) {
        return;
      }

      setFilter(cookieData);
    }
  }, []);

  // Set filter cookie
  useEffect(() => {
    // Skip if loading
    if (loading) {
      return;
    }

    if (filter.column) {
      if (process.env.NODE_ENV === 'development') {
        console.log('Setting cookie...');
        console.log({ filter });
      }

      setCookie(COOKIES.ANALYST_REVIEW_ORDERS_FILTER, filter, { path: '/' });
    } else {
      if (process.env.NODE_ENV === 'development') {
        console.log('Deleting cookie...');
      }

      removeCookie(COOKIES.ANALYST_REVIEW_ORDERS_FILTER, { path: '/' });
    }
  }, [filter, loading]);

  const setStatus = (status) => {
    switch (status) {
      case STATUS.TOXICOLOGY_ANALYST_PENDING:
      case STATUS.TOXICOLOGY_ANALYST_REVIEW:
        return { color: '#66ff8c' };
      case STATUS.TOXICOLOGY_ANALYST_IN_PROCESS:
      case STATUS.TOXICOLOGY_ANALYST_REVIEW_IN_PROCESS:
        return { color: '#ffff66' };
      // case STATUS.TOXICOLOGY_ANALYST_REVIEW:
        // return { color: '#ffd966' };
      case STATUS.TOXICOLOGY_LAB_RERUN:
        return { color: '#ffd966' };
      case STATUS.TOXICOLOGY_ANALYST_COMPLETE:
        return { color: '#F14668' };
      default:
        return {};
    }
  };

  const getBatchId = (order) => {
    const { batches } = order;

    if (batches.length < 1) {
      return 'unknown';
    }

    let idx = _.findIndex(batches, { testPanel: 'PAIN' });

    if (idx < 0) {
      idx = _.findIndex(batches, { testPanel: 'ETG' });
    }

    if (idx < 0) {
      idx = 0;
    }

    return (
      <span
        className="is-clickable"
        onClick={() => {
          if (filter.column) {
            // Reset filter
            setFilter({ column: null, value: null });
          } else {
            setFilter({ column: 'Batch ID', value: batches[idx].batchId });
          }
        }}
      >
        {batches[idx].batchId}
      </span>
    );
  };

  const renderFilter = () => {
    if (filter.column) {
      return (
        <>
          <span className="has-text-grey-lighter">
            Active Filter: {filter.column} [{filter.value}]
          </span>
          <span
            className="icon has-text-grey ml-3 is-clickable"
            data-tooltip="Clear filter"
            onClick={() => {
              setFilter({ column: null, value: null });
            }}
          >
            <i className="far fa-times-circle" />
          </span>
        </>
      );
    }

    return <span />;
  };

  const renderOrdersTable = () => {
    let tableData = _.cloneDeep(orders);

    if (filter.column) {
      tableData = orders.filter((order) => {
        if (filter.column === 'Clinic Code') {
          return order.clinicCode === filter.value;
        } else if (filter.column === 'Batch ID') {
          const idx = _.findIndex(order.batches, { batchId: filter.value });

          return idx > -1;
        }
      });
    }

    return (
      tableData.map((order) => {
        return (
          <tr key={order.id}>
            <td>
              <Link target="_blank" rel="noopener noreferrer"
                    to={`${ROUTES.RADEAS_PATIENT_PAGE.replace(':id', order.patientForeignKey)}`}>
                {order.patientLastName}, {order.patientFirstName}
              </Link>
            </td>
            <td>
              <span
                className="is-clickable"
                onClick={() => {
                  if (filter.column) {
                    // Reset filter
                    setFilter({ column: null, value: null });
                  } else {
                    setFilter({ column: 'Clinic Code', value: order.clinicCode });
                  }
                }}
              >
                {order.clinicCode}
              </span>
            </td>
            <td>{getBatchId(order)}</td>
            <td>{order.specimenId}</td>
            <td>{order.specimenType}</td>
            <td>{new Date(parseInt(order.createdDate)).toDateString()}</td>
            <td style={setStatus(order.status)} className="has-text-centered">
              <span>
                <span className="mr-3 icon is-small">
                  <i className="fas fa-circle fa-small" aria-hidden="true"/>
                </span>
                {order.status.split(':').pop()}
              </span>
            </td>
            <td style={{textAlign: "right"}}>
              <Link
                to={ROUTES.DASHBOARD_ANALYST_REVIEW_RESULTS.replace(':id', order.id)}
                className="button is-info is-small"
                onClick={() => setOrderStatus({ variables: {
                    id: order.id,
                    newStatus: STATUS.TOXICOLOGY_ANALYST_REVIEW_IN_PROCESS,
                    prevStatus: order.status,
                  }})}
              >
                Review
              </Link>
            </td>
          </tr>
        );
      })
    );
  };

  if (loading) return <Loading />;

  return (
    <div>
      <div className="page">
        <div className="columns">
          <div className="column">
            <h4 className="title is-4 is-inline-block mt-1">Review Orders</h4>
          </div>

          <div className="column">
            {renderFilter()}
          </div>
        </div>

        <br />

        {loading ? (
          <Loading />
        ) : (
          <div style={{ overflowX: "auto" }}>
            <table className="table is-fullwidth is-striped is-hoverable">
              <thead>
              <tr>
                <th>Patient Name</th>
                <th>Clinic Code</th>
                <th>Batch ID</th>
                <th>Specimen ID</th>
                <th>Specimen</th>
                <th>Created Date</th>
                <th>Status</th>
                <th />
              </tr>
              </thead>

              <tfoot>
              </tfoot>

              <tbody>
                {renderOrdersTable()}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewClinicOrdersList;
