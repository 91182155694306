import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';

import { ButtonLoading } from '../Loading';

// const URL = 'https://us-central1-radeaslis-dev.cloudfunctions.net/lab-scanner-specimen-intake';

const CheckInSpecimen = (props) => {
  const [specimenId, setSpecimenId] = useState('');
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [checkedIn, setCheckedIn] = useState([]);

  const inputRef = useRef(null);

  // Focus input on page load
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (props.specimenId && !checkedIn.includes(props.specimenId)) {
      handleSubmit();
      setCheckedIn([ ...checkedIn, props.specimenId ]);
    }
  }, [props.specimenId]);

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (loading) return;
    // if (!props.specimenId) return;

    const token = localStorage.getItem('token');

    const config = {
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    };

    let id = specimenId.length < 1 ? props.specimenId.trim() : specimenId.trim();

    if (checkedIn.includes(id)) {
      setStatus(`Specimen ID ${id} already checked in.`);

      setTimeout(() => {
        setStatus('');
      }, 3000);
    }

    // Look for id in props.orderList
    if (_.findIndex(props.orderList, { specimenId: id }) === -1) {
      const filtered = props.orderList.filter(({ specimenId }) => specimenId.endsWith(id));

      if (filtered.length === 1) {
        id = filtered[0].specimenId.trim();
        setSpecimenId(id);
      } else if (filtered.length > 1) {
        setStatus(`Multiple ${id}'s found. Please manually check-in.`);
        setSpecimenId('');

        setTimeout(() => {
          setStatus('');
        }, 3000);

        return;
      } else {
        setStatus(`"${id}" not found!`);
        // setSpecimenId('');

        setTimeout(() => {
          setStatus('');
        }, 3000);

        return;
      }
    }

    setLoading(true);

    axios.post(process.env.REACT_APP_LAB_SCANNER_INTAKE_URL, { specimenId: id }, config)
      .then((res) => {
        if (process.env.NODE_ENV === 'development') {
          console.log(res);
        }

        if (res.data.statusCode === 200) {
          setStatus(`Specimen ID ${id} received`);
        } else {
          setStatus(`Error receiving specimen ID ${specimenId}`);
        }

        setTimeout(() => {
          setStatus('');
        }, 3000);

        // setStatus(`Specimen ID ${specimenId} received`);
        setSpecimenId('');
        setLoading(false);

        // Notify parent component that check-in is complete
        props.onComplete(res.data);
      })
      .catch((err) => {
        console.log(err);

        setStatus(`Error receiving specimen ID ${specimenId}`);
        setLoading(false);

        // Notify parent component that check-in is complete
        props.onComplete();
      });
  };

  return (
    <form
      className="my-5"
      onSubmit={handleSubmit}
    >
      <div className="columns">
        <div className="column is-one-third pr-0">
          <div className="field is-grouped">
            <div className="control is-expanded">
              <input
                name="specimenId"
                type="text"
                className="input"
                placeholder="Specimen ID"
                value={specimenId}
                onChange={(e) => setSpecimenId(e.target.value)}
                ref={inputRef}
              />
            </div>
            <div className="control">
              <button
                className="button is-info"
                // onClick={handleSubmit}
                disabled={specimenId === '' ? true : false}
              >
                <span className="icon is-small mr-2">
                  <i className="fas fa-qrcode" />
                </span>
                Scan
              </button>
            </div>
          </div>
        </div>

        <div className="column pl-0">
          {loading ? <ButtonLoading /> : null}

          <p className="mx-5 pt-2">{status}</p>
        </div>
      </div>
    </form>
  );
};

export default CheckInSpecimen;
