import React, { createElement, Fragment, useEffect, useRef } from 'react';
import { render } from 'react-dom';
import { autocomplete } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';

import './searchBox.css';

const SearchBox = (props) => {
  const searchBoxRef = useRef(null);

  useEffect(() => {
    if (!searchBoxRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: searchBoxRef.current,
      placeholder: "Search...",
      classNames: { input: "input", inputWrapperPrefix: "inputWrapperPrefix", form: "form-aa" },
      renderer: { createElement, Fragment },
      render({ children }, root) {
        render(children, root);
      },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  return (
    <div ref={searchBoxRef} />
  );
};

export default SearchBox;
