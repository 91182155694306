import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import Loading from '../Loading';

export const PATIENTS_BY_CLINIC = gql`
  query PatientsByClinic(
    $clinicCode: String!
  ) {
    patientsByClinic(
      clinicCode: $clinicCode
    ) {
      id
      patientId
      firstName
      lastName
      birthMonth
      birthDay
      birthYear
      email
      phone
    }
  }
`;

const SelectPatient = ({ clinicCode, handleSelectPatient }) => {
  const [patients, setPatients] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(true);

  useQuery(
    PATIENTS_BY_CLINIC,
    {
      // fetchPolicy: 'network-only',
      variables: { clinicCode },
      onCompleted({ patientsByClinic }) {
        setPatients(patientsByClinic);
        setLoading(false);
      },
      onError(error) {
        console.log('Get patients by clinic', error);
        // TODO: show notification
      },
    }
  );

  const generatePatients = () => {
    if (filter.length === 0) {
      return generateRows(patients);
    } else {
      const filtered = patients.filter((patient) => {
        const birthday = `${patient.birthMonth}/${patient.birthDay}/${patient.birthYear}`;
        const birthdayNoZeros = `${parseInt(patient.birthMonth)}/${parseInt(patient.birthDay)}/${parseInt(patient.birthYear)}`;

        return (
          patient.patientId.toUpperCase().includes(filter.toUpperCase()) ||
          patient.firstName.toUpperCase().includes(filter.toUpperCase()) ||
          patient.lastName.toUpperCase().includes(filter.toUpperCase()) ||
          patient.email.toUpperCase().includes(filter.toUpperCase()) ||
          patient.phone.includes(filter) ||
          patient.phone.replace(/-/g, '').includes(filter) ||
          birthday.includes(filter) ||
          birthdayNoZeros.includes(filter)
        );
      });

      return generateRows(filtered);
    }
  };

  const generateRows = (p) => {
    return (
      p.map((patient) => (
        <tr key={patient.id}>
          <td>{patient.lastName}</td>
          <td>{patient.firstName}</td>
          <td>{patient.patientId}</td>
          <td>{patient.birthMonth}/{patient.birthDay}/{patient.birthYear}</td>
          <td>{patient.email}</td>
          <td>{patient.phone}</td>
          <td style={{textAlign: "right"}}>
            <button
              className="button has-text-info"
              onClick={() => handleSelectPatient(patient)}
            >
              Select
            </button>
          </td>
        </tr>
      ))
    );
  };

  if (loading) return <Loading />;

  return (
    <div>
      <h4 className="subtitle mt-2">Select a Patient</h4>

      <div className="my-5">
        <div className="columns">
          <div className="column is-one-third">
            <div className="field is-grouped">
              <div className="control is-expanded">
                <input
                  name="filter"
                  type="text"
                  className="input"
                  placeholder="Search patients"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </div>
              <div className="control">
                <button
                  className="button is-info"
                  onClick={() => setFilter('')}
                  disabled={filter === '' ? true : false}
                >
                  <span className="icon is-small mr-2">
                    <i className="fas fa-times-circle" />
                  </span>
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6" style={{ overflowX: "auto" }}>
        <table className="table is-fullwidth is-striped is-hoverable">
          <thead>
            <tr>
              <th>Last Name</th>
              <th>First Name</th>
              <th>Patient ID</th>
              <th>Birthday</th>
              <th>Email</th>
              <th>Phone</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {generatePatients()}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SelectPatient;
