import React, { useEffect, useRef, useState } from 'react';
import { gql, useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import download from 'downloadjs';
import _ from 'lodash';
import dateFormat from 'dateformat';

import UpdateSpecimenValidationResultsModal from './UpdateSpecimenValidationResultsModal';
import RequestLabReRun from './RequestLabReRun';

import { createNotification } from '../Notification';
import { GET_DRUG_METABOLITES } from '../../constants/gql';
import DrugReport from '../../drug-report';
import Loading, { ButtonLoading } from '../Loading';
import { TOXICOLOGY_REPORT_FLAGS } from '../../constants/flags';
import { useAuth } from '../auth';
import { filterTests } from '../../utils';
import { SET_TOXICOLOGY_ORDER_RESULTS, SET_ETG_RESULTS, SET_TEST_STRIPS_RESULTS } from './EnterToxicologyResults';
import { GET_RADEAS_USER_BY_ID, GET_ANALYST_INSTRUCTIONS, SET_TOXICOLOGY_LAB_RESULTS } from '../../constants/gql';
import * as ROUTES from '../../constants/routes';

import { decodeJwt } from '../../utils';

// const GENERATE_REPORT_PDF_URL = 'https://us-central1-radeaslis-dev.cloudfunctions.net/create-report-python';
// const REFRESH_DELAY = 1500;
const REFRESH_DELAY = 3000;

export const GET_TOXICOLOGY_COMPLETE_ORDER = gql`
  query GetToxicologyOrder(
    $id: String!
  ) {
    getToxicologyOrder(
      id: $id
    ) {
      id
      clinicCode
      clinicName
      providerName
      providerSuffix
      patientId
      patientForeignKey
      patientFirstName
      patientLastName
      patientBirthMonth
      patientBirthDay
      patientBirthYear
      patientEmail
      patientPhone
      specimenId
      specimenType
      clinicalPurpose
      medicationListTags
      medicationConsistentReview
      medicationInconsistentNotPrescribedReview
      medicationInconsistentRxNotFoundReview
      radeasAnalyst0
      radeasAnalyst0Notes
      radeasAnalyst1
      radeasAnalyst1Notes
      createdDate
      resultsUploadedDate
      specimenPH
      specimenSpecificGravity
      specimenCreatinine
      specimenRunDate
      labUploadedDate
      hasRetest
      retestResultId
      retestOriginalOrderId
      urineValidationReporting
      batches {
        id
        batchId
        testPanel
        machineName
        createdDate
        updatedDate
        isReRun
      }
      toxicologyTests {
        drugName
        drugClass
        drugBrand
        pos
        neg
        scrn
        result
        cutoff
        c_i
        flag
      }
      resultsReview {
        drugName
        drugClass
        drugBrand
        scrn
        pos
        result
        cutoff
        c_i
        flag
      }
      pocTests {
        code
        name
        result
      }
      etgTests
      etgResultsReview {
        drugName
        drugClass
        drugBrand
        result
        posNeg
        cutoff
        c_i
        flag
      }
      testStripsResultsReview {
        drugName
        drugClass
        drugBrand
        scrnAvailable
        isActive
        scrn
        cutoffs {
          urineDefault
          urineCustom
          oralFluidDefault
          oralFluidCustom
        }
        c_i
        flag
      }
      customTestResultsReview {
        drugName
        drugClass
        drugBrand
        result
        posNeg
        cutoff
        c_i
        flag
      }
      additionalFlags
    }
  }
`;

const ViewToxicologyResults = () => {
  const { id } = useParams();
  const history = useHistory();

  const [drugReport, setDrugReport] = useState(null);
  const [metabolites, setMetabolites] = useState(null);
  const [orderDetails, setOrderDetails] = useState({});
  const [customTestResults, setCustomTestResults] = useState([]);
  const [showMedicationSection, setShowMedicationSection] = useState(true);
  const [medicationComparisonSummary, setMedicationComparisonSummary] = useState({});
  const [medicationList, setMedicationList] = useState('');
  const [pocTests, setPocTests] = useState([]);
  const [filter, setFilter] = useState('');
  const [generatingReport, setGeneratingReport] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editorNotes, setEditorNotes] = useState('');
  const [originalResults, setOriginalResults] = useState({});
  const [additionalFlags, setAdditionalFlags] = useState([]);
  const [firstAnalyst, setFirstAnalyst] = useState({});
  const [reviewAnalyst, setReviewAnalyst] = useState({});
  const [analystInstructions, setAnalystInstructions] = useState('');

  const [loading, setLoading] = useState(true);

  const [showSpecimenValidationModal, setShowSpecimenValidationModal] = useState(null);
  const [tempStorage, setTempStorage] = useState({});

  // TODO: page reload hack
  const [init, setInit] = useState(false);
  const [timeOut, setTimeOut] = useState(null);

  const filterRef = useRef(null);

  // Require auth
  const authUser = useAuth({ messageType: 'info', messageText: 'Please log in' });
  const { authUserId, roles, exp, iat } = decodeJwt(authUser);

  // Reload page if it doesn't load in 5 seconds
  // TODO: Fix this. This is a hack to fix the page not loading issue
  // that happens when someone navigates away from this page back to the
  // pending orders list, and then clicks on a new specimen to enter results.
  // Possible graphql cache issue?
  useEffect(() => {
    // Set timeout of 5 seconds to force a page reload.
    // Gets canceled if "init" variable is set to true.
    const t = setTimeout(() => {
      window.location.reload();
    }, REFRESH_DELAY);

    setTimeOut(t);

    return () => {
      // Clear timeout interval on component unmount
      clearTimeout(timeOut);
    };
  }, []);

  // Cancel reload if page loads properly
  useEffect(() => {
    if (init) {
      clearTimeout(timeOut);
    }
  }, [init]);

  useEffect(() => {
    filterRef.current && filterRef.current.focus();
  }, []);

  useEffect(() => {
    if (medicationComparisonSummary.medicationConsistent?.length > 0 ||
        medicationComparisonSummary.medicationInconsistentNotPrescribed?.length > 0 ||
        medicationComparisonSummary.medicationInconsistentRxNotFound?.length > 0
       ) {
      setShowMedicationSection(true);
    }

  }, [medicationComparisonSummary]);

  // Update drugReport on medication list change
  useEffect(() => {
    if (!_.isEmpty(orderDetails)){
      const drugReport = new DrugReport({
        metabolites,
        medicationList: medicationList.split(',').map((name) => name.trim()),
        toxicologyTests: filterTests(orderDetails.toxicologyTests),
      });

      setDrugReport(drugReport);
    }
  }, [medicationList]);

  // Get the order details
  useQuery(
    GET_TOXICOLOGY_COMPLETE_ORDER,
    {
      // fetchPolicy: 'network-only',
      fetchPolicy: 'no-cache',
      variables: { id },
      onCompleted({ getToxicologyOrder }) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Query getToxicologyOrder');
          console.log({ getToxicologyOrder });
        }

        // Get analyst instructions for clinic
        getAnalystInstructions({ variables: { clinicCode: getToxicologyOrder.clinicCode } });

        // Get Analyst 0 and 1 details
        getAnalyst0({ variables: { id: getToxicologyOrder.radeasAnalyst0 } });
        getAnalyst1({ variables: { id: getToxicologyOrder.radeasAnalyst1 } });

        // Format specimen run date
        let year, month, day;
        if (getToxicologyOrder.specimenRunDate) {
          [year, month, day] = getToxicologyOrder.specimenRunDate.split('T')[0].split('-');
        } else {
          let runDate = new Date(parseInt(getToxicologyOrder.labUploadedDate ? getToxicologyOrder.labUploadedDate : getToxicologyOrder.resultsUploadedDate));
          runDate = dateFormat(runDate, "mm/dd/yyyy");
          [month, day, year] = runDate.split('/');
          // [month, day, year] = new Date().toLocaleDateString().split('/');
        }

        const data = _.cloneDeep(getToxicologyOrder);
        data.specimenRunDate = `${month}/${day}/${year}`;
        // data.results = _.cloneDeep(data.resultsReview);

        setOrderDetails(data);
        setCustomTestResults(getToxicologyOrder.customTestResultsReview);
        setMedicationComparisonSummary({
          medicationConsistent: getToxicologyOrder.medicationConsistentReview.join(', '),
          medicationInconsistentNotPrescribed: getToxicologyOrder.medicationInconsistentNotPrescribedReview.join(', '),
          medicationInconsistentRxNotFound: getToxicologyOrder.medicationInconsistentRxNotFoundReview.join(', '),
        });
        setMedicationList(data.medicationListTags.join(', '));
        getDrugMetabolitesQuery();

        if (data.medicationListTags?.length > 0) {
          setShowMedicationSection(true);
        } else {
          setShowMedicationSection(false);
        }

        // Process POC tests (show positive results only)
        if ('pocTests' in getToxicologyOrder) {
          if (getToxicologyOrder.pocTests.length > 0) {
            const posPocTests = getToxicologyOrder.pocTests.filter((test) => (
              test.result.toUpperCase() === 'POS'
            ));

            setPocTests(posPocTests);
          }
        }

        if ('additionalFlags' in getToxicologyOrder) {
          setAdditionalFlags(getToxicologyOrder.additionalFlags);
        }
      },
      onError(error) {
        console.log('Enter toxicology results error:', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      }
    }
  );

  // Clinic Analyst special instructions
  const [getAnalystInstructions, { loading: loadingAnalystInstructions }] = useLazyQuery(
    GET_ANALYST_INSTRUCTIONS,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ toxicologyTestProfileByClinicCode: instructions }) {
        if (process.env.NODE_ENV === 'development') console.log('Analyst Instructions:', instructions);

        setAnalystInstructions(instructions.analystInstructions);
      },
      onError(error) {
        console.log('ClinicPage query error:', error);
        createNotification('danger', 'Sorry, could not get clinic-specific analyst instructions.');
      }
    }
  );

  // Get First Analyst user by ID
  const [getAnalyst0] = useLazyQuery(
    GET_RADEAS_USER_BY_ID,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ getRadeasUserById }) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Radeas Analyst 0:', getRadeasUserById);
        }

        setFirstAnalyst(getRadeasUserById);
      },
      onError(error) {
        console.log('Get Radeas User By ID query:', error);
        createNotification('danger', 'Sorry, could not get first Analyst\'s info.');
      },
    },
  );

  // Get Review Analyst user by ID
  const [getAnalyst1] = useLazyQuery(
    GET_RADEAS_USER_BY_ID,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ getRadeasUserById }) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Radeas Analyst 1:', getRadeasUserById);
        }

        setReviewAnalyst(getRadeasUserById);
      },
      onError(error) {
        console.log('Get Radeas User By ID query:', error);
        createNotification('danger', 'Sorry, could not get review Analyst\'s info.');
      },
    },
  );

  // Get drug metabolites list
  const [getDrugMetabolitesQuery] = useLazyQuery(
    GET_DRUG_METABOLITES,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ drugMetabolites }) {
        setMetabolites(drugMetabolites);

        const drugReport = new DrugReport({
          drugMetabolites,
          // medicationList: orderDetails.medicationList && DrugReport.splitDrugList(orderDetails.medicationList),
          medicationList: orderDetails.medicationListTags,
          toxicologyTests: filterTests(orderDetails.toxicologyTests),
        });

        setDrugReport(drugReport);

        // TODO: Part of page reload hack
        setInit(true);

        setLoading(false);
      },
      onError(error) {
        console.log('Get drug metabolites:', error);
        createNotification('danger', 'Sorry, could not retrieve drug metabolites.');
      }
    }
  );

  // Save results
  const [saveOrderResults, { loading: savingTox }] = useMutation(
    SET_TOXICOLOGY_ORDER_RESULTS,
    {
      onCompleted({ setToxicologyOrderResults }) {
        createNotification('info', 'Results saved');
        history.go(0);
      },
      onError(error) {
        console.log(error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    }
  );

  // Save EtG/EtS results
  const [saveEtgResults, { loading: savingEtg }] = useMutation(
    SET_ETG_RESULTS,
    {
      onCompleted({ setEtgResult }) {
        if (process.env.NODE_ENV === 'development')
          console.log('setEtgResult:', setEtgResult);
      },
      onError(error) {
        console.log(error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    }
  );

  // Save test strips results
  const [saveTestStripsResults, { loading: savingTestStrips }] = useMutation(
    SET_TEST_STRIPS_RESULTS,
    {
      onCompleted({ setTestStripsResults }) {
        if (process.env.NODE_ENV === 'development')
          console.log('setTestStripsResults:', setTestStripsResults);
      },
      onError(error) {
        console.log(error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    }
  );

  // Update specimen validation results
  const [setSpecimenValidation, { loading: savingValidation }] = useMutation(
    SET_TOXICOLOGY_LAB_RESULTS,
    {
      onCompleted({ setToxicologyLabResults }) {
        if (process.env.NODE_ENV === 'development') console.log('Set Validation:', setToxicologyLabResults);

        setTempStorage({});

        createNotification('info', 'Specimen validation results updated. Press "Download Report" button to re-generate PDF report.');
        // handleCloseSpecimenValidationModal();
      },
      onError(error) {
        console.log('setToxicologyLabResults', error);

        setOrderDetails({
          ...orderDetails,
          specimenPH: tempStorage.specimenPH,
          specimenCreatinine: tempStorage.specimenCreatinine,
          specimenSpecificGravity: tempStorage.specimenSpecificGravity,
        });
        setTempStorage({});

        createNotification('danger', 'Sorry, could not update specimen validation values.');
        // handleCloseSpecimenValidationModal();
      },
    }
  );

  const generateTable = () => {
    if (!orderDetails.resultsReview) return null;

    if (filter === '') {
      return generateRows(orderDetails.resultsReview);
    } else {
      // Filter the results
      const filtered = orderDetails.resultsReview.filter((test, i) => {
        // Skip "All" and "Urine Validation"
        if (test.drugName === 'All (G0483; 80307)' || test.drugName === 'Urine Validation Testing') {
          return false;
        }

        return (
          test.drugName.toLowerCase().includes(filter.toLowerCase())
        );
      });

      return generateRows(filtered);
    }
  };

  const generateRows = (rows) => {
    return (
      rows.filter((test) => (
        !(test.drugName === 'All (G0483; 80307)' || test.drugName === 'Urine Validation Testing')
      )).map((test, i) => {
        const result = drugReport.evaluate({
          drugName: test.drugName,
          cutoff: test.cutoff,
          value: test.result,
        });

        // if (test.c_i !== result.cI) {
        //   mergeResults(test, {
        //     c_i: result.cI,
        //     flag: result.flags.join(', '),
        //   });
        // }

        return (
          <tr
            key={i}
            className={test.result !== "ND" ? "has-text-danger" : null}
            style={{ cursor: 'default' }}
          >
            <td><span className="has-text-weight-semibold">{test.drugName}</span></td>
            <td>{test.drugBrand}</td>
            <td>
              {test.scrn === '+' || test.scrn === '-' ?
                (<React.Fragment>
                    {isEditing && (<input
                      name="scrn"
                      type="checkbox"
                      checked={test.scrn === '+'}
                      onChange={() => handleScrn(test)}
                    />)}
                    <span className="ml-2">{test.scrn}</span>
                  </React.Fragment>
                )
                :
                null}
            </td>
            <td>{test.cutoff}</td>
            <td>{result.posNeg}</td>
            <td>{test.c_i}</td>
            <td>
              <input
                name="result"
                type="text"
                className="input"
                value={test.result}
                onChange={(e) => handleChange(e, test)}
                style={{ cursor: 'default' }}
                disabled={!isEditing}
              />
            </td>
            <td>
              {!isEditing ? test.flag : (
                <div className="select">
                  <select
                    style={{ width: '180px' }}
                    onChange={(e) => handleSelect(e, test)}
                    value={test.flag}
                  >
                    <option value="" />

                    {TOXICOLOGY_REPORT_FLAGS.map((flag) => (
                      <option
                        key={flag}
                        value={flag}
                        selected={flag === test.flag}
                      >
                        {flag}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </td>
          </tr>
        );
      })
    );
  };

  const generateEtg = () => {
    if (orderDetails.etgResultsReview?.length === 0)
      return;

    return (
      <>
        <br /><br />
        <hr />

        <h4 className="title is-4">Alcohol(s)</h4>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Test Performed</th>
              <th>Name</th>
              <th>Cutoff (ng/ml)</th>
              <th>POS/NEG</th>
              <th>C/I</th>
              <th>Result</th>
              <th>Flag</th>
            </tr>
            </thead>
            <tbody>
              {generateEtgRows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const generateTestStrips = () => {
    if (orderDetails.testStripsResultsReview?.length === 0)
      return;

    return (
      <>
        <br /><br />
        <hr />

        <h4 className="title is-4">Test Strips</h4>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Drug Name</th>
              <th>Drug Class</th>
              <th>Drug Brand</th>
              <th>Scrn</th>
              <th>Cutoff (ng/ml)</th>
              <th>C/I</th>
              <th>Flag</th>
            </tr>
            </thead>
            <tbody>
              {generateTestStripsRows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const generateTestStripsRows = () => {
    return (
      orderDetails.testStripsResultsReview.map((test, i) => {
        const cutoff = test.cutoffs.urineCustom ? test.cutoffs.urineCustom : test.cutoffs.urineDefault;

        return (
          <tr key={i}>
            <td><span className="has-text-weight-semibold">{test.drugName}</span></td>
            <td>{test.drugClass}</td>
            <td>{test.drugBrand}</td>
            <td>
              {isEditing && (<input
                name="scrn"
                type="checkbox"
                checked={test.scrn}
                onChange={() => handleTestStrip(i, test)}
                disabled={!isEditing}
              />)}
              <span className="ml-2">{test.scrn ? '+' : '-'}</span>
            </td>
            <td>{cutoff}</td>
            <td>{test.c_i ? test.c_i : ""}</td>
            <td>{generateTestStripFlag(test)}</td>
          </tr>
        );
      })
    );
  };

  const generateTestStripFlag = (test) => {
    if (_.isNull(test.flag)) {
      return "";
    } else {
      if (!isEditing) {
        return test.flag;
      } else {
        return (
          <div className="select">
            <select
              style={{ width: '180px' }}
              // onChange={(e) => handleTestStripsFlag(e, test)}
              value={test.flag}
            >
              <option value="" />

              {TOXICOLOGY_REPORT_FLAGS.map((flag) => (
                <option key={flag} value={flag}>
                  {flag}
                </option>
              ))}
            </select>
          </div>
        );
      }
    }
  };

  const handleTestStrip = (i, test) => {
    const updated = _.cloneDeep(orderDetails.testStripsResultsReview);

    updated[i] = {
      ...updated[i],
      scrn: !updated[i].scrn,
    };

    /* TODO: Handle test strip edits
    // Update medication comparison summary if needed
    if (testStripsWithFlags.includes(test.drugName.toUpperCase())) {
      // Regenerate medication comparison summary
      const result = drugReport.evaluate({
        drugName: test.drugName,
        cutoff: '5',                            // Dummy cutoff
        value: updated[i].scrn ? '100' : '0',   // Dummy value (screen is boolean)
      });

      // Merge results
      updated[i] = {
        ...updated[i],
        c_i: result.cI,
        flag: result.flags[0] ? result.flags[0] : "",
      };

      // Handle medication comparison summary
      handleAutoGeneratedMedicationComparisonSummary(result.medicationComparisonSummary);
    }
    */

    setOrderDetails({
      ...orderDetails,
      testStripsResultsReview: updated,
    });
  };

//   const handleTestStripsFlag = (e, test) => {
//     const newTests = _.cloneDeep(testStripsResults);
//     const index = _.findIndex(testStripsResults, { drugName: test.drugName });
//
//     newTests.splice(index, 1, { ...test, flag: e.target.value });
//
//     setTestStripsResults(newTests);
//   };

  const generateEtgRows = () => {
    return (
      orderDetails.etgResultsReview.map((row, i) => (
        <tr key={i}>
          <td>{row.drugName}</td>
          <td>{row.drugBrand}</td>
          <td>{row.cutoff}</td>
          <td>{row.posNeg}</td>
          <td>{row.c_i}</td>
          <td>
            <input
              name="result"
              type="text"
              className="input"
              value={row.result}
              onChange={(e) => handleChange(e, row)}
              style={{ cursor: 'default' }}
              disabled={!isEditing}
            />
          </td>
          <td>
            {!isEditing ? row.flag : (
              <div className="select">
                <select
                  style={{ width: '180px' }}
                  onChange={(e) => handleEtgFlagSelect(e, i)}
                  value={row.flag}
                >
                  <option value="" />

                  {TOXICOLOGY_REPORT_FLAGS.map((flag) => (
                    <option
                      key={flag}
                      value={flag}
                      selected={flag === row.flag}
                    >
                      {flag}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </td>
        </tr>
      ))
    );
  };

  const generateCustomTests = () => {
    if (customTestResults.length === 0)
      return;

    return (
      <>
        <br /><br />
        <hr />

        <h4 className="title is-4">Additional Tests</h4>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Drug Name</th>
              <th>Drug Class</th>
              <th>Drug Brand</th>
              <th>Cutoff (ng/ml)</th>
              <th>POS/NEG</th>
              <th>C/I</th>
              <th>Result</th>
              <th>Flag</th>
            </tr>
            </thead>
            <tbody>
              {generateCustomTestRows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const generateCustomTestRows = () => {
    return (
      customTestResults.map((row, i) => (
        <tr key={i}>
          <td>
            <input
              name="drugName"
              type="text"
              className="input"
              value={row.drugName}
              onChange={(e) => handleCustomDrugChange(e, i)}
              style={{ cursor: 'default' }}
              disabled={!isEditing}
            />
          </td>
          <td>
            <input
              name="drugClass"
              type="text"
              className="input"
              value={row.drugClass}
              onChange={(e) => handleCustomDrugChange(e, i)}
              style={{ cursor: 'default' }}
              disabled={!isEditing}
            />
          </td>
          <td>
            <input
              name="drugBrand"
              type="text"
              className="input"
              value={row.drugBrand}
              onChange={(e) => handleCustomDrugChange(e, i)}
              style={{ cursor: 'default' }}
              disabled={!isEditing}
            />
          </td>
          <td>
            <input
              name="cutoff"
              type="text"
              className="input"
              value={row.cutoff}
              onChange={(e) => handleCustomDrugChange(e, i)}
              style={{ cursor: 'default' }}
              disabled={!isEditing}
            />
          </td>
          <td>
            <input
              name="posNeg"
              type="text"
              className="input"
              value={row.posNeg}
              onChange={(e) => handleCustomDrugChange(e, i)}
              style={{ cursor: 'default' }}
              disabled={!isEditing}
            />
          </td>
          <td>
            <input
              name="c_i"
              type="text"
              className="input"
              value={row.c_i}
              onChange={(e) => handleCustomDrugChange(e, i)}
              style={{ cursor: 'default' }}
              disabled={!isEditing}
            />
          </td>
          <td>
            <input
              name="result"
              type="text"
              className="input"
              value={row.result}
              onChange={(e) => handleCustomDrugChange(e, i)}
              style={{ cursor: 'default' }}
              disabled={!isEditing}
            />
          </td>
          <td>
            {!isEditing ? row.flag : (
              <div className="select">
                <select
                  style={{ width: '180px' }}
                  onChange={(e) => handleCustomDrugFlagSelect(e, i)}
                >
                  <option value="" />

                  {TOXICOLOGY_REPORT_FLAGS.map((flag) => (
                    <option key={flag} value={flag}>
                      {flag}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </td>
        </tr>
      ))
    );
  };

  const handleScrn = (test) => {
    if (test.scrn === '-' || test.scrn === null) {
      mergeResults(test, { scrn: '+' });
    } else {
      mergeResults(test, { scrn: '-' });
    }
  };

  const mergeResults = (test, result) => {
    // const newToxicologyTests = _.cloneDeep(orderDetails.results);
    const newToxicologyTests = _.cloneDeep(orderDetails.resultsReview);
    const newVal = _.cloneDeep(test);
    const index = _.findIndex(newToxicologyTests, { drugName: test.drugName });

    newToxicologyTests.splice(index, 1, { ...newVal, ...result });

    setOrderDetails({
      ...orderDetails,
      // results: newToxicologyTests,
      resultsReview: newToxicologyTests,
    });
  };

  const mergeEtgResults = (test, result) => {
    const newEtgTests = _.cloneDeep(orderDetails.etgResultsReview);
    const newVal = _.cloneDeep(test);
    const index = _.findIndex(newEtgTests, { drugName: test.drugName });

    newEtgTests.splice(index, 1, { ...newVal, ...result });

    setOrderDetails({
      ...orderDetails,
      etgResultsReview: newEtgTests,
    });
  };

  const handleChange = (e, test) => {
    // Evaluate
    const result = drugReport.evaluate({
      drugName: test.drugName,
      cutoff: test.cutoff,
      value: e.target.value,
    });

    if (test.drugName.substring(0, 5).toUpperCase() === 'ETHYL') {
      mergeEtgResults(test, {
        c_i: result.cI,
        flag: result.flags.join(', '),
        posNeg: result.posNeg,
        result: e.target.value,
      });
    } else {
      mergeResults(test, {
        c_i: result.cI,
        flag: result.flags.join(', '),
        result: e.target.value,
      });
    }
  };

  const handleSelect = (e, test) => {
    mergeResults(test, {
      flag: e.target.value,
    });
  };

  const handleAdditionalFlags = (e, idx) => {
    setAdditionalFlags(additionalFlags.map((row, i) => {
      if (i === idx) {
        return e.target.value;
      }

      return row;
    }));
  };

  const handleDeleteAdditionalFlag = (idx) => {
    setAdditionalFlags(additionalFlags.filter((_, i) => i !== idx));
  };

  const handleShowSpecimenValidationModal = (validation) => {
    setShowSpecimenValidationModal({ ...validation });
  };

  const handleCloseSpecimenValidationModal = () => {
    setIsEditing(false);
    setShowSpecimenValidationModal(null);
  };

  const handleUpdateSpecimenValidationResults = ({ specimenPH, specimenSpecificGravity, specimenCreatinine }) => {
    setTempStorage({
      specimenPH: orderDetails.specimenPH,
      specimenSpecificGravity: orderDetails.specimenSpecificGravity,
      specimenCreatinine: orderDetails.specimenCreatinine,
    });

    setOrderDetails({
      ...orderDetails,
      specimenPH,
      specimenCreatinine,
      specimenSpecificGravity,
    });

    setSpecimenValidation({ variables: {
      isReRun: false,
      specimenId: orderDetails.specimenId,
      specimenPH,
      specimenSpecificGravity,
      specimenCreatinine,
    }});

    handleCloseSpecimenValidationModal();
  };

  const generateMedicationList = () => {
    return (
      <input
        name="medicationListInput"
        type="text"
        className="input mt-2"
        value={medicationList}
        onChange={(e) => setMedicationList(e.target.value)}
        style={{ cursor: 'default' }}
        disabled={!isEditing}
      />
    );
  };

  const handleEtgFlagSelect = (e, index) => {
    const etg = _.cloneDeep(orderDetails.etgResultsReview);
    etg[index].flag = e.target.value;

    setOrderDetails({
      ...orderDetails,
      etgResultsReview: etg,
    });
  };

  const handleCustomDrugChange = (e, i) => {
    const updated = _.cloneDeep(customTestResults);
    updated[i][e.target.name] = e.target.value;

    setCustomTestResults(updated);
  };

  const handleCustomDrugFlagSelect = (e, index) => {
    const updated = _.cloneDeep(customTestResults);
    updated[index].flag = e.target.value;

    setCustomTestResults(updated);
  };

  const generateTestStripsVariables = () => {
    return {
      toxicologyOrderId: orderDetails.id,
      step: '2',
      testStripsResults: orderDetails.testStripsResultsReview.map(({
        drugName,
        drugClass,
        drugBrand,
        cutoffs,
        scrn,
        c_i,
        flag,
      }) => ({ drugName, drugClass, drugBrand, scrn,
        cutoffs: {
          urineDefault: cutoffs.urineDefault,
          urineCustom: cutoffs.urineCustom,
          oralFluidDefault: cutoffs.oralFluidDefault,
          oralFluidCustom: cutoffs.oralFluidCustom,
        },
        cI: c_i && c_i === 'I' ? 'I' : 'C',
        flag: flag && flag.length > 0 ? flag : '',
      })),
    };
  };

  const handleSubmit = () => {
    saveEtgResults({
      variables: {
        toxicologyOrderId: orderDetails.id,
        step: '2',
        etgResults: orderDetails.etgResultsReview.map(({
          drugName,
          drugClass,
          drugBrand,
          result,
          posNeg,
          cutoff,
          c_i,
          flag,
        }) => ({ drugName, drugClass, drugBrand, result, posNeg, cutoff, c_i, flag })),
      },
    });

    saveTestStripsResults({
      variables: {
        toxicologyOrderId: orderDetails.id,
        step: '2',
        testStripsResults: orderDetails.testStripsResultsReview.map(({
          drugName,
          drugClass,
          drugBrand,
          cutoffs,
          scrn,
          c_i,
          flag,
        }) => ({ drugName, drugClass, drugBrand, scrn, c_i, flag,
          cutoffs: {
            urineDefault: cutoffs.urineDefault,
            urineCustom: cutoffs.urineCustom,
            oralFluidDefault: cutoffs.oralFluidDefault,
            oralFluidCustom: cutoffs.oralFluidCustom,
          },
        })),
      },
    });

    const data = {
      toxicologyOrderId: orderDetails.id,
      step: '2',
      medicationConsistent: medicationComparisonSummary.medicationConsistent.split(',').map((m) => m.trim()),
      medicationInconsistentNotPrescribed: medicationComparisonSummary.medicationInconsistentNotPrescribed.split(',').map((m) => m.trim()),
      medicationInconsistentRxNotFound: medicationComparisonSummary.medicationInconsistentRxNotFound.split(',').map((m) => m.trim()),
      medicationListTags: medicationList.split(',').map((name) => name.trim()),
      medicationList: medicationList,
      notes: editorNotes,
      results: filterTests(orderDetails.resultsReview).map(({
        drugName,
        drugClass,
        drugBrand,
        pos,
        neg,
        scrn,
        result,
        cutoff,
        c_i,
        flag,
      }) => ({ drugName, drugClass, drugBrand, pos, neg, scrn, result: result.trim(), cutoff, c_i, flag })),
      customTestResults: customTestResults.map(({
        drugName,
        drugClass,
        drugBrand,
        result,
        posNeg,
        cutoff,
        c_i,
        flag,
      }) => ({ drugName, drugClass, drugBrand, result, posNeg, cutoff, c_i, flag })),
      additionalFlags: additionalFlags
        .filter((flag) => flag.trim().length > 0)
        .map((flag) => flag.trim()),
    };

    // Generate the report, save to Google Bucket
    generateReportPdf();

    saveOrderResults({ variables: { ...data } });
  };

  const formatResult = (result, drugName, specimenType, panel='PAIN') => {
    let r;
    const fent = ['FENTANYL', 'NORFENTANYL'];

    if (result.trim()[0] === '>') {
      r = parseFloat(result.split('>')[1].trim());
    } else {
      r = parseFloat(result.trim());
    }

    // Check EtG
    if (panel === 'ETG') {
      if (r >= 10000) {
        return '> 10000';
      }

      return result.trim();
    }

    // Urine
    if (specimenType.toUpperCase() === 'URINE') {
      // Fentanyl/Norfentanyl
      if (fent.includes(drugName.toUpperCase())) {
        if (r > 200) {
          return '> 200';
        } else if (r < 0.2) {
          return '< 0.2';
        }
      }

      if (r > 2000) {
        return '> 2000';
      } else if (r < 2) {
        return '< 2';
      }

    // Oral Fluid
    } else if (specimenType.toUpperCase() === 'ORAL FLUID') {
      // Fentanyl/Norfentanyl
      if (fent.includes(drugName.toUpperCase())) {
        if (r > 600) {
          return '> 600';
        } else if (r < 0.6) {
          return '< 0.6';
        }
      }

      if (r > 6000) {
        return '> 6000';
      } else if (r < 6) {
        return '< 6';
      }
    }

    // if (r > 2000) {
    //   return '> 2000';
    // } else if (r < 2) {
    //   return '< 2';
    // }

    return result.trim();
  };

  const generateReportPdf = async (trigger=false) => {
    let createdDate = new Date(Number(orderDetails.createdDate));
    createdDate = dateFormat(createdDate, "mm/dd/yyyy");

    const data = {
      patientFirstName: orderDetails.patientFirstName && orderDetails.patientFirstName.trim(),
      patientLastName: orderDetails.patientLastName && orderDetails.patientLastName.trim(),
      patientId: orderDetails.patientId && orderDetails.patientId.trim().length > 0 ? orderDetails.patientId.trim() : 'Not Provided',
      patientBirthDate: `${orderDetails.patientBirthMonth}/${orderDetails.patientBirthDay}/${orderDetails.patientBirthYear}`,
      patientEmail: orderDetails.patientEmail && orderDetails.patientEmail.trim(),
      patientPhone: orderDetails.patientPhone && orderDetails.patientPhone.trim(),
      clinicCode: orderDetails.clinicCode && orderDetails.clinicCode.trim(),
      clinicName: orderDetails.clinicName && orderDetails.clinicName.trim(),
      providerName: orderDetails.providerName && orderDetails.providerName.trim(),
      sampleAcquisitionDate: createdDate,
      specimenId: orderDetails.specimenId && orderDetails.specimenId.trim(),
      specimenType: orderDetails.specimenType[0].toUpperCase() + orderDetails.specimenType.substring(1),
      analystId1: orderDetails.radeasAnalyst0,
      analystId2: orderDetails.radeasAnalyst1,
      runDate: orderDetails.specimenRunDate,
      medicationList: orderDetails.medicationListTags,
      medicationComparisonSummary: {
        'Consistent': medicationComparisonSummary.medicationConsistent.split(',').map((m) => m.trim()),
        'Inconsistent-NotPrescribed': medicationComparisonSummary.medicationInconsistentNotPrescribed.split(',').map((m) => m.trim()),
        'Inconsistent-RxNotFound': medicationComparisonSummary.medicationInconsistentRxNotFound.split(',').map((m) => m.trim()),
      },
      toxicologyTestResults: orderDetails.resultsReview
        .filter(({ drugName }) => (drugName.includes('All ') || drugName.includes('Urine Validation Testing') ? false : true))
        .map(({
          drugName,
          drugClass,
          drugBrand,
          scrn,
          result,
          cutoff,
          c_i,
          flag,
        }) => ({
          drugName,
          drugClass,
          drugBrand,
          scrn,
          // result: formatResult(result, drugName, orderDetails.specimenType, 'PAIN'),
          result: result,
          cutoff,
          posNeg: drugReport.evaluate({ drugName, cutoff, value: result }).posNeg,
          cI: c_i,
          flag,
        })
      ),
    };

    // Add EtG to data if necessary
    if (orderDetails.etgTests.length > 0) {
      const results = orderDetails.etgResultsReview.map(({
        drugName,
          drugClass,
          drugBrand,
          result,
          cutoff,
          posNeg,
          c_i,
          flag,
      }) => ({
        drugName,
        drugClass,
        drugBrand,
        // result: formatResult(result, drugName, orderDetails.specimenType, 'ETG'),
        result: result,
        cutoff,
        posNeg,
        cI: c_i,
        flag,
        scrn: null,
      }));

      data.toxicologyTestResults.push(...results);
    }

    // Add test strips if necessary
    if (orderDetails.testStripsResultsReview.length > 0) {
      const strips = orderDetails.testStripsResultsReview.map(({
        drugName,
        drugClass,
        drugBrand,
        scrn,
        cutoffs,
        c_i,
        flag,
      }) => {
        const cutoff = cutoffs.urineCustom ? cutoffs.urineCustom : cutoffs.urineDefault;

        return {
          drugName,
          drugClass,
          drugBrand,
          scrn: scrn ? '+' : '-',
          result: 'N/A',
          cutoff: `${cutoff}`,
          posNeg: scrn ? 'POS' : 'NEG',
          cI: c_i && c_i === 'I' ? 'I' : 'C',
          flag: flag && flag.length > 0 ? flag : '',
        };
      });

      data.toxicologyTestResults.push(...strips);
    }

    // Add custom tests if necessary
    if (customTestResults.length > 0) {
      const cResults = customTestResults.map(({
        drugName,
        drugClass,
        drugBrand,
        result,
        cutoff,
        posNeg,
        c_i,
        flag,
      }) => ({
        drugName,
        drugClass,
        drugBrand,
        // result: formatResult(result, drugName, orderDetails.specimenType),
        result: result,
        cutoff,
        posNeg,
        cI: c_i,
        flag,
        scrn: null,
      }));

      data.toxicologyTestResults.push(...cResults);
    }

    // Add additional flags if necessary
    if (additionalFlags.length > 0) {
      data.additionalFlags = additionalFlags;
    }

    // Add PH, specific gravity, and creatinine if urine validation test reporting is requested
    const urineValidation = orderDetails.toxicologyTests
      .filter(({ drugName }) => (drugName.includes('Urine Validation')));

    if ((urineValidation.length !== 0 && urineValidation[0].pos) || (orderDetails.urineValidationReporting)) {
      data['ph'] = orderDetails.specimenPH;
      data['sg'] = orderDetails.specimenSpecificGravity;
      data['cr'] = orderDetails.specimenCreatinine;
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('Generate Report data:', data);
    }

    try {
      // Download the PDF report
      let response;

      if (trigger) {
        console.log('Trigger');
        response = await axios.post(process.env.REACT_APP_SAVE_REPORT_TO_BUCKET_URL, data);
      } else {
        response = await axios.post(process.env.REACT_APP_GENERATE_REPORT_PDF_URL, data);
      }
      const contentType = response.headers['content-type'];
      download(response.data, `${orderDetails.specimenId}.pdf`, contentType);
    } catch (error) {
      console.log('Report Generation', error);
      createNotification('danger', 'Error generating report.');
    }

    setGeneratingReport(false);
  };

  const handleMedicationComparisonSummary = (e) => {
    const { name, value} = e.target;

    setMedicationComparisonSummary({
      ...medicationComparisonSummary,
      [name]: value,
    });
  };

  const saveOriginalResults = () => {
    setOriginalResults({
      results: _.cloneDeep(orderDetails.resultsReview),
      etgResults: _.cloneDeep(orderDetails.etgResultsReview),
      customTestResults: _.cloneDeep(customTestResults),
      medicationComparisonSummary: _.cloneDeep(medicationComparisonSummary),
      medicationList,
    });
  };

  const resetResults = () => {
    setOrderDetails({
      ...orderDetails,
      resultsReview: originalResults.results,
      etgResultsReview: originalResults.etgResults,
    });
    setMedicationList(originalResults.medicationList);
    setMedicationComparisonSummary(originalResults.medicationComparisonSummary);
    setCustomTestResults(originalResults.customTestResults);
    setEditorNotes('');
  };

  if (loading) return <Loading />;

  return (
    <section className="section">
      <div className="container">
        <div className="page">
          {orderDetails?.specimenId.slice(-3) !== '-RT' && !orderDetails?.hasRetest && orderDetails?.retestResultId !== 'PENDING' && (
            <div className="is-pulled-left">
              <RequestLabReRun
                orderDetails={orderDetails}
                setReRunRequested={() => {}}
                savePartialResults={() => {}}
                buttonText="Request Re-test"
                buttonColor="danger"
                isCompleteRetest={true}
              />
            </div>
          )}
          {orderDetails?.hasRetest && orderDetails?.retestResultId?.length > 0 && (
            <div className="is-pulled-left">
              <Link
                to={ROUTES.DASHBOARD_ANALYST_VIEW_RESULTS.replace(':id', orderDetails.retestResultId)}
                className="button is-primary"
                disabled={orderDetails?.retestResultId === "PENDING"}
                data-tooltip={orderDetails?.retestResultId === "PENDING" ? "Re-test ordered, results pending..." : null}
              >
                View Re-test Result
              </Link>
            </div>
          )}
          {orderDetails?.specimenId.slice(-3) === '-RT' && orderDetails?.retestOriginalOrderId?.length > 0 && (
            <div className="is-pulled-left">
              <Link
                to={ROUTES.DASHBOARD_ANALYST_VIEW_RESULTS.replace(':id', orderDetails.retestOriginalOrderId)}
                className="button is-primary"
                data-tooltip="This is a complete re-test of a previous order"
              >
                View Original Result
              </Link>
            </div>
          )}

          <div className="is-pulled-right">
            {generatingReport ? <ButtonLoading /> : null}

            <button
              className="button is-warning is-light ml-6"
              disabled={generatingReport}
              onClick={() => {
                setGeneratingReport(true);
                generateReportPdf();
              }}
            >
              Download Report
            </button>

            <button
              className="button ml-3"
              onClick={() => {
                history.push(ROUTES.DASHBOARD_ANALYST_COMPLETED_ORDERS);
                // history.goBack();
              }}
            >
              Back
            </button>
          </div>

          <br />
          <br />
          <br />

          <h3 className="title is-3">Order Details</h3>

          {/* <div> */}
          {/*   <div style={{ overflowX: "auto" }}> */}
          {/*     <table className="table is-fullwidth is-hoverable"> */}
          {/*       <thead> */}
          {/*       <tr> */}
          {/*         <th>Patient Name</th> */}
          {/*         <th>Patient ID</th> */}
          {/*         <th>Clinic Code</th> */}
          {/*         <th>Specimen ID</th> */}
          {/*         <th>Specimen Type</th> */}
          {/*       </tr> */}
          {/*       </thead> */}
          {/*       <tbody> */}
          {/*       <tr> */}
          {/*         <td> */}
          {/*           <Link target="_blank" rel="noopener noreferrer" */}
          {/*                 to={`${ROUTES.RADEAS_PATIENT_PAGE.replace(':id', orderDetails.patientForeignKey)}`}> */}
          {/*           {orderDetails.patientLastName}, {orderDetails.patientFirstName} */}
          {/*           </Link> */}
          {/*         </td> */}
          {/*         <td>{orderDetails.patientId}</td> */}
          {/*         <td>{orderDetails.clinicCode}</td> */}
          {/*         <td>{orderDetails.specimenId}</td> */}
          {/*         <td>{orderDetails.specimenType}</td> */}
          {/*       </tr> */}
          {/*       </tbody> */}
          {/*     </table> */}
          {/*   </div> */}

          <div>
            <div style={{ overflowX: "auto" }}>
              <table className="table is-fullwidth is-hoverable">
                <thead>
                <tr>
                  <th>Patient Name</th>
                  <th>Patient ID</th>
                  <th>Provider</th>
                  <th>Clinic</th>
                  <th>Clinic Code</th>
                  <th>Specimen ID</th>
                  <th>Specimen Type</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>
                    <Link
                      to={`${ROUTES.RADEAS_PATIENT_PAGE.replace(':id', orderDetails.patientForeignKey)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {orderDetails.patientLastName}, {orderDetails.patientFirstName}
                    </Link>
                  </td>
                  <td>{orderDetails.patientId}</td>
                  <td>{orderDetails.providerName}, {orderDetails.providerSuffix}</td>
                  <td>{orderDetails.clinicName}</td>
                  <td>{orderDetails.clinicCode}</td>
                  <td>{orderDetails.specimenId}</td>
                  <td>{orderDetails.specimenType}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <hr />

            {isEditing && (
              <div className="is-pulled-right">
                <button
                  className="button is-info is-small"
                  // data-tooltip="Set/update specimen validation values"
                  disabled={savingValidation}
                  onClick={() => handleShowSpecimenValidationModal({
                    specimenPH: orderDetails.specimenPH,
                    specimenSpecificGravity: orderDetails.specimenSpecificGravity,
                    specimenCreatinine: orderDetails.specimenCreatinine,
                  })}
                >
                  {savingValidation ? <ButtonLoading /> : "Update Validation"}
                </button>
              </div>
            )}

            <div className="columns my-3 has-text-white">
              <div className="column">
                {/* {orderDetails.specimenPH && `Specimen PH: ${orderDetails.specimenPH}`} */}
                Specimen PH: {orderDetails.specimenPH}
              </div>

              <div className="column">
                {/* {orderDetails.specimenSpecificGravity && `Specimen Specific Gravity: ${orderDetails.specimenSpecificGravity}`} */}
                Specimen Specific Gravity: {orderDetails.specimenSpecificGravity}
              </div>

              <div className="column">
                {/* {orderDetails.specimenCreatinine && `Specimen Creatinine: ${orderDetails.specimenCreatinine}`} */}
                Specimen Creatinine: {orderDetails.specimenCreatinine}
              </div>
            </div>

            <div className="my-3">
              <p className="has-text-white-ter">
                <span className="has-text-white has-text-weight-bold">
                  Clinical Purpose:{" "}
                </span>
                {orderDetails.clinicalPurpose ? orderDetails.clinicalPurpose.replace('::', ' ') : "Not specified"}
              </p>
            </div>

            {pocTests.length > 0 && (
              <div className="mt-5 mb-6">
                <p className="has-text-white has-text-weight-bold">Positive POC Tests:</p>
                <ul>
                  {pocTests.map((test, i) => (
                    <li
                      key={i}
                      className="ml-5 has-text-white-ter"
                    >
                      {test.name} ({test.code})
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="columns mt-4">
              <div className="column">
                {orderDetails.batches && orderDetails.batches.length > 0 && (
                  <>
                    <p className="has-text-white has-text-weight-bold">Test Machines:</p>
                    <table className="table is-striped">
                      <thead>
                        <tr>
                          <th>Panel</th>
                          <th>Name</th>
                          <th>Run Date</th>
                          <th>ReRun?</th>
                        </tr>
                      </thead>

                      <tbody>
                        {orderDetails.batches.map((batch) => (
                          <tr key={batch.id}>
                            <td>{batch.testPanel}</td>
                            <td>{batch.machineName}</td>
                            <td>{new Date(parseInt(batch.createdDate)).toLocaleString()}</td>
                            <td className="has-text-centered">{!!batch.isReRun ? <span className="icon"><i className="fas fa-check-circle" /></span> : null}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}

                <p className="mt-4 has-text-white has-text-weight-bold">Analyzed By: {firstAnalyst.firstName} {firstAnalyst.lastName} - {firstAnalyst.email}</p>
                <p className="has-text-white has-text-weight-bold">Reviewed By: {reviewAnalyst.firstName} {reviewAnalyst.lastName} - {reviewAnalyst.email}</p>
              </div>

              <div className="column">
                {analystInstructions.length > 0 && (
                  <div className="content">
                    <h4 className="is-size-6 title has-text-white mt-2">
                      Clinic-specific Instructions for Analysts:
                    </h4>

                    <ul>
                      {analystInstructions.split(/\r?\n/).map((line) => {
                        if (line.length > 0) {
                          return <li className="has-text-white">{line}</li>;
                        }

                        return null;
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {showMedicationSection && (
              <>
                <div className="my-5">
                  <p className="has-text-white"><span className="has-text-weight-bold">Medication List:</span> {generateMedicationList()}</p>
                </div>

                <div className="my-5">
                  <div className="columns">


                    <div className="column is-one-third">
                      <div className="field">
                        <label className="label" htmlFor="input-medicationInconsistentNotPrescribed">
                          Inconsistent - Not Prescribed
                        </label>
                        <textarea
                          id="input-medicationInconsistentNotPrescribed"
                          name="medicationInconsistentNotPrescribed"
                          className="textarea"
                          style={{ cursor: 'default' }}
                          placeholder=""
                          value={medicationComparisonSummary.medicationInconsistentNotPrescribed}
                          onChange={handleMedicationComparisonSummary}
                          // readOnly
                          disabled={!isEditing}
                        />
                      </div>
                    </div>

                    <div className="column is-one-third">
                      <div className="field">
                        <label className="label" htmlFor="input-medicationInconsistentRxNotFound">
                          Inconsistent - RX Not Found
                        </label>
                        <textarea
                          id="input-medicationInconsistentRxNotFound"
                          name="medicationInconsistentRxNotFound"
                          className="textarea"
                          style={{ cursor: 'default' }}
                          placeholder=""
                          value={medicationComparisonSummary.medicationInconsistentRxNotFound}
                          onChange={handleMedicationComparisonSummary}
                          // readOnly
                          disabled={!isEditing}
                        />
                      </div>
                    </div>

                    <div className="column is-one-third">
                      <div className="field">
                        <label className="label" htmlFor="input-medicationConsistent">
                          Consistent
                        </label>
                        <textarea
                          id="input-medicationConsistent"
                          name="medicationConsistent"
                          className="textarea"
                          style={{ cursor: 'default' }}
                          placeholder=""
                          value={medicationComparisonSummary.medicationConsistent}
                          onChange={handleMedicationComparisonSummary}
                          // readOnly
                          disabled={!isEditing}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <hr />

          <h4 className="title is-4">Toxicology</h4>

          <div className="my-5">
            <div className="columns">
              <div className="column is-one-third">
                <div className="field is-grouped">
                  <div className="control is-expanded">
                    <input
                      ref={filterRef}
                      name="filter"
                      type="text"
                      className="input"
                      placeholder="Filter by drug name"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  </div>
                  <div className="control">
                    <button
                      className="button is-info"
                      onClick={() => setFilter('')}
                      disabled={filter === '' ? true : false}
                    >
                    <span className="icon is-small mr-2">
                      <i className="fas fa-times-circle" />
                    </span>
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ overflowX: "auto" }}>
            <table className="table is-fullwidth is-striped is-hoverable">
              <thead>
              <tr>
                <th>Drug Name</th>
                <th>Drug Brand</th>
                <th>Scrn</th>
                <th>Cutoff (ng/ml)</th>
                <th>POS/NEG</th>
                <th>C/I</th>
                <th>Result</th>
                <th>Flag</th>
              </tr>
              </thead>
              <tbody>
              {generateTable()}
              </tbody>
            </table>
          </div>

          {generateEtg()}
          {generateTestStrips()}
          {generateCustomTests()}

          <br /><br />
          <hr />

          <div className="my-5">
            <div className="field">
              <label className="label" htmlFor="input-notes0">
                Analyst 1 Notes
              </label>
              <textarea
                id="input-notes0"
                name="notes0"
                className="textarea"
                placeholder="(none)"
                value={orderDetails.radeasAnalyst0Notes}
                disabled
                style={{ cursor: "default" }}
              />
            </div>

            <div className="field">
              <label className="label" htmlFor="input-notes1">
                Analyst 2 Notes
              </label>
              <textarea
                id="input-notes1"
                name="notes1"
                className="textarea"
                placeholder="(none)"
                value={orderDetails.radeasAnalyst1Notes}
                disabled
                style={{ cursor: "default" }}
              />
            </div>
          </div>

          <hr />

          {isEditing && (
            <>
              <div className="my-5">
                <div className="field">
                  <label className="label" htmlFor="input-notes">
                    Editor Notes
                  </label>
                  <textarea
                    id="input-notes"
                    name="notes"
                    className="textarea"
                    placeholder="(optional) Notes for editing a completed report"
                    value={editorNotes}
                    onChange={(e) => setEditorNotes(e.target.value)}
                  />
                </div>
              </div>

              <hr />
            </>
          )}

          <div className="my-5">
            <h4 className="title is-4">Additional Flags</h4>

            <div className="columns">
              <div className="column is-full">
                {additionalFlags.map((row, i) => (
                  <div key={i} className="field has-addons">
                    <div className="control is-expanded">
                      <input
                        name="additional-flag"
                        className="input is-fullwidth"
                        type="text"
                        value={additionalFlags[i]}
                        onChange={(e) => handleAdditionalFlags(e, i)}
                        style={{ cursor: 'default' }}
                        disabled={!isEditing}
                      />
                    </div>

                    <div className="control">
                      <button
                        className="button is-danger"
                        onClick={() => handleDeleteAdditionalFlag(i)}
                        style={{ cursor: 'default' }}
                        disabled={!isEditing}
                      >
                        <span className="icon">
                          <i className="fas fa-trash-alt" />
                        </span>
                      </button>
                    </div>
                  </div>
                ))}

                {isEditing && (
                  <button
                    className="button is-info my-4"
                    onClick={() => {
                      setAdditionalFlags([
                        ...additionalFlags,
                        "",
                      ]);
                    }}
                  >
                    Add Flag
                  </button>
                )}
              </div>
            </div>
          </div>

          <hr />

          <div className="is-pulled-left">
            {authUserId === 'orTP9Nwpe0kzVOS6Npsm' || authUserId === 'SW1WqMlkfBS1miOdov1c' ? (
              <button
                className="button is-danger"
                disabled={generatingReport}
                onClick={() => {
                  setGeneratingReport(true);
                  generateReportPdf(true);
                }}
              >
                RE-TRIGGER
              </button>
            ) : null}
          </div>

          <div className="is-pulled-right">
            {!isEditing ?
              (
                <button
                  className="button is-info"
                  disabled={generatingReport}
                  onClick={() => {
                    saveOriginalResults();
                    setIsEditing(true);
                  }}
                >
                  Edit Results
                </button>
              ) : (
                <>
                  <button
                    className="button is-info"
                    disabled={generatingReport}
                    onClick={handleSubmit}
                  >
                    Save
                  </button>

                  <button
                    className="button is-danger ml-3"
                    disabled={generatingReport}
                    onClick={() => {
                      resetResults();
                      setIsEditing(false);
                    }}
                  >
                    Cancel
                  </button>
                </>
              )
            }
          </div>
        </div>
      </div>

      <UpdateSpecimenValidationResultsModal
        isOpen={!!showSpecimenValidationModal}
        handleCloseModal={handleCloseSpecimenValidationModal}
        handleSave={handleUpdateSpecimenValidationResults}
        specimenPH={showSpecimenValidationModal?.specimenPH}
        specimenSpecificGravity={showSpecimenValidationModal?.specimenSpecificGravity}
        specimenCreatinine={showSpecimenValidationModal?.specimenCreatinine}
      />
    </section>
  );
};

export default ViewToxicologyResults;
