import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    border: 0,
    background: "transparent",
  },
};

const UpdateSpecimenValidationResultsModal = (props) => {
  const [ph, setPh] = useState(null);
  const [sg, setSg] = useState(null);
  const [creatinine, setCreatinine] = useState(null);

  useEffect(() => {
    if (props && !ph) setPh(props.specimenPH);
    if (props && !sg) setSg(props.specimenSpecificGravity);
    if (props && !creatinine) setCreatinine(props.specimenCreatinine);
  }, [props]);

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.handleCloseModal}
      style={modalStyles}
      ariaHideApp={false}
    >
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title has-text-white">Update Specimen Validation Values</p>
            {/* <button */}
            {/*   className="delete" */}
            {/*   aria-label="close" */}
            {/*   onClick={props.handleCloseModal} */}
            {/* /> */}
          </header>

          <section className="modal-card-body page">
            <div className="block columns">
              <div className="column">
                <label className="label" htmlFor="input-specimenPH">
                  Specimen PH
                </label>
              </div>

              <div className="column">
                <input
                  id="input-specimenPH"
                  name="specimenPH"
                  className="input"
                  type="text"
                  value={ph}
                  onChange={(e) => setPh(e.target.value)}
                />
              </div>
            </div>

            <div className="block columns">
              <div className="column">
                <label className="label" htmlFor="input-specimenSpecificGravity">
                  Specimen Specific Gravity
                </label>
              </div>

              <div className="column">
                <input
                  id="input-specimenSpecificGravity"
                  name="specimenSpecificGravity"
                  className="input"
                  type="text"
                  value={sg}
                  onChange={(e) => setSg(e.target.value)}
                />
              </div>
            </div>

            <div className="block columns">
              <div className="column">
                <label className="label" htmlFor="input-specimenCreatinine">
                  Specimen Creatinine
                </label>
              </div>

              <div className="column">
                <input
                  id="input-specimenCreatinine"
                  name="specimenCreatinine"
                  className="input"
                  type="text"
                  value={creatinine}
                  onChange={(e) => setCreatinine(e.target.value)}
                />
              </div>
            </div>

            <button
              className="button is-info mt-5 is-pulled-left"
              onClick={() => {
                props.handleSave({
                  specimenPH: ph,
                  specimenSpecificGravity: sg,
                  specimenCreatinine: creatinine,
                });
              }}
            >
              Save
            </button>

            <button
              className="button is-danger mt-5 is-pulled-right"
              onClick={() => {
                setPh(props.specimenPH);
                setSg(props.specimenSpecificGravity);
                setCreatinine(props.specimenCreatinine);

                props.handleCloseModal();
              }}
            >
              Cancel
            </button>
          </section>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateSpecimenValidationResultsModal;
