import React from 'react';

import { formatDate } from './utils';

const DailyStats = (props) => {
  return (
    <div>
      <div className="has-text-right">
        <p>{props.stats.timestamp && (`Last Updated ${formatDate(props.stats.timestamp)}`)}</p>
      </div>

      <div className="columns">
        <StatsBox title="24hr Detection Rate" value={props.stats.detection_rate} percentage={true} />
        <StatsBox title="24hr Inconclusive Rate" value={props.stats.inconclusive_rate} percentage={true} />
        <StatsBox title="24hr Resulted Tests" value={props.stats.resulted} />
        <StatsBox title="24hr Notifications Sent" value={props.stats.sent} />
      </div>
    </div>
  );
};

const StatsBox = ({ title, value, percentage }) => {
  const convertToPercentage = (num) => {
    return (num * 100).toFixed(2) + '%';
  };

  return (
    <div className="column">
      <div className="page box has-text-centered py-3">
        <p className="is-size-7 has-text-weight-semibold">{title}</p>

        <p className="mt-2 is-size-2 has-text-white has-text-weight-semibold">{percentage ? convertToPercentage(value) : value}</p>
      </div>
    </div>
  );
};

StatsBox.defaultProps = {
  percentage: false,
  value: 0.0,
};

export default DailyStats;
