import React from 'react';

const TrayVisualization = ({ rows, columns }) => {
  return (
    <div
      className=""
      style={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
        justifyItems: "center",
        alignItems: "center",
        background: "#293042",
        padding: "2%",
        borderRadius: "10px",
      }}
    >
      {Array.from(Array(rows * columns)).map((e, i) => (
        <div
          key={i}
          className="icon has-text-white"
        >
          <i className="fas fa-vial" />
          {/* <i className="fas fa-flask" /> */}
        </div>
      ))}
    </div>
  );
};

export default TrayVisualization;
