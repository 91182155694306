import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import TrayVisualization from './TrayVisualization';

import Loading, { ButtonLoading } from '../Loading';
import { createNotification } from '../Notification';
import { useAuth } from '../auth';

export const ALL_TEST_MACHINES = gql`
  query AllTestMachines {
    allTestMachines {
      id
      name
      description
      trayRows
      trayColumns
      panels {
        panelName
        isActive
        disabled
        updatedDate
      }
      isActive
      createdDate
      updatedDate
    }
  }
`;

export const UPDATE_TEST_MACHINE = gql`
  mutation UpdateTestMachine(
    $id: String!
    $trayRows: String
    $trayColumns: String
    $isActive: Boolean
  ) {
    updateTestMachine(
      data: {
        id: $id,
        trayRows: $trayRows,
        trayColumns: $trayColumns,
        isActive: $isActive,
      }
    ) {
      id
      name
      description
      trayRows
      trayColumns
      panels {
        panelName
        isActive
        disabled
        updatedDate
      }
      isActive
      createdDate
      updatedDate
    }
  }
`;

export const SET_PANEL_STATUS = gql`
  mutation UpdateTestMachine(
    $id: String!
    $panels: [PanelStatusInput]
  ) {
    updateTestMachine(
      data: {
        id: $id,
        panels: $panels,
      }
    ) {
      id
      name
      description
      trayRows
      trayColumns
      panels {
        panelName
        isActive
        disabled
        updatedDate
      }
      isActive
      createdDate
      updatedDate
    }
  }
`;

export const CREATE_TEST_MACHINE = gql`
  mutation CreateTestMachine(
    $name: String!
    $trayRows: String!
    $trayColumns: String!
    $isActive: Boolean!
  ) {
    createTestMachine(
      data: {
        name: $name,
        trayRows: $trayRows,
        trayColumns: $trayColumns,
        isActive: $isActive,
      }
    ) {
      id
      name
      trayRows
      trayColumns
      isActive
      createdDate
      updatedDate
    }
  }
`;

const TestMachines = () => {
  const [testMachines, setTestMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState({});

  const [newMachine, setNewMachine] = useState(false);
  const [machineName, setMachineName] = useState('');
  const [rows, setRows] = useState('');
  const [columns, setColumns] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(true);

  const history = useHistory();

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  useQuery(
    ALL_TEST_MACHINES,
    {
      onCompleted({ allTestMachines }) {
        if (process.env.NODE_ENV === 'development')
          console.log('Query AllTestMachines:', allTestMachines);

        setTestMachines(allTestMachines);
        setLoading(false);
      },
      onError(error) {
        console.log('allTestMachines', error);
        createNotification('danger', 'Sorry, could not retrieve test machines.');

        setLoading(false);
      },
    }
  );

  const [updateTestMachine, { loading: updating }] = useMutation(
    UPDATE_TEST_MACHINE,
    {
      onCompleted({ updateTestMachine }) {
        if (process.env.NODE_ENV === 'development')
          console.log('updateTestMachine:', updateTestMachine);

        createNotification('info', `Test Machine ${updateTestMachine.name} has been updated.`);

        // Refresh page
        history.go(0);
      },
      onError(error) {
        console.log('Update Test Machine', error);
        createNotification('danger', 'Sorry, could not update test machine.');
      },
    }
  );

  const [setPanelStatus] = useMutation(
    SET_PANEL_STATUS,
    {
      onCompleted({ updateTestMachine }) {
        if (process.env.NODE_ENV === 'development')
          console.log('updateTestMachine:', updateTestMachine);

        const i = _.findIndex(testMachines, { id: updateTestMachine.id });
        const updated = _.cloneDeep(testMachines);

        if (i > -1) {
          updated[i].updatedDate = updateTestMachine.updatedDate;
        }

        setTestMachines(updated);
      },
      onError(error) {
        console.log('Update Test Machine', error);
        createNotification('danger', 'Sorry, could not update status.');
      }
    }
  );

  const [saveNewTestMachine, { loading: saving }] = useMutation(
    CREATE_TEST_MACHINE,
    {
      onCompleted({ createTestMachine }) {
        if (process.env.NODE_ENV === 'development') console.log('createTestMachine:', createTestMachine);

        createNotification('info', `Test Machine ${createTestMachine.name} has been created.`);

        // Refresh page
        history.go(0);
      },
      onError(error) {
        console.log('Create Test Machine', error);
        createNotification('danger', 'Sorry, could not create test machine.');
      },
    }
  );

  const handleChange = (e) => {
    let value = parseInt(e.target.value) || '';
    value = value < 1 ? '' : value;

    switch (e.target.name) {
      case 'machineName':
        setMachineName(e.target.value);
        break;
      case 'rows':
        setRows(value);
        break;
      case 'columns':
        setColumns(value);
        break;
      case 'isActive':
        setIsActive(!isActive);
        break;
      default:
        break;
    }
  };

  const handleSelectTestMachine = (e) => {
    if (e.target.value === '-') {
      setSelectedMachine({});
      setColumns('');
      setRows('');
      setIsActive(false);
      setNewMachine(false);

      return;
    }

    const index = _.findIndex(testMachines, { id: e.target.value });

    setSelectedMachine({ ...testMachines[index] });
    setColumns(testMachines[index].trayColumns);
    setRows(testMachines[index].trayRows);
    setIsActive(testMachines[index].isActive);
    setNewMachine(false);
  };

  const validate = () => {
    const errorsObj = {};

    if (machineName.length < 1) {
      errorsObj['machineName'] = 'Required';
    }

    if (rows.length < 1) {
      errorsObj['rows'] = 'Required';
    }

    if (columns.length < 1) {
      errorsObj['columns'] = 'Required';
    }

    return errorsObj;
  };

  const submitMachineUpdates = () => {
    const data = {
      id: selectedMachine.id,
      trayRows: rows.toString(),
      trayColumns: columns.toString(),
      isActive
    };

    // Save mutation
    updateTestMachine({ variables: data });
  };

  const submitNewMachine = () => {
    // Validate
    const errorsObj = validate();

    if (!_.isEmpty(errorsObj)) {
      setErrors(errorsObj);

      return;
    }

    const data = {
      name: machineName,
      trayRows: rows.toString(),
      trayColumns: columns.toString(),
      isActive
    };

    // Save mutation
    saveNewTestMachine({ variables: data });
  };

  const renderPanelName = (name) => {
    switch (name.toUpperCase()) {
      case 'PAIN':
        return 'Pain';
      case 'ETG':
        return 'EtG';
      default:
        return name;
    }
  };

  const handlePanelActiveChange = (e, machine, idx) => {
    const updated = _.cloneDeep(testMachines);

    const i = _.findIndex(updated[idx].panels, { panelName: e.target.name });

    updated[idx].panels[i].isActive = !updated[idx].panels[i].isActive;

    setPanelStatus({
      variables: {
        id: machine.id,
        panels: updated[idx].panels.map(({ panelName, isActive }) => ({
          panelName,
          isActive,
        })),
      }
    });

    setTestMachines(updated);
  };

  if (loading) return <Loading />;

  /*****************************************************************
  return (
    <div className="section">
      <div className="container">
        <div className="page">
          <h4 className="title is-4 is-inline-block mt-1">Toxicology Machines</h4>

          <br />

          <div style={{ overflowX: "auto" }}>
            <table className="table is-fullwidth is-hoverable">
              <tbody>
                {testMachines.map((machine, idx) => (
                  <tr key={idx}>
                    <td>{machine.name}</td>
                    <td>
                      <div className="field">
                        <input
                          id={`switch${machine.name.replace(' ', '')}${machine.panels[0].panelName}`}
                          name={machine.panels[0].panelName}
                          className="switch is-rtl"
                          type="checkbox"
                          onChange={(e) => handlePanelActiveChange(e, machine, idx)}
                          checked={machine.panels[0].isActive}
                          disabled={machine.panels[0].disabled}
                        />
                        <label htmlFor={`switch${machine.name.replace(' ', '')}${machine.panels[0].panelName}`}>
                          {renderPanelName(machine.panels[0].panelName)}
                        </label>
                      </div>
                    </td>
                    <td>
                      <div className="field">
                        <input
                          id={`switch${machine.name.replace(' ', '')}${machine.panels[1].panelName}`}
                          name={machine.panels[1].panelName}
                          className="switch is-rtl"
                          type="checkbox"
                          onChange={(e) => handlePanelActiveChange(e, machine, idx)}
                          checked={machine.panels[1].isActive}
                          disabled={machine.panels[1].disabled}
                        />
                        <label htmlFor={`switch${machine.name.replace(' ', '')}${machine.panels[1].panelName}`}>
                          {renderPanelName(machine.panels[1].panelName)}
                        </label>
                      </div>
                    </td>
                    <td>
                      Last updated on {new Date(parseInt(machine.updatedDate)).toLocaleString()}
                    </td>
                    <td>Machine Details</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
  *****************************************************************/

  return (
    <div className="section">
      <div className="container">
        <div className="page">
          <h4 className="title is-4 is-inline-block mt-1">Toxicology Machines</h4>

          <br />

          <div className="select">
            <select
              id="input-testMachines"
              name="testMachines"
              className="input"
              onChange={handleSelectTestMachine}
            >
              <option defaultValue value="-">Select Test Machine</option>
              {testMachines.map((machine) => (
                <option
                  key={machine.id}
                  value={machine.id}
                >
                  {machine.name} &mdash; ({machine.isActive ? "Active" : "Inactive"})
                </option>
              ))}
            </select>
          </div>

          <hr />

          {!_.isEmpty(selectedMachine) && (
            <div className="columns">
              <div className="column is-half">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Dimensions</label>
                  </div>

                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          id="input-columns"
                          name="columns"
                          className="input"
                          type="number"
                          placeholder="Columns"
                          value={columns}
                          onChange={handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-arrow-circle-right" />
                        </span>
                      </p>
                    </div>

                    <div className="field">
                      <p className="control is-expanded has-icons-left has-icons-right">
                        <input
                          id="input-rows"
                          name="rows"
                          className="input"
                          type="number"
                          placeholder="Rows"
                          value={rows}
                          onChange={handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-arrow-circle-down" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label">
                    <label className="label" htmlFor="input-isActive">Is Active?</label>
                  </div>

                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="checkbox">
                          <input
                            id="input-isActive"
                            name="isActive"
                            type="checkbox"
                            checked={isActive}
                            onChange={handleChange}
                          />
                          <span
                            className={isActive ? "ml-3 has-text-success" : "ml-3 has-text-danger"}
                          >
                            ({isActive ? "Active" : "Inactive"})
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal mt-5">
                  <div className="field-label" />

                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <button
                          className="button is-info"
                          onClick={submitMachineUpdates}
                        >
                          Update
                        </button>
                        {updating && <ButtonLoading />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <br />

          {_.isEmpty(selectedMachine) && !newMachine && (
            <button
              className="button is-primary mt-4"
              onClick={() => setNewMachine(true)}
            >
              Add Machine
            </button>
          )}

          {newMachine && (
            <div className="columns">
              <div className="column is-half">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label" htmlFor="input-machineName">Name</label>
                  </div>

                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          id="input-machineName"
                          name="machineName"
                          className={errors.machineName ? "input error" : "input"}
                          type="text"
                          placeholder=""
                          value={machineName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Dimensions</label>
                  </div>

                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <input
                          id="input-columns"
                          name="columns"
                          className={errors.columns ? "input error" : "input"}
                          type="number"
                          placeholder="Columns"
                          value={columns}
                          onChange={handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-arrow-circle-right" />
                        </span>
                      </p>
                    </div>

                    <div className="field">
                      <p className="control is-expanded has-icons-left has-icons-right">
                        <input
                          id="input-rows"
                          name="rows"
                          className={errors.rows ? "input error" : "input"}
                          type="number"
                          placeholder="Rows"
                          value={rows}
                          onChange={handleChange}
                        />
                        <span className="icon is-small is-left">
                          <i className="fas fa-arrow-circle-down" />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label">
                    <label className="label" htmlFor="input-isActive">Is Active?</label>
                  </div>

                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <label className="checkbox">
                          <input
                            id="input-isActive"
                            name="isActive"
                            type="checkbox"
                            checked={isActive}
                            onChange={handleChange}
                          />
                          <span
                            className={isActive ? "ml-3 has-text-success" : "ml-3 has-text-danger"}
                          >
                            ({isActive ? "Active" : "Inactive"})
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal mt-5">
                  <div className="field-label" />

                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <button
                          className="button is-info"
                          onClick={submitNewMachine}
                        >
                          Save
                        </button>
                        {saving && <ButtonLoading />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <TrayVisualization rows={rows} columns={columns} />
        </div>
      </div>
    </div>
  );
};

export default TestMachines;
