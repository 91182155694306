import React from 'react';
import Popup from 'reactjs-popup';

import '../../styles/modal.css';

const ReRunModal = ({ openModal, closeModal, data }) => {
  return (
    <Popup
      open={openModal}
      onClose={closeModal}
      modal
    >
      {(close) => {
        return (
          <div>
            <span className="has-text-white is-pulled-right">
              <i className="fas fa-times fa-lg icon-link" onClick={close} />
            </span>

            <h4 className="title is-4">
              Re-run Request Notes
            </h4>

            <div className="content">
              <div className="columns">
                <div className="column is-one-quarter">
                  <p className="mb-0">
                    <span className="has-text-weight-semibold">
                      Analyst:
                    </span>
                  </p>

                  <p>
                    <span className="has-text-weight-semibold">
                      Date:
                    </span>
                  </p>

                  <p>
                    <span className="has-text-weight-semibold">
                      Batch ID:
                    </span>

                    <br />

                    <span className="has-text-weight-semibold">
                      Tray Location:
                    </span>
                  </p>

                  <p>
                    <span className="has-text-weight-semibold">
                      {data?.specimenReRunRequestedAnalystPanels.length === 1 ?
                        "Requested Panel:" :
                        "Requested Panels:"
                      }
                    </span>

                    <br />

                    <span className="has-text-weight-semibold">
                      Reason:
                    </span>
                  </p>

                  <p>
                    <span className="has-text-weight-semibold">
                      Analyst Notes:
                    </span>
                  </p>
                </div>

                <div className="column">
                  <p className="mb-0">
                    {data?.specimenReRunRequestedAnalystName}
                  </p>

                  <p>
                    {new Date(parseInt(data?.specimenReRunRequestedAnalystDate)).toDateString()}
                  </p>

                  <p>
                    {data?.batchIds[0]}

                    <br />

                    {data?.storageTrayLocation}
                  </p>

                  <p>
                    {data?.specimenReRunRequestedAnalystPanels.join(', ')}

                    <br />

                    {data?.specimenReRunRequestedAnalystReason}
                  </p>

                  <p>
                    {data?.specimenReRunRequestedAnalystNotes}
                  </p>
                </div>
              </div>
{/*               <p className="mb-0"> */}
{/*                 <span className="has-text-weight-semibold"> */}
{/*                   Analyst:{" "} */}
{/*                 </span> */}
{/*                 {data?.specimenReRunRequestedAnalystName} */}
{/*               </p> */}
{/*  */}
{/*               <p> */}
{/*                 <span className="has-text-weight-semibold"> */}
{/*                   Date:{" "} */}
{/*                 </span> */}
{/*                 {new Date(parseInt(data?.specimenReRunRequestedAnalystDate)).toDateString()} */}
{/*               </p> */}
{/*  */}
{/*               <p> */}
{/*                 <span className="has-text-weight-semibold"> */}
{/*                   Batch ID:{" "} */}
{/*                 </span> */}
{/*                 {data?.batchIds[0]} */}
{/*  */}
{/*                 <br /> */}
{/*  */}
{/*                 <span className="has-text-weight-semibold"> */}
{/*                   Tray Location:{" "} */}
{/*                 </span> */}
{/*                 {data?.storageTrayLocation} */}
{/*               </p> */}
{/*  */}
{/*               <p> */}
{/*                 <span className="has-text-weight-semibold"> */}
{/*                   {data?.specimenReRunRequestedAnalystPanels.length === 1 ? */}
{/*                     "Requested Panel: " : */}
{/*                     "Requested Panels: " */}
{/*                   } */}
{/*                 </span> */}
{/*                 {data?.specimenReRunRequestedAnalystPanels.join(', ')} */}
{/*  */}
{/*                 <br /> */}
{/*  */}
{/*                 <span className="has-text-weight-semibold"> */}
{/*                   Reason:{" "} */}
{/*                 </span> */}
{/*                 {data?.specimenReRunRequestedAnalystReason} */}
{/*  */}
{/*                 <br /> */}
{/*  */}
{/*                 <span className="has-text-weight-semibold"> */}
{/*                   Analyst Notes:{" "} */}
{/*                 </span> */}
{/*                 {data?.specimenReRunRequestedAnalystNotes} */}
{/*               </p> */}
            </div>
          </div>
        );
      }}
    </Popup>
  );
};

export default ReRunModal;
