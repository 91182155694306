import React from 'react';
import Modal from 'react-modal';

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    border: 0,
    background: "transparent",
  },
};

const SpecimenRejectedNotesModal = ({ isOpen, handleCloseModal, specimenId, message }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleCloseModal}
      style={modalStyles}
    >
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title has-text-white">Specimen ID {specimenId} Was Rejected</p>
            <button
              className="delete"
              aria-label="close"
              onClick={handleCloseModal}
            />
          </header>

          <section className="modal-card-body page">
            <div className="block">
              <p className="is-size-4">Analyst Notes:</p>
              <br />
              <p>{message}</p>
            </div>

            <button
              className="button is-info mt-5 is-pulled-right"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </section>
        </div>
      </div>
    </Modal>
  );
};

export default SpecimenRejectedNotesModal;
