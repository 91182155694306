import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import SingleCovidResult from './SingleCovidResult';
import { createNotification } from '../Notification';
import { ButtonLoading } from '../Loading';

const EnterCovidResults = (props) => {
  const [sampleId, setSampleId] = useState('');
  const [specimenIdValid, setSpecimenIdValid] = useState(false);
  const [validatingSampleId, setValidatingSampleId] = useState(false);
  const [submittingResults, setSubmittingResults] = useState(false);
  const [errors, setErrors] = useState({});

  const history = useHistory();

  const handleSampleId = (e) => {
    setSampleId(e.target.value);
  };

  const clearError = (name) => {
    const updatedErrors = {
      ...errors,
      [name]: undefined,
    };

    delete updatedErrors[name];

    setErrors(updatedErrors);
  };

  const handleSpecimenIdValidate = (e) => {
    e.preventDefault();

    // Do nothing if empty
    if (sampleId.length < 1) {
      setErrors({
        ...errors,
        sampleId: 'Sample ID is required',
      });

      return;
    }

    const headers = {
      'x-api-key': process.env.REACT_APP_COVID_RESULT_UPLOAD_API_KEY,
      's': sampleId,
    };

    setValidatingSampleId(true);

    axios.get(process.env.REACT_APP_COVID_SINGLE_RESULT_UPLOAD_URL, { headers })
      .then((res) => {
        // console.log(res);

        if (res.status === 200) {
          setSpecimenIdValid(true);

          clearError('sampleId');
        }

        setValidatingSampleId(false);
      })
      .catch((error) => {
        console.log({ error });

        setErrors({
          ...errors,
          sampleId: 'Invalid Sample ID',
        });

        setSpecimenIdValid(false);
        setValidatingSampleId(false);
      });
  };

  const handleResults = (results) => {
    const data = {
      ...results,
      sample_id: sampleId,
    };

    const headers = {
      'x-api-key': process.env.REACT_APP_COVID_RESULT_UPLOAD_API_KEY,
    };

    setSubmittingResults(true);

    axios.post(process.env.REACT_APP_COVID_SINGLE_RESULT_UPLOAD_URL, data, { headers })
      .then((res) => {
        // console.log(res);

        createNotification('info', 'Results saved.');

        setSubmittingResults(false);

        history.goBack();
      })
      .catch((error) => {
        console.log({ error });

        createNotification('danger', 'Sorry, unable to save results. Please try again.');

        setSubmittingResults(false);
      });
  };

  return (
    <section className="section">
      <div className="container">
        <div className="page">
          <button
            className="button is-danger is-pulled-right"
            onClick={() => history.goBack()}
          >
            Cancel
          </button>

          <h1 className="title is-3">Single Covid Result</h1>

          <form onSubmit={handleSpecimenIdValidate}>
            <div className={errors.sampleId ? "columns mt-6 mb-0" : "columns my-6"}>
              <div className="field is-grouped column is-half">
                <div className="control is-expanded">
                  <input
                    name="sampleId"
                    className={errors.sampleId ? "input error" : "input"}
                    type="text"
                    placeholder="Specimen ID"
                    value={sampleId}
                    onChange={handleSampleId}
                  />
                </div>

                <div className="control">
                  <button
                    className="button is-info"
                    onClick={handleSpecimenIdValidate}
                  >
                    {validatingSampleId ? <ButtonLoading /> : "Validate"}
                  </button>
                </div>
              </div>
            </div>
            {errors.sampleId && (
              <div className="input-feedback">{errors.sampleId}</div>
            )}
          </form>

          {specimenIdValid && (<SingleCovidResult handleSubmit={handleResults} submitting={submittingResults} />)}
        </div>
      </div>
    </section>
  );
};

export default EnterCovidResults;
