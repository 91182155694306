import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';

import { createNotification } from '../Notification';
import { ButtonLoading } from '../Loading';

import '../../styles/modal.css';

const RERUN_REASONS = [
  "Rerun only - Peak shifting off pane (Full MRM scan)",
  "Rerun only - Missing all data / no peaks present",
  "Rerun only - Abnormal or noisy data",
  "Rerun only - Select internal standards missing",
  // "Rerun only - Excluded analyte; rerun on different machine",
  "Rerun only - Excluded analyte; RERUN ON DIFFERENT MACHINE",
  "Redraw and rerun - Suspected carryover or contamination",
  "Redraw and rerun - Clinic request",
  "Redraw and rerun - Sample(s) likely mixed or swapped",
  "Redraw and rerun - All internal standards missing",
  "Redraw and rerun - Sample not run",
  "Rerun only - Refer to notes",
  "Redraw and rerun - Refer to notes",
];

const REQUEST_LAB_RERUN = gql`
  mutation RequestToxicologyLabReRun(
    $specimenId: String!,
    $panels: [String],
    $reason: String,
    $analystNotes: String,
    $isCompleteRetest: Boolean
  ) {
    requestToxicologyLabReRun(
      data: {
        specimenId: $specimenId
        panels: $panels
        reason: $reason
        analystNotes: $analystNotes
        isCompleteRetest: $isCompleteRetest
      }
    ) {
      id
    }
  }
`;

const RequestLabReRun = ({ orderDetails, setReRunRequested, savePartialResults, buttonText, buttonColor, isCompleteRetest, hidden }) => {
  const [testPanels, setTestPanels] = useState([]);
  const [selectedPanel, setSelectedPanel] = useState('');
  const [reRunReason, setReRunReason] = useState('');
  const [notes, setNotes] = useState('');
  const [savePartial, setSavePartial] = useState(true);
  const [errors, setErrors] = useState({});

  const history = useHistory();

  useEffect(() => {
    if (orderDetails.batches.length === 1) {
      setSelectedPanel(orderDetails.batches[0].testPanel.toUpperCase());
    }

    const batchSet = new Set();
    orderDetails.batches.forEach(({ testPanel }) => batchSet.add(testPanel.toUpperCase()));

    setTestPanels(Array.from(batchSet));

    // Don't save partial results if complete re-test
    if (isCompleteRetest) {
      setSavePartial(false);
    }
  }, []);

  const [requestLabReRun, { loading }] = useMutation(
    REQUEST_LAB_RERUN,
    {
      onCompleted({ requestToxicologyLabReRun }) {
        setReRunRequested(true);
        createNotification('info', 'Re-run request submitted');

        history.goBack();
      },
      onError(error) {
        console.log('Request Lab Re-run', error);
        createNotification('danger', 'Sorry, could not submit re-run request. Please try again.');
      },
    }
  );

  const handleChange = (e) => {
    setNotes(e.target.value);
  };

  const handleSubmit = (e, close) => {
    e.preventDefault();

    // Save partial results?
    if (savePartial) {
      savePartialResults();
    }

    // Check values
    let errors = {};
    if (!isCompleteRetest && selectedPanel.length === 0) {
      errors = {
        ...errors,
        selectedPanel: "Please select a panel",
      };
    }

    if (!isCompleteRetest && reRunReason.length === 0) {
      errors = {
        ...errors,
        reRunReason: "Please select a reason",
      };
    }

    setErrors(errors);

    const variables = {
      specimenId: orderDetails.specimenId,
      reason: reRunReason,
      analystNotes: notes,
      isCompleteRetest,
    };

    if (isCompleteRetest) {
      variables.panels = testPanels;
    } else {
      switch (selectedPanel) {
        case 'PAIN':
          variables.panels = ['PAIN'];
          break;
        case 'ETG':
          variables.panels = ['ETG'];
          break;
        case '::ALL::':
          variables.panels = ['PAIN', 'ETG'];
          break;
        default:
          break;
      }
    }

    console.log({ variables });

    requestLabReRun({ variables });





















    // close();




















  };

  const generatePanelLabel = (panel) => {
    switch (panel.toUpperCase()) {
      case 'PAIN':
        return 'Pain';
      case 'ETG':
        return 'EtG';
      default:
        return '';
    }
  };

  const handleSelectedPanelChange = (e) => {
    setSelectedPanel(e.target.value.toUpperCase());

    // Clear error
    const newErrors = { ...errors };
    delete newErrors.selectedPanel;
    setErrors(newErrors);
  };

  const handleSelectReRunReason = (e) => {
    setReRunReason(e.target.value);

    // Clear error
    const newErrors = { ...errors };
    delete newErrors.reRunReason;
    setErrors(newErrors);
  };

  if (hidden) {
    return null;
  }

  return (
    <Popup
      trigger={
        <button
          className={`button is-${buttonColor} is-light`}
        >
          {buttonText}
        </button>
      }
      modal
    >
      {(close) => (
        <div className="">
          {isCompleteRetest && (
            <h1 className="title is-4">Return to Lab for Complete Re-test</h1>
          )}

          {!isCompleteRetest && (
            <div className="field mb-3">
              <p className="label">
                {testPanels.length === 1 ?
                  'Panel:'
                  :
                  'Select Panel(s):'
                }
              </p>
              {testPanels.map((testPanel) => (
                <label key={testPanel} className="radio has-text-light mr-3">
                  <input
                    className="mr-1"
                    type="radio"
                    name="panel"
                    value={testPanel.toUpperCase()}
                    checked={selectedPanel === testPanel}
                    onChange={handleSelectedPanelChange}
                  />
                  {generatePanelLabel(testPanel)}
                </label>
              ))}

              {testPanels.length === 2 && (
                <label className="radio has-text-light">
                  <input
                    className="mr-1"
                    type="radio"
                    name="panel"
                    value="::ALL::"
                    checked={selectedPanel === '::ALL::'}
                    onChange={handleSelectedPanelChange}
                  />
                  Both
                </label>
              )}
              {errors.selectedPanel && (
                <div
                  className="input-feedback help"
                  data-testid="errors-selectedPanel"
                >
                  {errors.selectedPanel}
                </div>
              )}
            </div>
          )}

          {!isCompleteRetest && (
            <div className="field mb-3">
              <div className="select">
                <select
                  id="input-reRunReason"
                  name="reRunReason"
                  className="input"
                  onChange={handleSelectReRunReason}
                >
                  <option defaultValue value="">
                    Select Reason
                  </option>

                  {RERUN_REASONS.map((reason, i) => (
                    <option
                      key={i}
                      value={reason}
                    >
                      {reason}
                    </option>
                  ))}
                </select>
              </div>
              {errors.reRunReason && (
                <div
                  className="input-feedback help"
                  data-testid="errors-reRunReason"
                >
                  {errors.reRunReason}
                </div>
              )}
            </div>
          )}

          <div className="field">
            <label className="label" htmlFor="input-notes">
              Note {!isCompleteRetest && "for the Lab"}
            </label>
            <textarea
              id="input-notes"
              name="notes"
              className="textarea"
              placeholder=""
              value={notes}
              onChange={handleChange}
            />
          </div>

          {!isCompleteRetest && (
            <div className="field">
              <label className="checkbox has-text-white-ter">
                <input
                  className="mr-3"
                  type="checkbox"
                  checked={savePartial}
                  onChange={() => setSavePartial(!savePartial)}
                />
                Save entered results?
              </label>
            </div>
          )}

          <button
            className="button is-primary mb-2"
            onClick={(e) => handleSubmit(e, close)}
          >
            Submit
          </button>

          {loading && <ButtonLoading />}

          <button
            className="button is-danger is-pulled-right"
            onClick={(e) => {
              e.preventDefault();

              // Reset values
              setSelectedPanel('');
              setReRunReason('');
              setNotes('');
              setErrors({});

              close();
            }}
          >
            Cancel
          </button>
        </div>
      )}
    </Popup>
  );
};

export default RequestLabReRun;
