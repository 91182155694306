import React from 'react';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';

const ClinicInfo = ({ id, clinicInfo }) => {
  const formatPhoneNumber = (number) => {
    if (Object.prototype.toString.call(number) !== "[object String]") {
      return null;
    }

    const stripped = number
      .replace('-', '')
      .replace(' ', '')
      .replace('(', '')
      .replace(')', '');

    if (stripped.length < 10) {
      return stripped;
    }

    return `(${stripped.slice(0, 3)}) ${stripped.slice(3, 6)}-${stripped.slice(-4)}`
  };

  return (
    <div className="column is-one-third-desktop is-one-third-tablet">
      <div className="page">
        <h1 className="is-size-1 title mt-1">{clinicInfo.clinicName}</h1>
        <h2 className="subtitle is-4">({clinicInfo.clinicCode})</h2>

        <Link className="is-size-5" to={ROUTES.RADEAS_CLINIC_SUPPPLIES_AND_LOGISTICS.replace(':id', id)}>
          Supplies
        </Link>

        <br />
        <br />

        <p>Phone {formatPhoneNumber(clinicInfo.phone) || ""}</p>
        <p>Fax {formatPhoneNumber(clinicInfo.fax) || ""}</p>
        <br/>
        <p>{clinicInfo.addresses?.length > 0 && clinicInfo.addresses[0].address1 || ""}</p>
        <p>{clinicInfo.addresses?.length > 0 && clinicInfo.addresses[0].address2 || ""}</p>
        <p>
          {clinicInfo.addresses?.length > 0 && clinicInfo.addresses[0].city|| "" }
          {", "}
          {clinicInfo.addresses?.length > 0 && clinicInfo.addresses[0].state|| "" }
          {" "}
          {clinicInfo.addresses?.length > 0 && clinicInfo.addresses[0].zipCode|| "" }
        </p>

        <br/>
        <br/>

{/*         <span className="has-text-white is-size-4">Toxicology orders</span> */}
{/*  */}
{/*         <br/> */}
{/*         <br/> */}
{/*  */}
{/*         <p>in transit: <Link to="#">5</Link></p> */}
{/*         <p>processing: <Link to="#">3</Link></p> */}
{/*         <p>completed: <Link to="#">150</Link></p> */}
      </div>
    </div>
  );
};

export default ClinicInfo;
