import React, { useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { ButtonLoading } from '../Loading';
import { clearNotification, createNotification } from '../Notification';
import * as ROUTES from '../../constants/routes';

import '../../styles/form.css';

export const MIN_PASSWORD_LENGTH = 8;

export const RESET_RADEAS_USER_PASSWORD = gql`
  mutation ResetRadeasUserPassword(
    $token: String!,
    $password: String!
  ) {
    resetRadeasUserPassword(
      data: {
        token: $token
        password: $password
      }
    ) {
      id
      updatedDate
    }
  }
`;

const ResetPassword = () => {
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    // Clear notifications when navigating away
    return function cleanup() {
      clearNotification();
    }
  }, []);

  const [changePassword, { loading }] = useMutation(
    RESET_RADEAS_USER_PASSWORD,
    {
      onCompleted({ resetRadeasUserPassword }) {
        createNotification('info', 'Your password has been changed. Please log in.');

        history.push(ROUTES.SIGN_IN);
      },
      onError(error) {
        console.log('Password change', error);
        createNotification('danger', 'Sorry, couldn\'t change your password. Please try again.');
      },
    },
  );

  return (
    <div className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <div className="page">
              <h4 className="title is-4">Reset Password</h4>

              <Formik
                initialValues={{
                  password: '',
                  confirmPassword: ''
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  const { password } = values;

                  // Execute the mutation
                  changePassword({ variables: { token, password } });

                  resetForm();
                  setSubmitting(false);
                }}
                validationSchema={Yup.object().shape({
                  password: Yup.string()
                    .required("New password is required.")
                    .min(MIN_PASSWORD_LENGTH, `Password must be at least ${MIN_PASSWORD_LENGTH} characters.`),
                  confirmPassword: Yup.string()
                    .required("Password confirmation is required.")
                    .oneOf([Yup.ref('password'), null], "Passwords must match.")
                })}
              >
                {(formProps) => {
                  const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  } = formProps;

                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="block">
                        <label htmlFor="input-password">
                          New Password
                        </label>
                        <input
                          id="input-password"
                          name="password"
                          className={errors.password && touched.password ? "input error" : "input"}
                          type="password"
                          placeholder="Enter a password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {errors.password && touched.password && (
                          <div className="input-feedback" data-testid="errors-password">
                            {errors.password}
                          </div>
                        )}
                      </div>


                        <label htmlFor="input-confirmPassword">
                          Confirm New Password
                        </label>
                        <input
                          id="input-confirmPassword"
                          name="confirmPassword"
                          className={errors.confirmPassword && touched.confirmPassword ? "input error" : "input"}
                          type="password"
                          placeholder="Re-enter password"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        {(errors.confirmPassword && touched.confirmPassword ?
                          <div className="input-feedback help has-text-right" data-testid="errors-confirmPassword">
                            {errors.confirmPassword}
                          </div>:
                          <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode">
                          </div>
                        )}


                      <input
                        type="submit"
                        className="button is-primary"
                        value="Submit"
                        disabled={isSubmitting}
                      />
                      {loading ? <ButtonLoading /> : null}
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
