import jwt_decode from 'jwt-decode';
import _ from 'lodash';

export function decodeJwt(token) {
  try {
    const decoded = jwt_decode(token);

    const { exp, iat } = decoded;
    const authUserId = decoded.sub;
    const { roles } = decoded[process.env.REACT_APP_JWT_NAMESPACE];

    return { authUserId, roles, exp, iat };
  } catch {
    return { authUserId: null, roles: null, exp: null, iat: null };
  }
}

export function filterTests(tests) {
  const filtered = tests.filter((test) => !test.drugName.substring(0, 4).includes('All ') && !test.drugName.includes('Urine Validation Testing') && test.pos);

  return filtered;
}

export function mergeTestProfileWithDefaultTestProfile(testProfile, defaultTestProfile) {
  const merged = defaultTestProfile.map((drug) => {
    const match = _.find(testProfile.toxicologyTests, ({ drugName }) => drugName.toUpperCase() === drug.drugName.toUpperCase());

    // Match found
    if (match) {
      return {
        ...drug,
        isActive: !!match.pos,
      };
    }

    // Check if it's in custom tests
    if (testProfile.customTests.trim().toUpperCase().includes(drug.drugName.toUpperCase())) {
      return {
        ...drug,
        isActive: true,
      };
    }

    // Check alcohol
    if (drug.drugName.toUpperCase() === 'Ethyl glucuronide'.toUpperCase() || drug.drugName.toUpperCase() === 'Ethyl sulfate'.toUpperCase()) {
      console.log('Checking alcohol...');

      if (testProfile.etgTests.length > 0) {
        return {
          ...drug,
          isActive: true,
        };
      }
    }

    // Drug not found in original test profile, set isActive to false
    return {
      ...drug,
      isActive: false,
    };
  });

  return merged;
}
