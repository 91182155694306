import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import Loading from '../Loading';
import { createNotification } from '../Notification';
import {
  TOXICOLOGY_LAB_BATCHED_PENDING,
  TOXICOLOGY_LAB_BATCHED_RUNNING,
  TOXICOLOGY_LAB_BATCHED_COMPLETE,
} from '../../constants/status';
import * as ROUTES from '../../constants/routes';

export const ALL_BATCHES = gql`
  query AllBatches {
    allBatches {
      id
      batchId
      machineId
      machineName
      testPanel
      specimenIds
      status
      createdById
      createdByFirstName
      createdByLastName
      createdByEmail
      createdDate
      updatedDate
    }
  }
`;

export const UPDATE_BATCH_STATUS = gql`
  mutation UpdateBatch(
    $id: String!
    $status: String
  ) {
    updateBatch(
      data: {
        id: $id,
        status: $status
      }
    ) {
      id
      batchId
      machineId
      machineName
      testPanel
      specimenIds
      status
      createdById
      createdByFirstName
      createdByLastName
      createdByEmail
      createdDate
      updatedDate
    }
  }
`;

const BatchedSpecimenList = () => {
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);

  useQuery(
    ALL_BATCHES,
    {
      // fetchPolicy: 'network-only',
      onCompleted({ allBatches }) {
        if (process.env.NODE_ENV === 'development') console.log('Query allBatches:', allBatches);

        setBatches(allBatches);
        setLoading(false);
      },
      onError(error) {
        console.log('Query allBatches', error);
        createNotification('danger', 'Sorry, could not retrieve batches.');
      },
    }
  );

  const [updateBatchStatus, { loading: updating }] = useMutation(
    UPDATE_BATCH_STATUS,
    {
      onCompleted({ updateBatch }) {
        if (process.env.NODE_ENV === 'development') console.log('Mutation updateBatch:', updateBatch);

        // Update batches table
        const updated = _.cloneDeep(batches);
        const index = _.findIndex(batches, { id: updateBatch.id });

        updated[index] = updateBatch;

        setBatches(updated);
      },
      onError(error) {
        console.log('Mutation updateBatch', error);
        createNotification('danger', 'Sorry, could not set batch status to complete.');
      }
    }
  );

  const renderStatus = (status) => {
    switch (status.split(':').at(-1)) {
      case 'running':
        return <span className="has-text-warning">Running</span>;
      case 'complete':
        return <span className="has-text-success">Complete</span>;
      case 'pending':
        return <span className="has-text-danger">Pending</span>
      default:
        return <span className="has-text-danger">Unknown</span>;
    }

    return status;
  };

  const renderCompleteButton = (batch) => {
    if (batch.status === TOXICOLOGY_LAB_BATCHED_RUNNING) {
      return (
        <button
          className="button is-small is-info is-fullwidth"
          onClick={() => handleSetComplete(batch)}
          disabled={updating}
        >
          Set Complete
        </button>
      );
    }

    return (
      <button
        className="button is-small is-info is-fullwidth is-outlined"
        disabled
      >
        Set Complete
      </button>
    )
  };

  const handleSetComplete = (batch) => {
    const data = {
      id: batch.id,
      status: TOXICOLOGY_LAB_BATCHED_COMPLETE,
    };

    // Save mutation
    updateBatchStatus({ variables: data });
  };

  if (loading) return <Loading />;

  return (
    <div className="section">
      <div className="container">
        <div
          className="page px-0 pb-3 pt-5"
          // style={{ borderRadius: "10px" }}
        >
          <div className="mx-5">
            <h4 className="title is-4">Batches</h4>
          </div>

          <div className="mt-5" style={{ overflowX: "auto" }}>
            <table className="table is-fullwidth is-striped is-hoverable">
              <thead>
                <tr>
                  <th>Batch ID</th>
                  <th>Panel</th>
                  <th>Machine</th>
                  <th>Num Specimens</th>
                  <th>Created By</th>
                  <th>Created Date</th>
                  <th>Status</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                {batches && batches.map((batch) => (
                  <tr key={batch.id}>
                    <td>
                      <Link
                        to={ROUTES.DASHBOARD_LAB_BATCH_DETAILS.replace(':id', batch.id)}
                        style={{ textDecoration: "none" }}
                      >
                        {batch.batchId}
                      </Link>
                    </td>
                    <td>{batch.testPanel && batch.testPanel[0].toUpperCase() + batch.testPanel.substring(1).toLowerCase()}</td>
                    <td>{batch.machineName === "[Pending]" ? <span className="has-text-weight-semibold has-text-danger">PENDING</span> : batch.machineName}</td>
                    <td>{batch.specimenIds.length}</td>
                    <td>{batch.createdByLastName}, {batch.createdByFirstName}</td>
                    <td>{new Date(parseInt(batch.createdDate)).toDateString()}</td>
                    <td>{renderStatus(batch.status)}</td>
                    <td>{renderCompleteButton(batch)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchedSpecimenList;
