import React from 'react';

import SendResults from './SendResults';
import ResultsCenter from './ResultsCenter';

import EnterCovidResults from './EnterCovidResults';

import { useAuth } from '../auth';

const CovidDashboard = () => {
  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  return (
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-one-quarter">
            <SendResults />
          </div>

          <div className="column">
            <ResultsCenter />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CovidDashboard;

export { EnterCovidResults };
