import React from 'react';

const DrugTestMenuTable = ({ tableData }) => {
  const generateTable = () => {
    return (
      tableData.map((row, i) => {
        return (
          <tr key={i}>
            <td>{i+1}</td>
            <td>{row.drugClass}</td>
            <td>{`${new Array(row.parents.length+1).join("\u00A0\u00A0\u00A0\u00A0")}${row.drugName}`}</td>
            <td>{row.drugBrand}</td>
            <td>{row.scrnAvailable ? "Available" : null}</td>
            <td>{row.cutoffs.urineDefault}</td>
            <td>{row.cutoffs.oralFluidDefault}</td>
          </tr>
        );
      })
    );
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <table className="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th />
            <th>Drug Class</th>
            <th>Drug Name</th>
            <th>Drug Brand</th>
            <th>Scrn</th>
            <th>Urine Cutoff</th>
            <th>Oral Fluid Cutoff</th>
          </tr>
        </thead>

        <tbody>
          {generateTable()}
        </tbody>
      </table>
    </div>
  );
};

export default DrugTestMenuTable;
