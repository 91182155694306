import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';

import SpecimenRejectedNotesModal from '../ToxicologyOrder/SpecimenRejectedNotesModal';
import Loading from '../Loading';
import { createNotification } from '../Notification';
import { useAuth } from '../auth';
import * as STATUS from '../../constants/status';

const POLL_INTERVAL = 2500;

export const COMPLETED_TOXICOLOGY_LAB_RESULTS = gql`
  query AllCompletedLabSpecimens {
    allCompletedLabSpecimens {
      id
      specimenId
      clinicCode
      specimenType
      labUploadedDate
      status
      rejectSpecimenComment
    }
  }
`;

const CompletedSpecimenList = () => {
  const [labResults, setLabResults] = useState([]);
  const [showRejectedNotesModal, setShowRejectedNotesModal] = useState(null);
  const [loading, setLoading] = useState(true);

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  const { startPolling, stopPolling } = useQuery(
    COMPLETED_TOXICOLOGY_LAB_RESULTS,
    {
      // fetchPolicy: 'network-only',
      onCompleted({ allCompletedLabSpecimens }) {
        // Sort by date (desc)
        const specimens = _.orderBy(allCompletedLabSpecimens, ['labUploadedDate'], ['desc']);

        setLabResults(specimens);
        setLoading(false);
      },
      onError(error) {
        console.log('allCompletedLabSpecimens query', error);

        createNotification('danger', 'Sorry, something went wrong. Please refresh the page.');

        setLoading(false);
      }
    }
  );

  // Start/stop polling
  useEffect(() => {
    startPolling(POLL_INTERVAL);

    return () => stopPolling();
  });
  // }, [startPolling, stopPolling]);

  const setStatus = (status) => {
    switch (status) {
      case STATUS.TOXICOLOGY_LAB_REJECTED:
        return { color: '#F14668' };      // Red
      default:
        return { color: '#66ff8c' };      // Green
    }
  };

  const handleShowRejectedNotesModal = (order) => {
    setShowRejectedNotesModal(order);
  };

  const handleCloseRejectedNotesModal = () => {
    setShowRejectedNotesModal(null);
  };

  if (loading) return <Loading />;

  return (
    <>
      <div className="page">
        <h4 className="title is-4 is-inline-block mt-1">Completed Orders</h4>

        <br/>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Specimen ID</th>
              <th>Clinic Code</th>

              <th>Specimen Type</th>

              <th>Processed Date</th>

              <th>Status</th>
            </tr>
            </thead>

            <tfoot>

            </tfoot>
            <tbody>
            {labResults.map((result) => (
              <tr key={result.id}>
                <td>{result.specimenId}</td>
                <td>{result.clinicCode}</td>
                <td>{result.specimenType}</td>

                <td>{new Date(parseInt(result.labUploadedDate)).toDateString()}</td>

                <td style={setStatus(result.status)}>
                  <span>
                    {result.status === STATUS.TOXICOLOGY_LAB_REJECTED ? (
                      <div
                        className="is-clickable"
                        data-tooltip="View analyst notes"
                        onClick={() => handleShowRejectedNotesModal(result)}
                      >
                        <span className="mr-3 icon is-small">
                          <i className="fas fa-info-circle" aria-hidden="true"/>
                        </span>
                        {result.status.split(':').pop()}
                      </div>
                    ) : (
                      <>
                        <span className="mr-3 icon is-small">
                          <i className="fas fa-circle fa-small" aria-hidden="true"/>
                        </span>
                        complete
                      </>
                    )}
                  </span>
                </td>

              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>

      <SpecimenRejectedNotesModal
        isOpen={!!showRejectedNotesModal}
        handleCloseModal={handleCloseRejectedNotesModal}
        specimenId={showRejectedNotesModal?.specimenId}
        message={showRejectedNotesModal?.rejectSpecimenComment}
      />
    </>
  );
};

export default CompletedSpecimenList;
