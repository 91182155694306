import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Link, useHistory, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import PuffLoader from 'react-spinners/PuffLoader';

import ToxicologyOrderDetailsForm from './ToxicologyOrderDetailsForm';
import DownloadOrderPDFButton from './DownloadOrderPDFButton';
import Loading from '../Loading';
import { useAuth } from '../auth';
import { createNotification } from '../Notification';

import * as ROUTES from '../../constants/routes';
import * as STATUS from '../../constants/status';

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    border: 0,
    background: "transparent",
  },
};

export const GET_TOXICOLOGY_ORDER_BY_ID = gql`
  query GetToxicologyOrderById(
    $id: String!
  ) {
    getToxicologyOrder(
      id: $id
    ) {
      id
      clinicCode
      clinicName

      providerId
      providerName
      providerSuffix

      clinicalPurpose

      icd10Codes
      icd10CodeTags

      patientForeignKey
      patientId
      patientFirstName
      patientLastName
      patientBirthMonth
      patientBirthDay
      patientBirthYear
      patientEmail
      patientPhone

      specimenId
      specimenType
      specimenRunDate
      specimenLabNotes
      specimenAnomaly
      specimenAlcoholBiomarker
      specimenIsValid
      specimenLabMachineName
      specimenRunLabId

      batchIds
      batches {
        id
        batchId
        testPanel
      }
      storageTrayLocation

      specimenPH
      specimenSpecificGravity
      specimenCreatinine

      specimenReceivedLabUserId
      specimenReceivedLabDate

      specimenReRunRequestedAnalystId
      specimenReRunRequestedAnalystName
      specimenReRunRequestedAnalystNotes
      specimenReRunRequestedAnalystDate

      specimenReRunLabDate
      specimenReRunLabNotes
      specimenReRunLabTechId

      analystReserved {
        radeasUserId
        firstName
        lastName
        email
      }

      medicationList
      medicationListTags
      clinicUserId
      radeasUserId
      toxicologyTests {
        drugName
        drugClass
        drugBrand
        pos
        scrn
        result
        cutoff
        c_i
        flag
      }
      etgTests
      etgTestPanel {
        drugName
        drugClass
        drugBrand
        result
        cutoff
        posNeg
        c_i
        flag
      }
      pocTests {
        code
        name
        result
      }
      additionalTests {
        name
        result
      }
      customTests

      status
      prevStatus
      labStatus
      clinicStatus

      createdById
      createdDate
      updatedDate

      rejectSpecimen
      rejectSpecimenComment
      rejectSpecimenDate
      rejectSpecimenLabTechId
    }
  }
`;

export const UPDATE_TOXICOLOGY_ORDER = gql`
  mutation UpdateToxicologyOrder(
    $id: String!
    $specimenType: String
    $clinicalPurpose: String
    $medicationList: String
    $medicationListTags: [String]
    $icd10Codes: String
    $icd10CodeTags: [String]
    $providerId: String
    $providerName: String
    $providerSuffix: String
    $toxicologyTests: [UpdateToxicologyTestInput]
    $etgTests: [String]
    $customTests: String
    $pocTests: [POCTestInput]
  ) {
    updateToxicologyOrder(
      id: $id,
      data: {
        specimenType: $specimenType,
        clinicalPurpose: $clinicalPurpose,
        medicationList: $medicationList,
        medicationListTags: $medicationListTags,
        icd10Codes: $icd10Codes,
        icd10CodeTags: $icd10CodeTags,
        providerId: $providerId,
        providerName: $providerName,
        providerSuffix: $providerSuffix,
        toxicologyTests: $toxicologyTests,
        etgTests: $etgTests,
        customTests: $customTests,
        pocTests: $pocTests,
      }
    ) {
      id
      clinicCode
      clinicName

      providerId
      providerName
      providerSuffix

      clinicalPurpose

      icd10Codes
      icd10CodeTags

      patientForeignKey
      patientId
      patientFirstName
      patientLastName
      patientBirthMonth
      patientBirthDay
      patientBirthYear
      patientEmail
      patientPhone

      specimenId
      specimenType
      specimenRunDate
      specimenLabNotes
      specimenAnomaly
      specimenAlcoholBiomarker
      specimenIsValid
      specimenLabMachineName
      specimenRunLabId

      batchIds
      batches {
        id
        batchId
        testPanel
      }
      storageTrayLocation

      specimenPH
      specimenSpecificGravity
      specimenCreatinine

      specimenReceivedLabUserId
      specimenReceivedLabDate

      specimenReRunRequestedAnalystId
      specimenReRunRequestedAnalystName
      specimenReRunRequestedAnalystNotes
      specimenReRunRequestedAnalystDate

      specimenReRunLabDate
      specimenReRunLabNotes
      specimenReRunLabTechId

      analystReserved {
        radeasUserId
        firstName
        lastName
        email
      }

      medicationList
      medicationListTags
      clinicUserId
      radeasUserId
      toxicologyTests {
        drugName
        drugClass
        drugBrand
        pos
        scrn
        result
        cutoff
        c_i
        flag
      }
      etgTests
      etgTestPanel {
        drugName
        drugClass
        drugBrand
        result
        cutoff
        posNeg
        c_i
        flag
      }
      pocTests {
        code
        name
        result
      }
      additionalTests {
        name
        result
      }
      customTests

      status
      prevStatus
      labStatus
      clinicStatus

      createdById
      createdDate
      updatedDate

      rejectSpecimen
      rejectSpecimenComment
      rejectSpecimenDate
      rejectSpecimenLabTechId
    }
  }
`;

export const REJECT_SPECIMEN = gql`
  mutation RejectSpecimen(
    $isReRun: Boolean!,
    $specimenId: String!,
    $rejectSpecimen: Boolean,
    $rejectSpecimenComment: String,
  ) {
    setToxicologyLabResults(
      data: {
        isReRun: $isReRun,
        specimenId: $specimenId,
        rejectSpecimen: $rejectSpecimen,
        rejectSpecimenComment: $rejectSpecimenComment,
      }
    ) {
      id
      rejectSpecimenDate
    }
  }
`;

export const CONVERT_TO_ORAL_FLUID = gql`
  mutation ConvertSpecimenType(
    $id: String!,
    $newSpecimenType: String!,
  ) {
    convertSpecimenType(
      id: $id,
      newSpecimenType: $newSpecimenType,
    ) {
      id
      clinicCode
      clinicName

      providerId
      providerName
      providerSuffix

      clinicalPurpose

      icd10Codes
      icd10CodeTags

      patientForeignKey
      patientId
      patientFirstName
      patientLastName
      patientBirthMonth
      patientBirthDay
      patientBirthYear
      patientEmail
      patientPhone

      specimenId
      specimenType
      specimenRunDate
      specimenLabNotes
      specimenAnomaly
      specimenAlcoholBiomarker
      specimenIsValid
      specimenLabMachineName
      specimenRunLabId

      batchIds
      batches {
        id
        batchId
        testPanel
      }
      storageTrayLocation

      specimenPH
      specimenSpecificGravity
      specimenCreatinine

      specimenReceivedLabUserId
      specimenReceivedLabDate

      specimenReRunRequestedAnalystId
      specimenReRunRequestedAnalystName
      specimenReRunRequestedAnalystNotes
      specimenReRunRequestedAnalystDate

      specimenReRunLabDate
      specimenReRunLabNotes
      specimenReRunLabTechId

      analystReserved {
        radeasUserId
        firstName
        lastName
        email
      }

      medicationList
      medicationListTags
      clinicUserId
      radeasUserId
      toxicologyTests {
        drugName
        drugClass
        drugBrand
        pos
        scrn
        result
        cutoff
        c_i
        flag
      }
      etgTests
      etgTestPanel {
        drugName
        drugClass
        drugBrand
        result
        cutoff
        posNeg
        c_i
        flag
      }
      pocTests {
        code
        name
        result
      }
      additionalTests {
        name
        result
      }
      customTests

      status
      prevStatus
      labStatus
      clinicStatus

      createdById
      createdDate
      updatedDate

      rejectSpecimen
      rejectSpecimenComment
      rejectSpecimenDate
      rejectSpecimenLabTechId
    }
  }
`;

export const RESTORE_TESTS = gql`
  mutation RestoreTests(
    $id: String!,
    $specimenType: String!,
  ) {
    restoreTests(
      id: $id,
      specimenType: $specimenType,
    ) {
      id
      clinicCode
      clinicName

      providerId
      providerName
      providerSuffix

      clinicalPurpose

      icd10Codes
      icd10CodeTags

      patientForeignKey
      patientId
      patientFirstName
      patientLastName
      patientBirthMonth
      patientBirthDay
      patientBirthYear
      patientEmail
      patientPhone

      specimenId
      specimenType
      specimenRunDate
      specimenLabNotes
      specimenAnomaly
      specimenAlcoholBiomarker
      specimenIsValid
      specimenLabMachineName
      specimenRunLabId

      batchIds
      batches {
        id
        batchId
        testPanel
      }
      storageTrayLocation

      specimenPH
      specimenSpecificGravity
      specimenCreatinine

      specimenReceivedLabUserId
      specimenReceivedLabDate

      specimenReRunRequestedAnalystId
      specimenReRunRequestedAnalystName
      specimenReRunRequestedAnalystNotes
      specimenReRunRequestedAnalystDate

      specimenReRunLabDate
      specimenReRunLabNotes
      specimenReRunLabTechId

      analystReserved {
        radeasUserId
        firstName
        lastName
        email
      }

      medicationList
      medicationListTags
      clinicUserId
      radeasUserId
      toxicologyTests {
        drugName
        drugClass
        drugBrand
        pos
        scrn
        result
        cutoff
        c_i
        flag
      }
      etgTests
      etgTestPanel {
        drugName
        drugClass
        drugBrand
        result
        cutoff
        posNeg
        c_i
        flag
      }
      pocTests {
        code
        name
        result
      }
      additionalTests {
        name
        result
      }
      customTests

      status
      prevStatus
      labStatus
      clinicStatus

      createdById
      createdDate
      updatedDate

      rejectSpecimen
      rejectSpecimenComment
      rejectSpecimenDate
      rejectSpecimenLabTechId
    }
  }
`;

const ToxicologyOrderDetails = () => {
  const [orderDetails, setOrderDetails] = useState({});
  const [editOrderDetails, setEditOrderDetails] = useState(false);
  const [showRejectSpecimenModal, setShowRejectSpecimenModal] = useState(false);
  const [showConvertSpecimenTypeModal, setShowConvertSpecimenTypeModal] = useState(false);
  const [showRestoreTestsModal, setShowRestoreTestsModal] = useState(false);
  const [rejectSpecimenComment, setRejectSpecimenComment] = useState('');
  const [loading, setLoading] = useState(true);

  // Get order ID from URL
  const { id } = useParams();

  const history = useHistory();

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  // Get order details
  useQuery(
    GET_TOXICOLOGY_ORDER_BY_ID,
    {
      variables: { id },
      onCompleted({ getToxicologyOrder }) {
        if (process.env.NODE_ENV === 'development') console.log('getToxicologyOrder:', getToxicologyOrder);

        setOrderDetails(getToxicologyOrder);
        setLoading(false);
      },
      onError(error) {
        console.log('Get toxicology order details', error);
        createNotification('danger', 'Sorry, could not get order details.');
      },
    },
  );

  const [updateToxicologyOrder, { loading: loadingMutation }] = useMutation(
    UPDATE_TOXICOLOGY_ORDER,
    {
      onCompleted({ updateToxicologyOrder }) {
        // console.log('updateToxicologyOrder:', updateToxicologyOrder);

        setOrderDetails(updateToxicologyOrder);
        setEditOrderDetails(false);
      },
      onError(error) {
        console.log('Update toxicology order mutation', error);
        createNotification('danger', 'Sorry, could not update order. Please try again.');
      }
    }
  );

  const [rejectSpecimenMutation, { loading: rejecting }] = useMutation(
    REJECT_SPECIMEN,
    {
      onCompleted({ setToxicologyLabResults }) {
        console.log('Specimen rejected');
        createNotification('info', 'Specimen rejected.');

        handleCloseModal();
      },
      onError(error) {
        console.log('Reject Specimen', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      }
    }
  );

  const [convertToOralFluidMutation, { loading: convertingToOralFluid }] = useMutation(
    CONVERT_TO_ORAL_FLUID,
    {
      onCompleted({ convertToOralFluid }) {
        console.log('Order converted to Oral Fluid.');
        console.log('convertToOralFluid:', convertToOralFluid);
        setOrderDetails(convertToOralFluid);
        handleCloseModal();
      },
      onError(error) {
        console.log('Convert to Oral Fluid', error);
        createNotification('danger', 'Sorry, could not convert order. Please try again.');

        handleCloseModal();
      }
    },
  );

  const [restoreTestsMutation, { loading: restoringTests }] = useMutation(
    RESTORE_TESTS,
    {
      onCompleted({ restoreTests }) {
        console.log('Tests restored.');
        console.log('restoreTests:', restoreTests);
        setOrderDetails(restoreTests);
        handleCloseModal();
      },
      onError(error) {
        console.log('Restore Tests', error);
        createNotification('danger', 'Sorry, could not restore tests. Please try again.');

        handleCloseModal();
      }
    },
  );

  const handleSubmit = (values) => {
    const variables = {
      id: orderDetails.id,
      specimenType: values.specimenType,
      clinicalPurpose: values.clinicalPurpose === "Other" ? values.clinicalPurposeOther : values.clinicalPurpose,
      medicationList: values.medicationList,
      medicationListTags: values.medicationListTags,
      icd10Codes: values.icd10Codes,
      icd10CodeTags: values.icd10CodeTags,
      providerId: values.providerId,
      providerName: values.providerName,
      providerSuffix: values.providerSuffix,
      toxicologyTests: values.tests.map(({
        drugName,
        drugClass,
        drugBrand,
        pos,
        scrn,
        result,
        cutoff,
        c_i,
        flag,
      }) => ({ drugName, drugClass, drugBrand, pos, scrn, result, cutoff, c_i, flag })),
      etgTests: values.etgTests,
      customTests: values.customTests,
      pocTests: values.pocTests,
    };

    // Execute mutation
    updateToxicologyOrder({ variables });
  };

  const handleCancel = () => {
    setEditOrderDetails(false);
  };

  const handleEdit = () => {
    setEditOrderDetails(!editOrderDetails);
  };

  const handleRejectSpecimen = () => {
    console.log(`Reject specimen ${orderDetails.specimenId}`);

    rejectSpecimenMutation({ variables: {
      isReRun: false,
      specimenId: orderDetails.specimenId,
      rejectSpecimen: true,
      rejectSpecimenComment,
    }});
  };

  const handleConvertToOralFluid = () => {
    console.log(`Converting to oral fluid...`);

    convertToOralFluidMutation({ variables: {
      id,
      newSpecimenType: 'Oral Fluid',
    }});
  };

  const handleRestoreTests = () => {
    console.log(`Restoring tests...`);

    restoreTestsMutation({ variables: {
      id,
      specimenType: orderDetails.specimenType,
    }});
  };

  const handleCloseModal = () => {
    setShowRejectSpecimenModal(false);
    setShowConvertSpecimenTypeModal(false);
    setShowRestoreTestsModal(false);
  };

  const getCollectionDate = () => {
    let d = orderDetails?.createdDate;

    if (typeof(d) === 'string') {
      d = parseInt(d);
    }

    const cd = new Date(d);

    return `${cd.getMonth() + 1}/${cd.getDate()}/${cd.getFullYear()}`
  };

  return (
    <section className="section">
      <div className="columns">
        <div className="column is-two-thirds-desktop is-two-thirds-tablet">
          <div>
            <div className="is-pulled-right">
              {orderDetails?.status === STATUS.TOXICOLOGY_ANALYST_COMPLETE && (
                <Link
                  className="button is-primary mr-4"
                  to={ROUTES.DASHBOARD_ANALYST_VIEW_RESULTS.replace(':id', orderDetails.id)}
                >
                  View Results
                </Link>
              )}

              <button
                className="button is-danger mr-4"
                onClick={() => setShowRejectSpecimenModal(true)}
                disabled={orderDetails?.status === STATUS.TOXICOLOGY_LAB_REJECTED}
                data-tooltip={orderDetails?.status === STATUS.TOXICOLOGY_LAB_REJECTED ? "Specimen already rejected" : null}
              >
                Reject Specimen
              </button>

              <button
                className="button is-info"
                onClick={() => history.goBack()}
              >
                Back
              </button>
            </div>

            <div className="is-pulled-left">
              <DownloadOrderPDFButton specimenId={orderDetails.specimenId} />
            </div>
          </div>

          <br />
          <br />

          <h1 className="title is-size-4">Toxicology Order Details</h1>

          <div className="page">
            {loading ? (
              <Loading />
            ) : (
              <>

                {/* TODO: Fix Provider Name bug in ToxicologyOrderDetailsForm.js (line ~420) */}

                {/* <button */}
                {/*   className="button is-ghost is-pulled-right" */}
                {/*   // disabled={orderDetails.status !== STATUS.TOXICOLOGY_CLINIC_CREATED} */}
                {/* > */}
                {/*   <span */}
                {/*     className="icon is-cobalt" */}
                {/*     className={"has-tooltip-arrow icon is-cobalt"} */}
                {/*     // data-tooltip={orderDetails.status !== STATUS.TOXICOLOGY_CLINIC_CREATED ? null : "Modify order"} */}
                {/*     data-tooltip="Modify order" */}
                {/*     title="Edit order details" */}
                {/*     onClick={handleEdit} */}
                {/*   > */}
                {/*     <i className="fas fa-edit" /> */}
                {/*   </span> */}
                {/* </button> */}

                <h1 className="title has-text-primary is-size-2 mt-3">{orderDetails?.specimenId}</h1>

                <span className="sublabel is-4 mr-3">
                  Clinic
                </span>
                <span className="has-text-white">
                  {orderDetails?.clinicName}
                </span>

                <br />
                <br />

                <span className="sublabel is-4 mr-3">
                  Clinic Code
                </span>
                <span className="has-text-white">
                  {orderDetails?.clinicCode}
                </span>

                <br />
                <br />

                <span className="sublabel is-4 mr-3">
                  Specimen ID
                </span>
                <span className="has-text-white">
                  {orderDetails?.specimenId}
                </span>

                <br />
                <br />

                <span className="sublabel is-4 mr-3">
                  Collection Date
                </span>
                <span className="has-text-white">
                  {getCollectionDate()}
                </span>

                <br />
                <br />

                <span className="sublabel is-4 mr-3">
                  Patient
                </span>
                <span className="has-text-white">
                  <Link
                    to={`${ROUTES.RADEAS_PATIENT_PAGE.replace(':id', orderDetails?.patientForeignKey)}`}
                    target="_blank"
                  >
                    {orderDetails?.patientLastName}, {orderDetails?.patientFirstName}
                  </Link>
                </span>

                <br />
                <br />

                <ToxicologyOrderDetailsForm
                  orderDetails={orderDetails}
                  isEditing={editOrderDetails}
                  isUpdating={loadingMutation}
                  testDetails={handleSubmit}
                  handleCancel={handleCancel}
                />
              </>
            )}
          </div>

          <div className="mt-5">
            <div className="is-pulled-left">
              <button
                  className="button is-info"
                  onClick={() => setShowRestoreTestsModal(true)}
                >
                  Restore Tests
                </button>
            </div>

            {orderDetails?.specimenType?.toUpperCase() === "URINE" && (
              <div className="is-pulled-right">
                <button
                  className="button is-info"
                  onClick={() => setShowConvertSpecimenTypeModal(true)}
                >
                  Convert to Oral Fluid
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={showRejectSpecimenModal}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        <div className="modal is-active">
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title has-text-white">Reject Specimen ID {orderDetails?.specimenId}?</p>
              <button
                className="delete"
                aria-label="close"
                onClick={handleCloseModal}
              />
            </header>

            <section className="modal-card-body page">
              <div className="block">
                <label className="label" htmlFor="input-rejectSpecimenComment">
                  Comments
                </label>
                <textarea
                  id="input-rejectSpecimenComment"
                  name="rejectSpecimenComment"
                  className="textarea"
                  placeholder=""
                  value={rejectSpecimenComment}
                  onChange={(e) => setRejectSpecimenComment(e.target.value)}
                />
              </div>

              <button
                className="button is-info mt-5 is-pulled-right"
                onClick={handleCloseModal}
              >
                Cancel
              </button>

              <div className="is-pulled-left">
                <button
                  className="button is-danger mt-5"
                  onClick={handleRejectSpecimen}
                >
                  <span className="icon is-small">
                    {rejecting ?
                      <PuffLoader color="#83c3e8" size={30} className="" />
                      :
                      <i className="fas fa-times"></i>
                    }
                  </span>
                  <span>Confirm Reject</span>
                </button>
              </div>
            </section>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showConvertSpecimenTypeModal}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        <div className="modal is-active">
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title has-text-white">Convert URINE order to ORAL FLUID?</p>
              <button
                className="delete"
                aria-label="close"
                onClick={handleCloseModal}
              />
            </header>

            <section className="modal-card-body page">
              <div className="block">
                <p className="is-size-4 has-text-weight-semibold has-text-danger">
                  Note: This cannot be undone!
                </p>
              </div>

              <button
                className="button is-danger mt-5 is-pulled-right"
                onClick={handleCloseModal}
              >
                Cancel
              </button>

              <div className="is-pulled-left">
                <button
                  className="button is-info mt-5"
                  onClick={handleConvertToOralFluid}
                >
                  <span className="icon is-small">
                    {convertingToOralFluid ?
                      <PuffLoader color="#83c3e8" size={30} className="" />
                      :
                      <i className="fas fa-check-circle"></i>
                    }
                  </span>
                  <span>Confirm Conversion</span>
                </button>
              </div>
            </section>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={showRestoreTestsModal}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        <div className="modal is-active">
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title has-text-white">Restore Tests?</p>
              <button
                className="delete"
                aria-label="close"
                onClick={handleCloseModal}
              />
            </header>

            <section className="modal-card-body page">
              <div className="block">
                <p className="is-size-4 has-text-weight-semibold has-text-danger">
                  Note: This cannot be undone!
                </p>
              </div>

              <button
                className="button is-danger mt-5 is-pulled-right"
                onClick={handleCloseModal}
              >
                Cancel
              </button>

              <div className="is-pulled-left">
                <button
                  className="button is-info mt-5"
                  onClick={handleRestoreTests}
                >
                  <span className="icon is-small">
                    {restoringTests ?
                      <PuffLoader color="#83c3e8" size={30} className="" />
                      :
                      <i className="fas fa-check-circle"></i>
                    }
                  </span>
                  <span>Confirm Restore</span>
                </button>
              </div>
            </section>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default ToxicologyOrderDetails;
