import React, { useState } from 'react';
import { gql, useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import _ from 'lodash';

import ClinicInfo from './ClinicInfo';
import ClinicSettings from './ClinicSettings';
import ClinicDrugTestMenu from './ClinicDrugTestMenu';

import defaultClinicSettings from '../../constants/defaultClinicSettings';
import { useAuth } from '../auth';
import { createNotification } from '../Notification';
import * as ROUTES from '../../constants/routes';

export const GET_CLINIC_BY_ID = gql`
  query GetClinicById(
    $id: String!
  ) {
    getClinicById(
      id: $id
    ) {
      id
      clinicCode
      clinicShortCode
      clinicName
      phone
      fax
      addresses {
        address1
        address2
        city
        state
        zipCode
      }
    }
  }
`;

export const GET_CLINIC_SETTINGS = gql`
  query GetClinicSettings(
    $clinicCode: String!
  ) {
    toxicologyTestProfileByClinicCode(
      clinicCode: $clinicCode
    ) {
      id
      analystInstructions
      clinicSettings {
        name
        label
        value
      }
    }
  }
`;

export const UPDATE_CLINIC_SETTINGS = gql`
  mutation UpdateClinicSettings(
    $clinicCode: String!
    $clinicSettings: [ClinicSettingsInput]
    $analystInstructions: String
  ) {
    updateClinicSettings(
      data: {
        clinicCode: $clinicCode
        clinicSettings: $clinicSettings
        analystInstructions: $analystInstructions
      }
    ) {
      id
      clinicSettings {
        name
        label
        value
      }
      analystInstructions
    }
  }
`;

const ClinicPage = () => {
  const [clinicInfo, setClinicInfo] = useState({});
  const [clinicSettings, setClinicSettings] = useState([]);
  const [analystInstructions, setAnalystInstructions] = useState('');

  // Get clinic ID from URL
  const { id } = useParams();

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  // Run clinic query
  useQuery(
    GET_CLINIC_BY_ID,
    {
      variables: { id } ,
      onCompleted({ getClinicById }) {
        console.log({ getClinicById });

        setClinicInfo(getClinicById);

        // Get clinic settings
        getClinicSettings();
      },
      onError(error) {
        console.log('ClinicPage query error:', error);
        createNotification('danger', 'Sorry, could not get clinic details.');
      }
    },
  );

  // Clinic settings query
  const [getClinicSettings, { loading: loadingSettings }] = useLazyQuery(
    GET_CLINIC_SETTINGS,
    {
      variables: { clinicCode: clinicInfo.clinicCode },
      onCompleted({ toxicologyTestProfileByClinicCode: settings }) {
        if (process.env.NODE_ENV === 'development') console.log('Clinic Settings:', settings);

        // Check if missing settings, if so set defaults
        if (settings.clinicSettings.length < defaultClinicSettings.length) {
          // Note: mutation updates state on complete
          handleMissingSettings(settings.clinicSettings);
        } else {
          setClinicSettings(settings.clinicSettings);
        }

        if (settings.analystInstructions.length > 0) {
          setAnalystInstructions(settings.analystInstructions);
        } else {
          setAnalystInstructions('');
        }
      },
      onError(error) {
        console.log('ClinicPage query error:', error);
        createNotification('danger', 'Sorry, could not get clinic settings.');
      }
    }
  );

  // Save settings mutation
  const [saveClinicSettings, { loading: updatingSettings }] = useMutation(
    UPDATE_CLINIC_SETTINGS,
    {
      onCompleted({ updateClinicSettings: settings }) {
        if (process.env.NODE_ENV === 'development') console.log(settings);

        setClinicSettings(settings.clinicSettings);
        setAnalystInstructions(settings.analystInstructions);
        createNotification('info', `Settings updated.`);
      },
      onError(error) {
        console.log('Update clinic settings', error);
        createNotification('danger', 'Sorry, could not update clinic settings.');
      },
    }
  );

  const handleMissingSettings = (settings) => {
    if (process.env.NODE_ENV === 'development') console.log('handleMissingSettings()', settings);

    const missing = defaultClinicSettings.filter((setting) => {
      const idx = _.findIndex(settings, (e) => e.name === setting.name);

      return idx < 0;
    });

    // const updated = [ ...settings, ...missing ];
    const updated = settings.map(({ name, label, value }) => ({ name, label, value }));


    // Save default settings
    saveClinicSettings({ variables: {
      clinicCode: clinicInfo.clinicCode,
      clinicSettings: [ ...updated, ...missing ],
    }});
  }

  const handleUpdateSettings = ({ settings, instructions}) => {
    if (process.env.NODE_ENV === 'development') console.log('handleUpdateSettings:', { settings, instructions });

    saveClinicSettings({ variables: {
      clinicCode: clinicInfo.clinicCode,
      clinicSettings: settings,
      analystInstructions: instructions.trim(),
    }});
  };

  return (
    <section className="section">
      <div className="columns">
        <ClinicInfo id={id} clinicInfo={clinicInfo} />
        <ClinicSettings
          clinicSettings={clinicSettings}
          analystInstructions={analystInstructions}
          loading={loadingSettings}
          onSave={handleUpdateSettings}
          saving={updatingSettings}
        />
      </div>

      <div>
        <ClinicDrugTestMenu clinicInfo={clinicInfo} clinicSettings={clinicSettings} />
      </div>
    </section>
  );
};

export default ClinicPage;
