import React, { useEffect, useRef, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Field, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import PuffLoader from 'react-spinners/PuffLoader';

import Loading, { ButtonLoading } from '../Loading';
import { createNotification } from '../Notification';
import { useAuth } from '../auth';
import { SET_TOXICOLOGY_ORDER_STATUS } from '../AnalystDashboard';
import * as ROUTES from '../../constants/routes';
import * as STATUS from '../../constants/status';

import '../../styles/form.css';
import 'react-datepicker/dist/react-datepicker.css';

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    border: 0,
    background: "transparent",
  },
};

export const GET_TOXICOLOGY_LAB_RESULTS = gql`
  query getToxicologyOrderBySpecimenId(
    $specimenId: String!
  ) {
    getToxicologyOrderBySpecimenId(
      specimenId: $specimenId
    ) {
      id
      specimenId
      specimenType
      specimenRunDate
      specimenLabNotes
      specimenAnomaly
      specimenLabMachineName
      specimenPH
      specimenSpecificGravity
      specimenCreatinine
      specimenReRunLabDate
      specimenReRunLabNotes
      specimenReRunRequestedAnalystId
      specimenReRunRequestedAnalystName
      specimenReRunRequestedAnalystNotes
      specimenReRunRequestedAnalystDate
    }
  }
`;

export const REJECT_SPECIMEN = gql`
  mutation RejectSpecimen(
    $isReRun: Boolean!,
    $specimenId: String!,
    $rejectSpecimen: Boolean,
    $rejectSpecimenComment: String,
  ) {
    setToxicologyLabResults(
      data: {
        isReRun: $isReRun,
        specimenId: $specimenId,
        rejectSpecimen: $rejectSpecimen,
        rejectSpecimenComment: $rejectSpecimenComment,
      }
    ) {
      id
      rejectSpecimenDate
    }
  }
`;

export const SET_TOXICOLOGY_LAB_RESULTS = gql`
  mutation SetToxicologyLabResults(
    $isReRun: Boolean!,
    $specimenId: String!,
    $specimenType: String,
    $specimenRunDate: String,
    $specimenLabNotes: String,
    $specimenAnomaly: Boolean,
    $specimenAlcoholBiomarker: Boolean,
    $specimenIsValid: Boolean,
    $specimenPH: String,
    $specimenSpecificGravity: String,
    $specimenCreatinine: String,
    $specimenReRunLabDate: String,
    $specimenReRunLabNotes: String,
  ) {
    setToxicologyLabResults(
      data: {
        isReRun: $isReRun,
        specimenId: $specimenId,
        specimenType: $specimenType,
        specimenRunDate: $specimenRunDate,
        specimenLabNotes: $specimenLabNotes,
        specimenAnomaly: $specimenAnomaly,
        specimenAlcoholBiomarker: $specimenAlcoholBiomarker,
        specimenIsValid: $specimenIsValid,
        specimenPH: $specimenPH,
        specimenSpecificGravity: $specimenSpecificGravity,
        specimenCreatinine: $specimenCreatinine,
        specimenReRunLabDate: $specimenReRunLabDate,
        specimenReRunLabNotes: $specimenReRunLabNotes,
      }
    ) {
      id
      labUploadedDate
    }
  }
`;

const SpecimenValidation = ({ isReRun }) => {
  // Require quth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  const [labDetails, setLabDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [rejectSpecimenId, setRejectSpecimenId] = useState('');
  const [rejectSpecimenComment, setRejectSpecimenComment] = useState('');

  const history = useHistory();
  const { id } = useParams();
  const formRef = useRef();

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  useQuery(
    GET_TOXICOLOGY_LAB_RESULTS,
    {
      // fetchPolicy: 'network-only',
      variables: { specimenId: id },
      onCompleted({ getToxicologyOrderBySpecimenId }) {
        console.log(getToxicologyOrderBySpecimenId);
        setLabDetails(getToxicologyOrderBySpecimenId);
        setLoading(false);
      },
      onError(error) {
        console.log(error);
        // createNotification('danger', 'Sorry, something went wrong. Please try again.');
        setLoading(false);
      },
    },
  );

  const [rejectSpecimenMutation, { loading: rejecting }] = useMutation(
    REJECT_SPECIMEN,
    {
      onCompleted({ setToxicologyLabResults }) {
        setOrderStatus({ variables: {
            id: setToxicologyLabResults.id,
            newStatus: STATUS.TOXICOLOGY_LAB_REJECTED,
            prevStatus: STATUS.TOXICOLOGY_LAB_RECEIVED,
          }});

        createNotification('info', 'Specimen rejected.');
        history.push(ROUTES.DASHBOARD_LAB_SCANNED);
      },
      onError(error) {
        console.log('Reject Specimen', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      }
    }
  );

  const [uploadResults, { loading: uploading }] = useMutation(
    SET_TOXICOLOGY_LAB_RESULTS,
    {
      onCompleted({ setToxicologyLabResults }) {
        setOrderStatus({ variables: {
          id: setToxicologyLabResults.id,
          newStatus: STATUS.TOXICOLOGY_LAB_RECEIVED,
          prevStatus: STATUS.TOXICOLOGY_LAB_RECEIVED,
        }});

        createNotification('info', 'Results saved');
        history.push(ROUTES.DASHBOARD_LAB_SCANNED);
      },
      onError(error) {
        console.log('Set toxicology lab results', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    },
  );

  const [setOrderStatus] = useMutation(SET_TOXICOLOGY_ORDER_STATUS);

  const handleRejectSpecimen = () => {
    rejectSpecimenMutation({ variables: {
      isReRun,
      specimenId: rejectSpecimenId,
      rejectSpecimen: true,
      rejectSpecimenComment,
    }});
  };

  const handleOpenModal = (id) => {
    setRejectSpecimenId(id);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setRejectSpecimenId(null);
  };

  if (loading) return <Loading />;

  return (
    <div className="container my-5">
      <div className="page">
        <h4 className="title is-4 is-inline-block mt-1">Urine Validation</h4>
        <Formik
          initialValues={{
            specimenId: id || labDetails.specimenId || '',
            specimenType: labDetails.specimenType || 'Urine',
            specimenRunDate: !labDetails.specimenRunDate || labDetails.specimenRunDate === '' || labDetails.specimenRunDate === 'unknown' ? new Date() : labDetails.specimenRunDate,
            specimenLabNotes: labDetails.specimenLabNotes || '',
            specimenAnomaly: labDetails.specimenAnomaly || false,
            specimenAlcoholBiomarker: labDetails.specimenAlcoholBiomarker || false,
            specimenIsValid: labDetails.specimenIsValid || true,
            // specimenLabMachineName: labDetails.specimenLabMachineName || '',
            specimenPH: labDetails.specimenPH || '',
            specimenSpecificGravity: labDetails.specimenSpecificGravity || '',
            specimenCreatinine: labDetails.specimenCreatinine || '',
            specimenReRunLabDate: !labDetails.specimenReRunLabDate || labDetails.specimenReRunLabDate === '' || labDetails.specimenReRunLabDate === 'unknown' ? new Date() : labDetails.specimenReRunLabDate,
            specimenReRunLabNotes: labDetails.specimenReRunLabNotes || '',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            // console.log('onSubmit:', { variables: { isReRun, ...values }});
            uploadResults({ variables: { isReRun, ...values }});

            // resetForm();
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            specimenId: Yup.string()
              .required("Specimen ID is required."),
            specimenType: Yup.string(),
            specimenRunDate: Yup.string(),
            specimenLabNotes: Yup.string(),
            specimenAnomaly: Yup.boolean(),
            specimenAlcoholBiomarker: Yup.boolean(),
            specimenIsValid: Yup.boolean(),
            // specimenLabMachineName: Yup.string(),
            specimenPH: Yup.string(),
            specimenSpecificGravity: Yup.string(),
            specimenCreatinine: Yup.string(),
            specimenReRunLabDate: Yup.string(),
            specimenReRunLabNotes: Yup.string(),
          })}
          innerRef={formRef}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = formProps;

            return (
              <form onSubmit={handleSubmit} style={{ marginBottom: "150px" }}>
                <div className="block">
                  <label className="label" htmlFor="input-specimenId">
                    Specimen ID
                  </label>
                  <input
                    id="input-specimenId"
                    name="specimenId"
                    className={errors.specimenId && touched.specimenId ? "input error" : "input"}
                    type="text"
                    placeholder="Specimen ID"
                    value={values.specimenId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isReRun || id}
                  />
                  {errors.specimenId && touched.specimenId && (
                    <div className="input-feedback" data-testid="errors-specimenId">
                      {errors.specimenId}
                    </div>
                  )}
                </div>

                <div className="block">
                  <label className="label" htmlFor="input-specimenType">
                    Specimen Type
                  </label>
                  <div className="select">
                    <select
                      id="input-specimenType"
                      name="specimenType"
                      className={errors.specimenType && touched.specimenType ? "input error" : "input"}
                      value={values.specimenType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isReRun}
                    >
                      <option value="Urine" label="Urine" />
                      <option value="Oral Fluid" label="Oral Fluid" />
                    </select>
                  </div>
                  {errors.specimenType && touched.specimenType && (
                    <div className="input-feedback" data-testid="errors-specimenType">
                      {errors.specimenType}
                    </div>
                  )}
                </div>

                {!isReRun ? (
                  <div className="block">
                    <label className="label" htmlFor="input-specimenRunDate">
                      Run Date
                    </label>
                    <DatePickerField id="input-specimenRunDate" name="specimenRunDate" />
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="block">
                      <label className="label" htmlFor="input-specimenRunDate">
                        {/* Original Run Date: {values.specimenRunDate.split('T')[0]} */}
                        Original Run Date: {new Date(values.specimenRunDate).toLocaleString()}
                      </label>
                    </div>

                    <div className="block">
                      <label className="label" htmlFor="input-specimenReRunLabDate">
                        Re-run Date
                      </label>
                      <DatePickerField id="input-specimenReRunLabDate" name="specimenReRunLabDate" />
                    </div>
                  </React.Fragment>
                )}


                <div className="block">
                  <label className="checkbox">
                    <Field type="checkbox" name="specimenAlcoholBiomarker" />
                    <span className="has-text-weight-bold is-inline label ml-3">Alcohol Biomarker (EtG/EtS)</span>
                  </label>
                </div>

                <div className="block">
                  <label className="label" htmlFor="input-specimenPH">
                    Specimen pH
                  </label>
                  <input
                    id="input-specimenPH"
                    name="specimenPH"
                    className={errors.specimenPH && touched.specimenPH ? "input error" : "input"}
                    type="text"
                    placeholder="pH"
                    value={values.specimenPH}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.specimenPH && touched.specimenPH && (
                    <div className="input-feedback" data-testid="errors-specimenPH">
                      {errors.specimenPH}
                    </div>
                  )}
                </div>

                <div className="block">
                  <label className="label" htmlFor="input-specimenSpecificGravity">
                    Specimen Specific Gravity
                  </label>
                  <input
                    id="input-specimenSpecificGravity"
                    name="specimenSpecificGravity"
                    className={errors.specimenSpecificGravity && touched.specimenSpecificGravity ? "input error" : "input"}
                    type="text"
                    placeholder="Specific gravity"
                    value={values.specimenSpecificGravity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.specimenSpecificGravity && touched.specimenSpecificGravity && (
                    <div className="input-feedback" data-testid="errors-specimenSpecificGravity">
                      {errors.specimenSpecificGravity}
                    </div>
                  )}
                </div>

                <div className="block">
                  <label className="label" htmlFor="input-specimenCreatinine">
                    Specimen Creatinine
                  </label>
                  <input
                    id="input-specimenCreatinine"
                    name="specimenCreatinine"
                    className={errors.specimenCreatinine && touched.specimenCreatinine ? "input error" : "input"}
                    type="text"
                    placeholder="Creatinine"
                    value={values.specimenCreatinine}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.specimenCreatinine && touched.specimenCreatinine && (
                    <div className="input-feedback" data-testid="errors-specimenCreatinine">
                      {errors.specimenCreatinine}
                    </div>
                  )}
                </div>

                <div className="block">
                  <label className="checkbox">
                    <span className="has-text-weight-bold is-inline label">Validity Testing</span>
                    <Field type="checkbox" name="specimenIsValid" className="ml-2" />
                    <span className={values.specimenIsValid ? "ml-2 label is-inline" : "ml-2 has-text-danger is-inline"}>
                      {values.specimenIsValid ? "(pass)" : "(fail)"}
                    </span>
                  </label>
                </div>

                <br />

                <div className="block">
                  <label className="checkbox">
                    <span className="has-text-weight-bold label is-inline">Specimen Anomaly?</span>
                    <Field type="checkbox" name="specimenAnomaly" className="ml-2" />
                  </label>
                </div>

                <hr />

                {!isReRun ? (
                  <div className="block">
                    <label className="label" htmlFor="input-specimenLabNotes">
                      Lab Notes
                    </label>
                    <textarea
                      id="input-specimenLabNotes"
                      name="specimenLabNotes"
                      className={errors.specimenLabNotes && touched.specimenLabNotes ? "textarea error" : "textarea"}
                      placeholder="Notes"
                      value={values.specimenLabNotes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {errors.specimenLabNotes && touched.specimenLabNotes && (
                      <div className="input-feedback" data-testid="errors-specimenLabNotes">
                        {errors.specimenLabNotes}
                      </div>
                    )}
                  </div>
                ) : (
                  <React.Fragment>
                    <div className="block">
                      <label className="label" htmlFor="input-specimenReRunRequestedAnalystNotes">
                        Analyst Re-Run Notes
                      </label>
                      <textarea
                        id="input-specimenReRunRequestedAnalystNotes"
                        name="specimenReRunRequestedAnalystNotes"
                        className={errors.specimenReRunRequestedAnalystNotes && touched.specimenReRunRequestedAnalystNotes ? "textarea error" : "textarea"}
                        placeholder="Notes"
                        value={labDetails.specimenReRunRequestedAnalystNotes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled
                        style={{ cursor: "default" }}
                      />
                    </div>

                    <div className="block">
                      <label className="label" htmlFor="input-specimenReRunLabNotes">
                        Re-run Notes
                      </label>
                      <textarea
                        id="input-specimenReRunLabNotes"
                        name="specimenReRunLabNotes"
                        className={errors.specimenReRunLabNotes && touched.specimenReRunLabNotes ? "textarea error" : "textarea"}
                        placeholder="Notes"
                        value={values.specimenReRunLabNotes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.specimenReRunLabNotes && touched.specimenReRunLabNotes && (
                        <div className="input-feedback" data-testid="errors-specimenReRunLabNotes">
                          {errors.specimenReRunLabNotes}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )}

                <br />

                {/* <div className="block"> */}
                {/*   <label className="label" htmlFor="input-specimenLabMachineName"> */}
                {/*     Machine Name */}
                {/*   </label> */}
                {/*   <input */}
                {/*     id="input-specimenLabMachineName" */}
                {/*     name="specimenLabMachineName" */}
                {/*     className={errors.specimenLabMachineName && touched.specimenLabMachineName ? "input error" : "input"} */}
                {/*     type="text" */}
                {/*     placeholder="Machine name" */}
                {/*     value={values.specimenLabMachineName} */}
                {/*     onChange={handleChange} */}
                {/*     onBlur={handleBlur} */}
                {/*   /> */}
                {/*   {errors.specimenLabMachineName && touched.specimenLabMachineName && ( */}
                {/*     <div className="input-feedback" data-testid="errors-specimenLabMachineName"> */}
                {/*       {errors.specimenLabMachineName} */}
                {/*     </div> */}
                {/*   )} */}
                {/* </div> */}

                <input
                  type="submit"
                  className="button is-primary"
                  value="Submit"
                  disabled={isSubmitting}
                />
                <button
                  className="button is-danger is-pulled-right"
                  onClick={() => handleOpenModal(formRef.current.values.specimenId)}
                >
                  Reject Sample
                </button>
                {uploading ? <ButtonLoading /> : null}
              </form>
            );
          }}
        </Formik>
      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        <div className="modal is-active">
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title has-text-white">Reject Specimen ID {rejectSpecimenId}?</p>
              <button
                className="delete"
                aria-label="close"
                onClick={handleCloseModal}
              />
            </header>

            <section className="modal-card-body page">
              <div className="block">
                <label className="label" htmlFor="input-rejectSpecimenComment">
                  Comments
                </label>
                <textarea
                  id="input-rejectSpecimenComment"
                  name="rejectSpecimenComment"
                  className="textarea"
                  placeholder=""
                  value={rejectSpecimenComment}
                  onChange={(e) => setRejectSpecimenComment(e.target.value)}
                />
              </div>

              <button
                className="button is-info mt-5 is-pulled-right"
                onClick={handleCloseModal}
              >
                Cancel
              </button>

              <div className="is-pulled-left">
                <button
                  className="button is-danger mt-5"
                  onClick={handleRejectSpecimen}
                >
                  <span className="icon is-small">
                    {rejecting ?
                      <PuffLoader color="#83c3e8" size={30} className="" />
                      :
                      <i className="fas fa-times"></i>
                    }
                  </span>
                  <span>Confirm Reject</span>
                </button>
              </div>
            </section>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <DatePicker
      {...field}
      {...props}
      className="input"
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => setFieldValue(field.name, val)}
    />
  );
};

export default SpecimenValidation;
