import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery, useQuery, gql } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import Modal from 'react-modal';

import Loading from '../Loading';

import { createNotification } from '../Notification';
import { AUTH_USER_QUERY } from '../../constants/gql';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    border: 0,
    background: "transparent",
  },
};

export const ALL_RADEAS_USERS = gql`
  query AllRadeasUsers {
    allRadeasUsers {
      id
      firstName
      lastName
      email
      roles
      lastActivity
    }
  }
`;

export const UPDATE_RADEAS_USER_PERMISSIONS = gql`
  mutation UpdateRadeasUserPermissions(
    $id: String!,
    $roles: [String]!
  ) {
    updateRadeasUser(
      id: $id,
      data: {
        roles: $roles
      }
    ) {
      id
      firstName
      lastName
      email
      roles
      lastActivity
    }
  }
`;

export const DELETE_RADEAS_USER = gql`
  mutation DeleteRadeasUser($id: String!) {
    deleteRadeasUser(id: $id)
  }
`;

const RadeasUsersList = () => {
  const [authUser, setAuthUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);

  const history = useHistory();

  // TODO: debug "unauthorized" (race condition?)

  // Get auth user
  // const { data: authUserData, loading: authUserLoading } = useQuery(
  const { loading: authUserLoading } = useQuery(
    AUTH_USER_QUERY,
    {
      onCompleted({ authUser }) {
        // Require auth and admin
        if (!authUser) {
          // Redirect
          createNotification('info', 'Please log in.');
          history.push(ROUTES.SIGN_IN);
        } else if (!authUser.roles.includes(ROLES.RADEAS_ADMIN)) {
          // Redirect
          createNotification('danger', 'Not authorized');
          history.push(ROUTES.ROOT);
        }

        setAuthUser(authUser);

        getRadeasUsers();
      },
    },
  );

  // const { authUser } = authUserData;

  // // Require auth and admin
  // if (!authUser) {
  //   // Redirect
  //   createNotification('info', 'Please log in.');
  //   history.push(ROUTES.SIGN_IN);
  // } else if (!authUser.roles.includes(ROLES.RADEAS_ADMIN)) {
  //   // Redirect
  //   createNotification('danger', 'Not authorized');
  //   history.push(ROUTES.ROOT);
  // }

  // Setup get all Radeas users query
  const [getRadeasUsers] = useLazyQuery(
    ALL_RADEAS_USERS,
    {
      // fetchPolicy: 'network-only',
      onCompleted({ allRadeasUsers }) {
        // Sort by last name
        const sorted = _.sortBy(allRadeasUsers, (user) => user.lastName);

        setUsers(sorted);
        setLoading(false);
      },
      onError(error) {
        // TODO: show error message
        console.log('RadeasUsersList Query Error: ', error);

        setLoading(false);
      },
    }
  );

  // Setup update Radeas user permissions mutation
  const [updateUserPermissions] = useMutation(
    UPDATE_RADEAS_USER_PERMISSIONS,
    {
      onCompleted({ updateRadeasUser: user }) {
        // TODO: show message

        // Update the users array
        const updatedUsers = [...users];
        const index = updatedUsers.findIndex((u) => (u.id === user.id));
        updatedUsers.splice(index, 1, user);

        // Set state
        setUsers(updatedUsers);
        setUpdating(false);
      },
      onError(error) {
        // TODO: show error message
        console.log('RadeasUsersList updateUserPermissions() error: ', error);

        setUpdating(false);
      },
    },
  );

  // Setup delete Radeas user mutation
  const [deleteUser] = useMutation(
    DELETE_RADEAS_USER,
    {
      onCompleted({ deleteRadeasUser }) {
        // TODO: show message

        // Update the users array
        const updatedUsers = users.filter((u) => (u.id !== deleteRadeasUser));

        // Set state
        setUsers(updatedUsers);
        setUpdating(false);
      },
      onError(error) {
        // TODO: show error message
        console.log('RadeasUsersList handleDelete() error: ', error);

        setUpdating(false);
      },
    },
  );

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  const handleCheckbox = (event, user) => {
    // TODO: refresh token if updating self (update jwt roles)

    // Prevent double-submits
    if (!updating) {
      setUpdating(true);

      const { name } = event.target;
      const roles = [...user.roles];

      // Toggle the role
      if (user.roles.includes(name)) {
        _.remove(roles, ((role) => (role === name)));
      } else {
        roles.push(name);
      }

      // Run mutation
      updateUserPermissions({
        variables: {
          id: user.id,
          roles: roles,
        },
      });
    }
  };

  const handleDelete = (user) => {
    const { id } = user;

    if (showModal) {
      handleCloseModal();
    }

    // Prevent double-submits
    if (!updating) {
      setUpdating(true);

      // Run mutation
      deleteUser({ variables: { id } });
    }
  };

  const handleOpenModal = (user) => {
    setShowModal(true);
    setUserToDelete(user);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setUserToDelete(null);
  };

  if (authUserLoading) return <Loading />;

  return (
    <div className="page">
      <h4 className="title is-4">Radeas Users</h4>

      {loading ? (
        <Loading />
      ) : (
        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped">
            <thead>
            <tr>
              <th>
                Last Name
              </th>

              <th>
                First Name
              </th>

              <th>
                Email
              </th>

              <th>
                Last Activity
              </th>

              <th className="has-text-centered">
                Radeas Admin{" "}
                <span
                  className="has-tooltip-arrow"
                  data-tooltip="Can edit and administer both Radeas and Clinic users."
                >
                  <i className="far fa-question-circle is-size-7"></i>
                </span>
              </th>

              <th className="has-text-centered">
                Radeas User{" "}
                <span
                  className="has-tooltip-arrow"
                  data-tooltip="Default Radeas user, no admin privileges."
                >
                  <i className="far fa-question-circle is-size-7"></i>
                </span>
              </th>

              <th className="has-text-centered">
                Clinic Admin{" "}
                <span
                  className="has-tooltip-arrow"
                  data-tooltip="Can edit and administer Clinic users only."
                >
                  <i className="far fa-question-circle is-size-7"></i>
                </span>
              </th>

              <th className="has-text-centered">
                Clinic User{" "}
                <span
                  className="has-tooltip-arrow"
                  data-tooltip="Default Clinic user, no admin privileges."
                >
                  <i className="far fa-question-circle is-size-7"></i>
                </span>
              </th>

              <th />
            </tr>
            </thead>

            <tfoot>

            </tfoot>

            <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.lastName}</td>

                <td>{user.firstName}</td>

                <td>{user.email}</td>

                <td>{new Date(parseInt(user.lastActivity)).toDateString()}</td>

                <td className="has-text-centered">
                  <input
                    type="checkbox"
                    name={ROLES.RADEAS_ADMIN}
                    onChange={(event) => handleCheckbox(event, user)}
                    checked={user.roles.includes(ROLES.RADEAS_ADMIN) ? true : false}
                    disabled={authUser.roles.includes(ROLES.RADEAS_ADMIN) ? false : true}
                  />
                </td>
                <td className="has-text-centered">
                  <input
                    type="checkbox"
                    checked
                    disabled
                  />
                </td>
                <td className="has-text-centered">
                  <input
                    type="checkbox"
                    name={ROLES.RADEAS_CLINIC_ADMIN}
                    onChange={(event) => handleCheckbox(event, user)}
                    checked={user.roles.includes(ROLES.RADEAS_CLINIC_ADMIN) ? true : false}
                    disabled={authUser.roles.includes(ROLES.RADEAS_ADMIN) ? false : true}
                  />
                </td>
                <td className="has-text-centered">
                  <input
                    type="checkbox"
                    name={ROLES.CLINIC_USER}
                    onChange={(event) => handleCheckbox(event, user)}
                    checked={user.roles.includes(ROLES.CLINIC_USER) ? true : false}
                    disabled={authUser.roles.includes(ROLES.RADEAS_ADMIN) ? false : true}
                  />
                </td>

                <td style={{textAlign: "right"}}>
                  <button
                    className="button is-danger is-small"
                    onClick={() => handleOpenModal(user)}
                  >
                    Delete User
                  </button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      )}

      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        <div className="modal is-active">
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Delete User {userToDelete && userToDelete.email}?</p>
              <button
                className="delete"
                aria-label="close"
                onClick={handleCloseModal}
              />
            </header>

            <section className="modal-card-body">
              <p className="has-text-weight-semibold is-size-5">This cannot be undone.</p>

              <button
                className="button is-primary mt-5 is-pulled-left"
                onClick={handleCloseModal}
              >
                Cancel Delete
              </button>

              <button
                className="button is-danger mt-5 is-pulled-right"
                onClick={() => handleDelete(userToDelete)}
              >
                <span className="icon is-small">
                  <i className="fas fa-times"></i>
                </span>
                <span>Confirm Delete</span>
              </button>
            </section>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default RadeasUsersList;
