import React from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
// import { Formik, Field, useField, useFormikContext } from 'formik';
import { Formik, Field, useField, useFormikContext } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import Popup from 'reactjs-popup';

import { ButtonLoading } from '../Loading';
import { createNotification } from '../Notification';
import { SET_TOXICOLOGY_LAB_RESULTS } from '../../constants/gql';

import '../../styles/form.css';
import '../../styles/modal.css';
import 'react-datepicker/dist/react-datepicker.css';

const SpecimenValidationModal = ({ specimen, validationMissing, colors, isReRun }) => {
  let closeModal;

  const history = useHistory();

  const [uploadResults, { loading: uploading }] = useMutation(
    SET_TOXICOLOGY_LAB_RESULTS,
    {
      onCompleted({ setToxicologyLabResults }) {
        createNotification('info', 'Results saved');

        closeModal();

        // Refresh page
        history.go(0);
      },
      onError(error) {
        console.log('Set toxicology lab results', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    },
  );

  return (
    <Popup
      trigger={<i className="far fa-edit ml-2 edit" style={validationMissing ? { color: colors.red } : null} />}
      // contentStyle={{ overflowY: "auto", margin: "10px auto" }}
      contentStyle={{ overflowY: "auto", margin: "50px auto" }}
      lockScroll
      modal
    >
      {(close) => {
        closeModal = close;

        return (
          <div className="">
            <span className="has-text-white is-pulled-right">
              <i className="fas fa-times fa-lg icon-link" onClick={close} />
            </span>

            <h4 className="title is-4">
              Add/Edit Validation Data
            </h4>

            <Formik
              initialValues={{
                specimenId: specimen.specimenId || '',
                specimenType: specimen.specimenType || 'Urine',
                specimenRunDate: !specimen.specimenRunDate || specimen.specimenRunDate === '' || specimen.specimenRunDate === 'unknown' ? new Date() : specimen.specimenRunDate,
                specimenIsValid: specimen.specimenIsValid ?? true,
                specimenAnomaly: specimen.specimenAnomaly ?? false,
                specimenPH: specimen.specimenPH || '',
                specimenSpecificGravity: specimen.specimenSpecificGravity || '',
                specimenCreatinine: specimen.specimenCreatinine || '',
                specimenLabNotes: specimen.specimenLabNotes || '',
              }}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                uploadResults({ variables: { isReRun, ...values }});

                // resetForm();
                setSubmitting(false);
              }}
              validationSchema={Yup.object().shape({
                specimenId: Yup.string(),
                specimenType: Yup.string(),
                specimenRunDate: Yup.string(),
                specimenIsValid: Yup.boolean(),
                specimenAnomaly: Yup.boolean(),
                specimenPH: Yup.string(),
                specimenSpecificGravity: Yup.string(),
                specimenCreatinine: Yup.string(),
                specimenLabNotes: Yup.string(),
              })}
            >
              {(formProps) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = formProps;

                return (
                  <form onSubmit={handleSubmit} style={{ marginBotton: "150px" }}>
                    <div className="columns">
                      <div className="column is-half">
                        <div className="field">
                          <label className="label" htmlFor="input-specimenId">
                            Specimen ID
                          </label>
                          <input
                            id="input-specimenId"
                            name="specimenId"
                            className={errors.specimenId && touched.specimenId ? "input error" : "input"}
                            style={{ cursor: "default" }}
                            type="text"
                            value={specimen.specimenId}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled
                          />
                          {errors.specimenId && touched.specimenId && (
                            <div className="input-feedback" data-testid="errors-specimenId">
                              {errors.specimenId}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="columns">
                      <div className="column is-one-third">
                        <div className="field">
                          <label className="label" htmlFor="input-specimenType">
                            Specimen Type
                          </label>
                          <div className="select is-fullwidth">
                            <select
                              id="input-specimenType"
                              name="specimenType"
                              className={errors.specimenType && touched.specimenType ? "input error" : "input"}
                              value={values.specimenType}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <option value="Urine" label="Urine" />
                              <option value="Oral Fluid" label="Oral Fluid" />
                            </select>
                          </div>
                          {errors.specimenType && touched.specimenType && (
                            <div className="input-feedback" data-testid="errors-specimenType">
                              {errors.specimenType}
                            </div>
                          )}
                        </div>

                        <div className="block">
                          <label className="label" htmlFor="input-specimenRunDate">
                            Run Date
                          </label>
                          <DatePickerField id="input-specimenRunDate" name="specimenRunDate" />
                        </div>

                        <div className="block">
                          <label className="checkbox">
                            <span className="label has-text-weight-bold is-inline">
                              Validity Testing
                            </span>
                            <Field type="checkbox" name="specimenIsValid" className="ml-4" />
                              {values.specimenIsValid ? (
                                <span
                                  className="label has-text-weight-bold is-inline ml-3"
                                  style={{ color: colors.green }}
                                >
                                  (Valid)
                                </span>
                              ) : (
                                <span
                                  className="label has-text-weight-bold is-inline ml-3"
                                  style={{ color: colors.red }}
                                >
                                  (Invalid)
                                </span>
                              )}
                          </label>
                        </div>
                      </div>
                    </div>

                    <hr style={{ backgroundColor: colors.cobalt }}/>

                    <div className="block">
                      <label className="checkbox">
                        <span className="has-text-weight-bold label is-inline">Specimen Anomaly?</span>
                        <Field type="checkbox" name="specimenAnomaly" className="ml-2" />
                      </label>
                    </div>

                    {specimen.specimenType.toUpperCase() === 'URINE' && (
                      <div className="columns">
                        <div className="column is-half">
                          <div className="block">
                            <label className="label" htmlFor="input-specimenPH">
                              Specimen pH
                            </label>
                            <input
                              id="input-specimenPH"
                              name="specimenPH"
                              className={errors.specimenPH && touched.specimenPH ? "input error" : "input"}
                              type="text"
                              placeholder="pH"
                              value={values.specimenPH}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.specimenPH && touched.specimenPH && (
                              <div className="input-feedback" data-testid="errors-specimenPH">
                                {errors.specimenPH}
                              </div>
                            )}
                          </div>

                          <div className="block">
                            <label className="label" htmlFor="input-specimenSpecificGravity">
                              Specimen Specific Gravity
                            </label>
                            <input
                              id="input-specimenSpecificGravity"
                              name="specimenSpecificGravity"
                              className={errors.specimenSpecificGravity && touched.specimenSpecificGravity ? "input error" : "input"}
                              type="text"
                              placeholder="Specific gravity"
                              value={values.specimenSpecificGravity}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.specimenSpecificGravity && touched.specimenSpecificGravity && (
                              <div className="input-feedback" data-testid="errors-specimenSpecificGravity">
                                {errors.specimenSpecificGravity}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="column is-half">
                          <div className="block">
                            <label className="label" htmlFor="input-specimenCreatinine">
                              Specimen Creatinine
                            </label>
                            <input
                              id="input-specimenCreatinine"
                              name="specimenCreatinine"
                              className={errors.specimenCreatinine && touched.specimenCreatinine ? "input error" : "input"}
                              type="text"
                              placeholder="Creatinine"
                              value={values.specimenCreatinine}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            {errors.specimenCreatinine && touched.specimenCreatinine && (
                              <div className="input-feedback" data-testid="errors-specimenCreatinine">
                                {errors.specimenCreatinine}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    <hr style={{ backgroundColor: colors.cobalt }}/>

                    <div className="block">
                      <label className="label" htmlFor="input-specimenLabNotes">
                        Lab Notes
                      </label>
                      <textarea
                        id="input-specimenLabNotes"
                        name="specimenLabNotes"
                        className={errors.specimenLabNotes && touched.specimenLabNotes ? "textarea error" : "textarea"}
                        placeholder="(Optional) Notes"
                        value={values.specimenLabNotes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {errors.specimenLabNotes && touched.specimenLabNotes && (
                        <div className="input-feedback" data-testid="errors-specimenLabNotes">
                          {errors.specimenLabNotes}
                        </div>
                      )}
                    </div>

                    <button
                      className="button is-primary mb-2"
                    >
                      Submit
                    </button>
                    {uploading && <ButtonLoading />}

                    <button
                      className="button is-danger is-pulled-right"
                      onClick={(e) => {
                        e.preventDefault();
                        close();
                      }}
                    >
                      Cancel
                    </button>
                  </form>
                );
              }}
            </Formik>
          </div>
        );
      }}
    </Popup>
  );
};

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  return (
    <DatePicker
      {...field}
      {...props}
      className="input"
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => setFieldValue(field.name, val)}
    />
  );
};

export default SpecimenValidationModal;
