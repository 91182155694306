import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, Link } from 'react-router-dom';
import _ from 'lodash';
import Modal from 'react-modal';

import Loading from '../Loading';

import { createNotification } from '../Notification';
import { AUTH_USER_QUERY } from '../../constants/gql';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    border: 0,
    background: "transparent",
  },
};

export const ALL_CLINICS = gql`
  query AllClinics {
    allClinics {
      id
      clinicCode
      clinicName
      accessCode
      createdDate
    }
  }
`;

export const DELETE_CLINIC = gql`
  mutation DeleteClinic($id: String!) {
    deleteClinic(id: $id)
  }
`;

const ClinicUsersList = () => {
  const [clinics, setClinics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [clinicToDelete, setClinicToDelete] = useState(null);

  const history = useHistory();

  // Get auth user
  const { data: authUserData, loading: authUserLoading } = useQuery(AUTH_USER_QUERY);
  const { authUser } = authUserData;

  // Require auth and admin
  if (!authUser) {
    // Redirect
    createNotification('info', 'Please log in.');
    history.push(ROUTES.SIGN_IN);
  } else if (!authUser.roles.includes(ROLES.RADEAS_ADMIN)) {
    // Redirect
    createNotification('danger', 'Not authorized');
    history.push(ROUTES.ROOT);
  }

  // Setup get all Clinic users query
  // const { data } = useQuery(
  useQuery(
    ALL_CLINICS,
    {
      // fetchPolicy: 'network-only',
      onCompleted({ allClinics }) {
        // Sort by clinic name
        const sorted = _.sortBy(allClinics, (clinic) => clinic.clinicName);

        setClinics(sorted);
        setLoading(false);
      },
      onError(error) {
        console.log('ClinicsList Query Error: ', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');

        setLoading(false);
      },
    }
  );

  // Setup delete clinic mutation
  const [deleteClinic] = useMutation(
    DELETE_CLINIC,
    {
      onCompleted({ deleteClinic }) {
        createNotification('info', `Clinic "${clinicToDelete.clinicName}" has been deleted.`);

        // Update the clinics array
        const updatedClinics = clinics.filter((c) => (c.id !== deleteClinic));

        // Close modal
        if (showModal) {
          handleCloseModal();
        }

        // Set state
        setClinics(updatedClinics);
        setUpdating(false);
      },
      onError(error) {
        console.log('ClinicUsersList handleDelete() error: ', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');

        setUpdating(false);
      },
    },
  );

  useEffect(() => {
    Modal.setAppElement('body');
  }, []);

  const handleDelete = (clinic) => {
    const { id } = clinic;

    // Prevent double-submits
    if (!updating) {
      setUpdating(true);

      // Run mutation
      deleteClinic({ variables: { id } });
    }
  };

  const handleOpenModal = (clinic) => {
    setShowModal(true);
    setClinicToDelete(clinic);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setClinicToDelete(null);
  };

  if (authUserLoading) return <Loading />;

  return (

    <div className="page">

      <h4 className="title is-4 is-inline-block mt-1">Clinic Users</h4>
      <Link to={ROUTES.ADD_CLINIC} className="ml-6 button is-info is-pulled-right">
        <span className="icon is-small"><i className="fas fa-plus fa-sm" aria-hidden="true"></i></span>
        <span>Add Clinic</span>
      </Link>


      <br />

      {loading ? (
        <Loading />
      ) : (
        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped">
            <thead>
            <tr>
              <th>Clinic</th>

              <th>Clinic Code</th>

              <th>Access Code</th>

              <th>Created</th>

              <th />
              <th />
            </tr>
            </thead>

            <tfoot>

            </tfoot>

            <tbody>
            {clinics.map((clinic) => (
              <tr key={clinic.id}>
                {/* <td>{clinic.clinicName}</td> */}
                <td>
                  <Link
                    to={`${ROUTES.RADEAS_CLINIC_PAGE.replace(':id', clinic.id)}`}
                  >
                    {clinic.clinicName}
                  </Link>
                </td>

                <td>{clinic.clinicCode}</td>

                <td>{clinic.accessCode}</td>

                <td>{new Date(parseInt(clinic.createdDate)).toDateString()}</td>

                <td />
                {/*
                  <td>
                    <Link
                      to={`${ROUTES.CLINICS_ADMIN_DETAILS.replace(':id', clinic.id)}`}
                      className="button is-info"
                    >
                      Edit Clinic
                    </Link>
                  </td>
                  */}

                <td style={{textAlign: "right"}}>
                  <button
                    className="button is-danger is-small"
                    onClick={() => handleOpenModal(clinic)}
                  >
                    Delete Clinic
                  </button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      )}

      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        style={modalStyles}
      >
        <div className="modal is-active">
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">Delete Clinic {clinicToDelete && clinicToDelete.clinicName}?</p>
              <button
                className="delete"
                aria-label="close"
                onClick={handleCloseModal}
              />
            </header>

            <section className="modal-card-body">
              <p className="has-text-weight-semibold is-size-5">This cannot be undone.</p>

              <button
                className="button is-info mt-5 is-pulled-left"
                onClick={handleCloseModal}
              >
                Cancel Delete
              </button>

              <button
                className="button is-danger mt-5 is-pulled-right"
                onClick={() => handleDelete(clinicToDelete)}
              >
                <span className="icon is-small">
                  <i className="fas fa-times"></i>
                </span>
                <span>Confirm Delete</span>
              </button>
            </section>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ClinicUsersList;
