import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import Loading, { ButtonLoading } from '../Loading';
import { createNotification } from '../Notification';

export const GET_PATIENT_NOTES = gql`
  query GetPatientById(
    $id: String!
  ) {
    getPatientById(id: $id) {
      notes {
        note
        authorFirstName
        authorLastName
        authorEmail
        createdDate
      }
    }
  }
`;

export const ADD_PATIENT_NOTE = gql`
  mutation UpdatePatient(
    $id: String!,
    $notes: String
  ) {
    updatePatient(
      id: $id,
      data: {
        notes: $notes
      }
    ) {
      notes {
        note
        authorFirstName
        authorLastName
        authorEmail
        createdDate
      }
    }
  }
`;

const PatientNotes = () => {
  const [notes, setNotes] = useState([]);
  const [addNote, setAddNote] = useState(false);
  const [newNote, setNewNote] = useState('');
  const [loading, setLoading] = useState(true);

  // Get Patient ID from URL
  const { id } = useParams();

  // Get notes
  useQuery(
    GET_PATIENT_NOTES,
    {
      variables: { id },
      onCompleted({ getPatientById }) {
        if (process.env.NODE_ENV === 'development') console.log('Notes:', getPatientById.notes);

        setNotes(getPatientById.notes);
        setLoading(false);
      },
      onError(error) {
        console.log('Get Patient Notes', error);
        createNotification('danger', 'Sorry, could not retrieve patient notes.');
      }
    }
  );

  // Add note mutation
  const [savePatientNote, { loading: saving }] = useMutation(
    ADD_PATIENT_NOTE,
    {
      onCompleted({ updatePatient }) {
        if (process.env.NODE_ENV === 'development') console.log('Save Patient Note:', updatePatient.notes);

        setNotes(updatePatient.notes);
        setNewNote('');
        setAddNote(false);

        createNotification('info', 'Note saved');
      },
      onError(error) {
        console.log('Save patient note', error);
        createNotification('danger', 'Sorry, could not save note.');
      }
    }
  );

  const handleSubmit = () => {
    if (newNote.length > 0) {
      savePatientNote({
        variables: {
          id,
          notes: newNote,
        },
      });
    }
  };

  return (
    <div className="page">
      <span className="has-text-grey-light has-text-weight-semibold is-size-6">Notes</span>

      <hr />

      {loading ? (
        <Loading />
      ) : (
        <>
          {notes.map((note, i) => (
            <React.Fragment key={i}>
              <div>
                <p className="has-text-white">{note.note}</p>

                <p>
                  Created by {note.authorFirstName} {note.authorLastName} on
                  {" "}{new Date(parseInt(note.createdDate)).toDateString()}
                </p>
              </div>

              <hr />
            </React.Fragment>
          ))}

          {!addNote ? (
            <button
              type="button"
              className="button is-info is-small"
              onClick={() => setAddNote(true)}
            >
              <span className="icon">
                <i className="fas fa-plus"/>
              </span>
              <span>Add Note</span>
            </button>
          ) : (
            <>
              <div className="field">
                <label className="label" htmlFor="input-addNote">
                  Add Note
                </label>
                <textarea
                  id="input-addNote"
                  name="addNote"
                  className="textarea"
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                />
              </div>

              <div>
                <button
                  className="button is-info"
                  onClick={handleSubmit}
                  disabled={!newNote.length > 0}
                >
                  Add Note
                </button>
                {saving && <ButtonLoading />}

                <button
                  className="button is-danger is-pulled-right"
                  onClick={(e) => {
                    e.preventDefault();

                    setNewNote('');
                    setAddNote(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PatientNotes
