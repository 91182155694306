import React, { useEffect, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

import Loading, { ButtonLoading } from '../Loading';
import { createNotification } from '../Notification';
import { useAuth } from '../auth';
import { ALL_TEST_MACHINES } from '../TestMachines';
import { TOXICOLOGY_LAB_BATCHED_RUNNING } from '../../constants/status';
import * as STATUS from '../../constants/status';

import '../../styles/form.css';

export const GET_BATCH_BY_BATCH_ID = gql`
  query GetBatchByBatchId(
    $batchId: String!
  ) {
    getBatchByBatchId(
      batchId: $batchId
    ) {
      id
      batchId
      machineId
      machineName
      testPanel
      specimenIds
      orderIds
      status
      createdById
      createdByFirstName
      createdByLastName
      createdByEmail
      createdDate
      updatedDate
    }
  }
`;

export const GET_BATCH_BY_ID = gql`
  query GetBatchById(
    $id: String!
  ) {
    getBatchById(
      id: $id
    ) {
      id
      batchId
      machineId
      machineName
      testPanel
      specimenIds
      orderIds
      status
      createdById
      createdByFirstName
      createdByLastName
      createdByEmail
      createdDate
      updatedDate
    }
  }
`;

export const UPDATE_BATCH = gql`
  mutation UpdateBatch(
    $id: String!
    $machineId: String
    $machineName: String
    $status: String
  ) {
    updateBatch(
      data: {
        id: $id,
        machineId: $machineId,
        machineName: $machineName,
        status: $status
      }
    ) {
      id
      batchId
      machineId
      machineName
      testPanel
      specimenIds
      orderIds
      status
      createdById
      createdByFirstName
      createdByLastName
      createdByEmail
      createdDate
      updatedDate
    }
  }
`;

const BatchDetails = () => {
  const [testMachines, setTestMachines] = useState([]);
  const [batchData, setBatchData] = useState(null);
  const [editBatch, setEditBatch] = useState(false);
  const [newBatchData, setNewBatchData] = useState({});
  const [loading, setLoading] = useState({ batchData: true, testMachines: true });

  const { id } = useParams();
  const history = useHistory();

  // If machine is pending, open in editing mode
  useEffect(() => {
    if (!loading.batchData && batchData.machineId === "PENDING") {
      setEditBatch(true);
    }
  }, [batchData, loading]);

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

//   useQuery(
//     GET_BATCH_BY_BATCH_ID,
//     {
//       fetchPolicy: 'network-only',
//       variables: { batchId: id },
//       onCompleted({ getBatchByBatchId }) {
//         if (process.env.NODE_ENV === 'development') console.log('Query getBatchByBatchId:', getBatchByBatchId);
//
//         setBatchData(getBatchByBatchId);
//         setLoading({ ...loading, batchData: false });
//       },
//       onError(error) {
//         console.log('Query getBatchByBatchId', error);
//         createNotification('danger', 'Sorry, could not get batch info.');
//       },
//     }
//   );

  useQuery(
    GET_BATCH_BY_ID,
    {
      // fetchPolicy: 'network-only',
      variables: { id },
      onCompleted({ getBatchById }) {
        if (process.env.NODE_ENV === 'development') console.log('Query getBatchById:', getBatchById);

        setBatchData(getBatchById);
        setLoading({ ...loading, batchData: false });
      },
      onError(error) {
        console.log('Query getBatchById', error);
        createNotification('danger', 'Sorry, could not get batch info.');
      },
    }
  );

  useQuery(
    ALL_TEST_MACHINES,
    {
      // fetchPolicy: 'network-only',
      onCompleted({ allTestMachines }) {
        if (process.env.NODE_ENV === 'development') console.log('Query allTestMachines:', allTestMachines);

        setTestMachines(allTestMachines);
        setLoading({ ...loading, testMachines: false });
      },
      onError(error) {
        console.log('allTestMachines', error);
        createNotification('danger', 'Sorry, could not retrieve test machines.');
      },
    }
  );

  const [updateBatch, { loading: updating }] = useMutation(
    UPDATE_BATCH,
    {
      onCompleted({ updateBatch }) {
        if (process.env.NODE_ENV === 'development') console.log('Mutation updateBatch:', updateBatch);

        setBatchData(updateBatch);

        handleCancel();
        setEditBatch(false);

        createNotification('info', 'Batch updated.');
      },
      onError(error) {
        console.log('Mutation updateBatch', error);
        createNotification('danger', 'Sorry, could not set batch status to complete.');
      }
    }
  );

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'testMachines':
        handleTestMachine(e.target.value);
        break;
      case 'batchComplete':
        handleBatchComplete();
        break;
      default:
        break;
    }
  };

  const handleTestMachine = (id) => {
    // Lookup testMachine data
    const machine = _.find(testMachines, { id });

    // Check if selected machine is unchanged
    if (id === batchData.machineId) {
      const updated = { ...newBatchData };

      delete updated.machineId;
      delete updated.machineName;

      setNewBatchData(updated);

      return;
    }

    // Update batch data
    if (machine) {
      setNewBatchData({
        ...newBatchData,
        machineId: id,
        machineName: machine.name,
      });
    }
  };

  const handleBatchComplete = () => {
    if ('status' in newBatchData) {
      const switchStatus = newBatchData.status === STATUS.TOXICOLOGY_LAB_BATCHED_COMPLETE ? STATUS.TOXICOLOGY_LAB_BATCHED_RUNNING : STATUS.TOXICOLOGY_LAB_BATCHED_COMPLETE;

      if (switchStatus === batchData.status) {
        const updated = { ...newBatchData };

        delete updated.status;

        setNewBatchData(updated);

        return;
      }

      setNewBatchData({
        ...newBatchData,
        status: switchStatus,
      });
    } else {
      setNewBatchData({
        ...newBatchData,
        status: batchData.status === STATUS.TOXICOLOGY_LAB_BATCHED_COMPLETE ? STATUS.TOXICOLOGY_LAB_BATCHED_RUNNING : STATUS.TOXICOLOGY_LAB_BATCHED_COMPLETE,
      });
    }
  };

  const handleSubmit = () => {
    if (_.isEmpty(newBatchData)) {
      handleCancel();

      setEditBatch(false);
    } else {
      const data = {
        id: batchData.id,
        ...newBatchData,
      };

      if (batchData.status.includes("pending") && data.machineId !== "PENDING") {
        data["status"] = TOXICOLOGY_LAB_BATCHED_RUNNING;
      }

      // Run mutation
      updateBatch({ variables: data });
    }
  };

  const handleCancel = () => {
    setNewBatchData({});
    setEditBatch(false);
  };

  const renderChecked = () => {
    if ('status' in newBatchData) {
      if (newBatchData.status === STATUS.TOXICOLOGY_LAB_BATCHED_COMPLETE) {
        return true;
      } else {
        return false;
      }
    } else if (batchData.status === STATUS.TOXICOLOGY_LAB_BATCHED_COMPLETE) {
      return true;
    }

    return false;
  };

  const renderCompleteLabel = () => {
    if ('status' in newBatchData) {
      if (newBatchData.status === STATUS.TOXICOLOGY_LAB_BATCHED_COMPLETE) {
        return <span className="has-text-success">(Complete)</span>;
      } else {
        return <span className="has-text-warning">(Running)</span>;
      }
    } else if (batchData.status === STATUS.TOXICOLOGY_LAB_BATCHED_COMPLETE) {
      return <span className="has-text-success">(Complete)</span>;
    } else {
      return <span className="has-text-warning">(Running)</span>;
    }
  };

  const renderStatus = (status) => {
    switch (status.split(':').at(-1)) {
      case 'running':
        return <span className="has-text-warning">Running</span>;
      case 'complete':
        return <span className="has-text-success">Complete</span>;
      case 'pending':
        return <span className="has-text-danger">Pending</span>;
      default:
        return <span className="has-text-danger">Unknown</span>;
    }

    return status;
  };

  const renderSpecimenCount = () => {
    const machine = _.find(testMachines, { id: batchData.machineId });

    if (machine) {
      const specimenCount = batchData.specimenIds.length;
      const traySize = parseInt(machine.trayRows) * parseInt(machine.trayColumns);

      return `${specimenCount} / ${traySize}`;
    }
  };

  if (loading.batchData || loading.testMachines) return <Loading />;

  return (
    <div className="section">
      <div className="container">
        <div className="is-pulled-right">
          <button
            className="button is-info ml-3"
            onClick={() => history.goBack()}
          >
            Back
          </button>
        </div>

        <br />
        <br />
        <br />

        <div className="page">
          <div>
            <h4
              className="title is-4"
              style={{ display: "inline-block" }}
            >
              Batch ID: <span className="ml-4">{batchData.batchId}</span>
            </h4>

            <button className="button is-ghost is-pulled-right" type="button">
              <span
                className="icon is-cobalt has-tooltip-top"
                data-tooltip="Edit Batch"
                title="Edit batch"
                onClick={() => {
                  if (editBatch) handleCancel();

                  setEditBatch(!editBatch)
                }}
              >
                <i className="fas fa-edit" />
              </span>
            </button>

            <hr />
          </div>

          <div className="columns">
            <div className="column is-half">
              <span className="sublabel is-4 mr-3">Test Panel </span>
              <span className="has-text-white">{batchData.testPanel && batchData.testPanel[0].toUpperCase() + batchData.testPanel.substring(1).toLowerCase()}</span>

              <br /><br />

              <span className="sublabel is-4 mr-3">Status </span>
              <span className="has-text-white">{renderStatus(batchData.status)}</span>

              <br /><br />

              <span className="sublabel is-4 mr-3">Specimen Count </span>
              <span
                className="has-text-white has-tooltip-top"
                data-tooltip="num specimens / total tray size"
              >
                {renderSpecimenCount()}
              </span>

              <br /><br />

              <span className="sublabel is-4 mr-3">Created By </span>
              <span className="has-text-white">{batchData.createdByLastName}, {batchData.createdByFirstName} &mdash; {batchData.createdByEmail}</span>

              <br /><br />

              <span className="sublabel is-4 mr-3">Created Date </span>
              <span className="has-text-white">{new Date(parseInt(batchData.createdDate)).toDateString()}</span>

              <br /><br />

              <span className="sublabel is-4 mr-3">Last Updated </span>
              <span className="has-text-white">{batchData.updatedDate ? new Date(parseInt(batchData.updatedDate)).toDateString() : "n/a"}</span>
            </div>

            <div className="column is-half" style={{ borderLeft: "1px solid #333B52" }}>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label" htmlFor="input-testMachines">Machine</label>
                </div>

                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <div className="select">
                        <select
                          id="input-testMachines"
                          name="testMachines"
                          className="input"
                          value={newBatchData.machineId || batchData.machineId}
                          onChange={handleChange}
                          disabled={!editBatch}
                          style={!editBatch ? { cursor: "default" } : null}
                        >
                          <option
                            value="PENDING"
                          >
                            [Assigned Machine Pending]
                          </option>
                          {testMachines.map((machine) => (
                            <option
                              key={machine.id}
                              value={machine.id}
                            >
                              {machine.name} &mdash; ({machine.isActive ? "Active" : "Inactive"})
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {editBatch && (
                <>
                  <div className="field is-horizontal">
                    <div className="field-label">
                      <label className="label" htmlFor="input-batchComplete">Finished?</label>
                    </div>

                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            id="input-batchComplete"
                            name="batchComplete"
                            className="checkbox"
                            type="checkbox"
                            checked={renderChecked()}
                            onChange={handleChange}
                          />

                          <span className="ml-3">
                            {renderCompleteLabel()}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal mt-6">
                    <div className="field-label">
                      {/* <!-- Left empty for spacing --> */}
                    </div>

                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <button
                            className="button is-primary"
                            onClick={handleSubmit}
                          >
                            Submit
                          </button>
                          {updating && <ButtonLoading />}

                          <button
                            className="button is-danger is-pulled-right"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatchDetails;
