import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import _ from 'lodash';

import CreateBatchModal from './CreateBatchModal';
import TrayVisualization from '../TestMachines/TrayVisualization';

import { createNotification } from '../Notification';

export const ALL_ACTIVE_TEST_MACHINES = gql`
  query AllActiveTestMachines {
    allActiveTestMachines {
      id
      name
      trayRows
      trayColumns
      isActive
      createdDate
      updatedDate
    }
  }
`;

const CurrentBatch = ({ batchId, handleSetBatchSize, handleBatchComplete, currentBatch, handleSetTestPanel, handleSetTestMachine, handleGenerateBatch, generatingBatch }) => {
  const [testMachines, setTestMachines] = useState([]);
  const [selectedMachine, setSelectedMachine] = useState({});

  const [showTrayDims, setShowTrayDims] = useState(false);
  const [pendingRows, setPendingRows] = useState('');
  const [pendingCols, setPendingCols] = useState('');
  const [trayDimsError, setTrayDimsError] = useState(false);

  const [batchSize, setBatchSize] = useState(0);
  const [numSpecimensSelected, setNumSpecimensSelected] = useState(0);
  const [selectedPanel, setSelectedPanel] = useState('PAIN');

  useEffect(() => {
    handleSetTestPanel(selectedPanel);
  }, []);

  useEffect(() => {
    setNumSpecimensSelected(currentBatch.length);
  }, [currentBatch]);

  useEffect(() => {
    const rows = Number(pendingRows);
    const cols = Number(pendingCols);

    if (rows && rows > 0 && cols && cols > 0) {
      const batchSize = rows * cols;

      setBatchSize(batchSize);
      handleSetBatchSize(batchSize);
    }
  }, [pendingRows, pendingCols]);

  useQuery(
    ALL_ACTIVE_TEST_MACHINES,
    {
      onCompleted({ allActiveTestMachines }) {
        if (process.env.NODE_ENV === 'development') console.log('Query AllActiveTestMachines:', allActiveTestMachines);

        setTestMachines(allActiveTestMachines);
      },
      onError(error) {
        console.log('allActiveTestMachines', error);
        createNotification('danger', 'Sorry, could not retrieve active test machines.');
      },
    }
  );

  const handlePanelChange = (e) => {
    handleSetTestPanel(e.target.value.toUpperCase());

    setSelectedPanel(e.target.value.toUpperCase());
  };

  const handleSelectTestMachine = (e) => {
    // No machine selected
    if (e.target.value === '-') {
      handleSetTestMachine({});

      setSelectedMachine({});
      setBatchSize(0);
      handleSetBatchSize(0);

      setShowTrayDims(false);

      return;
    }

    // "Pending" selected
    if (e.target.value === '[Pending]') {
      const machineInfo = {
        id: "PENDING",
        name: "[Pending]",
        trayRows: "0",
        trayColumns: "0",
        isActive: true,
        createdDate: null,
        updatedDate: null,
      };

      handleSetTestMachine(machineInfo);

      setSelectedMachine(machineInfo);
      setBatchSize(0);
      handleSetBatchSize(0);

      setShowTrayDims(true);

      return;
    }

    const index = _.findIndex(testMachines, { id: e.target.value });
    const columns = parseInt(testMachines[index].trayColumns) || 0;
    const rows = parseInt(testMachines[index].trayRows) || 0;

    handleSetTestMachine({ ...testMachines[index] });

    setSelectedMachine({ ...testMachines[index] });
    setBatchSize(columns * rows);
    handleSetBatchSize(columns * rows);

    setShowTrayDims(false);
  };

  const handleDimsChange = (e) => {
    let errorFlag = false;
    const { name, value } = e.target;

    // Check value
    const check = Number(value);
    if (!Number.isInteger(check) || check < 1 && value != '') {
      errorFlag = true;
    }

    if (!errorFlag) {
      switch (name) {
        case 'pendingRows':
          setPendingRows(value);
          break;
        case 'pendingCols':
          setPendingCols(value);
          break;
        default:
          break;
      }
    }

    if (value == '') {
      errorFlag = true;
    }

    // Set/reset input error
    if (errorFlag) {
      setTrayDimsError(true);
    } else {
      setTrayDimsError(false);
    }
  };

  const handleCreateBatch = () => {
    handleGenerateBatch();
  };

  return (
    <div
      className="page p-5"
      // style={{ borderRadius: "10px", minHeight: "275px" }}
      style={{ borderRadius: "10px", minHeight: "330px" }}
    >
      <h4 className="title is-4">Current Batch</h4>

      <div
        className="pl-4 pr-4 py-2"
        style={{
          borderRadius: "10px",
          backgroundColor: "#333B52",
          color: "#FFF",
        }}
      >
        <div className="columns">
          <div className="column is-7">
            <TrayVisualization rows={9} columns={12} />
            <div className="has-text-centered mt-1">
              <span className="icon-text" style={{ cursor: "pointer" }}>
                <span className="icon">
                  <i className="fas fa-search-plus" />
                </span>
                <span>
                  view larger
                </span>
              </span>
            </div>
          </div>

          <div className="column">
            <p className="mt-2 mb-3">
              <span className="is-size-3 has-text-weight-semibold has-text-primary">
                {numSpecimensSelected}{" "}
              </span>
              / {batchSize} specimens selected
            </p>

            <div className="control mb-3">
              <label className="radio has-text-light mr-3">
                <input
                  className="mr-1"
                  type="radio"
                  name="panel"
                  value="PAIN"
                  checked={selectedPanel === 'PAIN'}
                  onChange={handlePanelChange}
                />
                Pain
              </label>

              <label className="radio has-text-light mr-3">
                <input
                  className="mr-1"
                  type="radio"
                  name="panel"
                  value="ETG"
                  checked={selectedPanel === 'ETG'}
                  onChange={handlePanelChange}
                />
                EtG
              </label>

              <label className="radio has-text-light">
                <input
                  className="mr-1"
                  type="radio"
                  name="panel"
                  value="PAIN::ETG"
                  checked={selectedPanel === 'PAIN::ETG'}
                  onChange={handlePanelChange}
                />
                Both
              </label>
            </div>

            <div className="select">
              <select
                id="input-testMachines"
                name="testMachines"
                className="input"
                onChange={handleSelectTestMachine}
              >
                <option defaultValue value="-">Select Test Machine</option>
                <option value="[Pending]">[Assigned Machine Pending]</option>
                {testMachines.map((machine) => (
                  <option
                    key={machine.id}
                    value={machine.id}
                  >
                    {machine.name}
                  </option>
                ))}
              </select>
            </div>

            {showTrayDims && (
              <>
                <div className="columns mt-1 mb-0">
                  <div className="column is-half">
                    <div className="block">
                      <label className="label">
                        Rows
                      </label>
                      <input
                        id="input-pendingRows"
                        name="pendingRows"
                        className="input"
                        type="number"
                        placeholder="rows"
                        value={pendingRows}
                        onChange={handleDimsChange}
                      />
                    </div>
                  </div>

                  <div className="column is-half">
                    <div className="block">
                      <label className="label">
                        Cols
                      </label>
                      <input
                        id="input-pendingCols"
                        name="pendingCols"
                        className="input"
                        type="number"
                        placeholder="columns"
                        value={pendingCols}
                        onChange={handleDimsChange}
                      />
                    </div>
                  </div>
                </div>
                {trayDimsError && (
                  <div className="input-feedback">
                    Dimensions must be at least 1
                  </div>
                )}
              </>
            )}

            {/* <button */}
            {/*   className="button is-info mt-4" */}
            {/*   onClick={handleGenerateBatch} */}
            {/*   disabled={_.isEmpty(selectedMachine)} */}
            {/* > */}
            {/*   Create Batch */}
            {/* </button> */}

            <CreateBatchModal
              batchId={batchId}
              machine={selectedMachine}
              batchSize={batchSize}
              currentBatchSize={numSpecimensSelected}
              handleConfirm={handleCreateBatch}
              handleBatchComplete={handleBatchComplete}
              loading={generatingBatch}
              disabled={_.isEmpty(selectedMachine) || trayDimsError || !batchSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentBatch;
