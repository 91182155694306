import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import _ from 'lodash';

import PatientProfile from './PatientProfile';
import PatientHistory from './PatientHistory';
import PatientNotes from './PatientNotes';
import NewPatient from './NewPatient';

import { ButtonLoading } from '../Loading';
import { useAuth } from '../auth';
import * as ROUTES from '../../constants/routes';

const PatientPage = () => {
  const [patient, setPatient] = useState({});

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  const handlePatient = (p) => {
    setPatient(p);
  };

  return (
    <section className="section">
      <div className="container">
        <h1 className="title is-size-4">Patient Details</h1>

        <div className="">
          {_.isEmpty(patient) ? (
            <ButtonLoading />
          ) : (
            <Link
              className="button is-info mb-4"
              to={{
                pathname: ROUTES.RADEAS_TOX_ORDER_CREATE,
                state: { patient },
              }}
            >
              Order Test
            </Link>
          )}
        </div>

        <div className="columns">
          <div className="column is-one-third-desktop is-one-third-tablet">
            <PatientProfile
              handlePatient={handlePatient}
            />
          </div>

          <div className="column is-two-thirds-desktop is-two-thirds-tablet">
            <PatientHistory />
            <br/><br/>
            <PatientNotes />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PatientPage;

export { NewPatient };
