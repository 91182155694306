import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';

import CheckInSpecimen from './CheckInSpecimen';
import Loading from '../Loading';

import { createNotification } from '../Notification';
import { useAuth } from '../auth';
import * as ROUTES from '../../constants/routes';
import * as STATUS from '../../constants/status';

const POLL_INTERVAL = 2500;

export const IN_TRANSIT_TOXICOLOGY_ORDERS = gql`
  query AllInTransitToxicologyOrders {
    allInTransitToxicologyOrders {
      id
      clinicCode
      clinicName
      specimenId
      specimenType
      status
      createdDate
    }
  }
`;

const InTransitSpecimenList = () => {
  const [orders, setOrders] = useState([]);
  const [checkInSpecimenId, setCheckInSpecimenId] = useState(null);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  // Get in-transit orders
  const { startPolling, stopPolling } = useQuery(
    IN_TRANSIT_TOXICOLOGY_ORDERS,
    {
      // fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted({ allInTransitToxicologyOrders }) {
        setOrders(allInTransitToxicologyOrders);

        setLoading(false);
      },
      onError(error) {
        console.log('In-transit toxicology orders', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');

        setLoading(false);
      }
    }
  );

  // Start/stop polling
  useEffect(() => {
    startPolling(POLL_INTERVAL);

    return () => stopPolling();
  });
  // }, [startPolling, stopPolling]);

  // const setStatusBackground = (status) => {
  //   switch (status) {
  //     case STATUS.TOXICOLOGY_CLINIC_CREATED:
  //       return { backgroundColor: '#66ff8c' };      // Green
  //     case STATUS.TOXICOLOGY_LAB_IN_PROCESS:
  //       return { backgroundColor: '#ffff66' };      // Yellow
  //     case STATUS.TOXICOLOGY_ANALYST_REVIEW:
  //       return { backgroundColor: '#ffd966' };      // Orange-ish
  //     case STATUS.TOXICOLOGY_LAB_RERUN:
  //       return { backgroundColor: '#ffd966' };      // Orange-ish
  //     case STATUS.TOXICOLOGY_ANALYST_COMPLETE:
  //       return { backgroundColor: '#ff6666' };      // Red
  //     default:
  //       return {};
  //   }
  // };

  const handleClickOrder = (order) => {
    history.push(ROUTES.RADEAS_TOX_ORDER_DETAILS.replace(':id', order.id));
  };

  const handleSpecimenCheckInComplete = (response) => {
    // Remove specimen from in-transit list
    if (response.statusCode === 200) {
      // Yes I know I can just go and filter by specimenId in checkInSpecimenId
      const idx = _.findIndex(orders, { specimenId: checkInSpecimenId });

      if (idx > -1) {
        const filteredOrders = orders.filter((order) => order.id !== orders[idx].id);

        setOrders(filteredOrders);
      }
    }

    setCheckInSpecimenId(null);
  };

  if (loading) return <Loading />;

  return (
    <div className="page" style={{ borderRadius: "10px" }}>
      <h4 className="title is-4 is-inline-block mt-1">In-Transit Orders</h4>

      <br />

      <div className="mb-5">
        <CheckInSpecimen orderList={orders} specimenId={checkInSpecimenId} onComplete={handleSpecimenCheckInComplete} />
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Clinic Name</th>
              <th>Clinic Code</th>
              <th>Specimen ID</th>
              <th>Type</th>
              <th>Created Date</th>
              <th>Status</th>
              <th />
            </tr>
            </thead>

            <tbody>
            {orders.map((order) => {
              if (order.status !== STATUS.TOXICOLOGY_CLINIC_CREATED) {
                return null;
              }

              const status = order.status.split(':').pop();

              return (
                <tr
                  key={order.id}
                  // className="is-clickable"
                  // onClick={() => handleClickOrder(order)}
                >
                  <td>{order.clinicName}</td>
                  <td>{order.clinicCode}</td>
                  <td>
                    <span>
                      <span
                        className="mr-2 icon is-small is-clickable"
                        data-tooltip="Click to check in specimen"
                        onClick={() => setCheckInSpecimenId(order.specimenId)}
                      >
                        <i className="fas fa-inbox" aria-hidden="true"/>
                      </span>
                      {order.specimenId}
                    </span>
                  </td>
                  <td>{order.specimenType}</td>
                  <td>{new Date(parseInt(order.createdDate)).toDateString()}</td>
                  <td>{status === 'created' ?
                    <span style={{color: '#66ff8c'}}><span className="mr-2 icon is-small">
                      <i className="fas fa-circle fa-small" aria-hidden="true"/>
                    </span> In transit</span>
                    :
                    status
                  }
                  </td>
                  <td>
                    <Link
                      to={ROUTES.RADEAS_TOX_ORDER_DETAILS.replace(':id', order.id)}
                      className="button is-info is-small"
                    >
                      View/Edit
                    </Link>
                  </td>
                  {/*<td style={setStatusBackground(order.status)} className="has-text-centered">{status === 'created' ? 'in-transit' : status}</td>*/}
                  {/* <td> */}
                  {/*   <Link */}
                  {/*     to={ROUTES.DASHBOARD_ANALYST_ENTER_RESULTS.replace(':id', order.id)} */}
                  {/*     className="button is-primary" */}
                  {/*     onClick={() => setOrderStatus({ variables: { */}
                  {/*       id: order.id, */}
                  {/*       newStatus: STATUS.TOXICOLOGY_ANALYST_IN_PROCESS, */}
                  {/*       prevStatus: order.status */}
                  {/*     }})} */}
                  {/*   > */}
                  {/*     Enter Results */}
                  {/*   </Link> */}
                  {/* </td> */}
                </tr>
              );
            })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default InTransitSpecimenList;
