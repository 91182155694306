import React, { useEffect, useState } from 'react';
import _ from 'lodash';

const ToDo = ({ specimens, handleClick }) => {
  const [toDos, setToDos] = useState([]);

  // Process specimens
  useEffect(() => {
    const clinics = [];

    specimens.forEach((specimen) => {
      const index = _.findIndex(clinics, { clinicCode: specimen.clinicCode });

      if (index === -1) {
        clinics.push({
          clinicCode: specimen.clinicCode,
          totalSpecimens: 1,
          selected: false,
        });
      } else {
        clinics.splice(index, 1, {
          ...clinics[index],
          totalSpecimens: ++clinics[index].totalSpecimens,
        });
      }
    });

    setToDos(clinics);
  }, [specimens]);

  const handleSelectToDo = (data) => {
    const clinics = _.cloneDeep(toDos);
    const index = _.findIndex(clinics, { clinicCode: data.clinicCode });

    if (index >= 0) {
      clinics.splice(index, 1, {
        ...clinics[index],
        selected: !clinics[index].selected,
      });

      setToDos(clinics);
    }

    const filtered = clinics.filter((clinic) => clinic.selected).map((clinic) => clinic.clinicCode);

    handleClick(filtered);
  };

  return (
    <div
      className="page p-5"
      style={{ borderRadius: "10px", height: "100%" }}
    >
      <h4 className="title is-4">To-Do</h4>

      {toDos.map((todo, i) => (
        <ToDoBox key={i} data={todo} handleClick={handleSelectToDo} />
      ))}
    </div>
  );
};

const ToDoBox = ({ data, handleClick }) => {
  console.log('ToDoBox data:', data);
  const { clinicCode, totalSpecimens, completedSpecimens = 0 } = data;

  return (
    <div
      className="pl-4 pr-4 py-2 mr-4 mb-4"
      style={{
        borderRadius: "10px",
        backgroundColor: "#333B52",
        color: "#FFF",
        display: "inline-block",
        border: data.selected ? "2px solid #00D1B2" : "2px solid #333B52",
      }}
      onClick={() => handleClick(data)}
    >
      <p className="is-size-7">{data.clinicCode}</p>
      <p className="is-size-5 has-text-weight-semibold">{totalSpecimens}</p>
      <p className="is-size-7">samples remain</p>

      {/* <p className="is-size-5">{completedSpecimens}/{totalSpecimens}</p> */}
      {/* <p className="is-size-7">Samples complete</p> */}
      {/* <p> */}
      {/*   <progress */}
      {/*     className="progress is-primary is-small mt-2" */}
      {/*     value={completedSpecimens} */}
      {/*     max={totalSpecimens} */}
      {/*   > */}
      {/*     {Math.round((completedSpecimens / totalSpecimens) * 100)}% */}
      {/*   </progress> */}
      {/* </p> */}
    </div>
  );
};

export default ToDo;
