import React from 'react';

import './iconStyles.css';

const CrossIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" x="0px" y="0px" className="cross">
      <g data-name="Cross">
        <path d="M21.06,13.06,18.12,16l2.94,2.94a1.49,1.49,0,0,1,0,2.12,1.5,1.5,0,0,1-2.12,0L16,18.12l-2.94,2.94a1.5,1.5,0,0,1-2.12,0,1.49,1.49,0,0,1,0-2.12L13.88,16l-2.94-2.94a1.5,1.5,0,0,1,2.12-2.12L16,13.88l2.94-2.94a1.5,1.5,0,0,1,2.12,2.12ZM29.5,8V24A5.51,5.51,0,0,1,24,29.5H8A5.51,5.51,0,0,1,2.5,24V8A5.51,5.51,0,0,1,8,2.5H24A5.51,5.51,0,0,1,29.5,8Zm-3,0A2.5,2.5,0,0,0,24,5.5H8A2.5,2.5,0,0,0,5.5,8V24A2.5,2.5,0,0,0,8,26.5H24A2.5,2.5,0,0,0,26.5,24Z" />
      </g>
    </svg>
  );
};

export default CrossIcon;
