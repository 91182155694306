import { gql } from '@apollo/client';

export const AUTH_USER_QUERY = gql`
  query AuthUser {
    authUser @client {
      id
      roles
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query Notification {
    notification @client {
      messageType
      messageText
    }
  }
`;

export const GET_DRUG_METABOLITES = gql`
  query DrugMetabolites {
    drugMetabolites {
      drugName
      parents
      metabolites
    }
  }
`;

export const GET_ALL_CLINICS = gql`
  query AllClinics {
    allClinics {
      id
      clinicCode
      clinicName
    }
  }
`;

export const GET_DRUG_NAMES = gql`
  query DrugNames {
    drugNames
  }
`;

export const SEARCH_ICD_10_CODES = gql`
  query SearchIcd10Codes(
    $searchTerm: String!
  ) {
    searchIcd10Codes(
      searchTerm: $searchTerm
    ) {
      code
      description
      createdDate
    }
  }
`;

export const SET_VALIDATION = gql`
  mutation SetToxicologyLabResults(
    $isReRun: Boolean!,
    $specimenId: String!,
    $specimenType: String,
    $specimenRunDate: String,
    $specimenLabNotes: String,
    $specimenAnomaly: Boolean,
    $specimenAlcoholBiomarker: Boolean,
    $specimenIsValid: Boolean,
    $specimenPH: String,
    $specimenSpecificGravity: String,
    $specimenCreatinine: String,
    $specimenReRunLabDate: String,
    $specimenReRunLabNotes: String,
  ) {
    setToxicologyLabResults(
      data: {
        isReRun: $isReRun,
        specimenId: $specimenId,
        specimenType: $specimenType,
        specimenRunDate: $specimenRunDate,
        specimenLabNotes: $specimenLabNotes,
        specimenAnomaly: $specimenAnomaly,
        specimenAlcoholBiomarker: $specimenAlcoholBiomarker,
        specimenIsValid: $specimenIsValid,
        specimenPH: $specimenPH,
        specimenSpecificGravity: $specimenSpecificGravity,
        specimenCreatinine: $specimenCreatinine,
        specimenReRunLabDate: $specimenReRunLabDate,
        specimenReRunLabNotes: $specimenReRunLabNotes,
      }
    ) {
      id
      clinicCode
      clinicName
      specimenId
      batchIds
      batches {
        id
        batchId
        testPanel
      }
      specimenType
      specimenIsValid
      specimenReceivedLabDate
      specimenAnomaly
      specimenPH
      specimenSpecificGravity
      specimenCreatinine
      additionalTests {
        name
        result
      }
      toxicologyTests {
        drugName
        pos
      }
      etgTests
    }
  }
`;

export const SET_TOXICOLOGY_LAB_RESULTS = gql`
  mutation SetToxicologyLabResults(
    $isReRun: Boolean!,
    $specimenId: String!,
    $specimenType: String,
    $specimenRunDate: String,
    $specimenLabNotes: String,
    $specimenAnomaly: Boolean,
    $specimenAlcoholBiomarker: Boolean,
    $specimenIsValid: Boolean,
    $specimenPH: String,
    $specimenSpecificGravity: String,
    $specimenCreatinine: String,
    $specimenReRunLabDate: String,
    $specimenReRunLabNotes: String,
  ) {
    setToxicologyLabResults(
      data: {
        isReRun: $isReRun,
        specimenId: $specimenId,
        specimenType: $specimenType,
        specimenRunDate: $specimenRunDate,
        specimenLabNotes: $specimenLabNotes,
        specimenAnomaly: $specimenAnomaly,
        specimenAlcoholBiomarker: $specimenAlcoholBiomarker,
        specimenIsValid: $specimenIsValid,
        specimenPH: $specimenPH,
        specimenSpecificGravity: $specimenSpecificGravity,
        specimenCreatinine: $specimenCreatinine,
        specimenReRunLabDate: $specimenReRunLabDate,
        specimenReRunLabNotes: $specimenReRunLabNotes,
      }
    ) {
      id
      labUploadedDate
    }
  }
`;

export const GET_RADEAS_USER_BY_ID = gql`
  query GetRadeasUserById(
    $id: String!
  ) {
    getRadeasUserById(
      id: $id
    ) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const GET_ANALYST_INSTRUCTIONS = gql`
  query ToxicologyTestProfileByClinicCode(
    $clinicCode: String!
  ) {
    toxicologyTestProfileByClinicCode(
      clinicCode: $clinicCode
    ) {
      id
      clinicName
      analystInstructions
    }
  }
`;
