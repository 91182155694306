import React from 'react';

import { CLINICS, PATIENTS, TOXICOLOGY_ORDERS } from '../../constants/collections';
import * as ROUTES from '../../constants/routes';
import './searchBox.css';

// URL's in Algolia "collections"
// 1) vial-solid.svg
// 2) user-solid.svg
// 3) clinic-medical-solid.svg

// Font Awesome
// 1) <i class="fas fa-vial"></i>
// 2) <i class="fas fa-user"></i>
// 3) <i class="fas fa-clinic-medical"></i>

const SearchItem = ({ hit, components }) => {
  const generateLink = () => {
    switch (hit.collection) {
      case TOXICOLOGY_ORDERS:
        return ROUTES.RADEAS_TOX_ORDER_DETAILS.replace(':id', hit.id);
      case PATIENTS:
        return ROUTES.RADEAS_PATIENT_PAGE.replace(':id', hit.id);
      case CLINICS:
        return ROUTES.RADEAS_CLINIC_PAGE.replace(':id', hit.id);
      default:
        return '#';
    }
  };

  const renderIcon = (collection) => {
    switch (collection) {
      case TOXICOLOGY_ORDERS:
        return <i className="fas fa-vial fa-2x" />;
      case PATIENTS:
        return <i className="fas fa-user-injured fa-2x" />;
      case CLINICS:
        return <i className="fas fa-clinic-medical fa-2x" />;
      default:
        return <i className="far fa-question-circle fa-2x" />;
    }
  };

  const url = generateLink();

  return (
    <div className="aa-ItemWrapper">
      <a href={url} className="aa-ItemLink">
        <div className="aa-ItemContent">
          <div className="aa-ItemIcon">
            {/* <img src={hit.collection} alt={hit.primary} width="40" height="40" /> */}
            {renderIcon(hit.collection)}
          </div>

          <div className="aa-ItemContentBody">
            <div className="aa-ItemContentTitle">
              <components.Highlight hit={hit} attribute="primary" highlightedTagName="em" />
            </div>

            <div className="aa-ItemContentDescription">
              <components.Snippet hit={hit} attribute="clinicCode" />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

export default SearchItem;
