export const ROOT = '/';
export const HOME = '/home';

// Radeas routes
export const DASHBOARD = '/dashboard';
export const ACCOUNT = '/account';
export const RADEAS_ADMIN = '/admin/radeas';
export const CLINICS_ADMIN = '/admin/clinics';
export const CLINICS_ADMIN_DETAILS = '/admin/clinics/:id';
export const RADEAS_ADMIN_DETAILS = '/radeas/admin/users/:id';
export const RADEAS_ADMIN_USERS = '/radeas/admin/users';
export const RADEAS_CLINIC_PAGE = '/clinic/:id';
export const RADEAS_CLINIC_SUPPPLIES_AND_LOGISTICS = '/clinic/supplies/:id';
export const TEST_MENU = '/admin/test-menu';

// Patient routes
export const RADEAS_PATIENT_CREATE = '/patients/new';
export const RADEAS_PATIENT_PAGE = '/patients/details/:id';

// Order routes
export const RADEAS_TOX_ORDER_CREATE = '/toxicology/orders/new';
export const RADEAS_TOX_ORDER_EDIT = '/toxicology/orders/edit/:id';
export const RADEAS_TOX_ORDER_DETAILS = '/toxicology/orders/details/:id';

// Analyst routes
export const DASHBOARD_ANALYST_NEW_ORDERS = '/dashboard/analyst/orders/new';
export const DASHBOARD_ANALYST_REVIEW_ORDERS = '/dashboard/analyst/orders/review';
export const DASHBOARD_ANALYST_COMPLETED_ORDERS = '/dashboard/analyst/orders/complete';
export const DASHBOARD_ANALYST_ENTER_RESULTS = '/dashboard/analyst/orders/enter-results/:id';
export const DASHBOARD_ANALYST_REVIEW_RESULTS = '/dashboard/analyst/orders/review-results/:id';
export const DASHBOARD_ANALYST_VIEW_RESULTS = '/dashboard/analyst/orders/results/:id';

// Lab routes
export const DASHBOARD_LAB = '/dashboard/lab';
export const DASHBOARD_LAB_IN_TRANSIT  = '/dashboard/lab/in-transit';
export const DASHBOARD_LAB_BATCHES  = '/dashboard/lab/batches';
export const DASHBOARD_LAB_BATCH_DETAILS = '/dashboard/lab/batch/:id';
export const DASHBOARD_LAB_SCANNED = '/dashboard/lab/received';
export const DASHBOARD_LAB_COMPLETE = '/dashboard/lab/specimen/complete';
export const DASHBOARD_LAB_RERUN = '/dashboard/lab/specimen/re-run';
export const DASHBOARD_LAB_ENTER_RESULTS = '/dashboard/lab/specimen/enter-results/:id';
export const DASHBOARD_LAB_ENTER_NEW_RESULTS = '/dashboard/lab/specimen/enter-results';
export const DASHBOARD_LAB_ENTER_RERUN_RESULTS = '/dashboard/lab/specimen/rerun-results/:id';
export const DASHBOARD_LAB_VIEW_RESULTS = '/dashboard/lab/specimen/results/:id';

export const DASHBOARD_TEST_MACHINES = '/dashboard/lab/test-machines';

export const DASHBOARD_COVID_RESULTS = '/dashboard/covid/results';
export const DASHBOARD_UPLOAD_COVID_RESULTS = '/dashboard/covid/enter-results';

// General auth routes
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const FORGOT_PASSWORD = '/reset-password';
export const RESET_PASSWORD = '/reset-password/:token';

// Clinic admin routes
export const CLINICS = '/clinics';
export const ADD_CLINIC = '/clinics/new';
