import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { customAlphabet } from 'nanoid';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { ButtonLoading } from '../Loading';

import { createNotification } from '../Notification';
import * as ROUTES from '../../constants/routes';

import '../../styles/form.css';

const nanoid = customAlphabet('23456789ABCDEFGHJKMNPQRSTUVWXYZ', 12);

export const REGISTER_CLINIC = gql`
  mutation CreateClinic(
    $clinicName: String!,
    $clinicCode: String!,
    $accessCode: String
  ) {
    createClinic(
      data: {
        clinicName: $clinicName,
        clinicCode: $clinicCode,
        accessCode: $accessCode
      }
    ) {
      id
      clinicName
    }
  }
`;

const NewClinic = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <NewClinicForm />
          </div>
        </div>
      </div>
    </section>
  );
};

const NewClinicForm = () => {
  const history = useHistory();

  const [createUser, { loading }] = useMutation(
    REGISTER_CLINIC,
    {
      onCompleted({ createClinic }) {
        if (createClinic) {
          // TODO: Set notofication
          createNotification('info', `Clinic "${createClinic.clinicName}" created!`);
          // console.log(`Created clinic: ${createClinic.id}`);

          // Go back to previous page after registration
          history.push(ROUTES.CLINICS_ADMIN);
        }
      },
      onError(error) {
        console.log('NewClinic error:', error);

        if (error.message === "Clinic code already registered") {
          createNotification('danger', 'Sorry, that Clinic Code is already in use. Please try a different one.');
        } else {
          createNotification('danger', 'Sorry, something went wrong. Please try again.');
        }
      },
    },
  );

  return (
    <div>
      <h3 className="title is-3">New Clinic</h3>

      <Formik
        initialValues={{
          clinicName: '',
          clinicCode: '',
          accessCode: nanoid(),
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          // const { clinicName, clinicCode, phone, address1, address2, city, state, zipCode } = values;
          const { clinicName, clinicCode, accessCode } = values;

          // Execute the mutation
          createUser({
            variables: {
              clinicName,
              clinicCode,
              accessCode,
            }
          });

          // resetForm();
          setSubmitting(false);
        }}
        validationSchema={Yup.object().shape({
          clinicName: Yup.string()
            .required("Clinic name is required"),
          clinicCode: Yup.string()
            .required("Clinic code is required"),
          accessCode: Yup.string()
            .test("len", "Must be at least 8 characters", (val) => val && val.length >= 8)
            .required("Access code is required"),
        })}
      >
        {(formProps) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = formProps;

          return (
            <form onSubmit={handleSubmit}>
              <div className="field">
                <label className="label" htmlFor="input-clinicName">
                  Clinic Name
                </label>
                <input
                  id="input-clinicName"
                  name="clinicName"
                  className={errors.clinicName && touched.clinicName ? "input error" : "input"}
                  type="text"
                  placeholder="Enter clinic name"
                  value={values.clinicName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.clinicName && touched.clinicName && (
                  <div className="input-feedback" data-testid="errors-clinicName">
                    {errors.clinicName}
                  </div>
                )}
              </div>

              <div className="field">
                <label className="label" htmlFor="input-clinicCode">
                  Clinic Code <span className="is-size-7">(Cannot be changed later.)</span>
                </label>
                <input
                  id="input-clinicCode"
                  name="clinicCode"
                  className={errors.clinicCode && touched.clinicCode ? "input error" : "input"}
                  type="text"
                  placeholder="Enter a clinic code"
                  value={values.clinicCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.clinicCode && touched.clinicCode && (
                  <div className="input-feedback" data-testid="errors-clinicCode">
                    {errors.clinicCode}
                  </div>
                )}
              </div>

              <div className="field">
                <label className="label" htmlFor="input-accessCode">
                  Access Code
                </label>
                <input
                  id="input-accessCode"
                  name="accessCode"
                  className={errors.accessCode && touched.accessCode ? "input error" : "input"}
                  type="text"
                  placeholder="Enter an 8+ digit access code"
                  value={values.accessCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.accessCode && touched.accessCode && (
                  <div className="input-feedback" data-testid="errors-accessCode">
                    {errors.accessCode}
                  </div>
                )}
              </div>

              <input
                type="submit"
                className="button is-primary mt-4 is-pulled-right"
                value="Submit"
                disabled={isSubmitting}
              />
              {loading ? <ButtonLoading /> : null}

              <button
                className="button mt-4"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(ROUTES.CLINICS_ADMIN);
                }}
              >
                Cancel
              </button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NewClinic;
