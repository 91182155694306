import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import SignOutButton from './auth/SignOutButton';

import { AUTH_USER_QUERY } from '../constants/gql';
import * as ROUTES from '../constants/routes';
import * as ROLES from '../constants/roles';

const SideNavigation = () => {
  // Get authUser
  const { data } = useQuery(AUTH_USER_QUERY);
  const { authUser } = data;

  return (authUser ?
    <div className="column is-one-quarter is-one-fifth-desktop side-nav">
      <div className="p-4" >
      <h1 className="title is-size-2 has-text-centered">Radeas Labs</h1>
      <h4 className="subtitle is-size-6 has-text-centered is">LAB INFORMATION SYSTEM</h4>
      <div className="ml-6">
        <div className="my-4">
          <span className="mr-2 icon has-text-white is-small"><i className="far fa-chart-bar" aria-hidden="true" /></span>
          <span className="title is-6">Analyst Portal</span>
          {/*<span className="icon is-small is-pulled-right"><i className="fas fa-angle-up" aria-hidden="true" /></span>*/}

          <div className="ml-5">
            <p className="my-1 ml-1">
              <Link to={ROUTES.DASHBOARD_ANALYST_NEW_ORDERS} className="side-nav-sub-link">Pending Orders</Link>
            </p>
            <p className="my-1 ml-1">
              <Link to={ROUTES.DASHBOARD_ANALYST_REVIEW_ORDERS} className="side-nav-sub-link">Review Orders</Link>
            </p>
            <p className="my-1 ml-1">
              <Link to={ROUTES.DASHBOARD_ANALYST_COMPLETED_ORDERS} className="side-nav-sub-link">Completed Orders</Link>
            </p>
          </div>
        </div>

        <hr className="has-background-white" />

        <div className="my-4">
          <span className="mr-2 icon has-text-white is-small"><i className="fas fa-flask" aria-hidden="true" /></span>
          <span className="title is-6">Lab Portal</span>
          {/*<span className="icon is-small is-pulled-right"><i className="fas fa-angle-up" aria-hidden="true" /></span>*/}

          <div className="ml-5">
            <p className="my-1 ml-1">
              <Link to={ROUTES.DASHBOARD_LAB_IN_TRANSIT} className="side-nav-sub-link">In-Transit</Link>
            </p>
            <p className="my-1 ml-1">
              <Link to={ROUTES.DASHBOARD_LAB_SCANNED} className="side-nav-sub-link">Received</Link>
            </p>
            <p className="my-1 ml-1">
              <Link to={ROUTES.DASHBOARD_LAB_BATCHES} className="side-nav-sub-link">Batches</Link>
            </p>
            <p className="my-1 ml-1">
              <Link to={ROUTES.DASHBOARD_LAB_COMPLETE} className="side-nav-sub-link">Complete</Link>
            </p>
            <p className="my-1 ml-1">
              <Link to={ROUTES.DASHBOARD_LAB_RERUN} className="side-nav-sub-link">Re-Run Requests</Link>
            </p>
          </div>
        </div>

        <hr className="has-background-white" />

        <div className="my-4">
          <span className="mr-2 icon has-text-white is-small"><i className="fas fa-vial" /></span>
          <span className="title is-6">
            <Link to={ROUTES.DASHBOARD_COVID_RESULTS} className="side-nav-link">COVID Results</Link>
          </span>
        </div>

        <hr className="has-background-white" />

        {!!authUser.roles.includes(ROLES.RADEAS_ADMIN) && (
          <>
            <div className="my-4">
              <span className="mr-2 icon has-text-white is-small"><i className="fas fa-tools" aria-hidden="true" /></span>
              <span className="title is-6">Entity Management</span>
              {/*<span className="icon is-small is-pulled-right"><i className="fas fa-angle-up" aria-hidden="true" /></span>*/}

              <div className="ml-5">
                <p className="my-1 ml-1">
                  <Link to={ROUTES.RADEAS_ADMIN} className="side-nav-sub-link">Radeas</Link>
                </p>
                <p className="my-1 ml-1">
                  <Link to={ROUTES.CLINICS_ADMIN} className="side-nav-sub-link">Clinics</Link>
                </p>
                <p className="my-1 ml-1">
                  <Link to={ROUTES.RADEAS_PATIENT_CREATE} className="side-nav-sub-link">Create Patient</Link>
                </p>
                <p className="my-1 ml-1">
                  <Link to={ROUTES.RADEAS_TOX_ORDER_CREATE} className="side-nav-sub-link">Create New Tox Order</Link>
                </p>
                <p className="my-1 ml-1">
                  <Link to={ROUTES.DASHBOARD_TEST_MACHINES} className="side-nav-sub-link">Tox Machines</Link>
                </p>
                <p className="my-1 ml-1">
                  <Link to={ROUTES.TEST_MENU} className="side-nav-sub-link">Test Menu</Link>
                </p>
              </div>
            </div>

            <hr className="has-background-white" />
          </>
        )}

        <div className="my-4">
          <span className="mr-2 icon has-text-white is-small"><i className="fas fa-user-circle" /></span>
          <span className="title is-6">
            <Link to={ROUTES.ACCOUNT} className="has-text-white">Account Management</Link>
          </span>
        </div>

        <div className="mt-6">
          <SignOutButton/>
        </div>
      </div></div></div>
    : null
  );
}

export default SideNavigation;
