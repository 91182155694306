import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import { ButtonLoading } from '../Loading';

import 'react-datepicker/dist/react-datepicker.css';

const SingleCovidResult = (props) => {
  const [values, setValues] = useState({
    test: "",
    result: "",
    specimenType: "",
    variant: "",

  });
  const [batchDate, setBatchDate] = useState(new Date());
  const [formattedBatchDate, setFormattedBatchDate] = useState(format(new Date(), "MM/dd/yyyy"));
  const [errors, setErrors] = useState({});

  const history = useHistory();

  const possibleTests = {
    'Covid-19-PCR Test': ['Not Detected', 'Detected', 'Inconclusive', 'Rejected'],
    'Covid-19-Antibody Rapid Test': ['IgG NEG', 'IgM NEG', 'IgG/IgM NEG',
                                     'IgG POS', 'IgM POS', 'IgG/IgM POS',
                                     'Inconclusive', 'Rejected'],
    'Covid-19/FLU Test': [
        'Covid-19 Not Detected/ Influenza A Not Detected/ Influenza B Not Detected',
        'Covid-19 Not Detected/ Influenza A Detected/ Influenza B Detected',
        'Covid-19 Not Detected/ Influenza A Not Detected/ Influenza B Detected',
        'Covid-19 Not Detected/ Influenza A Detected/ Influenza B Not Detected',
        'Covid-19 Detected/ Influenza A Not Detected/ Influenza B Not Detected',
        'Covid-19 Detected/ Influenza A Detected/ Influenza B Detected',
        'Covid-19 Detected/ Influenza A Not Detected/ Influenza B Detected',
        'Covid-19 Detected/ Influenza A Detected/ Influenza B Not Detected',
        'Inconclusive', 'Rejected'],

    'CFAB': [
        'Covid-19 Not Detected/ Influenza A Not Detected/ Influenza B Not Detected',
        'Covid-19 Not Detected/ Influenza A Detected/ Influenza B Detected',
        'Covid-19 Not Detected/ Influenza A Not Detected/ Influenza B Detected',
        'Covid-19 Not Detected/ Influenza A Detected/ Influenza B Not Detected',
        'Covid-19 Detected/ Influenza A Not Detected/ Influenza B Not Detected',
        'Covid-19 Detected/ Influenza A Detected/ Influenza B Detected',
        'Covid-19 Detected/ Influenza A Not Detected/ Influenza B Detected',
        'Covid-19 Detected/ Influenza A Detected/ Influenza B Not Detected',
        'Covid-19 Rejected/ Influenza A Rejected/ Influenza B Rejected',
        'Covid-19 Not Detected/ Influenza A Inconclusive/ Influenza B Inconclusive',
        'Covid-19 Detected/ Influenza A Inconclusive/ Influenza B Inconclusive',
        'Covid-19 Inconclusive/ Influenza A Inconclusive, Influenza B Inconclusive',
        'Covid-19 Inconclusive/ Influenza A Detected, Influenza B Not Detected',
        'Covid-19 Inconclusive/ Influenza A Not Detected, Influenza B Not Detected',
        'Covid-19 Inconclusive/ Influenza A Not Detected, Influenza B Detected',
        'Covid-19 Inconclusive/ Influenza A Detected, Influenza B Detected',
    ],
    'NAAT': ['Detected', 'Not Detected', 'Inconclusive', 'Rejected'],
    'BinaxNOW Antigen Test': ['Detected', 'Not Detected', 'Inconclusive', 'Rejected'],
    'CareStart Antigen Test': ['Detected', 'Not Detected', 'Inconclusive', 'Rejected'],
    'Celltrion DiaTrust COVID-19 Ag Rapid Test': ['Detected', 'Not Detected', 'Inconclusive', 'Rejected'],
    'LumiraDx SARS-CoV-2 Ag Test': ['Detected', 'Not Detected', 'Inconclusive', 'Rejected']
  };

  const specimenTypes = ['Nasal swab', 'Saliva', 'Nasopharyngeal swab'];

  // console.log(Object.keys(possibleTests));
  // console.log(testTypes);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleBatchDateChange = (date) => {
    if (!date) {
      return;
    }

    const formattedDate = format(date, "MM/dd/yyyy");

    setBatchDate(date);
    setFormattedBatchDate(formattedDate);
  };

  const validate = () => {
    const updatedErrors = { ...errors };

    Object.keys(values).map((name) => {
      if (name.toUpperCase() !== 'VARIANT' && values[name].length < 1) {
        updatedErrors[name] = 'Required';
      } else {
        updatedErrors[name] = undefined;

        delete updatedErrors[name];
      }
    });

    setErrors(updatedErrors);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    validate();

    const result = {
      test: values.test,
      test_type: values.specimenType,
      result: values.result,
      batch_date: formattedBatchDate,
    };

    if (values.variant.length > 0) {
      result['variant'] = values.variant;
    }

    if (Object.keys(errors).length === 0) {
      props.handleSubmit(result);
    }
  };

  return (
    <div className="columns">
      <div className="column is-half">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label className="label">
              Select Test
            </label>

            <div className="control">
              <div className="select is-fullwidth">
                <select
                  name="test"
                  onChange={handleChange}
                >
                  <option value=""></option>
                  {Object.keys(possibleTests).map((testName, i) => (
                    <option key={i} value={testName}>{testName}</option>
                  ))}
                </select>
              </div>
            </div>
            {errors.test && (
              <div className="input-feedback">{errors.test}</div>
            )}
          </div>

          <div className="field mt-6">
            <label className="label">
              Select Result
            </label>

            <div className="control">
              <div className="select is-fullwidth">
                <select
                  name="result"
                  onChange={handleChange}
                >
                  <option value=""></option>
                  {values.test && values.test.length > 0 && possibleTests[values.test].map((resultOption, i) => (
                    <option key={i} value={resultOption}>{resultOption}</option>
                  ))}
                </select>
              </div>
            </div>
            {errors.result && (
              <div className="input-feedback">{errors.result}</div>
            )}
          </div>

          <div className="field mt-6">
            <label className="label">
              Test Type
            </label>

            <div className="control">
              <div className="select is-fullwidth">
                <select
                  name="specimenType"
                  onChange={handleChange}
                >
                  <option value=""></option>
                  {specimenTypes.map((specimenOption, i) => (
                    <option key={i} value={specimenOption}>{specimenOption}</option>
                  ))}
                </select>
              </div>
            </div>
            {errors.specimenType && (
              <div className="input-feedback">{errors.specimenType}</div>
            )}
          </div>

          <div className="field mt-6">
            <label className="label">
              Variant
            </label>

            <div className="control">
              <input
                name="variant"
                className="input"
                type="text"
                value={values.variant ? values.variant : ""}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="field mt-6">
            <label className="label">
              Batch Date
            </label>

            <div className="control">
              <DatePicker
                className="input"
                selected={batchDate}
                onChange={handleBatchDateChange}
              />
            </div>
          </div>

          <div className="control mt-6">
            <button
              className="button is-info is-pulled-left"
              onClick={handleSubmit}
            >
              {props.submitting ? <ButtonLoading /> : "Submit"}
            </button>

            {/* <button */}
            {/*   className="button is-danger is-pulled-right" */}
            {/*   onClick={() => history.goBack()} */}
            {/* > */}
            {/*   Cancel */}
            {/* </button> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SingleCovidResult;
