import React, { useEffect, useRef, useState } from 'react';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { useHistory, useParams, Link } from 'react-router-dom';
import axios from 'axios';
import download from 'downloadjs';
import _ from 'lodash';
import dateFormat from 'dateformat';

import UpdateSpecimenValidationResultsModal from './UpdateSpecimenValidationResultsModal';
import RequestLabReRun from './RequestLabReRun';
import { createNotification } from '../Notification';
import { useAuth } from '../auth';
import { decodeJwt } from '../../utils';
import Loading, { ButtonLoading } from '../Loading';
import { SET_TOXICOLOGY_ORDER_STATUS } from './NewClinicOrdersList';
import { SET_TOXICOLOGY_ORDER_RESULTS, SET_ETG_RESULTS, SET_TEST_STRIPS_RESULTS } from './EnterToxicologyResults';
import { GET_DRUG_METABOLITES, GET_ANALYST_INSTRUCTIONS, SET_TOXICOLOGY_LAB_RESULTS } from '../../constants/gql';
import DrugReport from '../../drug-report';
import { TOXICOLOGY_REPORT_FLAGS } from '../../constants/flags';
import { filterTests } from '../../utils';
import { GET_RADEAS_USER_BY_ID } from '../../constants/gql';
import * as STATUS from '../../constants/status';
import * as ROUTES from '../../constants/routes';

// const REFRESH_DELAY = 1500;
const REFRESH_DELAY = 3000;

export const GET_TOXICOLOGY_REVIEW_ORDER = gql`
  query GetToxicologyOrder(
    $id: String!
  ) {
    getToxicologyOrder(
      id: $id
    ) {
      id
      clinicCode
      clinicName
      providerName
      providerSuffix
      providerNPI
      patientForeignKey
      patientId
      patientForeignKey
      patientFirstName
      patientLastName
      patientBirthMonth
      patientBirthDay
      patientBirthYear
      patientEmail
      patientPhone
      specimenId
      specimenType
      clinicalPurpose
      medicationListTags
      medicationConsistent
      medicationInconsistentNotPrescribed
      medicationInconsistentRxNotFound
      specimenPH
      specimenSpecificGravity
      specimenCreatinine
      createdDate
      resultsUploadedDate
      specimenPH
      specimenSpecificGravity
      specimenCreatinine
      specimenReceivedLabDate
      specimenRunDate
      labUploadedDate
      status
      prevStatus
      radeasAnalyst0
      radeasAnalyst0Notes
      urineValidationReporting
      toxicologyTests {
        drugName
        drugClass
        drugBrand
        pos
        neg
        scrn
        result
        cutoff
        c_i
        flag

        isActive
        parents
        scrnAvailable
        cutoffs {
          urineDefault
          urineCustom
          oralFluidDefault
          oralFluidCustom
        }
      }
      batches {
        id
        batchId
        testPanel
        machineName
        createdDate
        updatedDate
        isReRun
      }
      results {
        drugName
        drugClass
        drugBrand
        pos
        neg
        scrn
        result
        cutoff
        c_i
        flag
      }
      etgTests
      etgResults {
        drugName
        drugClass
        drugBrand
        result
        posNeg
        cutoff
        c_i
        flag
      }
      customTestResults {
        drugName
        drugClass
        drugBrand
        result
        posNeg
        cutoff
        c_i
        flag
      }
      testStripsResults {
        drugName
        drugClass
        drugBrand
        scrnAvailable
        isActive
        scrn
        cutoffs {
          urineDefault
          urineCustom
          oralFluidDefault
          oralFluidCustom
        }
        c_i
        flag
      }
      testStripsResultsReview {
        drugName
        drugClass
        drugBrand
        scrnAvailable
        isActive
        scrn
        cutoffs {
          urineDefault
          urineCustom
          oralFluidDefault
          oralFluidCustom
        }
        c_i
        flag
      }
      pocTests {
        code
        name
        result
      }
      resultsReview {
        drugName
        drugClass
        drugBrand
        pos
        neg
        scrn
        result
        cutoff
        c_i
        flag
      }
      etgResultsReview {
        drugName
        drugClass
        drugBrand
        result
        posNeg
        cutoff
        c_i
        flag
      }
      customTestResultsReview {
        drugName
        drugClass
        drugBrand
        result
        posNeg
        cutoff
        c_i
        flag
      }
      radeasAnalyst1Notes
      medicationInconsistentNotPrescribedReview
      medicationInconsistentRxNotFoundReview
      medicationConsistentReview
      additionalFlags
      ehrIntegrationData {
        id
        ormFilename
        receivingApplication
        receivingFacility
        sendingApplication
        sendingFacility
        specimenCollectionDate
        testPanelCode
        testPanelName
      }
    }
  }
`;

const ReviewToxicologyResults = () => {
  const { id } = useParams();
  const history = useHistory();

  const [authUser, setAuthUser] = useState('');
  const [drugReport, setDrugReport] = useState(null);
  const [metabolites, setMetabolites] = useState(null);
  const [orderDetails, setOrderDetails] = useState({});
  const [etgResults, setEtgResults] = useState([]);
  const [testStripsResults, setTestStripsResults] = useState([]);
  const [notes, setNotes] = useState('');
  const [showMedicationSection, setShowMedicationSection] = useState(true);
  const [medicationComparisonSummary, setMedicationComparisonSummary] = useState({});
  const [overrideMedicationComparisonSummary, setOverrideMedicationComparisonSummary] = useState(true);
  const [medicationList, setMedicationList] = useState('');
  const [filter, setFilter] = useState('');
  const [reRunRequested, setReRunRequested] = useState(false);
  const [customTestResults, setCustomTestResults] = useState([]);
  const [pocTests, setPocTests] = useState([]);
  const [additionalFlags, setAdditionalFlags] = useState([]);
  const [generatingReport, setGeneratingReport] = useState(false);
  const [firstAnalyst, setFirstAnalyst] = useState({});
  const [analystInstructions, setAnalystInstructions] = useState('');

  const [isV2, setIsV2] = useState(false);
  const [loading, setLoading] = useState(true);

  const [showSpecimenValidationModal, setShowSpecimenValidationModal] = useState(null);
  const [tempStorage, setTempStorage] = useState({});

  // Require auth
  const user = useAuth({ messageType: 'info', messageText: 'Please log in' });

  useEffect(() => {
    const { authUserId, roles } = decodeJwt(user);

    setAuthUser(authUserId);
  }, [user]);

  // TODO: page reload hack
  const [init, setInit] = useState(false);
  const [timeOut, setTimeOut] = useState(null);

  const filterRef = useRef(null);

  // Reload page if it doesn't load in 5 seconds
  // TODO: Fix this. This is a hack to fix the page not loading issue
  // that happens when someone navigates away from this page back to the
  // pending orders list, and then clicks on a new specimen to enter results.
  // Possible graphql cache issue?
  useEffect(() => {
    // Set timeout of 5 seconds to force a page reload.
    // Gets canceled if "init" variable is set to true.
    const t = setTimeout(() => {
      window.location.reload();
    }, REFRESH_DELAY);

    setTimeOut(t);

    return () => {
      // Clear timeout interval on component unmount
      clearTimeout(timeOut);
    };
  }, []);

  // Cancel reload if page loads properly
  useEffect(() => {
    if (init) {
      clearTimeout(timeOut);
    }
  }, [init]);

  // Automatically download PDF report since Analyst doesn't want to click the button
  useEffect(() => {
    if (init && !loading) {
      generateReportPdfForReview();
    }
  }, [init, loading]);

  // Handle back button press
  // TODO: Intercepting back button press is unreliable. State is being set,
  //       but previous page isn't always getting it.
//   useEffect(() => {
//     window.onpopstate = (e) => {
//       setOrderStatus({ variables: {
//         id: orderDetails.id,
//         newStatus: STATUS.TOXICOLOGY_ANALYST_REVIEW,
//         prevStatus: orderDetails.status,
//       }});
//
//       history.push(ROUTES.DASHBOARD_ANALYST_REVIEW_ORDERS);
//     };
//   });

  // Focus cursor in drug filter input element
  useEffect(() => {
    filterRef.current && filterRef.current.focus();
  }, []);

  // Update drugReport on medication list change
  useEffect(() => {
    if (!_.isEmpty(orderDetails)){
      const drugReport = new DrugReport({
        metabolites,
        medicationList: medicationList.split(',').map((name) => name.trim()),
        toxicologyTests: filterTests(orderDetails.toxicologyTests),
      });

      setDrugReport(drugReport);
    }
  }, [medicationList]);

  useEffect(() => {
    if (medicationComparisonSummary.medicationConsistent?.length > 0 ||
        medicationComparisonSummary.medicationInconsistentNotPrescribed?.length > 0 ||
        medicationComparisonSummary.medicationInconsistentRxNotFound?.length > 0
       ) {
      setShowMedicationSection(true);
    }

  }, [medicationComparisonSummary]);

  // Check if v2
  useEffect(() => {
    if (!isV2) {
      if (orderDetails.toxicologyTests && orderDetails.toxicologyTests.length > 0 && 'isActive' in orderDetails.toxicologyTests[0] && !_.isNull(orderDetails.toxicologyTests[0].isActive)) {
        setIsV2(true);
      }
    }
  }, [orderDetails]);

  useEffect(() => {
    if (isV2) {
      console.log('useEffect() isV2');
      const tests = orderDetails.toxicologyTests.map((test) => ({
        ...test,
        c_i: "C",
        flag: "",
        pos: true,
        result: "ND",
        cutoff: getCutoff(test.cutoffs, orderDetails.specimenType),
      }));

      // const etg = tests
      //   .filter(({ drugClass }) => drugClass.toUpperCase().includes('ALCOHOL'))
      //   .map((test) => ({
      //     ...test,
      //     posNeg: "NEG",
      //   }));

      setOrderDetails({
        ...orderDetails,
        toxicologyTests: tests.filter(({ drugClass }) => !drugClass.toUpperCase().includes('ALCOHOL')),
      });
      // setEtgResults(etg);
    }
  }, [isV2]);

  // Handle test strips C/I and flags
  const testStripsWithFlags = ['BARBITURATES', ];
  useEffect(() => {
    // Add C/I and flag if test strip uses flags
    if (testStripsResults.length > 0 ) {
      const updated = testStripsResults.map((test) => {
        if (testStripsWithFlags.includes(test.drugName.toUpperCase())) {
          if (_.isNull(test.c_i) && _.isNull(test.flag)) {
            return {
              ...test,
              c_i: 'C',
              flag: '',
            };
          }
        }

        return test;
      });

      // Prevent an infinite loop
      if (!_.isEqual(testStripsResults, updated)) {
        setTestStripsResults(updated);
      }
    }
  }, [testStripsResults]);

  // Get the order details
  useQuery(
    GET_TOXICOLOGY_REVIEW_ORDER,
    {
      // fetchPolicy: 'network-only',
      fetchPolicy: 'no-cache',
      variables: { id },
      onCompleted({ getToxicologyOrder }) {
        if (process.env.NODE_ENV === 'development') {
          console.log('getToxicologyOrder:', getToxicologyOrder);
        }

        // Get analyst instructions for clinic
        getAnalystInstructions({ variables: { clinicCode: getToxicologyOrder.clinicCode } });

        // Get Analyst 0 details
        getRadeasUserById({ variables: { id: getToxicologyOrder.radeasAnalyst0 } });

        // Merge saved toxicologyTests
        if (getToxicologyOrder.resultsReview.length > 0) {
          const tox = _.unionBy(getToxicologyOrder.resultsReview, getToxicologyOrder.results, 'drugName');

          const details = _.cloneDeep(getToxicologyOrder);
          details.results = tox;

          setOrderDetails(details);
        } else {
          setOrderDetails(getToxicologyOrder);
        }

        // Merge saved test strips results
        if (getToxicologyOrder.testStripsResultsReview.length > 0) {
          const strips = _.unionBy(getToxicologyOrder.testStripsResultsReview, getToxicologyOrder.testStripsResults, 'drugName');

          setTestStripsResults(strips);
        } else {
          setTestStripsResults(getToxicologyOrder.testStripsResults);
        }

        // Check for saved notes
        if (getToxicologyOrder.radeasAnalyst1Notes && getToxicologyOrder.radeasAnalyst1Notes.length > 0) {
          setNotes(getToxicologyOrder.radeasAnalyst1Notes);
        }

        // Process EtG if necessary
        let etg = [];

        if ('etgResults' in getToxicologyOrder) {
          // Check if result is null, replace with "ND"
          etg = _.cloneDeep(getToxicologyOrder.etgResults).map((row) => {
            if (row.result == null) {
              row.result = 'ND';
            }

            if (row.c_i == null) {
              row.c_i = 'C';
            }

            if (!('posNeg' in row)) {
              row['posNeg'] = 'NEG';
            }

            return row;
          });
        }

        // Merge saved EtG tests
        if (getToxicologyOrder.etgResultsReview.length > 0) {
          const mergedEtg = _.unionBy(getToxicologyOrder.etgResultsReview, etg, 'drugName');

          setEtgResults(mergedEtg);
        } else {
          setEtgResults(etg);
        }

        // Process custom tests if necessary
        let custom = [];

        if ('customTestResults' in getToxicologyOrder) {
          if (getToxicologyOrder.customTestResults.length > 0) {
            custom = getToxicologyOrder.customTestResults;
          }
        }

        // Merge saved custom test results
        if (getToxicologyOrder.customTestResultsReview.length > 0) {
          const mergedCustomTests = _.unionBy(getToxicologyOrder.customTestResultsReview, custom, 'drugName');

          setCustomTestResults(mergedCustomTests);
        } else {
          setCustomTestResults(custom);
        }

        // Process POC tests (show positive results only)
        if ('pocTests' in getToxicologyOrder) {
          if (getToxicologyOrder.pocTests.length > 0) {
            const posPocTests = getToxicologyOrder.pocTests.filter((test) => (
              test.result.toUpperCase() === 'POS'
            ));

            setPocTests(posPocTests);
          }
        }

        // Process medication list if necessary
        if ('medicationListTags' in getToxicologyOrder) {
          const meds = getToxicologyOrder.medicationListTags.join(', ');

          setMedicationList(meds);

          if (meds.length > 0) {
            setShowMedicationSection(true);
          } else {
            setShowMedicationSection(false);
          }
        }

        setMedicationComparisonSummary({
          medicationConsistent: getToxicologyOrder.medicationConsistentReview && getToxicologyOrder.medicationConsistentReview.length > 0 ? getToxicologyOrder.medicationConsistentReview.join(', ') : getToxicologyOrder.medicationConsistent.join(', '),
          medicationInconsistentNotPrescribed: getToxicologyOrder.medicationInconsistentNotPrescribedReview && getToxicologyOrder.medicationInconsistentNotPrescribedReview.length > 0 ? getToxicologyOrder.medicationInconsistentNotPrescribedReview.join(', ') : getToxicologyOrder.medicationInconsistentNotPrescribed.join(', '),
          medicationInconsistentRxNotFound: getToxicologyOrder.medicationInconsistentRxNotFoundReview && getToxicologyOrder.medicationInconsistentRxNotFoundReview.length > 0 ? getToxicologyOrder.medicationInconsistentRxNotFoundReview.join(', ') : getToxicologyOrder.medicationInconsistentRxNotFound.join(', '),
        });

        if ('additionalFlags' in getToxicologyOrder) {
          setAdditionalFlags(getToxicologyOrder.additionalFlags);
        }

        getDrugMetabolitesQuery();
      },
      onError(error) {
        console.log('Enter toxicology results error:', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      }
    }
  );

  // Clinic Analyst special instructions
  const [getAnalystInstructions, { loading: loadingAnalystInstructions }] = useLazyQuery(
    GET_ANALYST_INSTRUCTIONS,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ toxicologyTestProfileByClinicCode: instructions }) {
        if (process.env.NODE_ENV === 'development') console.log('Analyst Instructions:', instructions);

        setAnalystInstructions(instructions.analystInstructions);
      },
      onError(error) {
        console.log('ClinicPage query error:', error);
        createNotification('danger', 'Sorry, could not get clinic-specific analyst instructions.');
      }
    }
  );

  // Get Radeas user by ID
  const [getRadeasUserById] = useLazyQuery(
    GET_RADEAS_USER_BY_ID,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ getRadeasUserById }) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Radeas Analyst 0:', getRadeasUserById);
        }

        setFirstAnalyst(getRadeasUserById);
      },
      onError(error) {
        console.log('Get Radeas User By ID query:', error);
        createNotification('danger', 'Sorry, could not get first Analyst\'s info.');
      },
    },
  );

  // Get drug metabolites list
  const [getDrugMetabolitesQuery] = useLazyQuery(
    GET_DRUG_METABOLITES,
    {
      fetchPolicy: 'no-cache',
      onCompleted({ drugMetabolites }) {
        setMetabolites(drugMetabolites);

        const drugReport = new DrugReport({
          drugMetabolites,
          // medicationList: orderDetails.medicationList && DrugReport.splitDrugList(orderDetails.medicationList),
          medicationList: orderDetails.medicationListTags,
          toxicologyTests: filterTests(orderDetails.toxicologyTests),
        });

        setDrugReport(drugReport);

        // Activate all tests and child metabolites
        const order = _.cloneDeep(orderDetails);
        const tests = [];
        order.toxicologyTests.forEach((test) => {
          if (drugReport.allTestsAndMetabolites.includes(test.drugName.toUpperCase())) {
            tests.push({
              ...test,
              pos: true,
            });
          } else {
            tests.push({ ...test });
          }
        });

        order.toxicologyTests = tests;
        setOrderDetails(order);

        setInit(true);
        setLoading(false);

        // NOTE: moved to useEffect()
        // Automatically download PDF report since Analyst doesn't want to click the button
        // generateReportPdfForReview();
      },
      onError(error) {
        console.log('Get drug metabolites:', error);
        createNotification('danger', 'Sorry, could not retrieve drug metabolites.');
      }
    }
  );

  // Save Results
  const [saveOrderResults, { loading: savingTox }] = useMutation(
    SET_TOXICOLOGY_ORDER_RESULTS,
    {
      onCompleted({ setToxicologyOrderResults }) {
        setOrderStatus({ variables: {
          id: orderDetails.id,
          newStatus: STATUS.TOXICOLOGY_ANALYST_COMPLETE,
          prevStatus: orderDetails.status,
        }});

        createNotification('info', 'Results saved');
        history.push(ROUTES.DASHBOARD_ANALYST_REVIEW_ORDERS, { id, status: STATUS.TOXICOLOGY_ANALYST_COMPLETE });
      },
      onError(error) {
        // TODO: review this. Probably don't need to change order status
        // since error doesn't navigate away from this page.
        // setOrderStatus({ variables: {
        //   id: orderDetails.id,
        //   newStatus: STATUS.TOXICOLOGY_ANALYST_REVIEW,
        //   prevStatus: orderDetails.status,
        // }});

        console.log(error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    }
  );

  // Save partial results
  const [savePartialOrderResults, { loading: savingPartial }] = useMutation(
    SET_TOXICOLOGY_ORDER_RESULTS,
    {
      onCompleted({ setToxicologyOrderResults }) {
        // setOrderStatus({ variables: {
        //     id: orderDetails.id,
        //     newStatus: STATUS.TOXICOLOGY_ANALYST_REVIEW,
        //     prevStatus: orderDetails.status,
        //   }});

        createNotification('info', 'Results saved');
        // history.push(ROUTES.DASHBOARD_ANALYST_NEW_ORDERS);
      },
      onError(error) {
        // setOrderStatus({ variables: {
        //     id: orderDetails.id,
        //     newStatus: orderDetails.prevStatus,
        //     prevStatus: orderDetails.status,
        //   }});

        console.log(error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    }
  );

  // Save EtG/EtS results
  const [saveEtgResults, { loading: savingEtg }] = useMutation(
    SET_ETG_RESULTS,
    {
      onCompleted({ setEtgResult }) {
        if (process.env.NODE_ENV === 'development')
          console.log('setEtgResult:', setEtgResult);
      },
      onError(error) {
        console.log(error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    }
  );

  // Save test strips results
  const [saveTestStripsResults, { loading: savingTestStrips }] = useMutation(
    SET_TEST_STRIPS_RESULTS,
    {
      onCompleted({ setTestStripsResults }) {
        if (process.env.NODE_ENV === 'development')
          console.log('setTestStripsResults:', setTestStripsResults);
      },
      onError(error) {
        console.log(error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    }
  );

  // Update specimen validation results
  const [setSpecimenValidation, { loading: savingValidation }] = useMutation(
    SET_TOXICOLOGY_LAB_RESULTS,
    {
      onCompleted({ setToxicologyLabResults }) {
        if (process.env.NODE_ENV === 'development') console.log('Set Validation:', setToxicologyLabResults);

        setTempStorage({});

        createNotification('info', 'Specimen validation results updated.');
        // handleCloseSpecimenValidationModal();
      },
      onError(error) {
        console.log('setToxicologyLabResults', error);

        setOrderDetails({
          ...orderDetails,
          specimenPH: tempStorage.specimenPH,
          specimenCreatinine: tempStorage.specimenCreatinine,
          specimenSpecificGravity: tempStorage.specimenSpecificGravity,
        });
        setTempStorage({});

        createNotification('danger', 'Sorry, could not update specimen validation values.');
        // handleCloseSpecimenValidationModal();
      },
    }
  );

  // Set order status
  const [setOrderStatus] = useMutation(SET_TOXICOLOGY_ORDER_STATUS);

  const getCutoff = (cutoffs, specimenType) => {
    switch (specimenType.toUpperCase()) {
      case 'URINE':
        return cutoffs.urineCustom ? `${cutoffs.urineCustom}` : `${cutoffs.urineDefault}`;
      case 'ORAL FLUID':
        return cutoffs.oralFluidCustom ? `${cutoffs.oralFluidCustom}` : `${cutoffs.oralFluidDefault}`;
      default:
        return cutoffs.urineCustom ? `${cutoffs.urineCustom}` : `${cutoffs.urineDefault}`;
    }
  };

  const generateTable = () => {
    if (!orderDetails.results) return null;

    // Only display selected tests and their metabolites
    const tests = filterTests(orderDetails.results);

    if (filter === '') {
      return generateRows(tests);
    } else {
      // Filter the results
      const filtered = tests.filter((test, i) => {
        // Skip "All" and "Urine Validation"
        if (test.drugName.includes('All ') || test.drugName.includes('Urine Validation Testing')) {
          return false;
        }

        return (
          test.drugName.toLowerCase().includes(filter.toLowerCase())
        );
      });

      return generateRows(filtered);
    }
  };

  const handleAutoGeneratedMedicationComparisonSummary = (summary) => {
    const keys = Object.keys(summary);

    let update = false;

    keys.forEach((key) => {
      if (summary[key].join(', ').toUpperCase() !== medicationComparisonSummary[key].toUpperCase()) {
        update = true;
      }
    });

    if (update) {
      setMedicationComparisonSummary({
        medicationConsistent: summary['medicationConsistent'].join(', '),
        medicationInconsistentNotPrescribed: summary['medicationInconsistentNotPrescribed'].join(', '),
        medicationInconsistentRxNotFound: summary['medicationInconsistentRxNotFound'].join(', '),
      });
    }
  };

  const generateRows = (rows) => {
    return (
      rows.filter((test) => (
        !(test.drugName === 'All (G0483; 80307)' || test.drugName === 'Urine Validation Testing')
      )).map((test, i) => {
        const result = drugReport.evaluate({
          drugName: test.drugName,
          cutoff: test.cutoff,
          value: test.result,
        });

        if (test.c_i !== result.cI) {
          mergeResults(test, {
            c_i: result.cI,
            flag: result.flags.join(', '),
          });
        }

        // Handle medication comparison summary
        // handleAutoGeneratedMedicationComparisonSummary(result.medicationComparisonSummary);

        return (
          <tr key={i} className={test.result !== "ND" ? "has-text-danger" : null}>
            <td><span className="has-text-weight-semibold">{test.drugName}</span></td>
            <td>{test.drugBrand}</td>
            <td>
              {test.scrn === '+' || test.scrn === '-' ?
                (<React.Fragment>
                    <input
                      name="scrn"
                      type="checkbox"
                      checked={test.scrn === '+'}
                      onChange={() => handleScrn(test)}
                    />
                    <span className="ml-2">{test.scrn}</span>
                  </React.Fragment>
                )
                :
                null}
            </td>
            <td>{test.cutoff}</td>
            <td>{result.posNeg}</td>
            <td>{result.cI}</td>
            <td>
              <input
                name="result"
                type="text"
                className="input"
                value={test.result}
                onChange={(e) => handleChange(e, test)}
                onFocus={selectAll}
              />
            </td>
            <td>
              {/* <input */}
              {/*   name="flag" */}
              {/*   type="text" */}
              {/*   className="input" */}
              {/*   value={result.flags.join(', ')} */}
              {/*   readOnly */}
              {/* /> */}
              <div className="select">
                <select
                  style={{ width: '180px' }}
                  onChange={(e) => handleSelect(e, test)}
                  value={test.flag}
                >
                  <option value="" />

                  {TOXICOLOGY_REPORT_FLAGS.map((flag) => (
                    <option
                      key={flag}
                      value={flag}
                      selected={flag === test.flag}
                    >
                      {flag}
                    </option>
                  ))}
                </select>
              </div>
            </td>
          </tr>
        );
      })
    );
  };

  const generateTestStrips = () => {
    if (orderDetails.testStripsResults?.length === 0)
      return;

    return (
      <>
        <br /><br />
        <hr />

        <h4 className="title is-4">Test Strips</h4>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Drug Name</th>
              <th>Drug Class</th>
              <th>Drug Brand</th>
              <th>Scrn</th>
              <th>Cutoff (ng/ml)</th>
              <th>C/I</th>
              <th>Flag</th>
            </tr>
            </thead>
            <tbody>
              {generateTestStripsRows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const generateTestStripsRows = () => {
    return (
      testStripsResults.map((test, i) => {
        const cutoff = test.cutoffs.urineCustom ? test.cutoffs.urineCustom : test.cutoffs.urineDefault;

        return (
          <tr key={i}>
            <td><span className="has-text-weight-semibold">{test.drugName}</span></td>
            <td>{test.drugClass}</td>
            <td>{test.drugBrand}</td>
            <td>
              <input
                name="scrn"
                type="checkbox"
                checked={test.scrn}
                onChange={() => handleTestStrip(i, test)}
              />
              <span className="ml-2">{test.scrn}</span>
            </td>
            <td>{cutoff}</td>
            <td>{test.c_i ? test.c_i : ""}</td>
            <td>
              {!_.isNull(test.flag) ? (
                <div className="select">
                  <select
                    style={{ width: '180px' }}
                    onChange={(e) => handleTestStripsFlag(e, test)}
                    value={test.flag}
                  >
                    <option value="" />

                    {TOXICOLOGY_REPORT_FLAGS.map((flag) => (
                      <option key={flag} value={flag}>
                        {flag}
                      </option>
                    ))}
                  </select>
                </div>
              ) : ("")}
            </td>
          </tr>
        );
      })
    );
  };

  const handleTestStrip = (i, test) => {
    const updated = _.cloneDeep(testStripsResults);

    updated[i] = {
      ...updated[i],
      scrn: !updated[i].scrn,
    };

    // Update medication comparison summary if needed
    if (testStripsWithFlags.includes(test.drugName.toUpperCase())) {
      // Regenerate medication comparison summary
      const result = drugReport.evaluate({
        drugName: test.drugName,
        cutoff: '5',                            // Dummy cutoff
        value: updated[i].scrn ? '100' : '0',   // Dummy value (screen is boolean)
      });

      // Merge results
      updated[i] = {
        ...updated[i],
        c_i: result.cI,
        flag: result.flags[0] ? result.flags[0] : "",
      };

      // Handle medication comparison summary
      handleAutoGeneratedMedicationComparisonSummary(result.medicationComparisonSummary);
    }

    setTestStripsResults(updated);
  };

  const generateEtg = () => {
    if (orderDetails.etgTests?.length === 0)
      return;

    return (
      <>
        <br /><br />
        <hr />

        <h4 className="title is-4">Alcohol(s)</h4>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Test Performed</th>
              <th>Name</th>
              <th>Cutoff (ng/ml)</th>
              <th>POS/NEG</th>
              <th>C/I</th>
              <th>Result</th>
              <th>Flag</th>
            </tr>
            </thead>
            <tbody>
              {generateEtgRows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const generateEtgRows = () => {
    return (
      etgResults.map((row, i) => (
        <tr key={i}>
          <td>{row.drugName}</td>
          <td>{row.drugBrand}</td>
          <td>{row.cutoff}</td>
          <td>{row.posNeg}</td>
          <td>{row.c_i}</td>
          <td>
            <input
              name="result"
              type="text"
              className="input"
              value={row.result}
              // onChange={(e) => checkEtgResult(e, i)}
              onChange={(e) => handleChange(e, row)}
              onFocus={selectAll}
            />
          </td>
          <td>
            <div className="select">
              <select
                style={{ width: '180px' }}
                onChange={(e) => handleEtgFlagSelect(e, i)}
                value={row.flag}
              >
                <option value="" />

                {TOXICOLOGY_REPORT_FLAGS.map((flag) => (
                  <option
                    key={flag}
                    value={flag}
                    selected={flag === row.flag}
                  >
                    {flag}
                  </option>
                ))}
              </select>
            </div>
          </td>
        </tr>
      ))
    );
  };

  const checkEtgResult = (e, index) => {
    let result;

    const etg = _.cloneDeep(etgResults);
    etg[index].result = e.target.value;

    if (e.target.value.trim()[0] === '>') {
      result = parseFloat(e.target.value.trim().split('>')[1].trim());
    } else {
      result = parseFloat(e.target.value);
    }

    // Check if result is above cutoff
    let cutoff = parseFloat(etgResults[index].cutoff);

    if (_.isNaN(cutoff)) {
      cutoff = 500;

      etg[index].cutoff = cutoff.toString();
    }

    if (!_.isNaN(result)) {
      if (result >= cutoff) {
        etg[index].posNeg = 'POS';
        etg[index].c_i = 'I';
      } else {
        etg[index].posNeg = 'NEG';
        etg[index].c_i = 'C';
      }
    }

    setEtgResults(etg);
  };

  const handleEtgFlagSelect = (e, index) => {
    const etg = _.cloneDeep(etgResults);
    etg[index].flag = e.target.value;

    setEtgResults(etg);
  };

  const generateCustomTests = () => {
    if (customTestResults.length === 0)
      return;

    return (
      <>
        <br /><br />
        <hr />

        <h4 className="title is-4">Additional Tests</h4>

        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-striped is-hoverable">
            <thead>
            <tr>
              <th>Drug Name</th>
              <th>Drug Class</th>
              <th>Drug Brand</th>
              <th>Cutoff (ng/ml)</th>
              <th>POS/NEG</th>
              <th>C/I</th>
              <th>Result</th>
              <th>Flag</th>
            </tr>
            </thead>
            <tbody>
              {generateCustomTestRows()}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const generateCustomTestRows = () => {
    return (
      customTestResults.map((row, i) => (
        <tr key={i}>
          <td>
            <input
              name="drugName"
              type="text"
              className="input"
              value={row.drugName}
              onChange={(e) => handleCustomDrugChange(e, i)}
            />
          </td>
          <td>
            <input
              name="drugClass"
              type="text"
              className="input"
              value={row.drugClass}
              onChange={(e) => handleCustomDrugChange(e, i)}
            />
          </td>
          <td>
            <input
              name="drugBrand"
              type="text"
              className="input"
              value={row.drugBrand}
              onChange={(e) => handleCustomDrugChange(e, i)}
            />
          </td>
          <td>
            <input
              name="cutoff"
              type="text"
              className="input"
              value={row.cutoff}
              onChange={(e) => handleCustomDrugChange(e, i)}
            />
          </td>
          <td>
            <input
              name="posNeg"
              type="text"
              className="input"
              value={row.posNeg}
              onChange={(e) => handleCustomDrugChange(e, i)}
            />
          </td>
          <td>
            <input
              name="c_i"
              type="text"
              className="input"
              value={row.c_i}
              onChange={(e) => handleCustomDrugChange(e, i)}
            />
          </td>
          <td>
            <input
              name="result"
              type="text"
              className="input"
              value={row.result}
              onChange={(e) => handleCustomDrugChange(e, i)}
              onFocus={selectAll}
            />
          </td>
          <td>
            <div className="select">
              <select
                style={{ width: '180px' }}
                onChange={(e) => handleCustomDrugFlagSelect(e, i)}
                value={row.flag}
              >
                <option value="" />

                {TOXICOLOGY_REPORT_FLAGS.map((flag) => (
                  <option key={flag} value={flag}>
                    {flag}
                  </option>
                ))}
              </select>
            </div>
          </td>
        </tr>
      ))
    );
  };

  const handleCustomDrugChange = (e, i) => {
    const updated = _.cloneDeep(customTestResults);
    updated[i][e.target.name] = e.target.value;

    setCustomTestResults(updated);
  };

  const handleCustomDrugFlagSelect = (e, index) => {
    const updated = _.cloneDeep(customTestResults);
    updated[index].flag = e.target.value;

    setCustomTestResults(updated);
  };

  const handleSelect = (e, test) => {
    mergeResults(test, {
      flag: e.target.value,
    });
  };

  const handleTestStripsFlag = (e, test) => {
    const newTests = _.cloneDeep(testStripsResults);
    const index = _.findIndex(testStripsResults, { drugName: test.drugName });

    newTests.splice(index, 1, { ...test, flag: e.target.value });

    setTestStripsResults(newTests);
  };

  const generateMedicationList = () => {
    return (
      <input
        name="medicationListInput"
        type="text"
        className="input mt-2"
        value={medicationList}
        onChange={(e) => setMedicationList(e.target.value)}
      />
    );
  };

  const handleScrn = (test) => {
    if (test.scrn === '-' || test.scrn === null) {
      mergeResults(test, { scrn: '+' });
    } else {
      mergeResults(test, { scrn: '-' });
    }
  };

  const mergeResults = (test, result) => {
    const newToxicologyTests = _.cloneDeep(orderDetails.results);
    const newVal = _.cloneDeep(test);
    const index = _.findIndex(newToxicologyTests, { drugName: test.drugName });

    newToxicologyTests.splice(index, 1, { ...newVal, ...result });

    setOrderDetails({ ...orderDetails, results: newToxicologyTests });
  };

  const mergeEtgResults = (test, result) => {
    const newEtgTests = _.cloneDeep(etgResults);
    const newVal = _.cloneDeep(test);
    const index = _.findIndex(newEtgTests, { drugName: test.drugName });

    newEtgTests.splice(index, 1, { ...newVal, ...result });

    setEtgResults(newEtgTests);
  };

  const selectAll = (e) => {
    if (e.target.value.toUpperCase() === 'ND') {
      e.target.select();
    }
  };

  const handleChange = (e, test) => {
    // Evaluate
    const result = drugReport.evaluate({
      drugName: test.drugName,
      cutoff: test.cutoff,
      value: e.target.value,
    });

    // Handle medication comparison summary
    // handleAutoGeneratedMedicationComparisonSummary(result.medicationComparisonSummary);

    if (test.drugName.substring(0, 5).toUpperCase() === 'ETHYL') {
      mergeEtgResults(test, {
        c_i: result.cI,
        flag: result.flags.join(', '),
        posNeg: result.posNeg,
        result: e.target.value,
      });
    } else {
      mergeResults(test, {
        c_i: result.cI,
        flag: result.flags.join(', '),
        result: e.target.value,
      });
    }
  };

  const handleMedicationComparisonSummary = (e) => {
    const { name, value} = e.target;

    setMedicationComparisonSummary({
      ...medicationComparisonSummary,
      [name]: value,
    });
  };

  const handleAdditionalFlags = (e, idx) => {
    setAdditionalFlags(additionalFlags.map((row, i) => {
      if (i === idx) {
        return e.target.value;
      }

      return row;
    }));
  };

  const handleDeleteAdditionalFlag = (idx) => {
    setAdditionalFlags(additionalFlags.filter((_, i) => i !== idx));
  };

  const handleShowSpecimenValidationModal = (validation) => {
    setShowSpecimenValidationModal({ ...validation });
  };

  const handleCloseSpecimenValidationModal = () => {
    setShowSpecimenValidationModal(null);
  };

  const handleUpdateSpecimenValidationResults = ({ specimenPH, specimenSpecificGravity, specimenCreatinine }) => {
    setTempStorage({
      specimenPH: orderDetails.specimenPH,
      specimenSpecificGravity: orderDetails.specimenSpecificGravity,
      specimenCreatinine: orderDetails.specimenCreatinine,
    });

    setOrderDetails({
      ...orderDetails,
      specimenPH,
      specimenCreatinine,
      specimenSpecificGravity,
    });

    setSpecimenValidation({ variables: {
      isReRun: false,
      specimenId: orderDetails.specimenId,
      specimenPH,
      specimenSpecificGravity,
      specimenCreatinine,
    }});

    handleCloseSpecimenValidationModal();
  };

  const generateEtgVariables = (step) => {
    return {
      toxicologyOrderId: orderDetails.id,
      step,
      etgResults: etgResults.map(({
        drugName,
        drugClass,
        drugBrand,
        result,
        posNeg,
        cutoff,
        c_i,
        flag,
      }) => ({ drugName, drugClass, drugBrand, result, posNeg, cutoff, c_i, flag })),
    };
  };

  const generateTestStripsVariables = (step) => {
    return {
      toxicologyOrderId: orderDetails.id,
      step,
      testStripsResults: testStripsResults.map(({
        drugName,
        drugClass,
        drugBrand,
        cutoffs,
        scrn,
        c_i,
        flag,
      }) => ({ drugName, drugClass, drugBrand, scrn, c_i, flag,
        cutoffs: {
          urineDefault: cutoffs.urineDefault,
          urineCustom: cutoffs.urineCustom,
          oralFluidDefault: cutoffs.oralFluidDefault,
          oralFluidCustom: cutoffs.oralFluidCustom,
        },
      })),
    };
  };

  const generateToxVariables = (step) => {
    return {
      toxicologyOrderId: orderDetails.id,
      step,
      medicationConsistent: medicationComparisonSummary.medicationConsistent.split(',').map((m) => m.trim()),
      medicationInconsistentNotPrescribed: medicationComparisonSummary.medicationInconsistentNotPrescribed.split(',').map((m) => m.trim()),
      medicationInconsistentRxNotFound: medicationComparisonSummary.medicationInconsistentRxNotFound.split(',').map((m) => m.trim()),
      medicationListTags: medicationList.split(',').map((name) => name.trim()),
      medicationList: medicationList,
      notes,
      results: filterTests(orderDetails.results).map(({
        drugName,
        drugClass,
        drugBrand,
        pos,
        neg,
        scrn,
        result,
        cutoff,
        c_i,
        flag,
      }) => ({ drugName, drugClass, drugBrand, pos, neg, scrn, result: result.trim(), cutoff, c_i, flag })),
      customTestResults: customTestResults.map(({
        drugName,
        drugClass,
        drugBrand,
        result,
        posNeg,
        cutoff,
        c_i,
        flag,
      }) => ({ drugName, drugClass, drugBrand, result, posNeg, cutoff, c_i, flag })),
      additionalFlags: additionalFlags
        .filter((flag) => flag.trim().length > 0)
        .map((flag) => flag.trim()),
    };
  };

  const handleSavePartial = () => {
    // Save EtG results
    const etgVariables = generateEtgVariables('3');
    saveEtgResults({ variables: etgVariables });

    // Save test strips results
    const testStripsVariables = generateTestStripsVariables('3');
    saveTestStripsResults({ variables: testStripsVariables });

    // Save toxicology (pain) and custom tests
    const toxVariables = generateToxVariables('3');
    savePartialOrderResults({ variables: toxVariables });
  };

  const handleSubmit = () => {
    // Save EtG results
    const etgVariables = generateEtgVariables('1');
    saveEtgResults({ variables: etgVariables });

    // Save test strips results
    const testStripsVariables = generateTestStripsVariables('1');
    saveTestStripsResults({ variables: testStripsVariables });

    // Generate the report, save to Google Bucket
    generateReportPdf();

    // Save toxicology (pain) and custom tests
    const toxVariables = generateToxVariables('1');
    saveOrderResults({
      variables: {
        ...toxVariables,
        specimenId: orderDetails.specimenId,
      }
    });
    // saveOrderResults({ variables: { ...data } });
  };

//   const formatResult = (result) => {
//     if (!result) {
//       return result;
//     }
//
//     let r;
//
//     if (result.trim()[0] === '>') {
//       r = parseFloat(result.split('>')[1].trim());
//     } else {
//       r = parseFloat(result.trim());
//     }
//
//     if (r > 2000) {
//       return '> 2000';
//     } else if (r < 2) {
//       return '< 2';
//     }
//
//     return result.trim();
//   };

  const formatResult = (result, drugName, specimenType, panel='PAIN') => {
    if (drugName === 'Amphetamine') {
      console.log('formatResult() Amphetamine');
      console.log({ result, specimenType });
    }

    let r;
    const fent = ['FENTANYL', 'NORFENTANYL'];

    if (result.trim()[0] === '>') {
      r = parseFloat(result.split('>')[1].trim());
    } else {
      r = parseFloat(result.trim());
    }

    // Check EtG
    if (panel === 'ETG') {
      if (r >= 10000) {
        return '> 10000';
      }

      return result.trim();
    }

    // Urine
    if (specimenType.toUpperCase() === 'URINE') {
      if (drugName === 'Amphetamine') {
        console.log('URINE');
      }

      // Fentanyl/Norfentanyl
      if (fent.includes(drugName.toUpperCase())) {
        if (r > 200) {
          return '> 200';
        } else if (r < 0.2) {
          return '< 0.2';
        }
      }

      if (r > 2000) {
        return '> 2000';
      } else if (r < 2) {
        return '< 2';
      }

    // Oral Fluid
    } else if (specimenType.toUpperCase() === 'ORAL FLUID') {
      if (drugName === 'Amphetamine') {
        console.log('ORAL FLUID');
      }

      // Fentanyl/Norfentanyl
      if (fent.includes(drugName.toUpperCase())) {
        if (r > 600) {
          return '> 600';
        } else if (r < 0.6) {
          return '< 0.6';
        }
      }

      if (r > 6000) {
        return '> 6000';
      } else if (r < 6) {
        return '< 6';
      }
    }

    // if (r > 2000) {
    //   return '> 2000';
    // } else if (r < 2) {
    //   return '< 2';
    // }

    return result.trim();
  };

  const generateEhrIntegrationData = () => {
    if ('ehrIntegrationData' in orderDetails && orderDetails.ehrIntegrationData) {
      const {
        id,
        ormFilename,
        receivingApplication,
        receivingFacility,
        sendingApplication,
        sendingFacility,
        specimenCollectionDate,
        testPanelCode,
        testPanelName,
      } = orderDetails.ehrIntegrationData;

      return {
        id,
        ormFilename,
        receivingApplication,
        receivingFacility,
        sendingApplication,
        sendingFacility,
        specimenCollectionDate,
        testPanelCode,
        testPanelName,
        specimenReceivedLabDate: orderDetails.specimenReceivedLabDate,
      };
    }

    return {};
  };

  const generateReportPdf = async () => {
    let createdDate = new Date(Number(orderDetails.createdDate));
    createdDate = dateFormat(createdDate, "mm/dd/yyyy");

    let runDate = orderDetails.specimenRunDate ? new Date(orderDetails.specimenRunDate) : new Date(parseInt(orderDetails.labUploadedDate));
    runDate = dateFormat(runDate, "mm/dd/yyyy");

    // Generate EHR integration data so that the cloud function will
    // save in the staging bucket.
    const ehrIntegrationData = generateEhrIntegrationData();

    const data = {
      ehrIntegrationData,

      patientFirstName: orderDetails.patientFirstName && orderDetails.patientFirstName.trim(),
      patientLastName: orderDetails.patientLastName && orderDetails.patientLastName.trim(),
      patientId: orderDetails.patientId && orderDetails.patientId.trim().length > 0 ? orderDetails.patientId.trim() : 'Not Provided',
      patientBirthDate: `${orderDetails.patientBirthMonth}/${orderDetails.patientBirthDay}/${orderDetails.patientBirthYear}`,
      patientEmail: orderDetails.patientEmail && orderDetails.patientEmail.trim(),
      patientPhone: orderDetails.patientPhone && orderDetails.patientPhone.trim(),
      clinicCode: orderDetails.clinicCode && orderDetails.clinicCode.trim(),
      clinicName: orderDetails.clinicName && orderDetails.clinicName.trim(),
      providerName: orderDetails.providerName && orderDetails.providerName.trim(),
      providerNPI: orderDetails.providerNPI && orderDetails.providerNPI.trim(),
      sampleAcquisitionDate: createdDate,
      specimenId: orderDetails.specimenId,
      specimenType: orderDetails.specimenType[0].toUpperCase() + orderDetails.specimenType.substring(1),
      analystId1: orderDetails.radeasAnalyst0,
      analystId2: authUser,
      runDate: runDate,
      medicationList: orderDetails.medicationListTags,
      medicationComparisonSummary: {
        'Consistent': medicationComparisonSummary.medicationConsistent.split(',').map((m) => m.trim()),
        'Inconsistent-NotPrescribed': medicationComparisonSummary.medicationInconsistentNotPrescribed.split(',').map((m) => m.trim()),
        'Inconsistent-RxNotFound': medicationComparisonSummary.medicationInconsistentRxNotFound.split(',').map((m) => m.trim()),
      },
      toxicologyTestResults: filterTests(orderDetails.results)
        .filter(({ drugName }) => (drugName.includes('All ') || drugName.includes('Urine Validation Testing') ? false : true))
        .map(({
          drugName,
          drugClass,
          drugBrand,
          scrn,
          result,
          cutoff,
          c_i,
          flag,
        }) => ({
          drugName,
          drugClass,
          drugBrand,
          scrn,
          // result: formatResult(result, drugName, orderDetails.specimenType, 'PAIN'),
          result: result,
          cutoff,
          posNeg: drugReport.evaluate({ drugName, cutoff, value: result }).posNeg,
          cI: c_i,
          flag,
        })
      ),
    };

    // Add EtG to data if necessary
    if (orderDetails.etgTests.length > 0) {
      const results = etgResults.map(({
        drugName,
        drugClass,
        drugBrand,
        result,
        cutoff,
        posNeg,
        c_i,
        flag,
      }) => ({
        drugName,
        drugClass,
        drugBrand,
        // result: formatResult(result, drugName, orderDetails.specimenType, 'ETG'),
        result: result,
        cutoff,
        posNeg,
        cI: c_i,
        flag,
        scrn: null,
      }));

      data.toxicologyTestResults.push(...results);
    }

    // Add test strips if necessary
    if (testStripsResults.length > 0) {
      const strips = testStripsResults.map(({
        drugName,
        drugClass,
        drugBrand,
        scrn,
        cutoffs,
        c_i,
        flag,
      }) => {
        const cutoff = cutoffs.urineCustom ? cutoffs.urineCustom : cutoffs.urineDefault;

        return {
          drugName,
          drugClass,
          drugBrand,
          scrn: scrn ? '+' : '-',
          result: 'N/A',
          cutoff: `${cutoff}`,
          posNeg: scrn ? 'POS' : 'NEG',
          cI: c_i && c_i === 'I' ? 'I' : 'C',
          flag: flag && flag.length > 0 ? flag : '',
        };
      });

      data.toxicologyTestResults.push(...strips);
    }

    // Add custom tests if necessary
    if (customTestResults.length > 0) {
      const cResults = customTestResults.map(({
        drugName,
        drugClass,
        drugBrand,
        result,
        cutoff,
        posNeg,
        c_i,
        flag,
      }) => ({
        drugName,
        drugClass,
        drugBrand,
        // result: formatResult(result, drugName, orderDetails.specimenType),
        result: result,
        cutoff,
        posNeg,
        cI: c_i,
        flag,
        scrn: null,
      }));

      data.toxicologyTestResults.push(...cResults);
    }

    // Add additional flags if necessary
    if (additionalFlags.length > 0) {
      data.additionalFlags = additionalFlags;
    }

    // Add PH, specific gravity, and creatinine if urine validation test reporting is requested
    const urineValidation = orderDetails.toxicologyTests
      .filter(({ drugName }) => (drugName.includes('Urine Validation')));

    if ((urineValidation.length !== 0 && urineValidation[0].pos) || (orderDetails.urineValidationReporting)) {
      data['ph'] = orderDetails.specimenPH;
      data['sg'] = orderDetails.specimenSpecificGravity;
      data['cr'] = orderDetails.specimenCreatinine;
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('Generate Report data:', data);
      // console.log('Generate Report data JSON:', JSON.stringify(data));
    }

    try {
      const response = axios.post(process.env.REACT_APP_SAVE_REPORT_TO_BUCKET_URL, data);
    } catch (error) {
      console.log('Report Generation', error);
      createNotification('danger', 'Error generating report.');
    }
  };

  const generateReportPdfForReview = async () => {
    // Note: Do no create EHR integration data here. If it's sent to
    //       the cloud function, it will send the data to the staging
    //       bucket to be converted to HL7. We only want this to
    //       happen when the report is finalized.

    let createdDate = new Date(Number(orderDetails.createdDate));
    createdDate = dateFormat(createdDate, "mm/dd/yyyy");

    let runDate = orderDetails.specimenRunDate ? new Date(orderDetails.specimenRunDate) : new Date(parseInt(orderDetails.labUploadedDate));
    runDate = dateFormat(runDate, "mm/dd/yyyy");

    const data = {
      patientFirstName: orderDetails.patientFirstName && orderDetails.patientFirstName.trim(),
      patientLastName: orderDetails.patientLastName && orderDetails.patientLastName.trim(),
      patientId: orderDetails.patientId && orderDetails.patientId.trim().length > 0 ? orderDetails.patientId.trim() : 'Not Provided',
      patientBirthDate: `${orderDetails.patientBirthMonth}/${orderDetails.patientBirthDay}/${orderDetails.patientBirthYear}`,
      patientEmail: orderDetails.patientEmail && orderDetails.patientEmail.trim(),
      patientPhone: orderDetails.patientPhone && orderDetails.patientPhone.trim(),
      clinicCode: orderDetails.clinicCode && orderDetails.clinicCode.trim(),
      clinicName: orderDetails.clinicName && orderDetails.clinicName.trim(),
      providerName: orderDetails.providerName && orderDetails.providerName.trim(),
      providerNPI: orderDetails.providerNPI && orderDetails.providerNPI.trim(),
      sampleAcquisitionDate: createdDate,
      specimenId: orderDetails.specimenId && orderDetails.specimenId.trim(),
      specimenType: orderDetails.specimenType[0].toUpperCase() + orderDetails.specimenType.substring(1),
      analystId1: orderDetails.radeasAnalyst0,
      analystId2: orderDetails.radeasAnalyst1,
      runDate: runDate,
      medicationList: orderDetails.medicationListTags,
      medicationComparisonSummary: {
        'Consistent': medicationComparisonSummary.medicationConsistent.split(',').map((m) => m.trim()),
        'Inconsistent-NotPrescribed': medicationComparisonSummary.medicationInconsistentNotPrescribed.split(',').map((m) => m.trim()),
        'Inconsistent-RxNotFound': medicationComparisonSummary.medicationInconsistentRxNotFound.split(',').map((m) => m.trim()),
      },
      toxicologyTestResults: orderDetails.results
        .filter(({ drugName }) => (drugName.includes('All ') || drugName.includes('Urine Validation Testing') ? false : true))
        .filter(({ drugClass }) => (!drugClass.toUpperCase().includes('ALCOHOL')))
        .map(({
          drugName,
          drugClass,
          drugBrand,
          scrn,
          result,
          cutoff,
          c_i,
          flag,
        }) => ({
          drugName,
          drugClass,
          drugBrand,
          scrn,
          // result: formatResult(result, drugName, orderDetails.specimenType, 'PAIN'),
          result: result,
          cutoff,
          posNeg: drugReport.evaluate({ drugName, cutoff, value: result }).posNeg,
          cI: c_i,
          flag,
        })
      ),
    };

    // Add EtG to data if necessary
    if (etgResults.length > 0) {
      const results = orderDetails.etgResults.map(({
        drugName,
          drugClass,
          drugBrand,
          result,
          cutoff,
          posNeg,
          c_i,
          flag,
      }) => ({
        drugName,
        drugClass,
        drugBrand,
        // result: formatResult(result, drugName, orderDetails.specimenType, 'ETG'),
        result: result,
        cutoff,
        posNeg,
        cI: c_i,
        flag,
        scrn: null,
      }));

      data.toxicologyTestResults.push(...results);
    }

    // Add test strips if necessary
    if (testStripsResults.length > 0) {
      const strips = testStripsResults.map(({
        drugName,
        drugClass,
        drugBrand,
        scrn,
        cutoffs,
        c_i,
        flag,
      }) => {
        const cutoff = cutoffs.urineCustom ? cutoffs.urineCustom : cutoffs.urineDefault;

        return {
          drugName,
          drugClass,
          drugBrand,
          scrn: scrn ? '+' : '-',
          result: 'N/A',
          cutoff: `${cutoff}`,
          posNeg: scrn ? 'POS' : 'NEG',
          cI: c_i && c_i === 'I' ? 'I' : 'C',
          flag: flag && flag.length > 0 ? flag : '',
        };
      });

      data.toxicologyTestResults.push(...strips);
    }

    // Add custom tests if necessary
    if (customTestResults.length > 0) {
      const cResults = customTestResults.map(({
        drugName,
        drugClass,
        drugBrand,
        result,
        cutoff,
        posNeg,
        c_i,
        flag,
      }) => ({
        drugName,
        drugClass,
        drugBrand,
        // result: formatResult(result, drugName, orderDetails.specimenType),
        result: result,
        cutoff,
        posNeg,
        cI: c_i,
        flag,
        scrn: null,
      }));

      data.toxicologyTestResults.push(...cResults);
    }

    // Add additional flags if necessary
    if (additionalFlags.length > 0) {
      data.additionalFlags = additionalFlags;
    }

    // Add PH, specific gravity, and creatinine if urine validation test reporting is requested
    const urineValidation = orderDetails.toxicologyTests
      .filter(({ drugName }) => (drugName.includes('Urine Validation')));

    if ((urineValidation.length !== 0 && urineValidation[0].pos) || (orderDetails.urineValidationReporting)) {
      data['ph'] = orderDetails.specimenPH;
      data['sg'] = orderDetails.specimenSpecificGravity;
      data['cr'] = orderDetails.specimenCreatinine;
    }

    if (process.env.NODE_ENV === 'development') {
      console.log('Generate Report data:', data);
    }

    try {
      // Download the PDF report
      const response = await axios.post(process.env.REACT_APP_GENERATE_REPORT_PDF_URL, data);
      const contentType = response.headers['content-type'];
      download(response.data, `${orderDetails.specimenId}.pdf`, contentType);
    } catch (error) {
      console.log('Report Generation', error);
      createNotification('danger', 'Error generating report.');
    }

    setGeneratingReport(false);
  };

  if (loading) return <Loading />;

  return (
    <section className="section">
      <div className="container">
        <div className="page">
          <div
            style={{
              position: "-webkit-sticky",
              position: "sticky",
              top: 20,
              zIndex: 10
            }}
          >
            <div className="is-pulled-left">
              {savingTox ? <ButtonLoading /> : null}

              <button
                className="button is-primary"
                style={{ display: !!showSpecimenValidationModal ? "none" : "inline-block" }}
                disabled={savingTox || !!showSpecimenValidationModal}
                onClick={() => {
                  handleSubmit();
                  // history.goBack();
                }}
              >
                Submit Review
              </button>
            </div>

            <div className="is-pulled-right">
              <button
                className="button is-info ml-3"
                style={{ display: !!showSpecimenValidationModal ? "none" : "inline-block" }}
                disabled={!!showSpecimenValidationModal}
                onClick={() => {
                  setGeneratingReport(true);
                  generateReportPdfForReview();
                }}
              >
                {generatingReport ? <ButtonLoading /> : "View Report PDF"}
              </button>

              <button
                className="button is-info ml-3"
                style={{ display: !!showSpecimenValidationModal ? "none" : "inline-block" }}
                disabled={!!showSpecimenValidationModal}
                onClick={handleSavePartial}
              >
                {savingPartial ? <ButtonLoading /> : "Save Partial"}
              </button>

              <button
                className="button is-danger ml-3"
                style={{ display: !!showSpecimenValidationModal ? "none" : "inline-block" }}
                disabled={!!showSpecimenValidationModal}
                onClick={() => {
                  setOrderStatus({ variables: {
                    id: orderDetails.id,
                    newStatus: STATUS.TOXICOLOGY_ANALYST_REVIEW,
                    prevStatus: orderDetails.status,
                  }});

                  history.push(ROUTES.DASHBOARD_ANALYST_REVIEW_ORDERS, { id, status: STATUS.TOXICOLOGY_ANALYST_REVIEW });
                }}
              >
                Cancel
              </button>
            </div>

            <br /><br />
          </div>

          <h3 className="title is-3">Order Details</h3>

          <div>
            <div style={{ overflowX: "auto" }}>
              <table className="table is-fullwidth">
                <thead>
                <tr>
                  <th>Patient Name</th>
                  <th>Patient ID</th>
                  <th>Provider</th>
                  <th>Clinic</th>
                  <th>Clinic Code</th>
                  <th>Specimen ID</th>
                  <th>Specimen Type</th>
                </tr>
                </thead>

                <tbody>
                <tr>
                  <td>
                    <Link
                      to={`${ROUTES.RADEAS_PATIENT_PAGE.replace(':id', orderDetails.patientForeignKey)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {orderDetails.patientLastName}, {orderDetails.patientFirstName}
                    </Link>
                    </td>
                  <td>{orderDetails.patientId}</td>
                  <td>{orderDetails.providerName}, {orderDetails.providerSuffix}</td>
                  <td>{orderDetails.clinicName}</td>
                  <td>{orderDetails.clinicCode}</td>
                  <td>{orderDetails.specimenId}</td>
                  <td>{orderDetails.specimenType}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <hr />

            <div className="is-pulled-right">
              <button
                className="button is-info is-small"
                // data-tooltip="Set/update specimen validation values"
                disabled={savingValidation}
                onClick={() => handleShowSpecimenValidationModal({
                  specimenPH: orderDetails.specimenPH,
                  specimenSpecificGravity: orderDetails.specimenSpecificGravity,
                  specimenCreatinine: orderDetails.specimenCreatinine,
                })}
              >
                {savingValidation ? <ButtonLoading /> : "Update Validation"}
              </button>
            </div>

            <div className="columns my-3 has-text-white">
              <div className="column">
                {/* {orderDetails.specimenPH && `Specimen PH: ${orderDetails.specimenPH}`} */}
                Specimen PH: {orderDetails.specimenPH}
              </div>

              <div className="column">
                {/* {orderDetails.specimenSpecificGravity && `Specimen Specific Gravity: ${orderDetails.specimenSpecificGravity}`} */}
                Specimen Specific Gravity: {orderDetails.specimenSpecificGravity}
              </div>

              <div className="column">
                {/* {orderDetails.specimenCreatinine && `Specimen Creatinine: ${orderDetails.specimenCreatinine}`} */}
                Specimen Creatinine: {orderDetails.specimenCreatinine}
              </div>
            </div>

            <div className="my-3">
              <p className="has-text-white-ter">
                <span className="has-text-white has-text-weight-bold">
                  Clinical Purpose:{" "}
                </span>
                {orderDetails.clinicalPurpose ? orderDetails.clinicalPurpose.replace('::', ' ') : "Not specified"}
              </p>
            </div>

            {pocTests.length > 0 && (
              <div className="mt-5 mb-6">
                <p className="has-text-white has-text-weight-bold">Positive POC Tests:</p>
                <ul>
                  {pocTests.map((test, i) => (
                    <li
                      key={i}
                      className="ml-5 has-text-white-ter"
                    >
                      {test.name} ({test.code})
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="columns mt-4">
              <div className="column">
                {orderDetails.batches && orderDetails.batches.length > 0 && (
                  <>
                    <p className="has-text-white has-text-weight-bold">Test Machines:</p>
                    <table className="table is-striped">
                      <thead>
                        <tr>
                          <th>Panel</th>
                          <th>Name</th>
                          <th>Run Date</th>
                          <th>ReRun?</th>
                        </tr>
                      </thead>

                      <tbody>
                        {orderDetails.batches.map((batch) => (
                          <tr key={batch.id}>
                            <td>{batch.testPanel}</td>
                            <td>{batch.machineName}</td>
                            <td>{new Date(parseInt(batch.createdDate)).toLocaleString()}</td>
                            <td className="has-text-centered">{!!batch.isReRun ? <span className="icon"><i className="fas fa-check-circle" /></span> : null}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}

                <p className="mt-4 has-text-white has-text-weight-bold">Analyzed By: {firstAnalyst.firstName} {firstAnalyst.lastName} - {firstAnalyst.email}</p>
              </div>

              <div className="column">
                {analystInstructions.length > 0 && (
                  <div className="content">
                    <h4 className="is-size-6 title has-text-white mt-2">
                      Clinic-specific Instructions for Analysts:
                    </h4>

                    <ul>
                      {analystInstructions.split(/\r?\n/).map((line) => {
                        if (line.length > 0) {
                          return <li key={line} className="has-text-white">{line}</li>;
                        }

                        return null;
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {!showMedicationSection && (
              <div
                className="has-text-white is-clickable"
                onClick={() => setShowMedicationSection(!showMedicationSection)}
              >
                <span className="icon">
                  <i className="fas fa-angle-double-down" />
                </span>
                Show Medications Section
                <span className="icon">
                  <i className="fas fa-angle-double-down" />
                </span>
              </div>
            )}

            {showMedicationSection && (
              <>
                <div className="my-5">
                  <p className="has-text-white"><span className="has-text-weight-bold ">Medication List</span> {generateMedicationList()}</p>
                </div>

                <div className="my-5">
                  <div className="columns">
                    <div className="column is-one-third">
                      <div className="field">
                        <label className="label" htmlFor="input-medicationInconsistentNotPrescribed">
                          Inconsistent - Not Prescribed
                        </label>
                        <textarea
                          id="input-medicationInconsistentNotPrescribed"
                          name="medicationInconsistentNotPrescribed"
                          className="textarea"
                          placeholder=""
                          value={medicationComparisonSummary.medicationInconsistentNotPrescribed}
                          onChange={handleMedicationComparisonSummary}
                        />
                      </div>
                    </div>

                    <div className="column is-one-third">
                      <div className="field">
                        <label className="label" htmlFor="input-medicationInconsistentRxNotFound">
                          Inconsistent - RX Not Found
                        </label>
                        <textarea
                          id="input-medicationInconsistentRxNotFound"
                          name="medicationInconsistentRxNotFound"
                          className="textarea"
                          placeholder=""
                          value={medicationComparisonSummary.medicationInconsistentRxNotFound}
                          onChange={handleMedicationComparisonSummary}
                        />
                      </div>
                    </div>

                    <div className="column is-one-third">
                      <div className="field">
                        <label className="label" htmlFor="input-medicationConsistent">
                          Consistent
                        </label>
                        <textarea
                          id="input-medicationConsistent"
                          name="medicationConsistent"
                          className="textarea"
                          placeholder=""
                          value={medicationComparisonSummary.medicationConsistent}
                          onChange={handleMedicationComparisonSummary}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <hr />

          <h4 className="title is-4">Toxicology</h4>

          <div className="my-5">
            <div className="columns">
              <div className="column is-one-third">
                <div className="field is-grouped">
                  <div className="control is-expanded">
                    <input
                      ref={filterRef}
                      name="filter"
                      type="text"
                      className="input"
                      placeholder="Filter by drug name"
                      value={filter}
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  </div>
                  <div className="control">
                    <button
                      className="button is-info"
                      onClick={() => setFilter('')}
                      disabled={filter === '' ? true : false}
                    >
                    <span className="icon is-small mr-2">
                      <i className="fas fa-times-circle" />
                    </span>
                      Clear
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ overflowX: "auto" }}>
            <table className="table is-fullwidth is-striped is-hoverable">
              <thead>
              <tr>
                <th>Drug Name</th>
                <th>Drug Brand</th>
                <th>Scrn</th>
                <th>Cutoff (ng/ml)</th>
                <th>POS/NEG</th>
                <th>C/I</th>
                <th>Result</th>
                <th>Flag</th>
              </tr>
              </thead>

              <tbody>
              {generateTable()}
              </tbody>
            </table>
          </div>

          {generateEtg()}
          {generateTestStrips()}
          {generateCustomTests()}

          <br /><br />
          <hr />

          <div className="my-5">
            <div className="field">
              <label className="label" htmlFor="input-notes0">
                Analyst 1 Notes
              </label>
              <textarea
                id="input-notes0"
                name="notes0"
                className="textarea"
                placeholder="(none)"
                value={orderDetails.radeasAnalyst0Notes}
                disabled
                style={{ cursor: "default" }}
              />
            </div>

            <div className="field">
              <label className="label" htmlFor="input-notes1">
                Analyst 2 Notes
              </label>
              <textarea
                id="input-notes1"
                name="notes"
                className="textarea"
                placeholder="(optional)"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </div>
          </div>

          <hr />

          <div className="my-5">
            <h4 className="title is-4">Additional Flags</h4>

            <div className="columns">
              <div className="column is-full">
                {additionalFlags.map((row, i) => (
                  <div key={i} className="field has-addons">
                    <div className="control is-expanded">
                      <input
                        name="additional-flag"
                        className="input is-fullwidth"
                        type="text"
                        value={additionalFlags[i]}
                        onChange={(e) => handleAdditionalFlags(e, i)}
                      />
                    </div>

                    <div className="control">
                      <button
                        className="button is-danger"
                        onClick={() => handleDeleteAdditionalFlag(i)}
                      >
                        <span className="icon">
                          <i className="fas fa-trash-alt" />
                        </span>
                      </button>
                    </div>
                  </div>
                ))}

                <button
                  className="button is-info my-4"
                  onClick={() => {
                    setAdditionalFlags([
                      ...additionalFlags,
                      "",
                    ]);
                  }}
                >
                  Add Flag
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <UpdateSpecimenValidationResultsModal
        isOpen={!!showSpecimenValidationModal}
        handleCloseModal={handleCloseSpecimenValidationModal}
        handleSave={handleUpdateSpecimenValidationResults}
        specimenPH={showSpecimenValidationModal?.specimenPH}
        specimenSpecificGravity={showSpecimenValidationModal?.specimenSpecificGravity}
        specimenCreatinine={showSpecimenValidationModal?.specimenCreatinine}
      />
    </section>
  );
};

export default ReviewToxicologyResults;
