import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import DrugTestMenuTable from './DrugTestMenuTable';

import Loading from '../Loading';
import { useAuth } from '../auth';
import { createNotification } from '../Notification';

export const GET_TOX_TEST_DEFAULTS = gql`
  query GetToxicologyTestDefaults {
    getToxicologyTestDefaults {
      drugClass
      drugName
      scrnAvailable
      drugBrand
      cutoffs {
        urineDefault
        oralFluidDefault
        urineCustom
        oralFluidCustom
      }
      parents
    }
  }
`;

const DrugTestMenu = () => {
  const [drugTests, setDrugTests] = useState([]);
  const [initializaing, setInitializing] = useState(true);

  // Require auth
  useAuth({ messageType: 'info', messageText: 'Please log in' });

  // Run query
  useQuery(
    GET_TOX_TEST_DEFAULTS,
    {
      onCompleted({ getToxicologyTestDefaults: data }) {
        if (process.env.NODE_ENV === 'development') {
          console.log('Query getToxicologyTestDefaults');
          console.log({ data });
        }

        setDrugTests(data);
        setInitializing(false);
      },
      onError(error) {
        console.log('GetToxicologyTestDefaults query error:', error);
        createNotification('danger', 'Sorry, could not load drug test info.');

        setInitializing(false);
      }
    },
  );

  if (initializaing) return <Loading />;

  return (
    <section className="section">
      <div className="container">
        <div className="page">
          <h1 className="is-size-2 title mt-1">Test Menu</h1>

          <p className="mb-5">Note: These are the default values for all Clinics.</p>

          <DrugTestMenuTable tableData={drugTests} />
        </div>
      </div>
    </section>
  );
};

export default DrugTestMenu;
