import React, { useEffect, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { ButtonLoading } from '../Loading';

import Notification, {clearNotification, createNotification, useNotification} from '../Notification';
import * as ROUTES from '../../constants/routes';

import '../../styles/form.css';

// const SEND_EMAIL_URL = 'https://us-central1-radeaslis-dev.cloudfunctions.net/password-reset';

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $email: String!
  ) {
    sendResetPasswordEmail(
      email: $email,
      type: "RADEAS"
    )
  }
`;

const ForgotPassword = () => {
  useEffect(() => {
    // Clear notifications when navigating away
    return function cleanup() {
      clearNotification();
    }
  }, []);

  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="column is-half">
            <ResetPasswordForm />
          </div>
        </div>
      </div>
    </section>
  );
};

const ResetPasswordForm = () => {
  const [userEmail, setUserEmail] = useState('');
  const { messageType, messageText } = useNotification();
  const [resetPassword, { loading }] = useMutation(
    RESET_PASSWORD,
    {
      onCompleted({ sendResetPasswordEmail }) {
        let url = `${process.env.REACT_APP_RESET_PASSWORD_BASE_URL}/${sendResetPasswordEmail}`;

        if (process.env.NODE_ENV === 'development') {
          url = `http://localhost:3000/reset-password/${sendResetPasswordEmail}`;
        }

        axios.post(process.env.REACT_APP_SEND_EMAIL_URL, { url, email: userEmail })
          .then((res) => {
            createNotification('info', 'Please check your email to reset your password.');
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onError(error) {
        console.log('Reset password error:', error);
        createNotification('danger', 'Sorry, something went wrong. Please try again.');
      },
    },
  );

  return (
    <div>
      <Notification messageType={messageType} messageText={messageText} />
      <h1 className="title is-size-2 has-text-centered">Radeas Labs</h1>
      <h4 className="subtitle is-size-6 has-text-centered is">LAB INFORMATION SYSTEM</h4>
      <br/><br/>
      <div className="page">
        <h3 className="title is-4 has-text-centered">Reset Password</h3>
        <Formik
          initialValues={{
            email: '',
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const { email } = values;

            setUserEmail(email);

            // Execute the mutation
            resetPassword({ variables: { email: email && email.toLowerCase() } });

            // resetForm();
            setSubmitting(false);
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Enter a valid email.")
              .required("Email is required.")
          })}
        >
          {(formProps) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = formProps;

            return (
              <form onSubmit={handleSubmit}>
                <label className="label" htmlFor="input-email">
                  Email
                </label>
                <input
                  id="input-email"
                  name="email"
                  className={errors.email && touched.email ? "input error" : "input"}
                  type="email"
                  placeholder="Enter your email address"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {(errors.email && touched.email ?
                  <div className="input-feedback help has-text-right" data-testid="errors-email">
                    {errors.email}
                  </div>:
                  <div className="input-feedback help feedback-hidden" data-testid="errors-accessCode">
                  </div>
                )}

                <input
                  type="submit"
                  className="button is-primary"
                  value="Submit"
                  disabled={isSubmitting}
                />
                {loading ? <ButtonLoading /> : null}
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );};

const ForgotPasswordLink = () => {
  return (
    <p className="ml-5 mb-3">
      <Link to={ROUTES.FORGOT_PASSWORD}>Forgot Password?</Link>
    </p>
  );
};

export default ForgotPassword;

export { ForgotPasswordLink };
